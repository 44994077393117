// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Translations
import {withTranslation} from "react-i18next";
// Components
import {Button} from "@components/Input";
import {AlertInfo} from "@components/Alert";
// Assets
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";
// Vendor
import {isSandbox} from "vendor/application";
import {showNotify} from "vendor/application/disptach";
// API
import {IntegrationsAPI} from "api/application/Integrations";

class View extends Component {
    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations/directory`);
    }

    contractChannel = (channel) => {
        const {limitsIntegrations} = this.props.data;
        if (!limitsIntegrations[channel].in_plan) {
            this.props.dispatch(showNotify({type: "link", severity: "info"}));
        } else if (!limitsIntegrations[channel].available) {
            this.props.dispatch(showNotify({message: "channel_not_available", severity: "error"}));
        } else {
            this.contract();
        }
    }

    contract = async () => {
        const {access, actualProject, user} = this.props.data;
        var body = {
            channel: 'whatsapp',
            origin: 'integrations'
        }

        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props, body);
            await obj.interest_integrations(user.account_id);

            this.props.dispatch(showNotify({message: "integration_whatsapp", type: "contract", severity: "success"}));
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    render() {
        const {t} = this.props;

        const is_sandbox = isSandbox(this.props.data, "whatsapp");
        return (
            <div className={"cen_integrations"}>
                {
                    is_sandbox &&
                    <AlertInfo text={t(`view.sandbox_info`)}/>
                }
                <div className={"header"}>
                    <div className={"header_content"}>
                        <div onClick={() => this.return()} className={"header_action"}>
                            {arrowRightSvg()}
                        </div>
                        <div className={"header_image"}>
                            {channelsSvg("whatsapp")}
                        </div>
                        <div className={"header_title"}>
                            <span className={"title"}>{t('channels.whatsapp.title')}</span>
                            <span className={"description"}>{t('categories.business')}</span>
                        </div>
                    </div>
                    <div className={"header_actions"}>
                        {
                            !is_sandbox &&
                            <Button type={"primary"} onClick={() => {
                                this.contractChannel("whatsapp")
                            }} text={t('view.contract_button')}/>
                        }
                    </div>
                </div>
                <div className={"content"}>
                    <div className={"content_view"}>
                        <span>{t(`channels.whatsapp.description`)}</span>
                        <span
                            dangerouslySetInnerHTML={{__html: t(`channels.whatsapp.info`).replaceAll('[br]', "<br /><br />")}}/>
                        <div className={"content_view_images"}>
                            <img src={`${process.env.PUBLIC_URL}/img/views/integrations/whatsapp/view_01.png`} alt=""/>
                            <img src={`${process.env.PUBLIC_URL}/img/views/integrations/whatsapp/view_02.png`} alt=""/>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_view = connect(
    mapStateToProps
)(View);

export default withTranslation('integrations')(withRouter(connect_view));