// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Styles
import 'date-fns';
// Infrastructure
import {StatsAPI} from 'views/Bots/Stats/infrastructure';
// Translations
import {withTranslation} from "react-i18next";
import enLocale from "date-fns/locale/en-US";
import esLocale from 'date-fns/locale/es';
import ptLocale from 'date-fns/locale/pt';
// Vendor
import {showNotify} from 'vendor/application/disptach';
// Components
import Table from "components/Table";
import {Button, Input} from "@components/Input";
import {AlertInfo} from "@components/Alert";
import ViewCampaign from "views/Bots/Stats/application/StatsProject/SuncoCampaigns/viewCampaign";
// Material
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// Svg
import {deploySvg, svgCalendar, svgClock} from 'assets';

const localeMap = {
    en: enLocale,
    es: esLocale,
    pt: ptLocale
};


class StatsSuncoCampaigns extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            dateRange: {},
            start: false,
            end: false,
            objDateRange: {
                startDate: new Date(date.setMonth(date.getMonth() - 1)),
                endDate: new Date(date.setMonth(date.getMonth() + 1))
            },
            elements: false,
            campaign_name: '',
            campaign_selected: false,
            page: 1,
            max: 1,
            is_loading: false
        }
    }

    componentDidMount = () => {
        this.getStats();
    }

    // method that reloads the component when the current project has been changed
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.actualProject.id !== this.props.data.actualProject.id) {
            setTimeout(() => {
                this.getStats();
            }, 100);
        }
    }

    getStats = async (page) => {
        const {access, actualProject} = this.props.data;
        const {campaign_name} = this.state;
        try {
            const obj = new StatsAPI(actualProject.id, access, this.props, null);
            const res = await obj.get_stats_sunco_notifications_campaigns(this.getStartDate(), this.getEndDate(), campaign_name, page);

            var operation = res?.count / 10,
                is_decimal = (operation - Math.floor(operation)) !== 0,
                sum = is_decimal ? 1 : 0;

            this.setState({
                elements: res?.campaigns,
                is_loading: false,
                page: page ?? 1,
                max: Math.floor(operation) + sum
            })
        } catch (err) {
            this.setState({is_loading: false})
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    getDetails = async (show, i, page) => {
        const {access, actualProject} = this.props.data;
        const {elements} = this.state;
        if (show) {
            try {
                const campaign = elements[i];
                const obj = new StatsAPI(actualProject.id, access, this.props, null);
                const res = await obj.get_stats_sunco_campaigns(this.getStartDate(), this.getEndDate(), campaign.campaign_name, campaign.template_name, campaign.language, page);

                var operation = res?.count / 10,
                    is_decimal = (operation - Math.floor(operation)) !== 0,
                    sum = is_decimal ? 1 : 0;

                this.setState({
                    campaign_selected: {
                        iteration: i,
                        timestamp: +new Date(),
                        detail: {
                            ...res
                        },
                        ...elements[i],
                        max: Math.floor(operation) + sum
                    }
                })

            } catch (err) {
                this.props.dispatch(showNotify({message: err, severity: 'error'}));
            }
        } else {
            this.setState({campaign_selected: false})
        }
    }

    handleSelectStart = (start) => {
        var end = this.state.objDateRange.endDate;
        if (start > end) {
            end = start;
        }
        this.setState({
            objDateRange: {
                startDate: start,
                endDate: end
            }
        })
    }

    handleSelectEnd = (end) => {
        var start = this.state.objDateRange.startDate;
        if (end < start) {
            start = end;
        }
        this.setState({
            objDateRange: {
                startDate: start,
                endDate: end
            }
        })
    }

    getStartDate = (visibility) => {
        const {objDateRange} = this.state;
        var m = objDateRange.startDate.getMonth(),
            y = objDateRange.startDate.getFullYear(),
            d = objDateRange.startDate.getDate();

        if (visibility) {
            return `${d} / ${m + 1} / ${y}`;
        }
        return `${y}-${m + 1}-${d}`;
    }

    getEndDate = (visibility) => {
        const {objDateRange} = this.state;
        var m = objDateRange.endDate.getMonth(),
            y = objDateRange.endDate.getFullYear(),
            d = objDateRange.endDate.getDate();

        if (visibility) {
            return `${d} / ${m + 1} / ${y}`;
        }
        return `${y}-${m + 1}-${d}`;
    }

    openStart = () => {
        this.setState({start: !this.state.start});
    }

    openEnd = () => {
        this.setState({end: !this.state.end});
    }

    newSearch = () => {
        this.setState({is_loading: true});
        this.getStats();
    }

    handlePagination = (e, next_page) => {
        this.getStats(next_page);
    }

    render() {
        const {t} = this.props;
        const {
            objDateRange,
            start,
            end,
            elements,
            campaign_name,
            campaign_selected,
            is_loading,
            page,
            max
        } = this.state;
        return (
            <div className="stats-container">
                {
                    !campaign_selected &&
                    <div className="stats-header sunco_campaigns">
                        <div className="stats-date-container">
                            <div className="stats-date">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}
                                                         locale={localeMap[this.props.i18n.language]}>
                                    <div className="start-date" onClick={() => this.openStart()}>
                                        <div>
                                            <span>{svgCalendar()}</span>
                                            <span>{t('stats.start')}</span>
                                            <span>{deploySvg(false)}</span>
                                        </div>
                                        <div>
                                            <span>{svgClock()}</span>
                                            <span>{this.getStartDate(true)}</span>
                                        </div>
                                    </div>
                                    <DatePicker
                                        value={objDateRange.startDate}
                                        onChange={this.handleSelectStart}
                                        animateYearScrolling
                                        autoOk
                                        onClose={this.openStart}
                                        open={start}
                                    />
                                    <div className="end-date" onClick={() => this.openEnd()}>
                                        <div>
                                            <span>{svgCalendar()}</span>
                                            <span>{t('stats.end')}</span>
                                            <span>{deploySvg(false)}</span>
                                        </div>
                                        <div>
                                            <span>{svgClock()}</span>
                                            <span>{this.getEndDate(true)}</span>
                                        </div>
                                    </div>
                                    <DatePicker
                                        value={objDateRange.endDate}
                                        onChange={this.handleSelectEnd}
                                        animateYearScrolling
                                        autoOk
                                        onClose={this.openEnd}
                                        open={end}
                                    />
                                </MuiPickersUtilsProvider>

                            </div>
                            <Input
                                placeholder={t('stats.sunco_campaigns.placeholder')}
                                onChange={(e) => this.setState({campaign_name: e?.target?.value})}
                                value={campaign_name}
                            />
                        </div>
                        <div className={"actions"}>
                            <Button type={"primary"} onClick={() => {
                                this.newSearch()
                            }} text={t('stats.search_action_button')}/>
                        </div>
                    </div>
                }
                {
                    !elements ?
                        <AlertInfo className={"stats-alert"} text={t('stats.sunco_notifications.info')}/> :
                        elements.length === 0 && !is_loading ?
                            <AlertInfo className={"stats-alert"} text={t("stats.sunco_campaigns.info")}/> :
                            <div className={"stats-card"}>
                                <Table items={elements}
                                       onClickAction={this.getDetails}
                                       type={"sunco_campaigns"}
                                       is_loading={is_loading}
                                       handlePagination={this.handlePagination}
                                       max={max} page={page}
                                       header={['campaign_name', 'template_name', 'language']}>
                                    {campaign_selected ?
                                        <ViewCampaign getDetails={this.getDetails} startDate={this.getStartDate(true)}
                                                      endDate={this.getEndDate(true)}
                                                      campaign={JSON.parse(JSON.stringify(campaign_selected))}
                                        /> : ''}
                                </Table>
                            </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_stats = connect(
    mapStateToProps
)(StatsSuncoCampaigns);

export default withTranslation('common')(withRouter(connect_stats));