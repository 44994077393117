// React
import React, { Component } from 'react';
// Components
import {Button} from '@components/Input';

class CardEmpty extends Component {

    redirect = () => {
        const { history, type } = this.props;
        var status;
        switch (type) {
            case 'add': case 'project': status = 'create';
        }
        history.push(`${history.location.pathname}/${status}`)
    }

    render() {
        const { t, handleClickOpen, handleClickLink, type} = this.props;
        return (
            <div className="card flex empty">
                <div>
                    <h3>{t(`card.empty.${type}.title`)}</h3>
                    <p className="margin_0">{t(`card.empty.${type}.description`)}</p>
                </div>
                {
                    handleClickLink &&
                    <Button type={"primary"} text={t(`card.empty.${type}.button_create`)} onClick={() => handleClickLink()}/>
                }
                {   handleClickOpen &&
                    <Button type={"primary"} text={t(`card.empty.${type}.button_create`)} onClick={() => handleClickOpen()}/>
                }
            </div>
        );
    }
}

export default CardEmpty;