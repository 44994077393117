// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {Route, Switch, withRouter} from "react-router-dom";
// Infrastructure
// Components
import {Step, StepLabel, Stepper} from "@material-ui/core";
// Helpers
import {isAccessFlow} from "@helpers";
// Translations
import {withTranslation} from "react-i18next";
// Svg
import {arrowLeftSvg} from "@assets";
// Styles
import 'views/Bots/NLP/styles/index.scss';
import Build from "./Build";
import Flow from "views/Bots/NLP/application/Build/Flow/application";
import Share from "./Share";
import ProjectWebInterface from "./Design";
import {updateFilters} from "vendor/application/disptach";
// Amplitude API
import {AmplitudeAPI} from "@api/Amplitude";

const StepConnector = () => {
    return arrowLeftSvg()
}

class NLP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            project: false
        }
    }

    componentDidMount = () => {
        const {match, history, project} = this.props;
        var step;
        switch (match.params.action) {
            case 'design':
                step = 1;
                break;
            case 'share':
                step = 2;
                break;
            case 'build':
                step = 0;
                break;
            default:
                history.push(`/${project}/nlp/build`)
                step = 0;
                break;
        }

        this.setState({activeStep: step});
        this.activeProject(this.props.data.actualProject);
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        const {actualProject, filters} = this.props.data,
            nextActualProject = nextProps.data.actualProject;
        if (nextActualProject.id !== actualProject.id) {
            filters.intents.pagination.page = 1;
            filters.intents.search = '';
            filters.intents.context_selector.id = '';
            filters.intents.context_selector.name = '';
            this.props.dispatch(updateFilters(filters));
        }
    }

    activeProject = (project) => {
        const {history} = this.props;
        if (!project.active) {
            history.push(`/${project.id}/stats`);
        }
    }

    handleStep = (step) => {
        const {history} = this.props;
        const {user, device_id, actualProject} = this.props.data;
        var steps = ['build', 'design', 'share'];
        if (this.state.activeStep !== step) {
            new AmplitudeAPI({
                event_type: `Click on ${steps[step]}`,
                device_id: device_id,
                user_id: user.email
            }).eventLog();

            this.setState({activeStep: step});
            history.push(`/${actualProject?.id}/nlp/${steps[step]}`);
        }
    }

    render() {
        const {actualProject, plan} = this.props.data;
        const {getLimits, t} = this.props;
        const {activeStep} = this.state;
        return (
            actualProject &&
            <React.Fragment>
                <div className={`config_header normal`}>
                    <span/>
                    {
                        plan.plan.name !== 'consulting_sunco' &&
                        <div className={"config_header__steps"}>
                            <Stepper
                                connector={
                                    <StepConnector/>
                                }
                                activeStep={activeStep}
                            >
                                <Step onClick={() => this.handleStep(0)}>
                                    <StepLabel>{t(`card.content.project.steps.build`)}</StepLabel>
                                </Step>
                                <Step onClick={() => this.handleStep(1)}>
                                    <StepLabel>{t(`card.content.project.steps.design`)}</StepLabel>
                                </Step>
                                <Step onClick={() => this.handleStep(2)}>
                                    <StepLabel>{t(`card.content.project.steps.share`)}</StepLabel>
                                </Step>
                            </Stepper>
                        </div>
                    }
                    <div/>
                </div>
                <div className={`config_content ${isAccessFlow(actualProject.id) ? "config_content_flow" : ""}`}>
                    <Switch>
                        <Route path="/:project/nlp/build" exact>
                            {
                                isAccessFlow(actualProject.id) ?
                                    <Flow/> :
                                    <Build getLimits={getLimits} project={actualProject.id}
                                           actualProject={actualProject}/>
                            }
                        </Route>
                        <Route path="/:project/nlp/design" exact>
                            <ProjectWebInterface project_id={actualProject.id} chat_id={actualProject.chat_id}/>
                        </Route>
                        <Route path="/:project/nlp/share" exact>
                            <Share project_id={actualProject.id} chat_id={actualProject.chat_id}/>
                        </Route>
                    </Switch>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_nlp = connect(
    mapStateToProps
)(NLP);

export default withTranslation('common')(withRouter(connect_nlp));