// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Routing
import { withRouter } from 'react-router-dom';
// Translations
import { withTranslation } from "react-i18next";

class LoginInfo extends Component {

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <div className="platform_landing__info">
                    <div className="platform_landing__info__header">
                        <b>{t('landing.info.title')}</b>
                        <b>{t('landing.info.description')}</b>
                    </div>
                    <div className="platform_landing__info__content">
                        <ul>
                            <li><span dangerouslySetInnerHTML={{__html: t('landing.info.li_01') }} /></li>
                            <li><span dangerouslySetInnerHTML={{__html: t('landing.info.li_02') }} /></li>
                            <li><span dangerouslySetInnerHTML={{__html: t('landing.info.li_03') }} /></li>
                            <li><span dangerouslySetInnerHTML={{__html: t('landing.info.li_04') }} /></li>
                            <li><span dangerouslySetInnerHTML={{__html: t('landing.info.li_05') }} /></li>
                            <li><span dangerouslySetInnerHTML={{__html: t('landing.info.li_06') }} /></li>
                        </ul>
                        <b>{t('landing.info.footer')}</b>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const login = connect(
    mapStateToProps
)(LoginInfo);

export default withTranslation('common')(withRouter(login));