// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Components
import {Header} from '@components/Header';
import {MenuSidebar} from '@components/Sidebars';
// Views
import RouteApp from 'routes/application/route_app';
// Infrastructure
import {ProjectAPI} from 'api/application/Projects';
import {UsersAPI} from 'api/application/Users';
import {IntegrationsAPI} from 'api/application/Integrations';
import {ChargebeeAPI} from 'api/application/Chargebee';
// Material
import {CircularProgress} from '@material-ui/core';
// Vendor
import {
    resetState,
    showNotify,
    updateActualProject,
    updateEmpty,
    updateInitApp,
    updateLayouts,
    updateLimits,
    updateLimitsIntegrations,
    updateAccountIntegrations,
    updatePlan,
    updateProjects,
    updateRoles,
    updateUser
} from 'vendor/application/disptach';
// Application
import {
    addParamsProjectValue,
    returnAdministradorRole,
    returnCampaignsSidebar,
    returnEntrenadorRole,
    returnSuperAdminRole,
    setCookieByName
} from 'vendor/application';
import {handleActualTitle, isMaintenance, returnElementFromArray} from '@helpers';
// Translations
import {withTranslation} from "react-i18next";
// Jquery
import $ from 'jquery';

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    componentDidMount = () => {
        handleActualTitle(this.props.t, 'centribot');
        this.getAllProjects();
    }

    // method that reloads the component when the current project has been changed
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (this.props.data.actualProject && nextProps.data.actualProject && nextProps.data.actualProject.id && nextProps.data.actualProject.id !== this.props.data.actualProject.id) {
            setTimeout(() => {
                this.getLimits();
            }, 100);
        }
    }

    // GET projects form userID, if no actualProject ID exist on app we'll set the first project obtained from request
    // Then we'll call de getAllRoles
    // if the projects obtained are larger than 0 we call the function to obtain the contexts
    getAllProjects = async () => {
        const {access, actualProject} = this.props.data;
        if (access.isAccess && access.userId) {
            try {
                let obj = new UsersAPI(access.userId, access, this.props);
                var projects = await obj.get_projects();
                var paramsProject = addParamsProjectValue(projects);
                this.props.dispatch(updateProjects(paramsProject));
                var setProject;

                if (!actualProject.id) {
                    if (paramsProject.length > 0) {
                        paramsProject.forEach((project) => {
                            if (!project.sandbox && !setProject && project.active) {
                                setProject = project;
                            }
                        })
                    }
                    this.props.dispatch(updateActualProject(setProject ? setProject : {}));
                } else {
                    setProject = paramsProject.filter(project => project.id === actualProject.id)[0];
                    this.props.dispatch(updateActualProject(setProject ? setProject : {}));
                }
                this.getRoles();
            } catch (err) {
                this.props.dispatch(showNotify({message: err, severity: 'error'}));
            }
        } else {
            this.handleRedirect();
            this.props.dispatch(resetState())
        }
    }

    getRoles = async () => {
        const {access} = this.props.data;
        try {
            let obj = new UsersAPI(access.userId, access, this.props);
            let roles = await obj.get_roles();

            this.props.dispatch(updateRoles(roles));

            this.getUser();
        } catch (err) {
        }
    }

    getUser = async () => {
        const {access, roles} = this.props.data;
        const {history} = this.props;
        try {
            let obj = new UsersAPI(access.userId, access, this.props);
            let user = await obj.get_user_info();

            user.role = returnElementFromArray(user.roles[0], roles, 'id');

            let chargebee_api = new ChargebeeAPI(access.userId, access, this.props);
            let chargebee_customer = await chargebee_api.get_user_chargebee_id()

            user.chargebee_customer_id = chargebee_customer.chargebee_customer_id
            user.chargebee_customer_site = chargebee_customer.site

            if (user.lang) {
                this.props.i18n.changeLanguage(user.lang);
                setCookieByName(user.lang, 'b__lg');
            }

            this.props.dispatch(updateUser(user));

            // If the user role is Supervisor or Agent, they will be redirected to Centridesk if not have access to campaigns
            switch (user.role.name) {
                case 'Agent':
                case 'Supervisor':
                    const access = user?.campaigns?.access;
                    var access_to_campaigns = 0;
                    ['templates', 'groups', 'individual'].forEach((el) => {
                        if (access && access[el]) {
                            access_to_campaigns++;
                        }
                    });

                    ['templates', 'groups', 'individual'].forEach((el => {
                        var count = 0,
                            element = el === 'individual' ? 'webhook' : el;

                        if (!history.location.pathname.includes("campaigns")) {
                            if (access && access[el]) {
                                history.push(`/campaigns/${element}`);
                            } else {
                                window.location.href = `${process.env.REACT_APP_URL_CENTRIDESK}`;
                            }
                        } else {
                            if (access && access[el] && !history.location.pathname.includes(element)) {
                                count++;
                                if (count === access_to_campaigns) {
                                    history.push(`/campaigns/${element}`)
                                }
                            }
                        }
                    }))
                    break;
            }

            this.getPlan(user);
        } catch (err) {
            this.props.dispatch(updateInitApp(true));
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    getPlan = async (user) => {
        const {access, actualProject, projects} = this.props.data;

        try {
            const obj = new UsersAPI(access.userId, access, this.props, user);
            let res = await obj.get_account_by_id(user.account_id);

            res?.whatsapp_phones?.forEach(((phone, i) => {
                var project = returnElementFromArray(phone.project_id, projects, 'id');
                if (project) {
                    res.whatsapp_phones[i].project_name = project.name;
                }
            }))

            let chargebee_api = new ChargebeeAPI(access.userId, access, this.props);
            let chargebee_subscriptions = await chargebee_api.get_chargebee_user_subscriptions();

            res.chargebee_plans = chargebee_subscriptions;

            let chargebee_subcription_not_dummy = chargebee_subscriptions.filter(sub => !sub.is_dummy)[0];
            res.expiration_date = chargebee_subcription_not_dummy && chargebee_subcription_not_dummy.chargebee_subscription_status === 'in_trial' ?
                chargebee_subcription_not_dummy.trial_end : res.expiration_date;

            if (!actualProject?.id && projects.length > 0) {
                this.props.dispatch(updateActualProject(projects[0]))
            }
            this.props.dispatch(updatePlan(res));

            this.handleLayouts();

            if (actualProject && actualProject.id) {
                this.getActions(actualProject.id);
                this.getLimits(res.plan.id);
            } else {
                this.handleRedirect();
                this.props.dispatch(updateLimitsIntegrations({}))
            }
        } catch (err) {
        }
    }

    getLimits = async (planID) => {
        const {access, actualProject, limits} = this.props.data;
        try {
            let obj = new ProjectAPI(actualProject.id, access, this.props);
            var new_limits = await obj.get_project_limits();
            if (limits && limits.intents_create) {
                new_limits.plan.limits.intents_create = limits.intents_create;
            }
            this.props.dispatch(updateLimits(new_limits.plan.limits));
            this.getLimitsIntents(planID, new_limits.plan.integrations);
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getLimitsIntents = async (planID, integrations) => {
        const {access, actualProject, plan, limits} = this.props.data;
        var limitsCreate = limits;
        if (planID || plan && plan.plan && plan.plan.id) {
            try {
                let obj = new ProjectAPI(actualProject.id, access, this.props);
                let limitsIntents = await obj.get_plans(planID ? planID : plan.plan.id);

                limitsCreate.intents_create = limitsIntents.limits;
                limitsCreate.intents_create.intent_phrases_total = 0;
                limitsCreate.intents_create.intent_responses_total = 0;

                this.props.dispatch(updateLimits(limitsCreate));

                this.getIntegrations(integrations);
            } catch (err) {
                this.props.dispatch(showNotify({message: err, severity: 'error'}))
            }
        }
    }

    getIntegrations = async (new_limits) => {
        const {access, actualProject, user} = this.props.data;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            const integrations = await obj.get_integrations();

            const obj_account = new IntegrationsAPI(user.account_id, access, this.props, user);
            const account_integrations = await obj_account.get_integrations_by_account();
            integrations.forEach(integration => {
                if (integration.channel === 'zendesk_full') {
                    new_limits.zendesk_full.existIntegration = true;
                } else if (integration.channel === 'centridesk') {
                    new_limits.centridesk.existIntegration = true;
                } else if (integration.channel === 'zendesk_chat') {
                    new_limits.zendesk_chat.existIntegration = true;
                } else if (integration.channel === 'sunco') {
                    new_limits.sunco.existIntegration = true;
                }
            });

            this.props.dispatch(updateLimitsIntegrations(new_limits));
            this.props.dispatch(updateAccountIntegrations(account_integrations));
            this.handleRedirect();
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    // With the obtained roles we obtain that layouts can be accessed by the user
    handleLayouts = () => {
        const {user} = this.props.data;
        var array = [];
        switch (user.role.name) {
            case 'Trainer':
                array = returnEntrenadorRole()
                break;
            case 'Administrator':
                array = returnAdministradorRole()
                break;
            case 'SuperAdmin':
                array = returnSuperAdminRole()
                break;
        }

        this.props.dispatch(updateLayouts([...array, ...returnCampaignsSidebar()]));
    }

    // Method that redirects the application to the correct path
    handleRedirect = () => {
        const {history} = this.props;
        const {actualProject, projects, plan} = this.props.data;

        // If the plan has expired we'll redirect to admin platform
        // If plan is sunco and not have sunco access
        if (plan?.to_delete || (plan?.plan?.name === "consulting_sunco" && !plan?.plan?.integrations?.sunco)) {
            window.location.href = `${process.env.REACT_APP_URL_CENTRIMANAGER}`;
        }
        // If there are no projects and the path is not {/project/create} the path will be simply {/project}
        else if (!projects.length > 0 && history.location.pathname !== '/project/create') {
            history.push(`/dashboard`);
        }
        // if the path is the root and there are projects
        // we check if the user has access to the project layout
        else if (history.location.pathname === '/' && projects.length > 0) {
            history.push(`/${actualProject.id}/chatbot`);
        }
        else {
            // Else we get the project id on the path
            var id = history.location.pathname.split('/')[1];
            var actual = returnElementFromArray(id, projects, 'id');
            // if the id we have obtained NOT EXISTS on the projects array
            if (!actual) {
                // if there is we don't have actualProject selected and we have projects we'll update the actualProject state
                if (!actualProject && projects.length > 0) {
                    this.props.dispatch(updateActualProject(projects[0]));
                } else if (history.location.pathname === '/') {
                    history.push('/landing')
                }
            }
            // if the id we have obtained EXISTS on the projects array
            else {
                // if the project id obtained is equal to the current path we redirect to {id/chatbot}
                if (`/${id}` === history.location.pathname && actual.active) {
                    history.push(`/${actual.id}/chatbot`);
                } else if (!actual.active) {
                    history.push(`/${actual.id}/stats`);
                }
            }
        }

        this.props.dispatch(updateInitApp(true));
    }

    // Method consumed in the Sidebar component
    // {el} params : {name: "ProjectName", id: "ProjectID", text: "TextString"}
    setActualProject = (el) => {
        const {history} = this.props;

        var new_project = el.id;

        if (!el.active) {
            history.push(`/${new_project}/stats`);
        } else if (history.location.pathname.includes('integrations')) {
            history.push(`/${new_project}/integrations`);
        } else {
            history.push(`/${new_project}/chatbot`);
        }

        this.getActions(new_project, el);
    }

    getActions = async (projectID, el) => {
        const {access} = this.props.data;
        try {
            const obj = new ProjectAPI(projectID, access, this.props);
            const actions = await obj.get_project_actions();

            var value = false;
            actions.forEach(action => {
                if ((action.action === 'empty' || action.action === 'predefined_bot') && action.project_id === projectID && (action.in_progress || action.pending)) {
                    value = true;
                }
            });

            this.props.dispatch(updateEmpty(value));
            if (el) {
                this.props.dispatch(updateActualProject(el));
            }
        } catch (err) {
            if (el) {
                this.props.dispatch(updateActualProject(el));
            }
        }
    }

    handleScript = () => {
        var iframe = $('#bim-ifr');
        if (iframe) {
            iframe.remove();
        }
    }

    isLimits = () => {
        const {limits, actualProject} = this.props.data;
        if (!actualProject || !actualProject.id) {
            return true
        } else if (limits && limits.contexts && limits.intents_create) {
            return true;
        }
        return false;
    }

    render() {
        const {open} = this.state;
        const {
            projects,
            layouts,
            initApp,
            actualLayout,
            actualProject,
            user,
            access,
            limitsIntegrations,
            plan,
            roles
        } = this.props.data;
        return (
            <div className="cen-app">
                <div className={`cen-container ${(actualProject.id === undefined || user?.role?.name === "Trainer") ? 'project-none' : ''}`}>
                    {
                        initApp && this.isLimits() && limitsIntegrations && roles?.length > 0 ?
                            <React.Fragment>
                                <Header setActualProject={this.setActualProject} user={user} access={access}
                                        actualProject={actualProject} actualLayout={actualLayout}
                                        history={this.props.history} t={this.props.t} plan={plan}
                                        projects={JSON.parse(JSON.stringify(projects))}/>
                                {
                                    actualLayout.layout !== "dashboard" && (((projects.length > 0 || (projects.length === 0 && ["Agent", "Supervisor"].includes(user.role.name))) && layouts.length > 0 && !isMaintenance(plan?.id)) && user.role.name !== "Trainer") &&
                                    <MenuSidebar
                                        open={open}
                                        handleMenuToggle={() => this.setState({open: !open})}
                                        handleMenuClose={() => this.setState({open: false})}
                                    />
                                }
                                <RouteApp getLimits={this.getLimits}/>
                            </React.Fragment>
                            :
                            <div className="cen-init">
                                <CircularProgress color="primary" size={70}/>
                            </div>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_app = connect(
    mapStateToProps
)(App);

export default withTranslation(['common', 'commons'])(withRouter(connect_app));