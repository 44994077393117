// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
import enLocale from "date-fns/locale/en-US";
import esLocale from 'date-fns/locale/es';
import ptLocale from 'date-fns/locale/pt';
// Styles
import 'views/Campaigns/styles/index.scss';
// Date
import DateFnsUtils from "@date-io/date-fns";
import 'date-fns';
// Vendor
import {showNotify} from "vendor/application/disptach";
// Helpers
import {getWhatsappPhone} from "@helpers";
// Components
import Table from 'views/Campaigns/components/Table';
import {CampaignsAPI} from "views/Campaigns/infrastructure";
import {Search, SelectSimple} from "@components/Input";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
// Amplitude API
import {svgCalendar} from "assets";

const localeMap = {
    en: enLocale,
    es: esLocale,
    pt: ptLocale
};

class CampaignsList extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            groups: false,
            all_groups: false,
            page: 0,
            max: 0,
            status_filter: "all",
            name_filter: "",
            send_date: {
                startDate: new Date(date.setMonth(date.getMonth() - 12)),
                endDate: new Date(date.setMonth(date.getMonth() + 24))
            },
            open_start: false,
            open_end: false,
            languages: false,
            whatsapp_phone: false,
            templates: false
        }
    }

    componentDidMount = () => {
        this.handleProjectID();
    }

    handleProjectID = (e) => {
        const {plan} = this.props.data;
        var whatsapp_phone;
        if (!e) {
            whatsapp_phone = plan?.whatsapp_phones[0];
        } else {
            whatsapp_phone = e;
        }

        this.getGroups(whatsapp_phone.project_id);
        this.getTemplates(whatsapp_phone.project_id);
        this.setState({whatsapp_phone: whatsapp_phone});
    }

    getGroups = async (project_id) => {
        const {access} = this.props.data;
        try {
            let obj = new CampaignsAPI(project_id, access, this.props);
            let res = await obj.get_groups();

            res = res.reverse();
            var array = JSON.parse(JSON.stringify(res)),
                arr = [];

            while (array.length) {
                arr.push(array.splice(0, 10));
            }

            this.setState({all_groups: res, groups: arr, page: 1, max: arr.length});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }


    getTemplates = async (project_id) => {
        const {access} = this.props.data;
        try {
            let obj = new CampaignsAPI(project_id, access, this.props);
            let res = await obj.get_templates();

            this.setState({templates: res});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getCentripushLimits = async (account_id) => {
        const {access} = this.props.data;
        try {
            let obj = new CampaignsAPI(account_id, access, this.props);
            let res = await obj.get_centripush_limits();

            this.setState({centripush_limits: res});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    handleSelectStart = (start) => {
        var end = this.state.send_date.endDate;
        if (start > end) {
            end = start;
        }

        this.handleFilter({
            startDate: start,
            endDate: end
        }, 'send_date')
    }

    handleSelectEnd = (end) => {
        var start = this.state.send_date.startDate;
        if (end < start) {
            start = end;
        }

        this.handleFilter({
            startDate: start,
            endDate: end
        }, 'send_date')
    }

    getDate = (type, send) => {
        const {send_date} = this.state;
        var m = send_date[type].getMonth(),
            y = send_date[type].getFullYear()

        if (send) {
            return `${y}-${m + 1}-1`;
        }
        return `${m + 1} / ${y}`;
    }

    handlePagination = (e, next_page) => {
        this.setState({page: next_page});
    }

    handleFilter = (e, type) => {
        var {all_groups, status_filter, name_filter, send_date} = this.state;
        switch (type) {
            case 'search':
                name_filter = e.target.value;
                break;
            case 'status':
                status_filter = e;
                break;
            case 'send_date':
                send_date = e;
                break;
        }

        var groups = name_filter ? all_groups.filter(i => i.name.includes(name_filter)) : all_groups;
        groups = status_filter && status_filter !== 'all' ? groups.filter(i => i.status === status_filter) : groups;

        groups = groups.filter((i) => {
            var date = i.send_date || {};
            date = new Date(date);
            return date >= send_date.startDate && date <= send_date.endDate;
        });

        var array = JSON.parse(JSON.stringify(groups)),
            arr = [];

        while (array.length) {
            arr.push(array.splice(0, 10));
        }

        this.setState({
            groups: arr,
            page: 1,
            max: arr.length,
            status_filter: status_filter,
            name_filter: name_filter,
            send_date: send_date
        });
    }

    handleRoute = (item) => {
        const {whatsapp_phone} = this.state;
        const {setQuery} = this.props;

        setQuery(null, item.id, whatsapp_phone.project_id);
    }

    openDatePicker = (type) => {
        this.setState({[type]: !this.state[type]});
    }

    render() {
        const {t} = this.props;
        const {actualLayout, plan} = this.props.data;
        const {
            groups,
            name_filter,
            send_date,
            open_start,
            open_end,
            templates,
            whatsapp_phone,
            max,
            page,
            status_filter
        } = this.state;
        return (
            <React.Fragment>
                <div className="centribal_card">
                    <div className={"campaigns_header"}>
                        <div className={"campaigns_header_title"}>
                            <span
                                className={"title"}>{t(`common:card.content.${actualLayout.layout}.campaigns.header_title`)}</span>
                        </div>
                        <div className={"campaigns_header_actions"}>
                            {
                                whatsapp_phone?.phone &&
                                <SelectSimple
                                    label={t(`common:card.content.${actualLayout.layout}.campaigns.filters.whatsapp_phone`)}
                                    valueSelected={getWhatsappPhone(whatsapp_phone)}
                                    items={plan?.whatsapp_phones}
                                    onSelect={(e) => this.handleProjectID(e)}
                                    type={"whatsapp_phone"}
                                    className={"select_secondary templates_whatsapp_phones"}
                                    value={"phone"}
                                    t={t}
                                />
                            }
                            <MuiPickersUtilsProvider utils={DateFnsUtils}
                                                     locale={localeMap[this.props.i18n.language]}>
                                <div className="platform_date_picker_groups"
                                     onClick={() => this.openDatePicker("open_start")}>
                                    <span>{t(`common:card.content.${actualLayout.layout}.campaigns.filters.send_date_start`)}</span>
                                    <div>
                                        <span>{this.getDate("startDate")}</span>
                                        <span>{svgCalendar()}</span>
                                    </div>
                                </div>
                                <DatePicker
                                    value={send_date.startDate}
                                    onChange={this.handleSelectStart}
                                    animateYearScrolling
                                    className={"date_picker_groups"}
                                    views={["year", "month"]}
                                    openTo={"month"}
                                    autoOk
                                    onClose={() => this.openDatePicker("open_start")}
                                    open={open_start}
                                />
                                <div className="platform_date_picker_groups"
                                     onClick={() => this.openDatePicker("open_end")}>
                                    <span>{t(`common:card.content.${actualLayout.layout}.campaigns.filters.send_date_end`)}</span>
                                    <div>
                                        <span>{this.getDate("endDate")}</span>
                                        <span>{svgCalendar()}</span>
                                    </div>
                                </div>
                                <DatePicker
                                    value={send_date.endDate}
                                    onChange={this.handleSelectEnd}
                                    animateYearScrolling
                                    className={"date_picker_groups"}
                                    views={["year", "month"]}
                                    openTo={"month"}
                                    autoOk
                                    onClose={() => this.openDatePicker("open_end")}
                                    open={open_end}
                                />
                            </MuiPickersUtilsProvider>
                            <SelectSimple
                                label={t(`common:card.content.${actualLayout.layout}.campaigns.filters.status`)}
                                valueSelected={<span
                                    className={`status ${status_filter}`}>{t(`elements.custom.status.${status_filter}`)}</span>}
                                items={["all", "approved", "rejected", "pending", "requested"]}
                                onSelect={(e) => this.handleFilter(e, 'status')}
                                placeholder={t(`common:card.content.${actualLayout.layout}.campaigns.filters.status`)}
                                type={"status"}
                                className={"select_secondary templates_status"}
                                t={t}
                            />
                            <Search label={t(`common:card.content.${actualLayout.layout}.campaigns.filters.name`)} t={t}
                                    placeholder={t(`common:card.content.${actualLayout.layout}.campaigns.filters.name`)}
                                    onSearch={this.handleFilter} value={name_filter}/>
                        </div>
                    </div>
                    <div className={"campaigns_container_elements campaigns_container_elements_alone "}>
                        <div className={"campaigns_container_elements_content"}>
                            <Table conditional={groups && templates} items={groups} templates={templates}
                                   type={"history.campaigns"} onClick={this.handleRoute}
                                   handlePagination={this.handlePagination} page={page} max={max}
                                   header={['name', 'status', 'template', 'send_date', 'created_at']}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const campaigns_list = connect(
    mapStateToProps
)(CampaignsList);

export default withTranslation(['commons', 'common'])(withRouter(campaigns_list));