// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import 'views/Campaigns/styles/index.scss';
// Vendor
import {showNotify, updateActualLayout} from "vendor/application/disptach";
// Helpers
import {getWhatsappPhone} from "@helpers";
// Components
import Table from '../../components/Table';
import {CampaignsAPI} from "views/Campaigns/infrastructure";
import {Button, SelectSimple} from "@components/Input";
import Preview from '../../components/Preview';
import {Tooltip} from '@material-ui/core';
// Amplitude API
import {AmplitudeAPI} from '@api/Amplitude';

class Templates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            templates: false,
            all_templates: false,
            page: 0,
            max: 0,
            category_filter: "all",
            status_filter: "all",
            centripush_limits: false,
            categories: false,
            languages: false,
            whatsapp_phone: false,
            template_selected: false
        }
    }

    componentDidMount = () => {
        this.getCategories();
        this.getLanguages();
        this.setLayout();
    }

    getCategories = async () => {
        const {access} = this.props.data;
        try {
            let obj = new CampaignsAPI(false, access, this.props);
            let res = await obj.get_categories();

            res.unshift({name: this.props.t(`common:card.content.templates.filters.all_categories`), tag: "all"});

            this.setState({
                categories: res,
                category_filter: {
                    name: this.props.t(`common:card.content.templates.filters.all_categories`),
                    tag: "all"
                }
            });
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getLanguages = async () => {
        const {access} = this.props.data;
        try {
            let obj = new CampaignsAPI(false, access, this.props);
            let res = await obj.get_languages();

            this.setState({languages: res});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    setLayout = () => {
        const {actualLayout} = this.props.data;
        if (actualLayout.layout !== 'campaigns') {
            var data = {layout: 'campaigns', project: actualLayout.project};
            this.props.dispatch(updateActualLayout(data));
        }

        this.handleProjectID();
    }

    handleProjectID = (e) => {
        const {plan, user} = this.props.data;
        var new_whatsapp_phone;
        if (!e) {
            new_whatsapp_phone = plan?.whatsapp_phones[0];
        } else {
            new_whatsapp_phone = e;
        }
        this.getTemplates(new_whatsapp_phone.project_id);
        this.getCentripushLimits(user.account_id);
        this.setState({whatsapp_phone: new_whatsapp_phone});
    }

    getTemplates = async (project_id) => {
        const {access} = this.props.data;
        try {
            let obj = new CampaignsAPI(project_id, access, this.props);
            let res = await obj.get_templates();

            res = res.reverse();

            var array = JSON.parse(JSON.stringify(res.filter((template => template?.status !== "cancelled")))),
                arr = [];

            while (array.length) {
                arr.push(array.splice(0, 10));
            }

            this.setState({
                all_templates: res,
                templates: arr,
                page: 1,
                max: arr.length,
                template_selected: res[0] ?? {}
            });
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getCentripushLimits = async (account_id) => {
        const {access} = this.props.data;
        try {
            let obj = new CampaignsAPI(account_id, access, this.props);
            let res = await obj.get_centripush_limits();

            this.setState({centripush_limits: res});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    createTemplate = () => {
        const {user, device_id} = this.props.data;
        const {history} = this.props;

        new AmplitudeAPI({
            event_type: 'Click on create template',
            device_id: device_id,
            user_id: user.email
        }).eventLog();

        history.push(`${history.location.pathname}/create`);
    }

    handlePagination = (e, next_page) => {
        const {templates} = this.state;
        this.setState({page: next_page, template_selected: templates[next_page - 1][0]});
    }

    handleFilter = (e, type) => {
        var {all_templates, status_filter, category_filter} = this.state;

        switch (type) {
            case 'category':
                category_filter = e;
                break;
            case 'status':
                status_filter = e;
                break;
        }

        var templates = category_filter?.tag !== 'all' ? all_templates.filter(i => i.category.includes(category_filter.tag)) : all_templates;
        templates = status_filter && status_filter !== 'all' ? templates.filter(i => i.status === status_filter) : templates;

        var array = JSON.parse(JSON.stringify(templates)),
            arr = [];

        while (array.length) {
            arr.push(array.splice(0, 10));
        }

        this.setState({
            templates: arr,
            page: 1,
            max: arr.length,
            status_filter: status_filter,
            category_filter: category_filter,
            template_selected: arr[0]?.length ? arr[0][0] : {}
        });
    }

    selectElement = (element) => {
        this.setState({template_selected: element});
    }

    render() {
        const {t} = this.props;
        const {actualLayout, plan} = this.props.data;
        const {
            templates,
            centripush_limits,
            whatsapp_phone,
            categories,
            languages,
            max,
            page,
            status_filter,
            category_filter,
            template_selected
        } = this.state;
        return (
            <React.Fragment>
                <div className="centribal_card">
                    <div className={"campaigns_header"}>
                        <div className={"campaigns_header_title"}>
                            <span
                                className={"title"}>{t(`common:card.content.${actualLayout.layout}.header_title`)}</span>
                        </div>
                        <div className={"campaigns_header_actions"}>
                            {
                                whatsapp_phone?.phone &&
                                <SelectSimple
                                    label={t(`common:card.content.${actualLayout.layout}.filters.whatsapp_phone`)}
                                    valueSelected={getWhatsappPhone(whatsapp_phone)}
                                    items={plan?.whatsapp_phones}
                                    onSelect={(e) => this.handleProjectID(e)}
                                    type={"whatsapp_phone"}
                                    className={"select_secondary templates_whatsapp_phones"}
                                    value={"phone"}
                                    t={t}
                                />
                            }
                            <SelectSimple
                                label={t(`common:card.content.${actualLayout.layout}.filters.category`)}
                                valueSelected={category_filter?.name ? category_filter?.name : t(`common:card.content.${actualLayout.layout}.filters.category`)}
                                items={categories}
                                onSelect={(e) => this.handleFilter(e, 'category')}
                                placeholder={t(`common:card.content.${actualLayout.layout}.filters.category`)}
                                type={"normal"}
                                id={"groups_status"}
                                value={"name"}
                                className={"select_secondary templates_category"}
                                t={t}
                            />
                            <SelectSimple
                                label={t(`common:card.content.${actualLayout.layout}.filters.status`)}
                                valueSelected={<span
                                    className={`status ${status_filter}`}>{t(`elements.custom.status.${status_filter}`)}</span>}
                                items={["all", "approved", "rejected", "pending", "requested"]}
                                onSelect={(e) => this.handleFilter(e, 'status')}
                                placeholder={t(`common:card.content.${actualLayout.layout}.filters.status`)}
                                type={"status"}
                                className={"select_secondary templates_status"}
                                t={t}
                            />
                            {centripush_limits && centripush_limits.limits.templates.total >= centripush_limits.limits.templates.max ?
                                <Tooltip title={t(`common:card.content.${actualLayout.layout}.max_templates.${plan.plan.name}`)}>
                                    <span className={"platform_btn platform_btn__primary disabled"}>
                                        {t(`common:card.content.${actualLayout.layout}.button_create`)}
                                    </span>
                                </Tooltip> :
                                <Button type={"primary"}
                                        text={t(`common:card.content.${actualLayout.layout}.button_create`)}
                                        onClick={() => this.createTemplate()}/>
                            }
                        </div>
                    </div>
                    <div className={"campaigns_container_elements"}>
                        <div className={"campaigns_container_elements_content"}>
                            <Table conditional={categories && languages && templates} element_selected={template_selected} items={templates} categories={categories}
                                     languages={languages} type={"templates"}
                                     handlePagination={this.handlePagination} page={page} max={max}
                                     header={['name', 'status', 'language', 'category', 'created_at']}
                                     onClick={this.selectElement}/>
                        </div>
                        <Preview template_selected={template_selected}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const templates = connect(
    mapStateToProps
)(Templates);

export default withTranslation(['commons', 'common'])(withRouter(templates));