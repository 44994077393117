import { CustomRequest } from 'vendor/infrastructure/custom_requests';
import { updateSending } from 'vendor/application/disptach';
export {get_users} from './get_users';

export class UsersAPI {
    constructor(userID, token, props, body) {
        this.userID = userID;
        this.token = token;
        this.props = props;
        this.body = body;

        this.props.dispatch(updateSending(true));
    }

    async get_roles() {
        let request = new CustomRequest('GET', `users/${this.userID}/roles`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_projects() {
        let request = new CustomRequest('GET', `users/${this.userID}/projects`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async put_lang() {
        this.props.dispatch(updateSending(false));
        const request = new CustomRequest('PUT', `users/${this.userID}`, JSON.stringify(this.body), this.token, this.props );
        return await request.action();
    }

    async get_user_info() {
        let request = new CustomRequest('GET', `users/${this.userID}`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_roles() {
        let request = new CustomRequest('GET', `roles`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_account_by_id(id) {
        let request = new CustomRequest('GET', `accounts/${id}`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async nextChannel(channel) {
        const request = new CustomRequest('POST', `users/${this.userID}/channels/nexts/${channel}`, JSON.stringify(this.body), this.token, this.props );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }
}