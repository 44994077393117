import { CustomRequest } from 'vendor/infrastructure/custom_requests';
import { updateSending } from 'vendor/application/disptach';

export class StatsAPI {
    constructor(projectID, token, props, body) {
        this.projectID = projectID;
        this.token = token;
        this.props = props;
        this.body = body;

        this.props.dispatch(updateSending(true));
    }

    async get_stats_filter(start, end) {
        let request = new CustomRequest('GET', `projects/${this.projectID}/statistics?from=${start}&to=${end}`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_stats_consumption_filter(start, end, type, id) {
        let request = new CustomRequest('GET', `${type}/${id}/statistics?from=${start}&to=${end}`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_stats_whatsapp(month, type, id) {
        let request = new CustomRequest('GET', `${type}/${id}/statistics/whatsapp?from=${month}&to=${month}`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_stats_sunco_notifications(start, end, campaign_name) {
        let request = new CustomRequest('GET', `projects/${this.projectID}/statistics/sunco_notifications?from=${start}&to=${end}&campaign_name=${campaign_name}`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_stats_sunco_notifications_campaigns(start, end, campaign_name, page) {
        let request = new CustomRequest('GET', `projects/${this.projectID}/statistics/sunco_notifications/campaigns?from=${start}&to=${end}&campaign_name=${campaign_name}&page=${page ?? 1}&page_size=10`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_stats_sunco_campaigns(start, end, campaign_name, template_name, lang, page) {
        let request = new CustomRequest('GET', `projects/${this.projectID}/statistics/sunco_notifications/campaigns/${campaign_name}/templates/${template_name}/lang/${lang}?from=${start}&to=${end}&page=${page ?? 1}&page_size=10`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_polls_stats(start, end, channels) {
        let request = new CustomRequest('GET', `projects/${this.projectID}/statistics/polls?from=${start}&to=${end}&channels=${JSON.stringify(channels)}&limit=500`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }
}