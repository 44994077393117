// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Infrastructure
import {ProjectAPI} from '../../../../../api/application/Projects';
import {IntentsAPI} from 'views/Bots/NLP/application/Build/Tabs/Intents/infrastructure';
// Vendor
import {showNotify} from 'vendor/application/disptach';
import {addArtifact, inactivityBody, inactivityMessagesBody, isValidDate} from "vendor/application";
// Material
import {Chip, CircularProgress, Divider, Switch} from '@material-ui/core';
import {KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Alert from "@material-ui/lab/Alert";
// Components
import CardEmpty from 'components/Card/CardEmpty';
import {Button, Input} from "@components/Input";
import Select from "components/Input/select";
import FloatButtons from "components/Input/float_buttons";
// Translations
import {withTranslation} from "react-i18next";
// SVG
import {closeSvg, svgClock} from 'assets';

class ChatbotInactivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inactivity: {},
            new_inactivity: false,
            intents: [],
            desk_true: [],
            selectable_intents: [],
            lang_codes: [],
            all_lang_codes: [],
            selectedDate: ''
        }
    }

    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.data.actualProject.id !== this.props.data.actualProject.id) {
            this.init();
        }
    }

    init = () => {
        this.getIntentsDesk();
        this.getCodes();
    }

    getIntentsDesk = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntentsAPI(actualProject.id, access, this.props);
            const desk = await obj.get_intents_desk();

            desk.desk_true.forEach(el => {
                el.desk = true;
            });
            desk.desk_false.forEach(el => {
                el.desk = false;
            });
            this.setState({desk_true: desk.desk_true, desk_false: desk.desk_false});

            this.getInactivity();
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data));
        }
    }

    getInactivity = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props);
            const inactivity = await obj.get_inactivity();

            if (!inactivity.messages) {
                this.setState({inactivity: false, new_inactivity: true});
                this.handleDate()
            } else {
                this.setState({inactivity: inactivity});
                this.handleDate(inactivity);
                this.handleIntents();
            }
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data));
        }
    }

    getCodes = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props);
            const codes = await obj.get_codes();

            this.setState({lang_codes: codes, all_lang_codes: JSON.parse(JSON.stringify(codes))});
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data));
        }
    }

    handleDate = (inactivity) => {
        if (inactivity) {
            var time = inactivity.inactivity_time,
                split = time.split(":"),
                h = split[0],
                m = split[1],
                s = split[2];

            this.setState({selectedDate: new Date(new Date().setHours(h, m, s))});
        } else {
            this.setState({selectedDate: new Date(new Date().setHours(0, 1, 0))});
        }
    }

    handleIntents = () => {
        const {desk_true, desk_false, inactivity, intents, lang_codes} = this.state;

        inactivity.intents.forEach((intent) => {
            desk_true.forEach((dTrue) => {
                if (dTrue.id === intent) {
                    intents.push(dTrue);
                }
            });

            desk_false.forEach((dFalse) => {
                if (dFalse.id === intent) {
                    intents.push(dFalse);
                }
            })
        });

        inactivity.messages.forEach((message) => {
            lang_codes.forEach((lang, i) => {
                if (message.lang === lang) {
                    lang_codes.splice(i, 1);
                    i--;
                }
            })
        });

        var arr = desk_false.filter(this.comparer(intents));
        this.setState({selectable_intents: arr, intents: intents, lang_codes: lang_codes});
    }

    comparer(otherArray) {
        return function (current) {
            return otherArray.filter(function (other) {
                return other.id === current.id
            }).length === 0;
        }
    }


    handleTimePicker = (e) => {
        const {inactivity} = this.state;
        if (isValidDate(e)) {
            var h = e.getHours(),
                m = e.getMinutes(),
                s = e.getSeconds(),
                d = `${h.toString().length === 1 ? `0${h}` : h}:${m.toString().length === 1 ? `0${m}` : m}:${s.toString().length === 1 ? `0${s}` : s}`;

            if (h === 0 && m < 1) {
                e = new Date(new Date().setHours(0, 1, 0));
                d = '00:01:00';
            }
            inactivity.inactivity_time = d;
            this.setState({selectedDate: e});
        }
    }

    addInactivity = () => {
        const {lang_codes} = this.state;
        var inactivity = inactivityBody(this.props.project_lang);

        lang_codes.forEach((el, i) => {
            if (el === inactivity.default_lang) {
                lang_codes.splice(i, 1);
            }
        })

        this.setState({
            inactivity: inactivity,
            lang_codes: lang_codes,
            selectable_intents: this.state.desk_false,
            new_inactivity: true
        })
    }

    updateInactivity = async (e) => {
        e.preventDefault();
        const {inactivity, new_inactivity} = this.state;
        const {access, actualProject} = this.props.data;

        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props, inactivity);
            await obj.do_inactivity(new_inactivity ? 'POST' : 'PUT');

            this.props.dispatch(showNotify({
                message: "inactivities",
                type: new_inactivity ? "create" : "edit",
                severity: "success"
            }));

            this.setState({new_inactivity: false});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    // update element state
    updateElement = (e, variable, type, i) => {
        e.preventDefault();
        const {inactivity} = this.state;

        if (type && type !== 'boolean') {
            inactivity[variable][i][type] = e.target.value;
        } else if (type === 'boolean') {
            inactivity[variable] = !inactivity[variable];
        } else {
            inactivity[variable] = e.target.value;
        }

        this.setState({inactivity: inactivity});
    }

    selectIntentDesk = (e) => {
        const {intents, inactivity, selectable_intents} = this.state;

        selectable_intents.forEach((el, i) => {
            if (el.id === e) {
                intents.push(el);
                selectable_intents.splice(i, 1);
            }
        });

        inactivity.intents.push(e);
        this.setState({selectable_intents: selectable_intents, intents: intents, inactivity: inactivity});
    }

    handleDeleteIntent = (e, intent, iteration) => {
        const {intents, inactivity, selectable_intents} = this.state;

        intents.forEach(el => {
            if (el.id === intent.id) {
                intents.splice(iteration, 1);
                selectable_intents.push(el);
            }
        });

        inactivity.intents.forEach((el, i) => {
            if (el === intent.id) {
                inactivity.intents.splice(i, 1);
            }
        })

        this.setState({selectable_intents: selectable_intents, intents: intents, inactivity: inactivity});
    }

    addMessage = (e) => {
        const {inactivity, lang_codes} = this.state;

        var a = false;
        inactivity.messages.forEach((el) => {
            if (el.lang === e) {
                a = true;
            }
        });

        if (!a) {
            inactivity.messages.push(inactivityMessagesBody(e));
            lang_codes.forEach((el, i) => {
                if (el === e) {
                    lang_codes.splice(i, 1);
                }
            });

            this.setState({inactivity: inactivity, lang_codes: lang_codes});
        }
    }

    deleteMessage = (e, lang, i) => {
        const {inactivity, lang_codes} = this.state;

        lang_codes.push(lang);
        inactivity.messages.splice(i, 1);

        this.setState({inactivity: inactivity, lang_codes: lang_codes});
    }

    selectDefaultLang = (e) => {
        const {inactivity} = this.state;
        inactivity.default_lang = e;

        this.setState({inactivity: inactivity});
        this.addMessage(e);
    }

    handleArtifacts = (e, i, artifact_type, type) => {
        const {inactivity} = this.state;

        var res = addArtifact(i, artifact_type, type, inactivity);

        this.setState({inactivity: res});
    }

    render() {
        const {inactivity, selectedDate, selectable_intents, intents, all_lang_codes, lang_codes} = this.state;
        const {t} = this.props;
        const {isSending} = this.props.data;
        return (
            inactivity ? (
                inactivity.messages
                    ? <div className={"cen_card"}>
                        <div className={"header"}>
                            <div className={"header_content spacing_title"}>
                                <div className={"header_title"}>
                                    <span className={"title"}>{t('card.content.project.inactivity.title')}</span>
                                    <span
                                        className={"description"}>{t('card.content.project.inactivity.description')}</span>
                                </div>
                            </div>
                            <div className={"header_actions"}>
                                {isSending ? (
                                    <Button type={"primary"}
                                            text={<CircularProgress size={21}/>}
                                    />
                                ) : (
                                    <Button type={"primary"}
                                            onClick={(e) => this.updateInactivity(e)}
                                            text={t(`card.content.project.inactivity.button_save`)}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={"content"}>
                            <div className={`elements__main`}>
                                <div className={"elements__picker"}>
                                    <div>
                                        <div>
                                            <span>{t(`card.content.project.inactivity.switch`)}</span>
                                        </div>
                                        <Switch checked={inactivity.active} onClick={(e) => {
                                            this.updateElement(e, 'active', 'boolean')
                                        }} color="primary"/>
                                    </div>
                                </div>
                                <Divider/>
                                <div className={"elements__picker"}>
                                    <div>
                                        <div>
                                            <span>{t(`card.content.project.inactivity.time.title`)}</span>
                                            <span
                                                dangerouslySetInnerHTML={{__html: t(`card.content.project.inactivity.time.description`)}}></span>
                                        </div>
                                        {
                                            selectedDate &&
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <KeyboardTimePicker
                                                    className={"input-primary"}
                                                    ampm={false}
                                                    openTo="minutes"
                                                    views={["hours", "minutes", "seconds"]}
                                                    format="HH:mm:ss"
                                                    value={selectedDate}
                                                    onChange={(e) => this.handleTimePicker(e)}
                                                    keyboardIcon={svgClock()}
                                                />
                                            </MuiPickersUtilsProvider>
                                        }
                                    </div>
                                </div>
                                <Divider/>
                                <div>
                                    <div className={`elements__grid`}>
                                        <div className={`elements__title`}>
                                            <span>{t(`card.content.project.inactivity.default_lang.title`)}</span>
                                            <span>{t(`card.content.project.inactivity.default_lang.description`)}</span>
                                        </div>
                                        {/* default_lang */}
                                        {all_lang_codes &&
                                        <div className={"elements_codes"}>
                                            <Select
                                                strSelected={t(`languages:languages.${inactivity.default_lang}`)}
                                                placeholder={t(`card.content.project.inactivity.default_lang.select_placeholder`)}
                                                array={all_lang_codes}
                                                onSelect={this.selectDefaultLang}
                                                id={'code-language'}
                                                type={'code_language'}
                                                t={t}
                                            />
                                        </div>
                                        }
                                    </div>
                                </div>
                                <Divider/>
                                <div>
                                    <div className={`elements__grid`}>
                                        <div className={`elements__title`}>
                                            <span>{t(`card.content.project.inactivity.messages.title`)}</span>
                                            <span
                                                dangerouslySetInnerHTML={{__html: t(`card.content.project.inactivity.messages.description`)}}></span>
                                        </div>
                                        <div className={"elements_codes"}>
                                            <Select
                                                strSelected={t(`card.content.project.inactivity.messages.select_placeholder`)}
                                                placeholder={t(`card.content.project.inactivity.messages.select_placeholder`)}
                                                array={lang_codes}
                                                onSelect={this.addMessage}
                                                id={'code-language'}
                                                type={'code_language'}
                                                t={t}
                                            />
                                        </div>
                                        <div className={"pd-0"}>
                                            <div className={`elements__grid`}>
                                                {
                                                    inactivity.messages.length > 0 ?
                                                        inactivity.messages.map((message, i) => {
                                                            return (
                                                                <div key={i} className={"elements__inactivity"}>
                                                                    <div
                                                                        className={"elements__lang"}>{message.lang}</div>
                                                                    <Input value={message.response} type={'primary'}
                                                                           multiline
                                                                           inputProps={{maxLength: 2000}}
                                                                           placeholder={t(`card.content.project.inactivity.messages.card.placeholder`)}
                                                                           onChange={(e) => this.updateElement(e, 'messages', 'response', i)}/>

                                                                    <FloatButtons type={"inactivity"}
                                                                                  addArtifact={this.handleArtifacts}
                                                                                  iteration={i}
                                                                                  buttons_toggle_artifacts={["img", "link", "button", "carousel", "bubble", "for", "reply_button"]}/>
                                                                    {
                                                                        message.lang !== inactivity.default_lang &&
                                                                        <div className={"elements__close"}
                                                                             onClick={(e) => this.deleteMessage(e, message.lang, i)}>
                                                                            {closeSvg()}
                                                                        </div>
                                                                    }
                                                                </div>
                                                            )
                                                        }) :
                                                        <div className={"elements__alert"}>
                                                            <Alert severity="info">
                                                                <span>{t(`card.content.project.inactivity.messages.no_results`)}</span>
                                                            </Alert>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Divider/>
                                <div>
                                    <div className={`elements__grid`}>
                                        <div className={`elements__title`}>
                                            <span>{t(`card.content.project.inactivity.intents.title`)}</span>
                                            <span
                                                dangerouslySetInnerHTML={{__html: t(`card.content.project.inactivity.intents.description`)}}></span>
                                        </div>
                                        <div className={"elements_codes"}>
                                            <Select
                                                strSelected={t(`card.content.project.inactivity.intents.select_placeholder`)}
                                                placeholder={t(`card.content.project.inactivity.intents.select_placeholder`)}
                                                array={selectable_intents}
                                                onSelect={this.selectIntentDesk}
                                                id={'intents-entities'}
                                                type={'intents'}
                                                t={t}
                                            />
                                        </div>
                                        <div className={"elements__content"}>
                                            <div
                                                className={`elements__chips ${intents.length > 0 ? 'project' : ''}`}>
                                                {intents.length > 0 || this.state.desk_true.length > 0 ?
                                                    <>
                                                        {
                                                            this.state.desk_true.map((el, i) => {
                                                                if (!intents.map(i => i.id).includes(el.id)) {
                                                                    return (
                                                                        <Chip
                                                                            key={i}
                                                                            label={el.name}
                                                                            variant="outlined"
                                                                        />
                                                                    )
                                                                }
                                                            })
                                                        }
                                                        {
                                                            intents.map((el, i) => {
                                                                return (
                                                                    el.desk ?
                                                                        <Chip
                                                                            key={i}
                                                                            label={el.name}
                                                                            variant="outlined"
                                                                        />
                                                                        :
                                                                        <Chip
                                                                            key={i}
                                                                            label={el.name}
                                                                            onDelete={(e) => this.handleDeleteIntent(e, el, i)}
                                                                            variant="outlined"
                                                                        />
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    :
                                                    <div className={"elements__alert"}>
                                                        <Alert severity="info">
                                                            <span>{t(`card.content.project.inactivity.intents.no_results`)}</span>
                                                        </Alert>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : <div className={"cen_card"}>
                        <div className={"content"}>
                            <div className="loading">
                                <CircularProgress color="primary" size={50}/>
                            </div>
                        </div>
                    </div>
            ) : (
                <CardEmpty
                    data={this.props.data}
                    t={this.props.t}
                    type={'inactivity'}
                    handleClickLink={this.addInactivity}
                    open={false}
                />
            )
        )
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_chatbot_inactivity = connect(
    mapStateToProps
)(ChatbotInactivity);

export default withTranslation(['common', 'languages'])(connect_chatbot_inactivity);