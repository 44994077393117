// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Translations
import {withTranslation} from "react-i18next";
// Components
import {Input} from "@components/Input";
import {Divider, Switch} from "@material-ui/core";
import ElementColor from "../element_color";
import ElementIcon from "../element_icon";
import ElementSwitch from "../element_switch";
// Vendor
import {ElementsHandleAPI} from "vendor/application/handleMethods";
import {addArtifact} from "vendor/application";

class InputText extends Component {

    updateElementConfig = (e, type) => {
        e.preventDefault();
        const {config, updateConfig} = this.props;
        const obj = new ElementsHandleAPI(config, e.target.value, type);
        const res = obj.updateObj('edit');
        updateConfig(res);
    }

    handleArtifacts = (e, i, artifact_type, type) => {
        const {config, updateConfig} = this.props;
        var res = addArtifact(i, artifact_type, type, config);
        updateConfig(res);
    }

    render() {
        const {t, config, updateCustomization, updateConfig} = this.props;
        return (
            <div className={"cen_card"}>
                {/* INPUT TEXT */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.send.input_text.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.send.input_text.description')}</span>
                        </div>
                        <div className={"elements__info"}>
                            <Input value={config.text_send} type={'primary'} multiline
                                   placeholder={t('card.content.project.web_interface.customization.send.placeholder')}
                                   onChange={(e) => this.updateElementConfig(e, 'text_send')}/>
                        </div>
                    </div>
                </div>
                <Divider />
                {/* COLORS */}
                <div className={"content"}>
                    <div className="elements_custom grid">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.send.colors.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.send.colors.description')}</span>
                        </div>
                        <div>
                            <ElementColor
                                updateCustomization={updateCustomization}
                                element={"text"}
                                value={config.customization.input_text_color}
                                value_updated={"input_text_color"}
                            />
                            <ElementSwitch
                                color_1={"input_text_color"}
                                color_2={"input_bg_color"}
                                updateConfig={updateConfig}
                                config={config}
                            />
                            <ElementColor
                                updateCustomization={updateCustomization}
                                element={"bg"}
                                value={config.customization.input_bg_color}
                                value_updated={"input_bg_color"}
                            />
                        </div>
                    </div>
                </div>
                <Divider />
                {/* ICONS  SEND / MULTIMEDIA */}
                <div className={"elements_divider"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.send.multimedia.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.send.multimedia.description')}</span>
                        </div>
                        <div className={"elements_divider_container"}>
                            <div className={"elements_divider_colors"}>
                                <div>
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"bg"}
                                        value={config.customization.input_clip_bg_color}
                                        value_updated={"input_clip_bg_color"}
                                    />
                                    <ElementSwitch
                                        color_1={"input_clip_bg_color"}
                                        color_2={"input_clip_color"}
                                        updateConfig={updateConfig}
                                        config={config}
                                    />
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"icon"}
                                        value={config.customization.input_clip_color}
                                        value_updated={"input_clip_color"}
                                    />
                                </div>
                            </div>
                            <div className={"elements_divider_icons"}>
                                <ElementIcon
                                    element={"attachment_clip"}
                                    value={config.customization.attachment_clip}
                                    value_updated={"attachment_clip"}
                                    icon_type={"attachment_clip"}
                                    text_hide={true}
                                />
                                <div className={"element__icon_switch"}>
                                    <Switch checked={config.customization.attachment_clip} onClick={(e) => {
                                        updateCustomization(e, 'bool', 'attachment_clip')
                                    }}/>
                                    <span>{t('card.content.project.web_interface.customization.show_icon')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.send.send.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.send.send.description')}</span>
                        </div>
                        <div className={"elements_divider_container"}>
                            <div className={"elements_divider_colors"}>
                                <div>
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"bg"}
                                        value={config.customization.input_icon_bg_color}
                                        value_updated={"input_icon_bg_color"}
                                    />
                                    <ElementSwitch
                                        color_1={"input_icon_bg_color"}
                                        color_2={"input_icon_color"}
                                        updateConfig={updateConfig}
                                        config={config}
                                    />
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"icon"}
                                        value={config.customization.input_icon_color}
                                        value_updated={"input_icon_color"}
                                    />
                                </div>
                            </div>
                            <div className={"elements_divider_icons"}>
                                <ElementIcon
                                    updateCustomization={updateCustomization}
                                    element={"send"}
                                    img={config.agent_icon}
                                    value={config.customization.input_icon}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* SHOW INPUT */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.send.input_show.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.send.input_show.description')}</span>
                        </div>
                        <Switch checked={config.customization.input_show} onClick={(e) => {
                            updateCustomization(e, 'bool', 'input_show')
                        }}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const a = connect(
    mapStateToProps
)(InputText);

export default withTranslation('common')(withRouter(a));