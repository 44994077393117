import {deleteAllCookies, getCookieByName, getEntityBody, setCookieByName} from 'vendor/application';
import {v4 as uuid} from 'uuid';

/**
 * Este fichero contiene el reducer principal de nuestra aplicación.
 * Basándonos en los tipos de las acciones, se realizaran modificaciones
 * sobre el estado inicial
 */

var access = getCookieByName('b__ac');
var actualProject = getCookieByName('b__ap');
var actualLayout = getCookieByName('b__ly');
var cookie = getCookieByName('b__ck');
var deviceID = getCookieByName('b__di');

if (!deviceID) {
    deviceID = uuid();
}
setCookieByName(deviceID, 'b__di');

// Estado inicial de nuestra aplicación
const initialState = {
    access: {access: '', refresh: '', isAccess: false, userId: ''},
    notify: {message: '', severity: '', type: '', isNotify: false},
    initApp: false,
    cookie: cookie,
    empty: false,
    isSending: false,
    isDeleting: false,
    actualProject: {},
    actualLayout: {},
    roles: {},
    projects: {},
    layouts: {},
    user: {},
    plan: {},
    entity: getEntityBody(),
    limits: {},
    device_id: deviceID,
    filters: {
        contexts: {
            search: ''
        },
        intents: {
            context_selector: {
                id: 'all_contexts',
                name: ''
            },
            search: '',
            pagination: {
                page: 1,
                interval: 10
            },
            params: ['all_intents'],
            order_by: {
                type: 'name',
                value: 'asc'
            }
        },
        entities: {
            search: '',
            pagination: {
                page: 1,
                interval: 10
            },
            order_by: {
                type: '',
                value: false
            }
        }
    },
    limitsIntegrations: '',
    account_integrations: false
}

/**
 * El reducer recibe el estado actual y la acción a ejecutar. Si el estado
 * no está definido, obtenemos el estado por defecto
 */

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'UPDATE_STATE': {
            return Object.assign({}, state)
        }

        case 'UPDATE_ACCESS_STATUS': {
            setCookieByName(action.data, 'b__ac');
            return Object.assign({}, state, {
                access: action.data
            })
        }

        case 'UPDATE_COOKIE_STATUS': {
            setCookieByName(action.data, 'b__ck');
            return Object.assign({}, state, {
                access: action.data
            })
        }

        case 'UPDATE_USER': {
            return Object.assign({}, state, {
                user: action.data
            })
        }

        case 'UPDATE_PLAN': {
            return Object.assign({}, state, {
                plan: action.data
            })
        }

        case 'UPDATE_LIMITS': {
            return Object.assign({}, state, {
                limits: action.data
            })
        }

        case 'UPDATE_LIMITS_INTEGRATIONS': {
            return Object.assign({}, state, {
                limitsIntegrations: action.data
            })
        }

        case 'UPDATE_ACCOUNT_INTEGRATIONS': {
            return Object.assign({}, state, {
                account_integrations: action.data
            })
        }

        case 'UPDATE_EMPTY': {
            return Object.assign({}, state, {
                empty: action.data
            })
        }

        case 'SHOW_NOTIFY': {
            return Object.assign({}, state, {
                notify: {
                    message: action.data.message,
                    severity: action.data.severity,
                    isNotify: true,
                    type: action.data.type,
                    item: action.data.item
                }
            })
        }

        case 'HIDE_NOTIFY': {
            return Object.assign({}, state, {
                notify: {
                    message: action.data.message,
                    severity: action.data.severity,
                    isNotify: false,
                    type: action.data.type,
                    item: action.data.item
                }
            })
        }

        case 'UPDATE_ENTITY': {
            return Object.assign({}, state, {
                entity: action.data
            })
        }

        case 'UPDATE_ROLES': {
            return Object.assign({}, state, {
                roles: action.data
            })
        }

        case 'UPDATE_LAYOUTS': {
            return Object.assign({}, state, {
                layouts: action.data
            })
        }

        case 'UPDATE_ACTUAL_PROJECT': {
            setCookieByName(action.data, 'b__ap');
            return Object.assign({}, state, {
                actualProject: action.data
            })
        }

        case 'UPDATE_ACTUAL_LAYOUT': {
            setCookieByName(action.data, 'b__ly');
            return Object.assign({}, state, {
                actualLayout: action.data
            })
        }

        case 'UPDATE_PROJECTS': {
            return Object.assign({}, state, {
                projects: action.data
            })
        }

        case 'UPDATE_SENDING': {
            return Object.assign({}, state, {
                isSending: action.data
            })
        }

        case 'UPDATE_DELETING': {
            return Object.assign({}, state, {
                isDeleting: action.data
            })
        }

        case 'UPDATE_FILTERS': {
            return Object.assign({}, state, {
                filters: action.data
            })
        }

        case 'UPDATE_INIT_APP': {
            return Object.assign({}, state, {
                initApp: action.data
            })
        }

        case 'RESET_STATE': {
            deleteAllCookies();
            if (document.getElementById('bim-ifr')) {
                document.getElementById('bim-ifr').remove();
            }
            return initialState;
        }

        default: {
            if (access) {
                state = {...state, access}
            }
            if (actualProject) {
                state = {...state, actualProject}
            }
            if (actualLayout) {
                state = {...state, actualLayout}
            }
            if (cookie) {
                state = {...state, cookie}
            }
            return state;
        }
    }
}

export default reducer;
