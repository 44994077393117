import { CustomRequest } from 'vendor/infrastructure/custom_requests';
import { updateSending, updateDeleting } from 'vendor/application/disptach';

export class EntitiesAPI {
    constructor(projectID, token, props, body, notSending) {
        this.projectID = projectID;
        this.token = token;
        this.props = props;
        this.body = body;

        if ( !notSending ) {
            this.props.dispatch(updateSending(true));
        }
    }

    async put_entity() {
        const request = new CustomRequest('PUT', `projects/${this.projectID}/entities/${this.props.id}`, JSON.stringify(this.body), this.token, this.props, true );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async post_entity() {
        const request = new CustomRequest('POST', `projects/${this.projectID}/entities`, JSON.stringify(this.body), this.token, this.props, true );

        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_entities() {
        let request = new CustomRequest('GET', `projects/${this.projectID}/entities`, null, this.token, this.props, true );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_entity_by_id() {
        let request = new CustomRequest('GET', `projects/${this.projectID}/entities/${this.props.id}`, null, this.token, this.props, true );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async delete_entity(id) {
        let request = new CustomRequest('DELETE', `projects/${this.projectID}/entities/${(this.props.id) ? this.props.id : id}`, null, this.token, this.props, true );
        var req = await request.action();
        this.props.dispatch(updateDeleting(false));
        return req;
    }


}