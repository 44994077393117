import { CustomRequest } from 'vendor/infrastructure/custom_requests';
import { updateSending } from 'vendor/application/disptach';

export class CampaignsAPI {
    constructor(id, token, props, body) {
        this.id = id;
        this.token = token;
        this.props = props;
        this.body = body;
        this.props.dispatch(updateSending(true));
    }

    async get_templates() {
        let request = new CustomRequest('GET', `projects/${this.id}/templates`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_template(template_id) {
        let request = new CustomRequest('GET', `projects/${this.id}/templates/${template_id}`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async post_template() {
        let request = new CustomRequest('POST', `projects/${this.id}/templates`, JSON.stringify(this.body), this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_groups() {
        let request = new CustomRequest('GET', `projects/${this.id}/campaigns`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_individual_notifications(filters) {
        let request = new CustomRequest('GET', `projects/${this.id}/notifications?from=${filters.start}&to=${filters.end}&page=${filters.page ?? 1}&page_size=10`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_group(id) {
        let request = new CustomRequest('GET', `projects/${this.id}/campaigns/${id}`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async cancel_group(id) {
        let request = new CustomRequest('PUT', `projects/${this.id}/campaigns/${id}`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_group_detail(id) {
        let request = new CustomRequest('GET', `projects/${this.id}/campaigns/${id}/detail`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async post_group() {
        let request = new CustomRequest('POST', `projects/${this.id}/campaigns`, JSON.stringify(this.body), this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_webhook() {
        let request = new CustomRequest('GET', `accounts/${this.id}/centripush`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async post_webhook() {
        let request = new CustomRequest('POST', `accounts/${this.id}/centripush`, JSON.stringify(this.body), this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_categories() {
        let request = new CustomRequest('GET', `templates/categories`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_languages() {
        let request = new CustomRequest('GET', `templates/languages`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_centripush_limits() {
        let request = new CustomRequest('GET', `accounts/${this.id}/centripush/limits`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

}