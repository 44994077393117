// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Translations
import {withTranslation} from "react-i18next";
// Styles
import 'components/Accordion/styles/styles.scss';
// Components
import CreateContextModal from "views/Bots/NLP/application/Build/Tabs/Contexts/application/context_create_modal";
import {Button, SelectSimple} from '@components/Input';
import {Chip} from "@material-ui/core";
import {AlertInfo} from "@components/Alert";
import {PopUp} from "@components/Modals";
// API
import {AmplitudeAPI} from '@api/Amplitude';
import {ContextsAPI} from "views/Bots/NLP/application/Build/Tabs/Contexts/infrastructure";
// Assets
import {addSvg} from "@assets";
// Vendor
import {idExist, returnElementFromArray} from "vendor/application";
import {showNotify} from "vendor/application/disptach";

class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contexts_selected: '',
            contexts_show: '',
            new_context: {
                name: "",
                description: ""
            },
            open: false
        }
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.contexts.length !== this.props.contexts.length) {
            this.handleContexts();
        }
    }

    componentDidMount = () => {
        this.handleContexts();
    }

    handleContexts = () => {
        const {context_type, contexts, intent} = this.props;

        var contexts_selected = [],
            contexts_show = JSON.parse(JSON.stringify(contexts));

        intent.contexts[context_type].forEach((el) => {
            var element = returnElementFromArray(context_type === 'output' ? el.id : el, contexts, 'id');
            if (element) {
                contexts_selected.push(element);
                var i = idExist(element.id, contexts_show, true);
                if (i !== false) {
                    contexts_show.splice(i, 1);
                }
            }
        });

        this.setState({contexts_selected: contexts_selected, contexts_show: contexts_show});
    }

    addContext = (id) => {
        const {context_type, contexts, intent, updateIntent} = this.props;
        const {contexts_selected, contexts_show} = this.state;

        var context = returnElementFromArray(id, contexts, 'id'),
            context_iteration = idExist(context.id, contexts_show, true);

        contexts_selected.push(context);
        contexts_show.splice(context_iteration, 1);

        if (context_type === 'output') {
            intent.contexts[context_type].push({id: context.id, lifespan: 0});
        } else {
            intent.contexts[context_type].push(context.id);
        }

        this.setState({
            contexts_selected: contexts_selected,
            contexts_show: contexts_show,
        });

        updateIntent(intent);
    }

    deleteContext = (element) => {
        const {context_type, intent, updateIntent} = this.props;
        const {contexts_selected, contexts_show} = this.state;

        var context_iteration = idExist(element.id, contexts_selected, true);

        contexts_selected.splice(context_iteration, 1);
        intent.contexts[context_type].splice(context_iteration, 1);
        contexts_show.push(element);

        contexts_show.sort(function (aa, bb) {
            return new Date(aa.created_at) - new Date(bb.created_at);
        });

        this.setState({
            contexts_selected: contexts_selected,
            contexts_show: contexts_show
        });

        updateIntent(intent);
    }

    updateLifespan = (e, i) => {
        const {context_type, intent, updateIntent} = this.props;
        const {contexts_selected} = this.state;

        contexts_selected[i].lifespan = e.target.value;
        intent.contexts[context_type][i].lifespan = e.target.value;

        this.setState({
            contexts_selected: contexts_selected
        });

        updateIntent(intent);
    }

    updateNewContext = (e, type) => {
        const {new_context} = this.state;
        if (type === "name" && e.match(/^[a-zA-Z0-9_\/]*$/) || type === "description") {
            new_context[type] = e;
        }

        this.setState({new_context: new_context});
    }

    createContext = async () => {
        const {new_context} = this.state;
        const {access, actualProject, user, device_id} = this.props.data;
        const {contexts, updateContexts} = this.props;
        try {
            const obj = new ContextsAPI(actualProject.id, access, this.props, new_context);
            const res = await obj.post_context();

            this.props.dispatch(showNotify({message: "contexts", type: "create", severity: "success"}));

            contexts.push(res);
            updateContexts(contexts);

            new AmplitudeAPI({
                event_type: 'Create context',
                device_id: device_id,
                user_id: user.email
            }).eventLog();

            this.setState({open: false, new_context: {name: "", description: ""}});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    render() {
        const {type, intent, t} = this.props;
        const {contexts_selected, contexts_show, new_context, open} = this.state;
        return (
            <div className={"accordion__element"}>
                {
                    contexts_selected &&
                    <div
                        className={`accordion__element_contexts ${contexts_selected.length === 0 ? 'accordion__element_contexts_flex' : ''}`}>
                        <div className={"elements__contexts"}>
                            <SelectSimple
                                valueSelected={false}
                                items={contexts_show}
                                onSelect={this.addContext}
                                placeholder={t(`intents.config.accordion.${type}.contexts.select`)}
                                type={type}
                                className={"select_primary"}
                                value={"id"}
                                t={t}
                            />
                            <Button className={"btn_icon"} type={"white"} onClick={() => {
                                this.setState({open: true})
                            }} text={addSvg()}/>
                        </div>
                        <div className={`elements__chips`}>
                            {contexts_selected.length > 0 ?
                                contexts_selected.map((el, i) => {
                                    return (
                                        <Chip
                                            key={i}
                                            label={<span>
                                        {el.name}
                                                {type === 'responses' &&
                                                <div className={"lifespan"}>
                                                    <span>{t(`intents.config.accordion.${type}.contexts.lifespan`)} </span>
                                                    <input type="number"
                                                           onChange={(e) => {
                                                               this.updateLifespan(e, i)
                                                           }}
                                                           value={intent.contexts.output[i].lifespan}/>
                                                </div>
                                                }</span>}
                                            variant="outlined"
                                            onDelete={() => this.deleteContext(el)}
                                        />
                                    )
                                }) :
                                <AlertInfo text={t(`intents.config.accordion.${type}.contexts.info`)}/>
                            }
                        </div>
                    </div>
                }
                <PopUp
                    open={open}
                    handleClose={() => this.setState({open: false, new_context: {name: "", description: ""}})}
                    t={t}
                    width={400}
                    content={
                        <CreateContextModal
                            type={"content"}
                            new_context={JSON.parse(JSON.stringify(new_context))}
                            updateContext={this.updateNewContext}
                        />
                    }
                    buttons={
                        <CreateContextModal
                            type={"button"}
                            new_context={JSON.parse(JSON.stringify(new_context))}
                            createContext={this.createContext}
                        />
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const contexts = connect(
    mapStateToProps
)(Index);

export default withTranslation(['common', 'languages'])(contexts);