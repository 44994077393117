// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {Route, Switch, withRouter} from 'react-router-dom';
// Components
import NotFound from 'components/NotFound/application/404';
// Containers
import Templates from 'views/Campaigns/application/Templates/templates_list';
import TemplatesCreate from 'views/Campaigns/application/Templates/templates_create';
import Webhook from 'views/Campaigns/application/Webhook';
import Notifications from 'views/Campaigns/application/Notifications';
import History from 'views/Campaigns/application/History';
// Application
import {updateActualLayout} from 'vendor/application/disptach';

class RouteCampaigns extends Component {
    componentDidMount = () => {
        this.props.dispatch(updateActualLayout({layout: 'campaigns', project: this.props.data.actualLayout.project}));
    }

    componentDidUpdate = () => {
        const {match, data} = this.props;
        this.setLayout(match.params.type, data.actualLayout.project);
    }

    setLayout = (layout, project) => {
        const {actualLayout} = this.props.data;
        if (actualLayout.layout !== layout || actualLayout.project !== project) {
            var data = {layout: layout, project: project};
            this.props.dispatch(updateActualLayout(data));
        }
    }

    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route path="/campaigns/templates" exact component={Templates}/>
                    <Route path="/campaigns/templates/create" exact component={TemplatesCreate}/>
                    <Route path="/campaigns/webhook" exact component={Webhook}/>
                    <Route path="/campaigns/notifications" exact component={Notifications}/>
                    <Route path="/campaigns/history" exact component={History}/>
                    <Route path="/campaigns/:type" component={NotFound}/>
                </Switch>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_route_campaigns = connect(
    mapStateToProps
)(RouteCampaigns);

export default withRouter(connect_route_campaigns);