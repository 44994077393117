// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Translations
import { withTranslation } from "react-i18next";
// Material
import { FormControl, Select, MenuItem, Input } from '@material-ui/core';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class SelectMultiple extends Component {

    handleOnSelect = (e) => {
        e.preventDefault();
        this.props.onSelect(e.target.value);
    }

    handleItem = (item, i) => {
        const { id, value } = this.props;
        return <MenuItem className={`${id}-item`} key={i} value={value ? item[value] : item}>{item.name}</MenuItem>
    }

    render() {
        const { selectedArray, elementsArray, t, placeholder, id, className } = this.props;
        return (
            <FormControl className={`input-select ${className}`}>
                <Select
                    multiple
                    displayEmpty
                    id={id}
                    className={className}
                    value={selectedArray}
                    onChange={this.handleOnSelect}
                    input={<Input />}
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return <em>{placeholder}</em>;
                        }
                        return <span style={{textTransform: 'capitalize'}}>{selected.join(', ')}</span>;
                    }}
                    MenuProps={{
                        popoverclasses: id,
                        getContentAnchorEl: null,
                        anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                        },
                        style: {
                            maxHeight: 120 * 4.5,
                            width: '20ch',
                        }
                    }}
                    IconComponent={ExpandMoreIcon}
                >
                    <MenuItem disabled value="">
                        <em>{placeholder}</em>
                    </MenuItem>
                    {
                        elementsArray && elementsArray.length > 0 ?
                            elementsArray.map((item, i) => {
                                return (
                                    this.handleItem(item, i)
                                )
                            })
                            : <MenuItem disabled value={0}>{t('select.no_results')}</MenuItem>
                    }
                </Select>
            </FormControl>

        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_select = connect(
    mapStateToProps
)(SelectMultiple);

export default withTranslation(['common', 'languages'])(connect_select);