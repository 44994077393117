// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Styles
import 'date-fns';
// Translations
import {withTranslation} from "react-i18next";
import enLocale from "date-fns/locale/en-US";
import esLocale from 'date-fns/locale/es';
import ptLocale from 'date-fns/locale/pt';
// Infrastructure
import {ProjectAPI} from '../../../../../api/application/Projects';
// Vendor
import {showNotify} from 'vendor/application/disptach';
// Components
import {AlertInfo} from "@components/Alert";
import {Divider} from "@material-ui/core";
import {Button} from "@components/Input";
// Material
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Alert from "@material-ui/lab/Alert";
// Svg
import {svgCalendar, svgClock, deploySvg} from 'assets';

const localeMap = {
    en: enLocale,
    es: esLocale,
    pt: ptLocale
};

class StatsWhatsappSessions extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            dateRange: {},
            start: false,
            end: false,
            objDateRange: {
                startDate: new Date(date.setMonth(date.getMonth() - 1)),
                endDate: new Date(date.setMonth(date.getMonth() + 1))
            }
        }
    }

    doAction = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props, {
                "action": "request_whatsapp_sessions",
                "info": {
                    "from": this.getStartDate(true),
                    "to": this.getEndDate(true)
                }
            });
            await obj.do_actions();

            this.props.dispatch(showNotify({message: "project", type: "request_statistics", severity: "success"}));
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    // update objDateRange start
    handleSelectStart = (start) => {
        var end = this.state.objDateRange.endDate;
        if (start > end) {
            end = start;
        }
        this.setState({
            objDateRange: {
                startDate: start,
                endDate: end
            }
        })
    }

    // update initalDateRange end
    handleSelectEnd = (end) => {
        var start = this.state.objDateRange.startDate;
        if (end < start) {
            start = end;
        }
        this.setState({
            objDateRange: {
                startDate: start,
                endDate: end
            }
        })
    }

    getStartDate = (send) => {
        const {objDateRange} = this.state;
        var m = objDateRange.startDate.getMonth(),
            y = objDateRange.startDate.getFullYear()

        if (send) {
            return `${y}-${m + 1}-1`;
        }
        return `${m + 1} / ${y}`;
    }

    getEndDate = (send) => {
        const {objDateRange} = this.state;
        var m = objDateRange.endDate.getMonth(),
            y = objDateRange.endDate.getFullYear();

        if (send) {
            return `${y}-${m + 1}-1`;
        }
        return `${m + 1} / ${y}`;
    }

    openStart = () => {
        this.setState({start: !this.state.start});
    }

    openEnd = () => {
        this.setState({end: !this.state.end});
    }

    render() {
        const {t} = this.props;
        const {
            objDateRange,
            start,
            end
        } = this.state;

        return (
            <div className="stats-container">
                <AlertInfo className={"app_bar"} text={t('stats.info_1')} />
                <Divider />
                <div className="stats-header">
                    <div className="stats-date-container">
                        <div className="stats-date">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}
                                                     locale={localeMap[this.props.i18n.language]}>
                                <div className="start-date" onClick={() => this.openStart()}>
                                    <div>
                                        <span>{svgCalendar()}</span>
                                        <span>{t('stats.start')}</span>
                                        <span>{deploySvg(false)}</span>
                                    </div>
                                    <div>
                                        <span>{svgClock()}</span>
                                        <span>{this.getStartDate()}</span>
                                    </div>
                                </div>
                                <DatePicker
                                    value={objDateRange.startDate}
                                    onChange={this.handleSelectStart}
                                    animateYearScrolling
                                    views={["year", "month"]}
                                    openTo={"month"}
                                    autoOk
                                    onClose={this.openStart}
                                    open={start}
                                />
                                <div className="end-date" onClick={() => this.openEnd()}>
                                    <div>
                                        <span>{svgCalendar()}</span>
                                        <span>{t('stats.end')}</span>
                                        <span>{deploySvg(false)}</span>
                                    </div>
                                    <div>
                                        <span>{svgClock()}</span>
                                        <span>{this.getEndDate()}</span>
                                    </div>
                                </div>
                                <DatePicker
                                    value={objDateRange.endDate}
                                    onChange={this.handleSelectEnd}
                                    animateYearScrolling
                                    views={["year", "month"]}
                                    openTo={"month"}
                                    autoOk
                                    onClose={this.openEnd}
                                    open={end}
                                />
                            </MuiPickersUtilsProvider>

                        </div>
                    </div>

                    <div className="actions">
                        <Button type={"primary"}
                                text={t('stats.whatsapp_sessions.action_button')}
                                onClick={(e) => this.doAction(e)}/>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_stats_statistics = connect(
    mapStateToProps
)(StatsWhatsappSessions);

export default withTranslation('common')(withRouter(connect_stats_statistics));