// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Translations
import {withTranslation} from "react-i18next";
// Styles
import 'components/Accordion/styles/styles.scss';
// Components
import {Input} from "@components/Input";

class ChatGPT extends Component {
    updateSelectorElement = (e, type) => {
        const {intent, updateIntent} = this.props;
        switch (type) {
            case 'max_tokens':
            case 'max_interactions':
                intent.desks.info = {
                    ...intent.desks.info,
                    [type]: parseInt(e.target.value)
                }
                break;
            default:
                break;
        }

        updateIntent(intent);
    }

    render() {
        const {intent, t} = this.props;
        return (
            <div className={"info__integration"}>
                <div className={"info__integration_content"}>
                    <div className={"element__integration_selector"}>
                        <Input
                            onChange={(e) => this.updateSelectorElement(e, 'max_tokens')}
                            value={intent.desks.info.max_tokens ?? 0}
                            inputType={"number"}
                            label={t(`common:intents.integrations.max_tokens`)}
                            type={"primary"}
                        />
                    </div>
                    <div className={"element__integration_selector"}>
                        <Input
                            onChange={(e) => this.updateSelectorElement(e, 'max_interactions')}
                            value={intent.desks.info.max_interactions ?? 0}
                            inputType={"number"}
                            label={t(`common:intents.integrations.max_interactions`)}
                            type={"primary"}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const channel = connect(
    mapStateToProps
)(ChatGPT);

export default withTranslation(['commons', 'common', 'languages'])(channel);