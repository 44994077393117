// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Translations
import {withTranslation} from "react-i18next";
// Material UI
import {Dialog, DialogContent, CircularProgress} from '@material-ui/core';
// Components
import {Button} from '@components/Input';
// Application
import {ElementsHandleAPI} from 'vendor/application/handleMethods';
import $ from 'jquery';

class DialogAction extends Component {
    componentDidMount = () => {
        this.handlePaste();
    }

    handlePaste = () => {
        $(document).on("cut copy paste", "#dialog_action", function (e) {
            e.preventDefault();
        });
    }

    handleAction = (e) => {
        const {item, handleAction} = this.props;

        handleAction(e, item.id);
    }

    handleCancel = () => {
        this.setState({data: {name: ''}});
        this.props.handleClickOpen(this.props.item.action);
    }

    render() {
        const {handleClickOpen, open, t, actualLayout, item, success} = this.props;
        return (
            item && !item?.action || item?.action && item?.action.type !== 'mouseup' ?
                <Dialog className={`dialog_action ${success ? 'dialog_success' : 'dialog_delete'}`}
                        onClose={handleClickOpen} open={open}>
                    <DialogContent>
                        <div className={"dialog"}>
                            <span className={`${(item.action === 'publish_sandbox' || success) ? 'confirm' : 'error'} title`}>{t(`card.dialog_action.${actualLayout}.title`)}</span>
                            <span className={"description"} dangerouslySetInnerHTML={{__html: t(`card.dialog_action.${actualLayout}.description_01`, {name: item?.name})}} />
                            {
                                t(`card.dialog_action.${actualLayout}.description_02`) &&
                                <span
                                    className={"description"}>{t(`card.dialog_action.${actualLayout}.description_02`)}</span>
                            }
                        </div>
                        <div className="btn-container">
                            <Button type={"secondary"} text={t(`card.dialog_action.${actualLayout}.button_cancel`)}
                                    onClick={this.handleCancel}/>
                            {
                                this.props.data.isSending ? (
                                    <Button type={"primary"} text={<CircularProgress size={21}/>}/>
                                ) : (
                                    <Button
                                        type={`${(item.action === 'publish_sandbox' || success) ? 'success' : 'normal_delete'}`}
                                        onClick={(e) => this.handleAction(e)}
                                        text={t(`card.dialog_action.${actualLayout}.button_action`)}/>
                                )
                            }
                        </div>
                    </DialogContent>
                </Dialog> : ''
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_dialog_action = connect(
    mapStateToProps
)(DialogAction);

export default withTranslation('common')(withRouter(connect_dialog_action));
