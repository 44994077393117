// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Infrastructure
import {ProjectAPI} from 'api/application/Projects';
// Application
import {ElementsHandleAPI} from 'vendor/application/handleMethods';
// Material UI
import {AppBar, Divider, Tab, Tabs} from '@material-ui/core';
// Vendor
import {isPlanAllowed} from 'vendor/application';
import {showNotify, updateActualLayout} from 'vendor/application/disptach';
// Components
import {Button, Input, SelectSimple} from "@components/Input";
import BusinessHours from './Tabs/BusinessHours/application';
import ChatbotActions from './Tabs/actions';
import ChatbotInactivity from './Tabs/inactivity';
import ChatbotPolls from "./Tabs/polls";
import ChatbotWebhook from "./Tabs/webhook";
import UpgradePlan from "components/Plan/application/upgrade_plan";
import {TabPanel} from "components/Navigation/tab_panel";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import 'views/Bots/ChatBot/styles/index.scss';
// Amplitude API
import {AmplitudeAPI} from '@api/Amplitude';

class ChatbotEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: '',
            limits: '',
            countries: [],
            country: '',
            timezones: '',
            empty: false
        }
    }

    componentDidMount = () => {
        const {actualProject} = this.props.data;
        const {history} = this.props;
        if (!actualProject.active) {
            history.push(`/${actualProject.id}/stats`);
        } else {
            this.getProject();
            this.handleTabs();
            this.setLayout();
        }
    }

    setLayout = () => {
        const {actualLayout} = this.props.data;
        var data = {layout: 'chatbot', project: actualLayout.project};
        this.props.dispatch(updateActualLayout(data));
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.data.actualProject.id !== this.props.data.actualProject.id) {
            this.getProject();
        }
    }

    getProject = async () => {
        const {access, actualProject} = this.props.data;
        try {
            let obj = new ProjectAPI(actualProject.id, access, this.props);
            let project = await obj.get_project_by_id();
            this.setState({project: project});

            this.getCountries();
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    getCountries = async () => {
        const {access, actualProject} = this.props.data;
        try {
            let obj = new ProjectAPI(actualProject.id, access, this.props);
            let countries = await obj.get_countries();
            if (this.state.project.timezone) {
                this.handleCountry(countries);
            }
            this.setState({countries: countries});

        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    handleCountry = (countries) => {
        const {project} = this.state;
        countries.forEach(country => {
            country.timezones.forEach(timezone => {
                if (timezone === project.timezone) {
                    this.setState({country: country.name, timezones: country.timezones});
                }
            })
        })
    }

    returnToList = () => {
        const {location, id, history} = this.props;
        var url = location.pathname.replace(`/edit/${id}`, '');
        history.push(url);
    }

    updateElement = (e, type, i) => {
        const {project} = this.state;
        const obj = new ElementsHandleAPI(project, e.target.value, type, i);
        const res = obj.updateObj();
        this.setState({project: res});
    }

    onSave = async (e) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;

        var empty = '';

        Object.keys(this.state.project).forEach(el => {
            if (!this.state.project[el] && (el === 'lang' || el === 'name' || el === 'description')) {
                if (!empty) {
                    empty = el;
                }
            }
        });

        if (!empty) {
            try {
                const obj = new ProjectAPI(actualProject.id, access, this.props, this.state.project);
                await obj.save_project();

                this.props.dispatch(showNotify({message: "project", type: "edit", severity: "success"}));

            } catch (err) {
                this.props.dispatch(showNotify({message: err, severity: 'error'}));
            }
        } else {
            this.props.dispatch(showNotify({message: `${empty}_null`, severity: 'error'}));
            this.setState({error: empty});
        }
    }

    onSelect = (e) => {
        const {project} = this.state;
        project.lang = e;
        this.setState({project: project})
    }

    onSelectCountry = (e) => {
        const {countries} = this.state;
        countries.forEach(country => {
            if (country.name === e) {
                this.state.project.timezone = country.timezones[0];
                this.setState({country: country.name, timezones: country.timezones, project: this.state.project});
            }
        });
    }

    onSelectTimezone = (e) => {
        this.state.project.timezone = e;
        this.setState({project: this.state.project});
    }

    handleTabs = () => {
        const {history} = this.props;
        const queryString = window.location.search,
            urlParams = new URLSearchParams(queryString),
            tab = urlParams.get('tab');

        switch (tab) {
            case 'general':
                this.setState({tabValue: 0});
                break;
            case 'actions':
                this.setState({tabValue: 1});
                break;
            case 'inactivity':
                this.setState({tabValue: 2});
                break;
            case 'polls':
                this.setState({tabValue: 3});
                break;
            case 'webhook':
                this.setState({tabValue: 4});
                break;
            case 'business_hours':
                this.setState({tabValue: 5});
                break;
            default:
                history.push(`${this.props.location.pathname}?tab=general`)
                this.setState({tabValue: 0});
                break;
        }
    }

    setQuery = (e, to) => {
        e.preventDefault();
        const {device_id, user} = this.props.data;

        new AmplitudeAPI({
            event_type: `AO: Click on ${to}`,
            device_id: device_id,
            user_id: user.email
        }).eventLog();

        this.props.history.push(`${this.props.location.pathname}?tab=${to}`);
        setTimeout(() => {
            this.handleTabs();
        }, 10);
    }

    a11yProps(index) {
        return {
            id: `project-tab-${index}`,
            'aria-controls': `project-tabpanel-${index}`,
        };
    }

    render() {
        const {
            project,
            tabValue,
            error,
            countries,
            country,
            timezones
        } = this.state;
        const {t} = this.props;
        const {plan, limits, limitsIntegrations, actualProject} = this.props.data;
        return (
            project &&
            <React.Fragment>
                <AppBar id={"app_bar"} style={{borderRadius: "5px 5px 0 0", overflow: "hidden"}} position="static"
                        color="default">
                    <Tabs
                        value={tabValue}
                        onChange={this.handleChange}
                        variant="scrollable"
                        scrollButtons="on"
                        textColor="primary"
                    >
                        {/* GENERAL */}
                        <Tab label={t(`card.content.project.general.title`)} onClick={(e) => {
                            this.setQuery(e, 'general')
                        }} {...this.a11yProps(0)} />
                        {/* ACTIONS */}
                        <Tab label={t(`card.content.project.actions.title`)} onClick={(e) => {
                            this.setQuery(e, 'actions')
                        }} {...this.a11yProps(1)} />
                        {/* ACTIONS */}
                        <Tab label={t(`card.content.project.inactivity.title`)} onClick={(e) => {
                            this.setQuery(e, 'inactivity')
                        }} {...this.a11yProps(2)} />
                        {/* POLLS */}
                        <Tab label={t(`card.content.project.polls.title`)} onClick={(e) => {
                            this.setQuery(e, 'polls')
                        }} {...this.a11yProps(3)} />
                        {/* WEBHOOK */}
                        <Tab label={'Webhook'} onClick={(e) => {
                            this.setQuery(e, 'webhook')
                        }} {...this.a11yProps(4)} />
                        {/* BUSINESS HOURS */}
                        {(limits.hours_control && (limitsIntegrations.zendesk_full?.existIntegration || limitsIntegrations.centridesk?.existIntegration || limitsIntegrations.zendesk_chat?.existIntegration || limitsIntegrations.sunco?.existIntegration)) &&
                        <Tab label={t(`business_hours.title`)} onClick={(e) => {
                            this.setQuery(e, 'business_hours')
                        }} {...this.a11yProps(5)} />
                        }
                    </Tabs>
                    <Divider/>
                </AppBar>
                {/* GENERAL */}
                <TabPanel value={tabValue} index={0}>
                    {/* GENERAL */}
                    <div className={"cen_card"}>
                        <div className={"header"}>
                            <div className={"header_content"}>
                                <div className={"header_title"}>
                                    <span className={"title"}>{t('card.content.project.general.title')}</span>
                                    <span
                                        className={"description"}>{t('card.content.project.general.description')}</span>
                                </div>
                            </div>
                            <div className={"header_actions"}>
                                <Button onClick={(e) => {
                                    this.onSave(e)
                                }} type={"primary"} text={t('card.content.project.button_save')}/>
                            </div>
                        </div>
                        <div className={"content"}>
                            {/* NAME */}
                            <div className={"elements_custom"}>
                                <div className={"elements_custom__info"}>
                                    <span
                                        className="elements_custom__title">{t(`card.content.project.general.name.title`)}</span>
                                    <span
                                        className="elements_custom__description">{t(`card.content.project.general.name.description`)}</span>
                                </div>
                                <div className="platform_input_content">
                                    <Input error={error === 'name'} onChange={(e) => {
                                        this.updateElement(e, 'name')
                                    }} value={project.name} type={'primary'}
                                           placeholder={t(`card.content.project.general.name.placeholder`)}/>
                                </div>
                            </div>
                            <Divider/>
                            {/* DESCRIPTION */}
                            <div className={"elements_custom"}>
                                <div className={"elements_custom__info"}>
                                    <span
                                        className="elements_custom__title">{t(`card.content.project.general.p_description.title`)}</span>
                                    <span
                                        className="elements_custom__description">{t(`card.content.project.general.p_description.description`)}</span>
                                </div>
                                <div className="platform_input_content">
                                    <Input error={error === 'description'} value={project.description}
                                           type={'primary'} minRows={3} multiline
                                           placeholder={t(`card.content.project.general.p_description.placeholder`)}
                                           onChange={(e) => this.updateElement(e, 'description')}/>
                                </div>
                            </div>
                            <Divider/>
                            {/* LANGUAGE */}
                            <div className={"elements_custom"}>
                                <div className={"elements_custom__info"}>
                                    <span
                                        className="elements_custom__title">{t(`card.content.project.general.language.title`)}</span>
                                    <span
                                        className="elements_custom__description">{t(`card.content.project.general.language.description`)}</span>
                                </div>
                                <div className="platform_input_content">
                                    <SelectSimple
                                        valueSelected={project.lang ? t(`languages:languages.${project.lang}`) : 'select'}
                                        placeholder={t(`select.language_text`)}
                                        className={"select_secondary"}
                                        type={'lang'}
                                        error={error === 'lang'}
                                        disabled={true}
                                        t={t}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* BUSINESS HOURS */}
                    {
                        countries && countries.length > 0 &&
                        <div className={"cen_card"}>
                            <div className={"header"}>
                                <div className={"header_content"}>
                                    <div className={"header_title"}>
                                        <span
                                            className={"title"}>{t('card.content.project.business_hours.title')}</span>
                                        <span
                                            className={"description"}>{t('card.content.project.business_hours.description')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"content"}>
                                {/* COUNTRY */}
                                <div className={"elements_custom"}>
                                    <div className={"elements_custom__info"}>
                                    <span
                                        className="elements_custom__title">{t(`card.content.project.business_hours.country.title`)}</span>
                                        <span
                                            className="elements_custom__description">{t(`card.content.project.business_hours.country.description`)}</span>
                                    </div>
                                    <div className="platform_input_content">
                                        <SelectSimple
                                            valueSelected={country}
                                            placeholder={t(`card.content.project.business_hours.country.placeholder`)}
                                            items={countries}
                                            onSelect={this.onSelectCountry}
                                            className={"select_secondary"}
                                            type={"country-timezone"}
                                            value={"name"}
                                            t={t}
                                        />
                                    </div>
                                </div>
                                <Divider/>
                                {/* TIMEZONE */}
                                <div className={`elements_custom ${country ? '' : 'opacity_5'}`}>
                                    <div className={"elements_custom__info"}>
                                    <span
                                        className="elements_custom__title">{t(`card.content.project.business_hours.timezone.title`)}</span>
                                        <span
                                            className="elements_custom__description">{t(`card.content.project.business_hours.timezone.description`)}</span>
                                    </div>
                                    <div className="platform_input_content">
                                        <SelectSimple
                                            valueSelected={project.timezone ? project.timezone : ''}
                                            placeholder={t(`card.content.project.business_hours.timezone.placeholder`)}
                                            items={timezones}
                                            onSelect={this.onSelectTimezone}
                                            className={"select_secondary"}
                                            t={t}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </TabPanel>
                {/* ACTIONS */}
                <TabPanel value={tabValue} index={1}>
                    <ChatbotActions getProject={this.getProject} project={project} project_id={actualProject.id}
                                    project_lang={project.lang} project_name={project.name}/>
                </TabPanel>
                {/* INACTIVITY */}
                <TabPanel value={tabValue} index={2}>
                    {
                        isPlanAllowed(plan.plan.name) ? (
                            <ChatbotInactivity project_id={actualProject.id} project_lang={project.lang}
                                               project_name={project.name}/>
                        ) : (
                            <UpgradePlan/>
                        )
                    }
                </TabPanel>
                {/* POLLS */}
                <TabPanel value={tabValue} index={3}>
                    {
                        isPlanAllowed(plan.plan.name) ? (
                            <ChatbotPolls project_id={actualProject.id} project_lang={project.lang}
                                          project_name={project.name}/>
                        ) : (
                            <UpgradePlan/>
                        )
                    }
                </TabPanel>
                {/* WEBHOOK */}
                <TabPanel value={tabValue} index={4}>
                    {
                        isPlanAllowed(plan.plan.name) ? (
                            <ChatbotWebhook project_id={actualProject.id} project_lang={project.lang}
                                            project_name={project.name}/>
                        ) : (
                            <UpgradePlan/>
                        )
                    }
                </TabPanel>
                {/* BUSINESS HOURS */}
                <TabPanel value={tabValue} index={5}>
                    <BusinessHours project={actualProject.id} actualProject={actualProject}/>
                </TabPanel>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_chatbot = connect(
    mapStateToProps
)(ChatbotEdit);

export default withTranslation('common')(withRouter(connect_chatbot));