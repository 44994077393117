// React
import React from 'react';
// Components
import {Input} from '@components/Input';

const InputElement = ({data, i, value, type, on_change_type, on_blur_type, multiline}) => {
    return (
        <Input
            id={data.id}
            placeholder={`Escribe aquí...`}
            inputProps={{maxLength: 4096}}
            multiline={multiline}
            value={value}
            onChange={(e) => {
                data.updateNode(on_change_type, {
                    id: data.id,
                    type: type,
                    iteration: i,
                    [type]: e?.target?.value
                })
            }}
            onBlur={() => {
                data.updateNode(on_blur_type, {...data, type_update: type}, i)
            }}
        />
    )
}

export default InputElement;