// React
import React, {Component} from 'react';
import {connect} from "react-redux";
// Svg
import {viewSvg} from "assets";
// Components
import LongImage from 'components/Files/Image/LongImage';
// Styles
import './styles/styles.scss';

class ShortImage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        }
    }

    handleClickOpen = () => {
        const {open} = this.state;
        this.setState({open: !open})
    }

    render() {
        const {open} = this.state;
        const {image, alt} = this.props;
        return (
            <React.Fragment>
                <div className={"image__element_short"} onClick={() => this.handleClickOpen()}>
                    <div className={"image__element_button"}>{viewSvg()}</div>
                    <img src={`${process.env.PUBLIC_URL}/img/views/${image}`} alt={alt}/>
                </div>
                {
                    open &&
                    <LongImage image={image} alt={alt} handleClickOpen={this.handleClickOpen}/>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const image = connect(
    mapStateToProps
)(ShortImage);

export default image;