// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {Link, withRouter} from "react-router-dom";
// Material
import {CircularProgress, Divider} from '@material-ui/core';
// Vendor
import {showNotify} from 'vendor/application/disptach';
// Infrastructure
import {IntegrationsAPI} from 'api/application/Integrations';
import {BusinessAPI} from 'views/Bots/ChatBot/application/Tabs/BusinessHours/infrastructure';
// Application
import {ElementsHandleAPI} from 'vendor/application/handleMethods';
import {getCentrideskBody, getStatusArray} from "vendor/application";
// Translations
import {withTranslation} from "react-i18next";
// Components
import {Button} from '@components/Input';
import {ElementsCustom} from "@components/Elements";
import DialogAction from "components/Dialog/DialogAction";
// SVG
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            centridesk: false,
            hours: '',
            integration: true,
            tagValid: true,
            open: false,
            settings: {
                tags: "",
                tags_hours_control: "",
                tags_individual: "",
                tags_campaign: ""
            }
        }
    }

    componentDidMount() {
        this.getHours();
        this.getIntegration();
    }

    // get integrations from actual project id
    getIntegration = async () => {
        const {access, actualProject} = this.props.data;
        try {
            let obj = new IntegrationsAPI(actualProject.id, access, this.props);
            let integration = await obj.get_integration_by_id();
            let default_integration = getCentrideskBody();

            if (!integration.info.centripush) {
                integration.info.centripush = {...default_integration.info.centripush};
            }
            if (!integration.info.hours_control) {
                integration.info.hours_control = {
                    "subject": "",
                    "tags": [],
                    "status": "new",
                    "active": false
                }
            }

            this.setState({centridesk: integration});
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data));
            this.return();
        }
    }

    getHours = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new BusinessAPI(actualProject.id, access, this.props);
            const hours = await obj.get_hours();

            this.setState({hours: hours});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    // update element state
    updateElement = (e, type, i) => {
        const {centridesk} = this.state;
        const obj = new ElementsHandleAPI(centridesk.info, e.target.value, type, i);
        obj.updateObj();

        this.setState({centridesk: centridesk});
    }

    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations`);
    }

    putIntegration = async (e) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {centridesk} = this.state;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props, centridesk);
            await obj.put_integration();
            this.props.dispatch(showNotify({message: "integrations", type: "edit", severity: "success"}));
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    handleDelete = async () => {
        const {access, actualProject} = this.props.data;
        const {centridesk} = this.state;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            await obj.delete_integration(centridesk.id);

            this.props.dispatch(showNotify({message: "integrations", type: "delete", severity: "success"}));

            this.return();
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    handleTags = (e, type, elementType, chipToDelete, i) => {
        const {centridesk, settings} = this.state;
        var element_to_update;

        switch (elementType) {
            case 'tags':
                element_to_update = centridesk.info.tags;
                break;
            case 'tags_hours_control':
                element_to_update = centridesk.info.hours_control.tags;
                break;
            case 'tags_individual':
                element_to_update = centridesk.info.centripush.individual.tags;
                break;
            case 'tags_campaign':
                element_to_update = centridesk.info.centripush.campaign.tags;
                break;
        }

        var tag = e.target.value;
        switch (type) {
            case 'add':
                if (tag.match(/^[a-zA-Z0-9_\/]*$/)) {
                    settings[elementType] = tag;
                    this.setState({settings: settings});
                }
                break;
            case 'delete':
                element_to_update.forEach(el => {
                    if (el === chipToDelete) {
                        element_to_update.splice(i, 1);
                    }
                });
                this.setState({centridesk: centridesk})
                break;
            case 'enter':
                element_to_update.push(tag);
                settings[elementType] = "";
                this.setState({centridesk: centridesk, settings: settings});
                break;
        }
    }

    onSelectStatus = (e, type) => {
        const {centridesk} = this.state;
        switch (type) {
            case 'status_hours_control':
                centridesk.info.hours_control.status = e;
                break;
            case 'status_campaign':
                centridesk.info.centripush.campaign.status = e;
                break;
            case 'status_individual':
                centridesk.info.centripush.individual.status = e;
                break;
        }
        this.setState({centridesk: centridesk});
    }

    render() {
        const {centridesk, hours, settings, open} = this.state;
        const {t, match} = this.props;
        const {limits, isSending, plan, actualLayout} = this.props.data;
        return (
            <React.Fragment>
                {/* INFO */}
                <div className={"cen_integrations"}>
                    <div className={"header"}>
                        <div className={"header_content"}>
                            <div onClick={() => this.return()} className={"header_action"}>
                                {arrowRightSvg()}
                            </div>
                            <div className={"header_image"}>
                                {channelsSvg("centridesk")}
                            </div>
                            <div className={"header_title"}>
                                <span className={"title"}>{t('integrations:channels.centridesk.title')}</span>
                                <span className={"description"}>{t('integrations:categories.business')}</span>
                            </div>
                        </div>
                        <div className={"header_actions"}>
                            {
                                isSending ? (
                                    <Button type={"primary"} disabled text={<CircularProgress size={21}/>}/>
                                ) : (
                                    <Button type={"primary"}
                                            text={t('integrations:save.action_button')}
                                            onClick={(e) => this.putIntegration(e)}/>
                                )
                            }
                        </div>
                    </div>
                    {
                        centridesk
                            ? <div className={"integration"}>
                                {/* ZENDESK EMAIL */}
                                <ElementsCustom
                                    t={t}
                                    type={"centridesk"}
                                    input_type={"input"}
                                    element_type={"subject"}
                                    element={centridesk.info.subject}
                                    updateElement={this.updateElement}
                                />
                                <Divider/>
                                {/* TAGS */}
                                <ElementsCustom
                                    t={t}
                                    type={"centridesk"}
                                    input_type={"input_with_tags"}
                                    element_type={"tags"}
                                    element={settings.tags}
                                    elements={centridesk.info.tags}
                                    updateElement={this.handleTags}
                                />
                            </div>
                            : <div className={"content"}>
                                <div className="loading">
                                    <CircularProgress color="primary" size={50}/>
                                </div>
                            </div>
                    }
                </div>
                {
                    centridesk &&
                        <React.Fragment>
                            {/* HOURS CONTROL */}
                            <div className={"cen_integrations"}>
                                <div className={"header"}>
                                    <div className={"header_content"}>
                                        <div className={"header_title"}>
                                                <span
                                                    className={"title"}>{t('integrations:channels.centridesk.hours_control.title')}</span>
                                            <span
                                                className={"description"}>{t('integrations:channels.centridesk.hours_control.description')}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"integration"}>
                                    {
                                        limits.hours_control && hours && hours.active &&
                                        <React.Fragment>
                                            {/* ACTIVE */}
                                            <ElementsCustom
                                                t={t}
                                                type={"hours_control"}
                                                input_type={"switch"}
                                                element_type={"subject_active"}
                                                element={centridesk.info.hours_control.active}
                                                updateElement={this.updateElement}
                                            />
                                            <Divider/>
                                            {/* TICKET SUBJECT */}
                                            <ElementsCustom
                                                t={t}
                                                type={"hours_control"}
                                                input_type={"input"}
                                                element_type={"subject_hours_control"}
                                                element={centridesk.info.hours_control.subject}
                                                updateElement={this.updateElement}
                                            />
                                            <Divider/>
                                            {/* TAGS */}
                                            <ElementsCustom
                                                t={t}
                                                type={"hours_control"}
                                                input_type={"input_with_tags"}
                                                element_type={"tags_hours_control"}
                                                element={settings.tags_hours_control}
                                                elements={centridesk.info.hours_control.tags}
                                                updateElement={this.handleTags}
                                            />
                                            <Divider/>
                                            {/* STATUS */}
                                            <ElementsCustom
                                                t={t}
                                                type={"hours_control"}
                                                input_type={"select"}
                                                element_type={"status_hours_control"}
                                                element={centridesk.info.hours_control.status ? <span
                                                    className={`status ${centridesk.info.hours_control.status}`}>{t(`elements.custom.status.${centridesk.info.hours_control.status}`)}</span> : ''}
                                                elements={getStatusArray()}
                                                updateElement={this.onSelectStatus}
                                            />
                                        </React.Fragment>
                                    }
                                    {limits.hours_control && (!hours || !hours.active) && (
                                        <div className="info_message">
                                                <span>
                                                    {t('integrations:channels.centridesk.hours_control.no_active')} <Link
                                                    to={`/${match.params.project}/chatbot?tab=business_hours&to=business`}>{t('integrations:channels.centridesk.hours_control.title')}</Link>
                                                </span>
                                        </div>
                                    )}
                                    {!limits.hours_control && (
                                        <div className="info_message">
                                                <span>
                                                    {t('integrations:channels.centridesk.hours_control.no_limits')}
                                                </span>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {/* CENTRIPUSH */}
                            <div className={"cen_integrations"}>
                                <div className={"header"}>
                                    <div className={"header_content"}>
                                        <div className={"header_title"}>
                                                <span
                                                    className={"title"}>{t('integrations:channels.centridesk.centripush.title')}</span>
                                            <span
                                                className={"description"}>{t('integrations:channels.centridesk.centripush.description')}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"integration"}>
                                    {
                                        plan?.whatsapp_phones?.length > 0 && plan?.platform?.centripush ?
                                            <React.Fragment>
                                                {/* INDIVIDUAL */}
                                                <div className={"elements_custom"}>
                                                    <div className={"elements_custom__info"}>
                                                <span
                                                    style={{fontWeight: 500}}
                                                    className={"elements_custom__title"}>{t('integrations:channels.centridesk.centripush.individual.title')}</span>
                                                        <span
                                                            className={"elements_custom__description"}>{t('integrations:channels.centridesk.centripush.individual.description')}</span>
                                                    </div>
                                                </div>
                                                <Divider/>
                                                {/* ACTIVE */}
                                                <ElementsCustom
                                                    t={t}
                                                    type={"centripush"}
                                                    input_type={"switch"}
                                                    element_type={"individual_active"}
                                                    element={centridesk.info.centripush.individual.active}
                                                    updateElement={this.updateElement}
                                                />
                                                <Divider/>
                                                {/* TICKET SUBJECT */}
                                                <ElementsCustom
                                                    t={t}
                                                    type={"centripush"}
                                                    input_type={"input"}
                                                    element_type={"subject_individual"}
                                                    element={centridesk.info.centripush.individual.subject}
                                                    updateElement={this.updateElement}
                                                />
                                                <Divider/>
                                                {/* TAGS */}
                                                <ElementsCustom
                                                    t={t}
                                                    type={"centripush"}
                                                    input_type={"input_with_tags"}
                                                    element_type={"tags_individual"}
                                                    element={settings.tags_individual}
                                                    elements={centridesk.info.centripush.individual.tags}
                                                    updateElement={this.handleTags}
                                                />
                                                <Divider/>
                                                {/* STATUS */}
                                                <ElementsCustom
                                                    t={t}
                                                    type={"centripush"}
                                                    input_type={"select"}
                                                    element_type={"status_individual"}
                                                    element={centridesk.info.centripush.individual.status ? <span
                                                        className={`status ${centridesk.info.centripush.individual.status}`}>{t(`elements.custom.status.${centridesk.info.centripush.individual.status}`)}</span> : ''}
                                                    elements={getStatusArray()}
                                                    updateElement={this.onSelectStatus}
                                                />
                                                <Divider/>
                                                {/* CAMPAIGNS */}
                                                <div className={"elements_custom"}>
                                                    <div className={"elements_custom__info"}>
                                                        <span style={{fontWeight: 500, marginTop: 30}}
                                                              className={"elements_custom__title"}>{t('integrations:channels.centridesk.centripush.campaign.title')}</span>
                                                        <span
                                                            className={"elements_custom__description"}>{t('integrations:channels.centridesk.centripush.campaign.description')}</span>
                                                    </div>
                                                </div>
                                                <Divider/>
                                                {/* ACTIVE */}
                                                <ElementsCustom
                                                    t={t}
                                                    type={"centripush"}
                                                    input_type={"switch"}
                                                    element_type={"campaign_active"}
                                                    element={centridesk.info.centripush.campaign.active}
                                                    updateElement={this.updateElement}
                                                />
                                                <Divider/>
                                                {/* TICKET SUBJECT */}
                                                <ElementsCustom
                                                    t={t}
                                                    type={"centripush"}
                                                    input_type={"input"}
                                                    element_type={"subject_campaign"}
                                                    element={centridesk.info.centripush.campaign.subject}
                                                    updateElement={this.updateElement}
                                                />
                                                <Divider/>
                                                {/* TAGS */}
                                                <ElementsCustom
                                                    t={t}
                                                    type={"centripush"}
                                                    input_type={"input_with_tags"}
                                                    element_type={"tags_campaign"}
                                                    element={settings.tags_campaign}
                                                    elements={centridesk.info.centripush.campaign.tags}
                                                    updateElement={this.handleTags}
                                                />
                                                <Divider/>
                                                {/* STATUS */}
                                                <ElementsCustom
                                                    t={t}
                                                    type={"centripush"}
                                                    input_type={"select"}
                                                    element_type={"status_campaign"}
                                                    element={centridesk.info.centripush.campaign.status ? <span
                                                        className={`status ${centridesk.info.centripush.campaign.status}`}>{t(`elements.custom.status.${centridesk.info.centripush.campaign.status}`)}</span> : ''}
                                                    elements={getStatusArray()}
                                                    updateElement={this.onSelectStatus}
                                                />
                                            </React.Fragment> :
                                            <div className="content">
                                                <div className="info_message">
                                                <span>
                                                    {t('integrations:channels.centridesk.centripush.no_active')}
                                                </span>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                            {/* DELETE */}
                            <div className={"cen_integrations delete"}>
                                <div className={"integration"}>
                                    <div className={"integration_info"}>
                            <span
                                className={"integration_info_title"}>{t('integrations:channels.centridesk.delete.title')}</span>
                                        <span
                                            className={"integration_info_description"}>{t('integrations:channels.centridesk.delete.description')}</span>
                                    </div>
                                    <Button type={"normal_delete"}
                                            text={t('integrations:channels.centridesk.delete.action_button')}
                                            onClick={() => this.setState({open: true})}/>
                                </div>
                            </div>
                            {/* DELETE MODAL */}
                            <DialogAction t={t} actualLayout={actualLayout.layout} handleAction={this.handleDelete}
                                          handleClickOpen={() => this.setState({open: false})} common open={open} item={{name: ""}}/>
                        </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const edit = connect(
    mapStateToProps
)(Edit);

export default withTranslation(['commons', 'common'])(withRouter(edit));