// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Styles
import 'date-fns';
// Translations
import {withTranslation} from "react-i18next";
// Components
import StatsConsumption from 'views/Bots/Stats/application/StatsConsumption';
import StatsProject from "views/Bots/Stats/application/StatsProject";
import {AlertInfo} from "@components/Alert";
import {Divider} from '@material-ui/core';
import {TabPanel} from "components/Navigation/tab_panel";
// Material
import {AppBar, Tab, Tabs} from '@material-ui/core';
// Amplitude API
import {AmplitudeAPI} from '@api/Amplitude';

class Stats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabValue: undefined
        }
    }

    componentDidMount() {
        this.handleTabs();
    }

    handleTabs = () => {
        const {history} = this.props;
        const queryString = window.location.search,
            urlParams = new URLSearchParams(queryString),
            tab = urlParams.get('from');

        switch (tab) {
            case 'stats_project':
                this.setState({tabValue: 0});
                break;
            case 'stats_account':
                this.setState({tabValue: 1});
                break;
            default:
                history.push(`${this.props.location.pathname}?from=stats_project`)
                this.setState({tabValue: 0});
                break;
        }
    }

    a11yProps(index) {
        return {
            id: `stats-tab-${index}`,
            'aria-controls': `stats-tabpanel-${index}`,
        };
    }

    setQuery = (e, to) => {
        e.preventDefault();
        const {user, device_id} = this.props.data;
        this.props.history.push(`${this.props.location.pathname}?from=${to}`);

        var event_type = to === 'stats_project' ? 'projects' : 'accounts';
        new AmplitudeAPI({
            event_type: `Click on ${event_type} stats`,
            device_id: device_id,
            user_id: user.email
        }).eventLog();

        setTimeout(() => {
            this.handleTabs();
        }, 10);
    }

    render() {
        const {project, actualProject, t} = this.props;
        const {tabValue} = this.state;
        return (
            tabValue !== undefined &&
            <React.Fragment>
                <AppBar id={"app_bar"} style={{borderRadius: "5px 5px 0 0"}} position="static" color="default">
                    <div className="header-container">
                        <h2>{t('stats.title')}</h2>
                    </div>
                </AppBar>
                <Divider/>
                <AlertInfo className={"app_bar"} text={t('stats.info')}/>
                <Divider/>
                <AppBar id={"app_bar"} position="static" color="default">
                    <Tabs
                        value={tabValue}
                        variant="scrollable"
                        scrollButtons="on"
                        textColor="primary"
                    >
                        {/* Project Stats */}
                        <Tab label={t('stats.stats_project')} onClick={(e) => {
                            this.setQuery(e, 'stats_project')
                        }} {...this.a11yProps(0)} />
                        {/* Account Stats */}
                        <Tab label={t('stats.stats_account')} onClick={(e) => {
                            this.setQuery(e, 'stats_account')
                        }} {...this.a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <Divider/>
                <TabPanel value={tabValue} index={0}>
                    <StatsProject project={project} actualProject={actualProject}/>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <StatsConsumption project={project} actualProject={actualProject} type={"accounts"}/>
                </TabPanel>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_stats = connect(
    mapStateToProps
)(Stats);

export default withTranslation('common')(withRouter(connect_stats));