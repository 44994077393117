// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Material
import {Divider} from '@material-ui/core';
// Vendor
import {showNotify} from 'vendor/application/disptach';
// Infrastructure
import {IntegrationsAPI} from 'api/application/Integrations';
// Application
import {getTelegramBody} from 'vendor/application';
// Translations
import {withTranslation} from "react-i18next";
// Components
import {ElementsCustom} from "@components/Elements";
import {Button} from "@components/Input";
// SVG
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            body: getTelegramBody(),
            pages: ''
        }
    }

    // update element state
    updateElement = (e, type, i) => {
        const {body} = this.state;
        body.info[type] = e.target.value;

        this.setState({body: body});
    }

    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations/telegram`);
    }

    postIntegration = async (e) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {body} = this.state;
        const {history} = this.props;

        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props, body);
            const integration = await obj.post_integration();

            this.props.dispatch(showNotify({message: "integrations", type: "create", severity: "success"}));

            history.push(`/${actualProject.id}/integrations/telegram/edit/${integration.id}`);
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    createBot = () => {
        window.open('https://telegram.me/botfather');
    }

    render() {
        const {body} = this.state;
        const {t} = this.props;
        return (
            body &&
            <div className={"cen_integrations"}>
                <div className={"header"}>
                    <div className={"header_content"}>
                        <div onClick={() => this.return()} className={"header_action"}>
                            {arrowRightSvg()}
                        </div>
                        <div className={"header_image"}>
                            {channelsSvg("telegram")}
                        </div>
                        <div className={"header_title"}>
                            <span className={"title"}>{t('integrations:channels.telegram.title')}</span>
                            <span className={"description"}>{t('integrations:categories.customer')}</span>
                        </div>
                    </div>
                </div>
                <div className={"integration"}>
                    {/* AUTHENTICATE */}
                    <div className={"elements_custom"}>
                        <div className={"elements_custom__info"}>
                                <span
                                    className={"elements_custom__title"}>{t(`elements.custom.telegram.card.create_bot.title`)}</span>
                            <span
                                className={"elements_custom__description"}>{t(`elements.custom.telegram.card.create_bot.description_1`)}<a
                                target="_blank"
                                href="https://web.telegram.org/#/login">{t(`elements.custom.telegram.card.create_bot.description_link`)}</a>{t(`elements.custom.telegram.card.create_bot.description_2`)}</span>
                        </div>
                        <Button type={"primary"}
                                text={t(`elements.custom.telegram.card.create_bot.button_activated`)}
                                onClick={(e) => this.createBot(e)}/>
                    </div>
                    <Divider/>
                    {/* BOT NAME */}
                    <ElementsCustom
                        t={t}
                        type={"telegram"}
                        input_type={"input"}
                        element_type={"bot_name"}
                        element={body.info.bot_name}
                        updateElement={this.updateElement}
                    />
                    <Divider/>
                    {/* BOT TOKEN */}
                    <ElementsCustom
                        t={t}
                        type={"telegram"}
                        input_type={"input"}
                        element_type={"bot_token"}
                        element={body.info.bot_token}
                        updateElement={this.updateElement}
                    />
                    <Divider/>
                    {/* AUTHENTICATE */}
                    <ElementsCustom
                        t={t}
                        type={"telegram"}
                        input_type={"button"}
                        element_type={"add_integration"}
                        disabled={!body.info.bot_token}
                        updateElement={(e) => this.postIntegration(e)}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state
    }
}

const create = connect(
    mapStateToProps
)(Create);

export default withTranslation(['commons', 'common'])(withRouter(create));