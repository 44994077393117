// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Routing
import { withRouter } from 'react-router-dom';
// Translations
import { withTranslation } from "react-i18next";
// Material
import Alert from '@material-ui/lab/Alert';

class UpgradePlan extends Component {

    handleManagerRedirect = (e) => {
        e.preventDefault();
        window.location.href = `${process.env.REACT_APP_URL_CENTRIMANAGER}/subscription`;
    }
    render() {
        const {t} = this.props;
        return (
            <Alert className={"upgrade-plan"} severity="info">
                {t('card.upgrade_plan.description')}
                <button className="btn-primary" onClick={(e) => this.handleManagerRedirect(e)}>{t('card.upgrade_plan.button_action')}</button>
            </Alert>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const upgrade_plan = connect(
    mapStateToProps
)(UpgradePlan);

export default withTranslation('common')(withRouter(upgrade_plan));
