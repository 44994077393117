// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import './styles.scss';
// Components
import {Button} from "@components/Input";
import {ElementsCustom} from "@components/Elements";
import {CircularProgress, Divider} from '@material-ui/core';
import Table from "components/Table";
// Assets
import {svgRefresh} from "assets";

class ViewCampaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign_detail: false,
            template: false,
            project_id: false,
            page: 1,
            percentage: 0,
            color_class: "color_inherit",
            value: 0
        }
    }

    componentDidMount = () => {
        this.handleChartData();
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.timestamp !== this.props.timestamp) {
            this.handleChartData();
        }
    }

    handleChartData = () => {
        const {campaign} = this.props;
        var percentage = (campaign.detail.total_delivered / campaign.detail.total) * 100;
        var round = Math.round(percentage, 1);

        var color_class;
        if (round === 0) {
            color_class = "color_inherit";
        } else if (round <= 25) {
            color_class = "color_0";
        } else if (round <= 50) {
            color_class = "color_25";
        } else if (round <= 75) {
            color_class = "color_50";
        } else {
            color_class = "color_75";
        }

        this.setState({color_class: color_class, value: round === 0 ? 100 : round, percentage: round});
    }

    handlePagination = (e, next_page) => {
        const {campaign, getDetails} = this.props;
        getDetails(true, campaign.iteration, next_page);
        this.setState({page: next_page});
    }

    render() {
        const {
            percentage,
            page,
            value,
            color_class
        } = this.state;
        const {t, getDetails, campaign, startDate, endDate} = this.props;

        return (
            campaign ?
                <React.Fragment>
                    <div className={"campaigns_view"}>
                        <div className={"campaigns_view_info"}>
                            <div>
                                <div className={"campaigns_view_header"}>
                                    <div className={"campaigns_view_header_title"}>
                                        <span>{t(`common:stats.sunco_campaigns.view.campaign_view.title`)}</span>
                                        <span>{t(`common:stats.sunco_campaigns.view.campaign_view.description`)}</span>
                                        <div className={"date"}>
                                            <span>{startDate}</span>
                                            <span>-</span>
                                            <span>{endDate}</span>
                                        </div>
                                    </div>
                                    <div className={"campaigns_view_header_actions"}>
                                        <Button type={"secondary"}
                                                text={t(`common:card.content.groups.button_return`)}
                                                onClick={() => getDetails(false)}/>
                                        <Button type={"secondary"}
                                                text={svgRefresh()}
                                                onClick={() => this.handlePagination(null, page)}/>
                                    </div>
                                </div>
                                <div className={"card"}>
                                    <div className={"content"}>
                                        <ElementsCustom
                                            t={t}
                                            type={"groups_view"}
                                            input_type={"input"}
                                            element_type={"name"}
                                            element={campaign.campaign_name}
                                            disabled={true}
                                        />
                                        <Divider/>
                                        <ElementsCustom
                                            t={t}
                                            type={"groups_view"}
                                            input_type={"input"}
                                            element_type={"template"}
                                            element={campaign.template_name}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className={"campaigns_view_header"}>
                                    <div className={"campaigns_view_header_title"}>
                                        <span>{t(`common:stats.sunco_campaigns.view.details.title`)}</span>
                                        <span>{t(`common:stats.sunco_campaigns.view.details.description`)}</span>
                                    </div>
                                </div>
                                <Table items={campaign?.detail.detail} type={"sunco_campaigns"}
                                       handlePagination={this.handlePagination} page={page ?? 1} max={campaign.max}
                                       header={['phone', 'status', 'variables', 'delivered_user_at', 'delivery_failure_at']}/>
                            </div>
                        </div>
                        <div className={"campaigns_view_status"}>
                            <div>
                                <div className={"campaigns_view_header"}>
                                    <div className={"campaigns_view_header_title"}>
                                        <span>{t(`common:stats.sunco_campaigns.view.chart.title`)}</span>
                                        <span>{t(`common:stats.sunco_campaigns.view.chart.description`)}</span>
                                    </div>
                                </div>
                                <div className={"campaigns_view_doughnut"}>
                                    <div className={"chart"}>
                                        <span className={color_class}>{percentage}%</span>
                                        <CircularProgress className={color_class} variant="determinate" thickness={4} size={200} value={value} />
                                    </div>
                                    <div className="fields">
                                        <div><span/><span>0 - 25</span></div>
                                        <div><span/><span>25 - 50</span></div>
                                        <div><span/> <span>50 - 75</span></div>
                                        <div><span/> <span>75 - 100</span></div>
                                    </div>
                                    <Divider/>
                                    <div className="operation_divider">
                                        <div className={"operation_divider_header"}>
                                            <span>{t(`common:stats.sunco_campaigns.view.chart.total_delivered`)}</span>
                                            <span>{t(`common:stats.sunco_campaigns.view.chart.total`)}</span>
                                        </div>
                                        <div className={"operation_divider_result"}>
                                                        <span
                                                            className={color_class}>{campaign.detail.total_delivered}</span>
                                            <span>/</span>
                                            <span>{campaign.detail.total}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            :
                <div className="card">
                    <div className="loading-data">
                        <CircularProgress color="primary" size={70}/>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const view_campaign = connect(
    mapStateToProps
)(ViewCampaign);

export default withTranslation(['commons', 'common'])(withRouter(view_campaign));