// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Material UI
import {AppBar, Tab, Tabs, Divider} from '@material-ui/core';
// Translations
import {withTranslation} from "react-i18next";
// Views
import Intent from "views/Bots/NLP/application/Build/Tabs/Intents/application/intent";
import IntentCreate from "views/Bots/NLP/application/Build/Tabs/Intents/application/intent_create";
import IntentEdit from "views/Bots/NLP/application/Build/Tabs/Intents/application/intent_edit";
import Context from "views/Bots/NLP/application/Build/Tabs/Contexts/application/context";
import Entities from "views/Bots/NLP/application/Build/Tabs/Entities/application/entities";
import EntityCreate from "views/Bots/NLP/application/Build/Tabs/Entities/application/entity_create";
import EntityEdit from "views/Bots/NLP/application/Build/Tabs/Entities/application/entity_edit";
import UpgradePlan from "components/Plan/application/upgrade_plan";
// Components
import {TabPanel} from "components/Navigation/tab_panel";
// Dispatch
import {showNotify} from "vendor/application/disptach";
// SVG
import {ProjectAPI} from "api/application/Projects";
// Vendor
import {isPlanAllowed} from 'vendor/application';
// Amplitude API
import {AmplitudeAPI} from '@api/Amplitude';

class Build extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: '',
            action: '',
            id: ''
        }
    }

    componentDidMount = () => {
        if (!this.props.data.actualProject.chat_id) {
            this.getChatId();
        }
        this.handleTabs();
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.actualProject.id !== this.props.data.actualProject.id) {
            setTimeout(() => {
                if (!nextProps.actualProject.chat_id) {
                    this.getChatId();
                }
                this.handleTabs();
            }, 100);
        }
    }

    getChatId = async () => {
        const {access} = this.props.data;
        const {actualProject} = this.props.data;
        try {
            let obj = new ProjectAPI(actualProject.id, access, this.props);
            let project = await obj.get_project_by_id();
            this.setState({chat_id: project.chat_id});
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data));
        }
    }

    handleTabs = () => {
        const {history} = this.props;
        const {empty, actualProject} = this.props.data;
        var queryString = window.location.search,
            urlParams = new URLSearchParams(queryString),
            action = urlParams.get('action'),
            tab = urlParams.get('tab'),
            id = urlParams.get('id');

        if (empty) {
            var data = {message: `nlp_sidebar`, severity: 'error'};
            this.props.dispatch(showNotify(data));

            history.push(`/${actualProject.id}/chatbot`)
        } else {
            switch (tab) {
                case 'intents':
                    this.setState({tabValue: 0});
                    break;
                case 'contexts':
                    this.setState({tabValue: 1});
                    break;
                case 'entities':
                    this.setState({tabValue: 2});
                    break;
                default:
                    history.push(`${this.props.location.pathname}?tab=intents`);
                    tab = 'intents';
                    this.setState({tabValue: 0});
                    break;
            }
            this.setState({tab: tab, action: action, id: id});
        }
    }

    setQuery = (e, to) => {
        e.preventDefault();
        this.props.history.push(`${this.props.location.pathname}?tab=${to}`);
        this.handleAmplitude(`Click on ${to}`)
        this.handleTabs();
    }

    handleAmplitude = (event_type) => {
        const {user, device_id} = this.props.data;
        new AmplitudeAPI({
            event_type: event_type,
            device_id: device_id,
            user_id: user.email
        }).eventLog();
    }

    openTestChat = (e) => {
        e.preventDefault();
        const {actualProject} = this.props.data;
        this.handleAmplitude(`Test chatbot`)
        window.open(`${process.env.PUBLIC_URL}/test_chat?project_id=${actualProject.id}&chat_id=${actualProject.chat_id || this.state.chat_id}`);
    }

    a11yProps(index) {
        return {
            id: `nlp-tab-${index}`,
            'aria-controls': `nlp-tabpanel-${index}`,
        };
    }

    render() {
        const {tabValue, tab, action, id} = this.state;
        const {t, getLimits, project, actualProject} = this.props;
        const {plan} = this.props.data;
        return (
            tab &&
            <React.Fragment>
                <AppBar id={"app_bar"} position="static" color="default">
                    <Tabs
                        value={tabValue}
                        variant="scrollable"
                        scrollButtons="on"
                        textColor="primary"
                    >
                        {/* INTENTS */}
                        <Tab label={t('header.title.intents')} onClick={(e) => {
                            this.setQuery(e, 'intents')
                        }} {...this.a11yProps(0)} />
                        {/* CONTEXTS */}
                        <Tab label={t('header.title.contexts')} onClick={(e) => {
                            this.setQuery(e, 'contexts')
                        }} {...this.a11yProps(1)} />
                        {/* ENTITIES */}
                        <Tab label={t('header.title.entities')} onClick={(e) => {
                            this.setQuery(e, 'entities')
                        }} {...this.a11yProps(2)} />
                    </Tabs>
                    <Divider />
                </AppBar>
                {/* INTENTS */}
                <TabPanel value={tabValue} index={0}>
                    {
                        !action &&
                        <Intent handleTabs={this.handleTabs} getLimits={getLimits} project={project}
                                actualProject={actualProject}/>
                    }
                    {
                        action === 'create' &&
                        <IntentCreate handleTabs={this.handleTabs}/>
                    }
                    {
                        action === 'edit' &&
                        <IntentEdit handleTabs={this.handleTabs} id={id}/>
                    }
                </TabPanel>
                {/* CONTEXTS */}
                <TabPanel value={tabValue} index={1}>
                    {
                        !action &&
                        <Context handleTabs={this.handleTabs} getLimits={this.props.getLimits} project={project}
                                 actualProject={actualProject}/>
                    }
                </TabPanel>
                {/* ENTITIES */}
                <TabPanel value={tabValue} index={2}>
                    {
                        isPlanAllowed(plan.plan.name) ? (
                            <React.Fragment>
                                {
                                    !action &&
                                    <Entities handleTabs={this.handleTabs} project={project}
                                              actualProject={actualProject}/>
                                }
                                {
                                    action && action === 'create' &&
                                    <EntityCreate handleTabs={this.handleTabs}/>
                                }
                                {
                                    action && action === 'edit' &&
                                    <EntityEdit handleTabs={this.handleTabs} id={id}/>
                                }
                            </React.Fragment>
                        ) : (
                            <UpgradePlan/>
                        )
                    }
                </TabPanel>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_build = connect(
    mapStateToProps
)(Build);

export default withTranslation('common')(withRouter(connect_build));