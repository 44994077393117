// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Material
import {CircularProgress, Divider, TextField} from '@material-ui/core';
// Vendor
import {showNotify} from 'vendor/application/disptach';
import {ElementsHandleAPI} from "vendor/application/handleMethods";
import {addArtifact} from "vendor/application";
// Infrastructure
import {IntegrationsAPI} from 'api/application/Integrations';
// Translations
import {withTranslation} from "react-i18next";
// Components
import DialogAction from 'components/Dialog/DialogAction';
import {AlertInfo} from "@components/Alert";
import Alert from "@material-ui/lab/Alert";
import ShortImage from "components/Files/Image/ShortImage";
import {Button, Input} from "@components/Input";
import FloatButtons from "components/Input/float_buttons";
import {ElementsCustom} from "@components/Elements";
// SVG
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sunco: '',
            open: false,
            tagValid: true,
            set_tag: "",
            delete: {
                name: ''
            }
        }
    }

    componentDidMount = () => {
        this.getIntegration();
    }

    getIntegration = async () => {
        const {access, actualProject} = this.props.data;
        try {
            let obj = new IntegrationsAPI(actualProject.id, access, this.props);
            let integration = await obj.get_integration_by_id();

            if (!integration.info.zendesk.use_custom_fields) {
                integration.info.zendesk.use_custom_fields = {
                    "active": false,
                    "subdomain": "",
                    "email": "",
                    "token": ""
                }
            }

            if (!integration.info.zendesk.ticket_field_conversation_id) {
                integration.info.zendesk.ticket_field_conversation_id = "";
            }

            if (!integration.info.campaigns) {
                integration.info.campaigns = {
                    "app": {
                        "key_id": "",
                        "key_secret": ""
                    }
                }
            }

            this.setState({sunco: integration});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
            this.return();
        }
    }

    // update element state
    updateElement = (e, type, i) => {
        const {sunco} = this.state;
        const obj = new ElementsHandleAPI(sunco.info, e.target.value, type, i);
        const res = obj.updateObj();

        var init = true;

        Object.keys(sunco.info).forEach(el => {
            if (!sunco.info[el]) {
                init = false;
            }
        });
        sunco.info = res;
        this.setState({sunco: sunco, isValid: init});
    }

    updateZendeskElement = (e, type) => {
        const {sunco} = this.state;
        if (type === 'historical_messages_number') {
            sunco.info.zendesk[type] = e.target.value ? parseInt(e.target.value) : '';
        } else {
            sunco.info.zendesk[type] = e.target.value;
        }

        this.setState({sunco: sunco});
    }

    updateBotElement = (e, type) => {
        const {sunco} = this.state;
        sunco.info.bot[type] = e.target.value;

        this.setState({sunco: sunco});
    }

    return = () => {
        const {location, match, history} = this.props;
        var url = location.pathname.replace(`/sunco/edit/${match.params.id}`, '');
        history.push(url);
    }

    handleClickOpen = () => {
        this.setState({open: !this.state.open});
    }

    handleDelete = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            await obj.delete_integration(this.state.sunco.id);

            this.props.dispatch(showNotify({message: "integrations", type: "delete", severity: "success"}));

            this.return();
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    handleTags = (e, type, elementType, chipToDelete, i) => {
        var {sunco, set_tag} = this.state;
        var element_to_update;

        switch (elementType) {
            case 'tags':
                element_to_update = sunco.info.zendesk.tags;
                break;
        }

        var tag = e.target.value;
        switch (type) {
            case 'add':
                if (tag.match(/^[a-zA-Z0-9_\/]*$/)) {
                    set_tag = tag;
                    this.setState({set_tag: set_tag});
                }
                break;
            case 'delete':
                element_to_update.forEach(el => {
                    if (el === chipToDelete) {
                        element_to_update.splice(i, 1);
                    }
                });
                this.setState({sunco: sunco})
                break;
            case 'enter':
                element_to_update.push(tag);
                set_tag = "";
                this.setState({sunco: sunco, set_tag: set_tag});
                break;
        }
    }

    putIntegration = async (e, type) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {sunco} = this.state;

        if (this.handleKeyValidation()) {
            try {
                const obj = new IntegrationsAPI(actualProject.id, access, this.props, sunco);
                await obj.put_integration();

                switch (type) {
                    case 'switchboard':
                        this.putSwitchBoard();
                        break;
                    default:
                        this.props.dispatch(showNotify({message: "integrations", type: "edit", severity: "success"}));
                        break;
                }
            } catch (err) {
                this.props.dispatch(showNotify({message: err, severity: 'error'}));
            }
        } else {
            this.props.dispatch(showNotify({message: "integration_sunco_api", severity: 'error'}));
        }
    }

    putSwitchBoard = async () => {
        const {sunco} = this.state;
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props, null);
            await obj.put_switchboard();

            this.props.dispatch(showNotify({message: "integrations", type: "switchboard", severity: "success"}));

            sunco.info.switchboard.updated = true;
            this.setState({sunco: sunco})
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    handleKeyValidation = () => {
        const {sunco} = this.state;
        var switchboard = sunco.info.switchboard;
        var campaigns = sunco.info.campaigns;
        return !!(switchboard.key_id && switchboard.key_secret || campaigns.app.key_id && campaigns.app.key_secret);
    }

    handleArtifacts = (e, i, artifact_type, type) => {
        const {sunco} = this.state;
        var res = addArtifact(i, artifact_type, type, sunco);
        this.setState({sunco: res});
    }

    render() {
        const {sunco, open, set_tag} = this.state;
        const {actualLayout, isSending} = this.props.data;
        const {t} = this.props;
        return (
            sunco &&
            <React.Fragment>
                <Alert className={"cen_alert"} severity="info">
                    <span
                        dangerouslySetInnerHTML={{__html: t('integrations:channels.sunco.info_alert')}}/>
                    <button className={"btn btn-primary"}
                            onClick={() => window.location.href = process.env.REACT_APP_URL_CENTRIMANAGER}>{"ADMIN"}</button>
                </Alert>
                <div className={"cen_integrations"}>
                    <div className={"header"}>
                        <div className={"header_content"}>
                            <div onClick={() => this.return()} className={"header_action"}>
                                {arrowRightSvg()}
                            </div>
                            <div className={"header_image"}>
                                {channelsSvg("sunco")}
                            </div>
                            <div className={"header_title"}>
                                <span className={"title"}>{t('integrations:channels.sunco.title')}</span>
                                <span className={"description"}>{t('integrations:categories.customer')}</span>
                            </div>
                        </div>
                        <div className={"header_actions"}>
                            {
                                isSending ? (
                                    <Button type={"primary"} disabled text={<CircularProgress size={21}/>}/>
                                ) : (
                                    <Button type={"primary"}
                                            text={t('integrations:save.action_button')}
                                            onClick={(e) => this.putIntegration(e)}/>
                                )
                            }
                        </div>
                    </div>
                    {
                        sunco
                            ? <div className={"integration"}>
                                <ElementsCustom
                                    t={t}
                                    type={"sunco"}
                                    input_type={"string"}
                                    element_type={"app_id"}
                                    element={sunco.info.app_id}
                                />
                                <Divider/>
                                <div className={`integration_element column`}>
                                    <div className={"integration_element__info"}>
                                        <span>{t('integrations:channels.sunco.api_key.title')}</span>
                                        <span>{t('integrations:channels.sunco.api_key.description')}</span>
                                    </div>
                                    <ShortImage image={"sunco_api_key.png"} alt={"Sunco API KEY example"}/>
                                    <div className={"integration__info"}>
                                        <span>{t('integrations:channels.sunco.api_key.link.description')}</span>
                                        <a className={"integration__element_link"} target={"_blank"}
                                           href={`https://app.smooch.io/apps/${sunco.info.app_id}/settings`}>
                                            {t('integrations:channels.sunco.api_key.link.button')}
                                        </a>
                                    </div>
                                    <div className={"integration__table"}>
                                        <div className={"integration__table_header"}>
                                            <span>{t('integrations:channels.sunco.api_key.table.id.title')}</span>
                                            <span>{t('integrations:channels.sunco.api_key.table.secret.title')}</span>
                                        </div>
                                        <div className={"integration__table_element"}>
                                            <TextField
                                                onChange={(e) => {
                                                    this.updateElement(e, 'switchboard_key_id')
                                                }}
                                                value={sunco.info.switchboard.key_id}
                                                className={`input-primary`}
                                                placeholder={t('integrations:channels.sunco.api_key.table.id.placeholder')}
                                                inputProps={{maxLength: 400}}
                                            />
                                            <TextField
                                                onChange={(e) => {
                                                    this.updateElement(e, 'switchboard_key_secret')
                                                }}
                                                value={sunco.info.switchboard.key_secret}
                                                className={`input-primary`}
                                                placeholder={t('integrations:channels.sunco.api_key.table.secret.placeholder')}
                                                inputProps={{maxLength: 400}}
                                            />
                                        </div>
                                    </div>
                                    <div className={"integration__table_action"}>
                                        {
                                            isSending ? (
                                                <Button type={"primary"} disabled text={<CircularProgress size={21}/>}/>
                                            ) : (
                                                sunco.info.switchboard.updated ? (
                                                    <Button type={"primary"}
                                                            disabled
                                                            text={t('integrations:channels.sunco.api_key.activated_button')}/>
                                                ) : (
                                                    <Button type={"primary"}
                                                            text={t('integrations:channels.sunco.api_key.active_button')}
                                                            onClick={(e) => this.putIntegration(e, "switchboard")}/>
                                                )
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                            : <div className={"content"}>
                                <div className="loading">
                                    <CircularProgress color="primary" size={50}/>
                                </div>
                            </div>
                    }
                </div>
                {
                    sunco &&
                    <React.Fragment>
                        {/* TITLE ZENDESK CUSTOM */}
                        <div className={"cen_integrations"}>
                            <div className={"header"}>
                                <div className={"header_content"}>
                                    <div className={"header_title"}>
                                        <span
                                            className={"title"}>{t('integrations:channels.sunco.zendesk.title')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"integration"}>
                                {/* HISTORICAL MESSAGES NUMBER */}
                                <ElementsCustom
                                    t={t}
                                    type={"sunco"}
                                    input_type={"input"}
                                    element_type={"historical_messages_number"}
                                    element={sunco.info.zendesk.historical_messages_number}
                                    updateElement={this.updateZendeskElement}
                                />
                                <Divider/>
                                {/* TAGS */}
                                <ElementsCustom
                                    t={t}
                                    type={"sunco"}
                                    input_type={"input_with_tags"}
                                    element_type={"tags"}
                                    element={set_tag}
                                    elements={sunco.info.zendesk.tags}
                                    updateElement={this.handleTags}
                                />
                                <Divider/>
                                {/* TICKET FIELD CONVERSATION ID */}
                                <ElementsCustom
                                    t={t}
                                    type={"sunco"}
                                    input_type={"input"}
                                    element_type={"ticket_field_conversation_id"}
                                    element={sunco.info.zendesk.ticket_field_conversation_id}
                                    updateElement={this.updateZendeskElement}
                                />
                                <Divider/>
                                <div className={"elements_custom"}>
                                    <div className={"elements_custom__info"}>
                                <span
                                    className={"elements_custom__title"}>{t(`elements.custom.sunco.card.return_to_bot_message.title`)}</span>
                                    </div>
                                    <div className={"elements_custom__copy"}>
                                        <Input
                                            placeholder={t(`elements.custom.sunco.card.return_to_bot_message.placeholder`)}
                                            value={sunco.info.zendesk.return_to_bot_message}
                                            multiline
                                            onChange={(e) => this.updateZendeskElement(e, "return_to_bot_message")}
                                        />
                                        <FloatButtons type={"sunco_bot_message"} addArtifact={this.handleArtifacts}
                                                      buttons_toggle_artifacts={["link", "button", "carousel", "bubble", "for"]}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* BOT */}
                        <div className={"cen_integrations"}>
                            <div className={"header"}>
                                <div className={"header_content"}>
                                    <div className={"header_title"}>
                                        <span
                                            className={"title"}>{t('integrations:channels.sunco.bot.title')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"integration"}>
                                {/* DISPLAY NAME */}
                                <ElementsCustom
                                    t={t}
                                    type={"sunco"}
                                    input_type={"input"}
                                    element_type={"display_name"}
                                    element={sunco.info.bot?.display_name}
                                    updateElement={this.updateBotElement}
                                />
                                <Divider/>
                                {/* AVATAR URL */}
                                <ElementsCustom
                                    t={t}
                                    type={"sunco"}
                                    input_type={"input"}
                                    element_type={"avatar_url"}
                                    element={sunco.info.bot?.avatar_url}
                                    updateElement={this.updateBotElement}
                                />
                            </div>
                        </div>
                        {/*USE CUSTOM FIELDS*/}
                        <div className={"cen_integrations"}>
                            <div className={"header"}>
                                <div className={"header_content"}>
                                    <div className={"header_title"}>
                                        <span
                                            className={"title"}>{t('integrations:channels.sunco.use_custom_fields.title')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"integration"}>
                                {/*ACTIVE*/}
                                <ElementsCustom
                                    t={t}
                                    type={"sunco"}
                                    input_type={"switch"}
                                    element_type={"use_custom_fields_active"}
                                    element={sunco.info.zendesk?.use_custom_fields?.active}
                                    updateElement={this.updateElement}
                                />
                                <Divider/>
                                {/*SUBDOMAIN*/}
                                <ElementsCustom
                                    t={t}
                                    type={"sunco"}
                                    input_type={"input_zendesk"}
                                    element_type={"use_custom_fields_subdomain"}
                                    element={sunco.info.zendesk?.use_custom_fields?.subdomain}
                                    updateElement={this.updateElement}
                                />
                                <Divider/>
                                {/*EMAIL*/}
                                <ElementsCustom
                                    t={t}
                                    type={"sunco"}
                                    input_type={"input"}
                                    element_type={"use_custom_fields_email"}
                                    element={sunco.info.zendesk?.use_custom_fields?.email}
                                    updateElement={this.updateElement}
                                />
                                <Divider/>
                                {/*TOKEN*/}
                                <ElementsCustom
                                    t={t}
                                    type={"sunco"}
                                    input_type={"input"}
                                    element_type={"use_custom_fields_token"}
                                    element={sunco.info.zendesk?.use_custom_fields?.token}
                                    updateElement={this.updateElement}
                                />
                            </div>
                        </div>
                        {/* DELETE */}
                        <div className={"cen_integrations delete"}>
                            <div className={"integration"}>
                                <div className={"integration_info"}>
                            <span
                                className={"integration_info_title"}>{t('integrations:channels.sunco.delete.title')}</span>
                                    <span
                                        className={"integration_info_description"}>{t('integrations:channels.sunco.delete.description')}</span>
                                </div>
                                <Button type={"normal_delete"}
                                        text={t('integrations:channels.sunco.delete.action_button')}
                                        onClick={() => this.setState({open: true})}/>
                            </div>
                        </div>
                    </React.Fragment>
                }
                <DialogAction t={t} actualLayout={actualLayout.layout} handleAction={this.handleDelete}
                              handleClickOpen={this.handleClickOpen} common open={open} item={this.state.delete}/>

            </React.Fragment>

        );
    }

}


const mapStateToProps = state => {
    return {
    data: state
}
}

const edit = connect(
mapStateToProps
)(Edit);

export default withTranslation(['commons', 'common'])(withRouter(edit));