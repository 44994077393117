// Vendor
import { updateAccessStatus, updateSending, updateInitApp, resetState } from 'vendor/application/disptach';
import $ from 'jquery';

export class CustomLogin {
    constructor(body, url, props) {
        this.base_url = process.env.REACT_APP_URL_BACK;
        this.url = url;
        this.body = body;
        this.props = props;
    }

    async make_request_token() {
        try {
            return await $.ajax({
                url: `${this.base_url}${this.url}`,
                type: 'POST',
                data: JSON.stringify(this.body),
                headers: {
                    "Content-Type": "application/json"
                }
            })
        } catch(error) {
            switch(error.status) {
                case 401 :
                throw error.responseJSON.detail;
                case 400 :
                if ( error.responseJSON.password ) {
                    throw error.responseJSON.password[0];
                } else {
                    throw error.responseJSON.username[0];
                }
            }
        }
    }

    async make_request_refresh() {
        const { access } = this.props.data;
        var result;
        try {
            result = await $.ajax({
                url: `${this.base_url}${this.url}`,
                type: 'POST',
                data: JSON.stringify(this.body),
                headers: {
                    "Content-Type": "application/json"
                }
            });
        } catch(error) {
            result =  { response : error , status : error.status };
        }

        switch(result.status) {
            case 401: case 400:
            this.props.dispatch(updateInitApp(true));
            this.props.dispatch(updateSending(false));
            this.props.dispatch(resetState());
            this.props.history.push('/landing/login');
            throw `Session Expired`;
            default:
            var obj = result;
            var acc = access;
            acc.access = obj.access;
            var data = { ...acc, isAccess : true, userId : access.userId };
            this.props.dispatch(updateAccessStatus(data));
            this.props.dispatch(updateSending(false));
            return obj;
        }
    }

    async make_signin() {
        try {
            return await $.ajax({
                url: `${this.base_url}${this.url}`,
                type: 'POST',
                data: JSON.stringify(this.body),
                headers: {
                    "Content-Type": "application/json"
                }
            })
        } catch(error) {
            switch(error.status) {
                case 500 :
                this.props.dispatch(updateSending(false));
                throw "internal_server";
                default:
                this.props.dispatch(updateSending(false));
                throw error.responseJSON.message;
            }
        }
    }

    async make_verify( id ){
        try {
            return await $.ajax({
                url: `${this.base_url}${this.url}/${id}`,
                type: 'PUT',
                data: JSON.stringify(this.body),
                headers: {
                    "Content-Type": "application/json"
                }
            })
        } catch(error) {

            return error;
        }
    }

    async password_reestablish(type){
        try {
            return await $.ajax({
                url: `${this.base_url}${this.url}`,
                type: type,
                data: JSON.stringify(this.body),
                headers: {
                    "Content-Type": "application/json"
                }
            })
        } catch(error) {
            // switch(error.status) {
            //     case 401 :
            //     throw error.responseJSON.detail;
            //     case 400 :
            //     throw error.responseJSON.message;
            //     case 500 :
            //     throw "internal_server";
            // }
            return error;
        }
    }

    async password_reestablish_check(){
        try {
            return await $.ajax({
                url: `${this.base_url}${this.url}`,
                type: 'GET',
                data: null,
                headers: {
                    "Content-Type": "application/json"
                }
            })
        } catch(error) {
            // switch(error.status) {
            //     case 401 :
            //     throw error.responseJSON.detail;
            //     case 400 :
            //     throw error.responseJSON.message;
            //     case 500 :
            //     throw "internal_server";
            // }
            return error;
        }
    }
}