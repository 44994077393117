// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Infrastructure
import {UsersAPI} from "../../../../../api/application/Users";
import {ProjectAPI} from '../../../../../api/application/Projects';
// Application
import {ElementsHandleAPI} from 'vendor/application/handleMethods';
// Material UI
import {CircularProgress, Divider} from '@material-ui/core';
import Dropzone from 'react-dropzone';
// Vendor
import {showNotify, updateActualProject, updateEmpty, updateFilters, updateProjects} from 'vendor/application/disptach';
import {handleRedirectToSandbox, isPlanAllowed} from "vendor/application";
// Icons
import {exportSvg, importSvg, openNewWindow, viewSvg} from 'assets';
// Components
import DialogAction from 'components/Dialog/DialogAction';
import Select from 'components/Input/select';
import UpgradePlan from "components/Plan/application/upgrade_plan";
import {Button} from '@components/Input';
// Translations
import moment from 'moment';
// Styles
import 'views/Bots/ChatBot/styles/index.scss';

class ChatbotActions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: '',
            open: false,
            delete: {
                name: ''
            },
            action: '',
            bots: false,
            limits: false,
            selectedBot: '',
            countries: [],
            country: '',
            timezones: '',
            empty: false
        }
    }

    componentDidMount = () => {
        this.init();
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.data.actualProject.id !== this.props.data.actualProject.id) {
            this.init();
        }
    }

    componentWillUnmount = () => {
        clearInterval(this.interval);
    }

    init = () => {
        this.getActions();
        this.getLimits();
        this.getPredefinedBot(this.props.project_lang);
    }

    initInterval = () => {
        this.interval = setInterval(() => {
            this.getActions();
        }, 10000);
    }

    getActions = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props);
            const actions = await obj.get_project_actions();

            var valueLocal = false;

            actions.forEach((action) => {
                if (action.project_id === actualProject.id && (action.in_progress || action.pending) && action.action !== 'request_conversations' && action.action !== 'delete_sandbox') {
                    valueLocal = true;
                }
            });

            if (actions?.length > 0 && actions[0].in_progress && !this.state.actions && valueLocal) {
                this.initInterval();
            }

            if (this.state.actions?.length > 0) {
                var old_action = this.state.actions[0],
                    new_action = actions[0];

                if (old_action.in_progress && !new_action.in_progress) {
                    this.getLimits();
                    clearInterval(this.interval);
                }
            }

            this.props.dispatch(updateEmpty(valueLocal));
            this.setState({empty: valueLocal, actions: actions});
        } catch (err) {
        }
    }

    getActionOne = async (action_id) => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props);
            const action = await obj.get_project_action(action_id);

            if ((action.action === 'delete_sandbox' && action.result) || (action.action === 'create_sandbox' && action.result === 1)) {
                clearInterval(this.sandboxAction);
                this.props.getProject();
                this.getProjects();
            }
        } catch (err) {
        }
    }

    getLimits = async () => {
        const {access, actualProject} = this.props.data;
        try {
            let obj = new ProjectAPI(actualProject.id, access, this.props);
            var limits = await obj.get_project_limits();
            this.setState({limits: limits.plan.limits});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getPredefinedBot = async (lang) => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props, null);
            const res = await obj.predefined_bot(lang);
            this.setState({bots: res});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    // update element state
    updateElement = (e, type, i) => {
        const {project} = this.state;
        const obj = new ElementsHandleAPI(project, e.target.value, type, i);
        const res = obj.updateObj();
        this.setState({project: res});
    }

    onSelect = (e) => {
        const {project} = this.state;
        project.lang = e;
        this.setState({project: project})
    }

    handleClickOpen = (action) => {
        this.setState({open: !this.state.open, action: action});
    }

    handleAction = async () => {
        const {action} = this.state;

        switch (action) {
            case 'empty':
                this.emptyProject();
                break;
            case 'delete':
                this.deleteProject();
                break;
            case 'delete_sandbox':
                this.deleteSandbox();
                break;
            case 'publish_sandbox':
                this.publishSandbox();
                break;
        }

        this.setState({open: false});
    }

    emptyProject = async () => {
        const {access, actualProject, filters} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props, {"action": "empty"});
            await obj.do_actions();

            this.props.dispatch(updateEmpty(true));

            this.setState({empty: true, open: false});

            filters.intents.pagination.interval = 10;
            filters.intents.pagination.page = 1;
            this.props.dispatch(updateFilters(filters));

            this.handleActionRequest("empty")
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    deleteProject = async () => {
        const {history} = this.props;
        const {actualProject, projects, access} = this.props.data;

        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props, null);
            await obj.delete_project(actualProject.id);

            this.props.dispatch(showNotify({message: "project", type: "delete", severity: "success"}));

            actualProject.active = false;
            this.props.dispatch(updateActualProject(actualProject));
            projects.forEach((project, i) => {
                if (project.id === actualProject.id) {
                    projects[i].active = false;
                    this.props.dispatch(updateProjects(projects));
                }
            });
            history.push(`/${actualProject.id}/stats`);

        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    createPredefinedBot = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props, {
                "action": "predefined_bot",
                "info": {"bot_id": this.state.selectedBot.id}
            });
            await obj.do_actions();


            this.props.dispatch(updateEmpty(true));

            this.setState({empty: true});

            this.handleActionRequest("predefined_bot");
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    createSandbox = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props, {
                "action": "create_sandbox"
            });
            let action = await obj.do_actions();

            this.sandboxAction = setInterval(() => {
                this.getActionOne(action.id);
            }, 3000);

            this.setState({empty: true});

            this.handleActionRequest("create_sandbox");
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    updateSandbox = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props, {
                "action": "update_sandbox"
            });
            await obj.do_actions();

            this.setState({empty: true});

            this.handleActionRequest("update_sandbox");
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    publishSandbox = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props, {
                "action": "publish_sandbox"
            });
            await obj.do_actions();

            this.setState({empty: true});

            this.handleActionRequest("publish_sandbox");
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    deleteSandbox = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props, {
                "action": "delete_sandbox"
            });
            let action = await obj.do_actions();


            this.sandboxAction = setInterval(() => {
                this.getActionOne(action.id);
            }, 3000);

            this.handleActionRequest("delete_sandbox");
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    getProjects = async () => {
        const {access} = this.props.data;
        try {
            let obj = new UsersAPI(access.userId, access, this.props);
            var projects = await obj.get_projects();

            this.props.dispatch(updateProjects(projects));
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    exportProject = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props, {
                "action": "export"
            });
            await obj.do_actions();

            this.handleActionRequest("export");
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    onSelectBot = (e) => {
        const {bots} = this.state;
        var element = bots.filter(x => x.id === e);

        if (element.length > 0) {
            this.setState({selectedBot: element[0]});
        }
    }

    handleStatus = (item) => {
        const {t} = this.props;
        if (item.pending) {
            return (
                <span className="pending">{t(`card.content.project.actions.table.content.status.pending`)}</span>);
        } else if (item.in_progress) {
            return (<span
                className="in_progress">{t(`card.content.project.actions.table.content.status.in_progress`)}</span>);
        } else if (item.result === 1) {
            return (<span className="finish">{t(`card.content.project.actions.table.content.status.finish`)}</span>);
        } else if (item.result === 2) {
            var emails = ["centribal.com", "centribalmadrid", "pcentribalc"],
                email = this.props.data.user.email;

            if (emails.some(el => email.includes(el)) && item.action === 'import') {
                return (
                    <div className={"table_element__view"}>
                        <span
                            className="error">{t(`card.content.project.actions.table.content.status.finish_with_errors`)}</span>
                        <div onClick={() => console.log('[pclogs]:', item.error)}>{viewSvg()}</div>
                    </div>
                )
            }
            return (<span
                className="error">{t(`card.content.project.actions.table.content.status.finish_with_errors`)}</span>);
        }
    }

    handleFinishDiff = (item) => {
        if (item.result !== 0) {
            var fecha1 = moment(item.created_at, "YYYY-MM-DD HH:mm:ss");
            var fecha2 = moment(item.finished_at, "YYYY-MM-DD HH:mm:ss");

            var horas = fecha2.diff(fecha1, 'h');
            var minutos = fecha2.diff(fecha1, 'm');
            var segundos = fecha2.diff(fecha1, 's');

            if (horas) {
                return `${horas}:${minutos}h`;
            } else if (minutos) {
                return `${minutos}m`;
            }
            return `${segundos}s`;
        } else return (<CircularProgress size={21}/>);
    }

    importProject = async (e) => {
        const {access, actualProject} = this.props.data;

        if (e.length === 1 && (e[0].type === 'application/zip' || e[0].type === 'application/x-zip-compressed')) {
            var formData = new FormData();
            formData.append('file', e[0]);
            try {
                const obj = new ProjectAPI(actualProject.id, access, this.props, formData);
                await obj.importProject();

                this.handleActionRequest("import");
            } catch (err) {
                this.props.dispatch(showNotify({message: err, severity: 'error'}));
            }

        } else if (e.length > 1) {
            this.props.dispatch(showNotify({message: "zip_multiple", severity: 'error'}));
        } else if (e[0].type !== 'application/zip' && e[0].type !== 'application/x-zip-compressed') {
            this.props.dispatch(showNotify({message: "zip_no_zip", severity: 'error'}));
        }
    }

    handleActionRequest = (type) => {
        this.props.dispatch(showNotify({message: "project", type: type, severity: "success"}));
        this.getActions();
        this.initInterval();
    }

    render() {
        const {
            bots,
            empty,
            limits,
            selectedBot,
            actions,
            action,
            open
        } = this.state;
        const {plan} = this.props.data;
        const {t, project_name, project} = this.props;
        return (
            bots && limits
                ? <React.Fragment>
                    {/* ACTIONS */}
                    <div className={"cen_card"}>
                        <div className={"header"}>
                            <div className={"header_content"}>
                                <div className={"header_title"}>
                                    <span className={"title"}>{t('card.content.project.actions.title')}</span>
                                    <span
                                        className={"description"}>{t('card.content.project.actions.description')}</span>
                                </div>
                            </div>
                        </div>
                        <div className={"content scroll_table"}>
                            <div className={`elements__main`}>
                                {
                                    actions && actions.length > 0 ?
                                        <table style={{marginTop: 0, marginBottom: 0}} className={`table-actions`}>
                                            <thead>
                                            <tr>
                                                <th>{t(`card.content.project.actions.table.header.action`)}</th>
                                                <th>{t(`card.content.project.actions.table.header.status`)}</th>
                                                <th>{t(`card.content.project.actions.table.header.creation`)}</th>
                                                <th>{t(`card.content.project.actions.table.header.time`)}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                actions.map((el, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <td className="bck-project">{t(`card.content.project.actions.table.content.${el.action}`)}</td>
                                                            <td>
                                                                {this.handleStatus(el)}
                                                            </td>
                                                            <td>{el.created_at}</td>
                                                            <td>{this.handleFinishDiff(el)}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            </tbody>
                                        </table> : (
                                            <span
                                                className="actions-null">{t(`card.content.project.actions.table.empty`)}</span>
                                        )
                                }
                            </div>
                        </div>
                    </div>
                    {/* TEMPLATES */}
                    <div className={"cen_card"}>
                        <div className={"header"}>
                            <div className={"header_content"}>
                                <div className={"header_title"}>
                                            <span
                                                className={"title"}>{t('card.content.project.actions.template.title')}</span>
                                    <span
                                        className={"description"}>{t('card.content.project.actions.template.description')}
                                        <span
                                            className="error"> {t(`card.content.project.actions.template.description_warning`)}</span>
                                            </span>
                                </div>
                            </div>
                        </div>
                        <div className={"content"}>
                            <div className={`elements__main`}>
                                <div
                                    className={`${!empty && limits.intents.total === 0 && limits.contexts.total === 0 ? '' : 'opacity_5'}`}>
                                    <div>
                                        <div className="elements_title">
                                            <span
                                                className={"title"}>{t(`card.content.project.actions.template.select.title`)}</span>
                                            <span
                                                className={"subtitle"}>{t(`card.content.project.actions.template.select.description`)} </span>
                                        </div>
                                        <Select
                                            strSelected={selectedBot && selectedBot.name ? selectedBot.name : ''}
                                            array={bots}
                                            onSelect={this.onSelectBot}
                                            placeholder={t(`card.content.project.actions.template.select.placeholder`)}
                                            value={'id'}
                                            id={'select-bot'}
                                            disabled={!empty && limits.intents.total === 0 && limits.contexts.total === 0 ? false : true}
                                            t={t}
                                        />
                                    </div>
                                </div>
                                <Divider/>
                                <div className={`${selectedBot && !empty ? '' : 'opacity_5'}`}>
                                    <div>
                                        <div className="elements_title">
                                            <span
                                                className={"title"}>{t(`card.content.project.actions.template.create.title`)}</span>
                                            <span
                                                className={"subtitle"}>{t(`card.content.project.actions.template.create.description`)} </span>
                                        </div>
                                        <Button onClick={(e) => {
                                            this.createPredefinedBot(e)
                                        }} type={"primary"}
                                                text={t(`card.content.project.actions.template.create.button`)}
                                                disabled={!(!empty && selectedBot && limits.intents.total === 0 && limits.contexts.total === 0)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* IMPORT/EXPORT */}
                    <div className={"cen_card"}>
                        <div className={"header"}>
                            <div className={"header_content"}>
                                <div className={"header_title"}>
                                    <span className={"title"}>{t('card.content.project.actions.json.title')}</span>
                                    <span
                                        className={"description"}>{t('card.content.project.actions.json.description')}
                                            </span>
                                </div>
                            </div>
                        </div>
                        <div className={"content"}>
                            <div className={`elements__main ${!empty ? '' : 'opacity_5'}`}>
                                <div>
                                    <div>
                                        <div className="elements_title">
                                            <span
                                                className={"title"}>{t(`card.content.project.actions.json.export.title`)}</span>
                                            <span
                                                className={"subtitle"}
                                                dangerouslySetInnerHTML={{__html: t(`card.content.project.actions.json.export.description`)}}></span>
                                        </div>
                                        <Button onClick={(e) => {
                                            this.exportProject(e)
                                        }}
                                                className={"icon"}
                                                type={"primary"}
                                                text={<>{t(`card.content.project.actions.json.export.button`)}{exportSvg()}</>}
                                                disabled={empty}
                                        />
                                    </div>
                                </div>
                                <Divider/>
                                <div>
                                    <div>
                                        <div className="elements_title">
                                            <span
                                                className={"title"}>{t(`card.content.project.actions.json.import.title`)}</span>
                                            <span
                                                className={"subtitle"}
                                                dangerouslySetInnerHTML={{__html: t(`card.content.project.actions.json.import.description`)}}></span>
                                        </div>
                                        {
                                            empty ?
                                                (
                                                    <Button className={"icon"}
                                                            type={"primary"}
                                                            text={<>{t(`card.content.project.actions.json.import.button`)}{importSvg()}</>}
                                                            disabled
                                                    />
                                                ) : (
                                                    <Dropzone onDrop={this.importProject}>
                                                        {({getRootProps, getInputProps}) => (
                                                            <div className="btn btn-primary import" {...getRootProps()}>
                                                                <input id={"zip-file"} {...getInputProps()} />
                                                                <p>{t(`card.content.project.actions.json.import.button`)} {importSvg()}</p>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* SANDBOX*/}
                    {
                        !project.sandbox &&
                        <div className={"cen_card"}>
                            <div className={"header"}>
                                <div className={"header_content"}>
                                    <div className={"header_title"}>
                                                <span
                                                    className={"title"}>{t('card.content.project.actions.sandbox.title')}</span>
                                        <span
                                            className={"description"}>{t('card.content.project.actions.sandbox.description')}
                                            </span>
                                    </div>
                                </div>
                            </div>
                            <div className={"content"}>
                                <div
                                    className={`${!isPlanAllowed(plan.plan.name) ? 'elements__upgrade' : 'elements__main'} ${!empty ? '' : 'opacity_5'}`}>
                                    {
                                        !isPlanAllowed(plan.plan.name) ? (
                                                <div>
                                                    <UpgradePlan/>
                                                </div>
                                            ) :
                                            <React.Fragment>
                                                {
                                                    !project.sandbox && !project.sandbox_id &&
                                                    <div>
                                                        <div>
                                                            <div className="elements_title">
                                                                    <span
                                                                        className={"title"}>{t(`card.content.project.actions.sandbox.create.title`)}</span>
                                                                <span className={"subtitle"}
                                                                      dangerouslySetInnerHTML={{__html: t(`card.content.project.actions.sandbox.create.description`)}}></span>
                                                            </div>
                                                            <Button type={"primary"}
                                                                    text={t(`card.content.project.actions.sandbox.create.button`)}
                                                                    onClick={(e) => this.createSandbox(e)}
                                                                    disabled={empty}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    project.sandbox_id &&
                                                    <React.Fragment>
                                                        <div>
                                                            <div>
                                                                <div className="elements_title">
                                                                    <span
                                                                        className={"title"}>{t(`card.content.project.actions.sandbox.redirect.title`)}</span>
                                                                    <span className={"subtitle"}
                                                                          dangerouslySetInnerHTML={{__html: t(`card.content.project.actions.sandbox.redirect.description`)}}></span>
                                                                </div>
                                                                {
                                                                    project.sandbox_id === this.props.data.actualProject.id ?
                                                                        <Button
                                                                            onClick={() => handleRedirectToSandbox(project.sandbox_id, false, this.props.history)}
                                                                            type={"primary"}
                                                                            text={t(`card.content.project.actions.sandbox.redirect.button`)}
                                                                            disabled={empty}
                                                                        />
                                                                        :
                                                                        <Button
                                                                            onClick={() => handleRedirectToSandbox(project.sandbox_id, true)}
                                                                            className={"icon"}
                                                                            type={"primary"}
                                                                            text={<>{t(`card.content.project.actions.sandbox.redirect.button`)}{openNewWindow()}</>}
                                                                            disabled={empty}
                                                                        />
                                                                }
                                                            </div>
                                                        </div>
                                                        <Divider/>
                                                        <div>
                                                            <div>
                                                                <div className="elements_title">
                                                                    <span
                                                                        className={"title"}>{t(`card.content.project.actions.sandbox.update.title`)}</span>
                                                                    <span className={"subtitle"}
                                                                          dangerouslySetInnerHTML={{__html: t(`card.content.project.actions.sandbox.update.description`)}}></span>
                                                                </div>
                                                                <Button onClick={(e) => this.updateSandbox(e)}
                                                                        type={"primary"}
                                                                        text={t(`card.content.project.actions.sandbox.update.button`)}
                                                                        disabled={empty}
                                                                />
                                                            </div>
                                                        </div>
                                                        <Divider/>
                                                        <div>
                                                            <div>
                                                                <div className="elements_title">
                                                                    <span
                                                                        className={"title"}>{t(`card.content.project.actions.sandbox.publish.title`)}</span>
                                                                    <span className={"subtitle"}
                                                                          dangerouslySetInnerHTML={{__html: t(`card.content.project.actions.sandbox.publish.description`)}}></span>
                                                                </div>
                                                                <Button
                                                                    onClick={() => this.handleClickOpen("publish_sandbox")}
                                                                    type={"primary"}
                                                                    text={t(`card.content.project.actions.sandbox.publish.button`)}
                                                                    disabled={empty}
                                                                />
                                                            </div>
                                                        </div>
                                                        <Divider/>
                                                        <div>
                                                            <div>
                                                                <div className="elements_title">
                                                                        <span
                                                                            className={"title"}>{t(`card.content.project.actions.sandbox.delete.title`)}</span>
                                                                    <span className={"subtitle"}
                                                                          dangerouslySetInnerHTML={{__html: t(`card.content.project.actions.sandbox.delete.description`)}}></span>
                                                                </div>
                                                                <Button
                                                                    onClick={() => this.handleClickOpen("delete_sandbox")}
                                                                    type={"primary"}
                                                                    text={t(`card.content.project.actions.sandbox.delete.button`)}
                                                                    disabled={empty}
                                                                />
                                                            </div>
                                                        </div>
                                                    </React.Fragment>

                                                }
                                            </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    }
                    {/* EMPTY PROJECT */}
                    <div className={"cen_card"}>
                        <div className={"header"}>
                            <div className={"header_content"}>
                                <div className={"header_title"}>
                                    <span className={"title"}>{t('card.content.project.actions.empty.title')}</span>
                                    <span
                                        className={"description"}>{t('card.content.project.actions.empty.description')}
                                            </span>
                                </div>
                            </div>
                        </div>
                        <div className={"content"}>
                            <div className={`elements__main`}>
                                <div
                                    className={`${empty || (limits.intents.total === 0 && limits.contexts.total === 0) ? 'opacity_5' : ''}`}>
                                    <div>
                                        <div className="elements_title">
                                            <span
                                                className={"title"}>{t(`card.content.project.actions.empty.project.title`)}</span>
                                            <span
                                                className={"subtitle"}>{t(`card.content.project.actions.empty.project.description`)}</span>
                                        </div>
                                        <Button onClick={() => this.handleClickOpen('empty')}
                                                type={"warning"}
                                                text={t(`card.content.project.actions.empty.project.button`)}
                                                disabled={empty || (limits.intents.total === 0 && limits.contexts.total === 0)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* DELETE*/}
                    {
                        !project.sandbox &&
                        <div className={"cen_card"}>
                            <div className={"header"}>
                                <div className={"header_content"}>
                                    <div className={"header_title"}>
                                                  <span
                                                      className={"title error"}>{t(`card.content.project.actions.delete.title`)}</span>
                                        <span
                                            className={"description"}>{t(`card.content.project.actions.delete.description`)}
                                            <span
                                                className="error"> {t(`card.content.project.actions.delete.description_warning`)}</span>
                                            </span>
                                    </div>
                                </div>
                            </div>
                            <div className={"content"}>
                                <div className={`elements__main`}>
                                    <div
                                        className={`${empty || project.sandbox_id ? 'opacity_5' : ''}`}>
                                        <div>
                                            <div className="elements_title">
                                                    <span
                                                        className={"title"}>{t(`card.content.project.actions.delete.project.title`)}</span>
                                                <span
                                                    className={"subtitle"}>{t(`card.content.project.actions.delete.project.description`)}</span>
                                            </div>
                                            <Button onClick={() => this.handleClickOpen('delete')}
                                                    type={"normal_delete"}
                                                    text={t(`card.content.project.actions.delete.project.button`)}
                                                    disabled={empty || project.sandbox_id}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    <DialogAction t={t} actualLayout={`project_${action}`}
                                  handleAction={this.handleAction} handleClickOpen={this.handleClickOpen}
                                  open={open} item={{
                        name: action && (action === 'delete_sandbox' || action === 'publish_sandbox') ? `${project_name} Sandbox` : project_name,
                        action: action
                    }}/>
                </React.Fragment>
                : <div className={"cen_card"}>
                    <div className={"content"}>
                        <div className="loading">
                            <CircularProgress color="primary" size={50}/>
                        </div>
                    </div>
                </div>
        );
    }

}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_chatbot_actions = connect(
    mapStateToProps
)(ChatbotActions);

export default withTranslation('common')(withRouter(connect_chatbot_actions));