// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Translations
import {withTranslation} from "react-i18next";
// Styles
import 'components/Accordion/styles/styles.scss';
// Components
import {Radio} from "@material-ui/core";
import {Button, Input, SelectAutocomplete, SelectSimple} from "@components/Input";
import UseCustomFields from "components/Accordion/AccordionIntents/params/use_custom_fields";
import FloatButtons from "components/Input/float_buttons";
import EntityCreateModal from "views/Bots/NLP/application/Build/Tabs/Entities/application/entity_create_modal";
import {PopUp} from "@components/Modals";
// Assets
import {addSvg, closeSvg, copySvg, deleteSvg} from "@assets";
// Vendor
import {addArtifact, handleCopy, isSessionParam, returnElementFromArray} from 'vendor/application';

class Params extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            updateCustom: false
        }
    }

    updateParam = (e, type, i, bool) => {
        const {intent, updateIntent} = this.props;
        var value = e?.target?.value ?? e;

        if (type === 'entity_type') {
            intent.parameters[i].identification = false;
        }

        if (type === 'prompts') {
            intent.parameters[i][type][0] = value;
        } else if (type === 'name') {
            if (value.match(/^[a-zA-Z0-9_-]*$/gm)) {
                intent.parameters[i].name = value;
                intent.parameters[i].variable = `$${value}`;
            }
        } else {
            intent.parameters[i][type] = bool ? !intent.parameters[i][type] : value;
        }

        updateIntent(intent);
    }

    updateArtifacts = (e, i, artifact_type, type) => {
        const {intent, updateIntent} = this.props;

        var res = addArtifact(i, artifact_type, type, intent);

        updateIntent(res);
    }

    showUseCustomFields = () => {
        const {integrations} = this.props;
        if (integrations?.length > 0) {
            const integration = integrations?.filter(int => int.channel === "zendesk_full")[0];
            var use_custom = integration?.multiple.filter(int => int.use_custom_fields);
            if (use_custom?.length > 0) {
                return true;
            }
        }
        return false;
    }

    render() {
        const {
            intent,
            params,
            updateElement,
            updateParams,
            integrations,
            t
        } = this.props;
        const {open} = this.state;
        return (
            <div className={"accordion__content"}>
                {
                    intent.parameters.length > 0 &&
                    <div className={"accordion__element_param_content"}>
                        {
                            intent.parameters.map((el, i) => {
                                return (
                                    <div key={i} className={"accordion__element_param"}>
                                        <div className={"accordion__element_param_header"}>
                                            <div className={"accordion__element_param_header_content"}>
                                                <span>$</span>
                                                <Input
                                                    type={"transparent"}
                                                    placeholder={t(`intents.config.accordion.params.param.name`)}
                                                    inputProps={{'aria-label': 'description'}}
                                                    value={el.name}
                                                    onChange={(e) => {
                                                        this.updateParam(e, 'name', i)
                                                    }}
                                                />
                                            </div>
                                            <div className={"accordion__element_param_header_actions"}>
                                                <div onClick={() => handleCopy(`${el.variable}`)}>
                                                    {copySvg()}
                                                </div>
                                                <div onClick={(e) => {
                                                    updateElement(e, "parameters", "delete", i)
                                                }}>
                                                    {deleteSvg()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"accordion__element_content"}>
                                            <div
                                                className={`accordion__params_checkbox ${isSessionParam(el.entity_type) ? 'identification' : ''}`}>
                                                <div className={"actions"}>
                                                    <SelectSimple
                                                        className={"select_secondary"}
                                                        valueSelected={el.entity_type ? el.entity_type : false}
                                                        items={params}
                                                        onSelect={(e) => this.updateParam(e, 'entity_type', i)}
                                                        placeholder={t(`intents.config.accordion.params.param.entity`)}
                                                        type={i}
                                                        t={t}
                                                    />
                                                    <Button className={"btn_icon"} type={"white"} onClick={() => {
                                                        this.setState({open: true})
                                                    }} text={addSvg()}/>
                                                </div>
                                                {
                                                    isSessionParam(el.entity_type) &&
                                                    <div className={"checkbox"}>
                                                        <span>{t(`intents.config.accordion.params.param.identification`)}</span>
                                                        <Radio
                                                            checked={el.identification}
                                                            onClick={(e) => {
                                                                this.updateParam(e, 'identification', i, 'bool')
                                                            }}
                                                            disableRipple
                                                        />
                                                    </div>
                                                }
                                                <div className={"checkbox"}>
                                                    <span>{t(`intents.config.accordion.params.param.required`)} </span>
                                                    <Radio
                                                        checked={el.required}
                                                        onClick={(e) => {
                                                            this.updateParam(e, 'required', i, 'bool')
                                                        }}
                                                        disableRipple
                                                    />
                                                </div>
                                            </div>
                                            <div className={"accordion__params_prompts"}>
                                                <Input
                                                    id={`params-prompts-${i}`}
                                                    placeholder={t(`intents.config.accordion.params.param.prompt`)}
                                                    value={el.prompts[0] ? el.prompts[0] : ''}
                                                    multiline
                                                    inputProps={{maxLength: 4096}}
                                                    onChange={(e) => {
                                                        this.updateParam(e, 'prompts', i)
                                                    }}
                                                />
                                                <FloatButtons type={"params"} addArtifact={this.updateArtifacts}
                                                              iteration={i}
                                                              buttons_toggle_artifacts={["file", "img", "link", "button", "carousel", "bubble", "for"]}/>
                                            </div>
                                            {
                                                integrations.length > 0 &&
                                                    <UseCustomFields {...this.props} el={el} i={i} />                             
                                            }
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                }
                <PopUp
                    open={open}
                    handleClose={() => this.setState({open: false})}
                    t={t}
                    width={"auto"}
                    content={
                        <EntityCreateModal type={"content"}/>
                    }
                    buttons={
                        <EntityCreateModal type={"button"} updateParams={updateParams}
                                           close={() => this.setState({open: false})}/>
                    }
                />
                <Button type={"dashed"} onClick={(e) => {
                    updateElement(e, "parameters", "add")
                }} text={t('intents.config.accordion.params.button')}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const accordion_params = connect(
    mapStateToProps
)(Params);

export default withTranslation('common')(accordion_params);