// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import 'views/Campaigns/styles/index.scss';
// API
import {CampaignsAPI} from "views/Campaigns/infrastructure";
import {get_customers_filter, get_agents} from "api/application/Customers";
import {get_users} from "api/application/Users";
// Components
import {ElementsCustom} from "@components/Elements";
import {AlertInfo} from "@components/Alert";
import {PopUp} from "@components/Modals";
import {Button, Input, Search, SelectSimple} from "@components/Input";
import {AppBar, CircularProgress, Divider, Tab, Tabs} from '@material-ui/core';
import Preview from "../../components/Preview";
import {TabPanel} from "components/Navigation/tab_panel";
import CSVReader from 'react-csv-reader';
// SVG
import {closeSvg, csvSvg} from "@assets";
// Vendor
import {showNotify} from "vendor/application/disptach";
import {getGroupBody, isValidDate} from 'vendor/application';
// Moment
import moment from 'moment';
// Helpers
import {returnElementFromArray} from "@helpers";
// Jquery
import $ from 'jquery';
// Amplitude API
import {AmplitudeAPI} from '@api/Amplitude';
// Assets
import {campaignsSvg} from "assets";
import Table from "../../components/Table";

class Campaign extends Component {
    constructor(props) {
        super(props);
        this.state = {
            group: getGroupBody(),
            whatsapp_phone: false,
            templates: false,
            template: false,
            centridesk: {
                agents: false,
                customers: false,
                page: 1,
                max: 1,
                selected: [],
                all_selected: false,
                filters: {
                    str: '',
                    type: 'name'
                },
                placeholders: false
            },
            params: [],
            isDisabled: false,
        }
    }

    componentDidMount = () => {
        const {plan} = this.props.data;
        this.getTemplates(plan?.whatsapp_phones[0]);
        this.getUsers();
        this.handleTabs();
    }

    getTemplates = async (phone) => {
        const {access} = this.props.data;
        try {
            let obj = new CampaignsAPI(phone.project_id, access, this.props);
            let templates = await obj.get_templates();

            var approved_templates = [];
            templates.forEach((template => {
                if (template.status === 'approved') {
                    approved_templates.push(template);
                }
            }))

            var params = this.getParams(approved_templates[0]);

            this.setState({
                templates: approved_templates,
                template: approved_templates[0] ?? false,
                params,
                whatsapp_phone: phone
            });
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getParams = (template) => {
        var param_regex = /{{\w*}}/gm,
            params_count = template?.body.match(param_regex)?.length ?? 0;

        var params = [];
        if (params_count) {
            for (var x = 0; x < params_count; x++) {
                params.push("");
            }
        }

        return params;
    }

    getUsers = () => {
        get_users(this.props).then((users) => {
            var agents = get_agents(users, this.props.data.roles);
            this.getCustomers(null, agents);
        })
    }

    getCustomers = (filter, agents) => {
        const {centridesk} = this.state;
        get_customers_filter(this.props, filter ?? this.state.filter).then((res) => {

            var customers = [],
                regExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

            res.customers.forEach(customer => {
                if (customer.phone?.match(regExp)) {
                    customer.selected = false;
                    customers.push(customer);
                }
            })

            var arr = [];
            while (customers.length) {
                arr.push(customers.splice(0, 5));
            }

            centridesk.customers = arr;
            centridesk.max = arr.length;
            centridesk.page = 1;
            centridesk.all_selected = false;
            centridesk.selected = [];
            centridesk.agents = agents ?? this.state.centridesk.agents;

            this.setState({centridesk})
        })
    }

    handleTabs = () => {
        const {history} = this.props;
        var queryString = window.location.search,
            urlParams = new URLSearchParams(queryString),
            tab = urlParams.get('tab');

        switch (tab) {
            case 'csv':
                this.setState({tabValue: 0});
                break;
            case 'centridesk_users':
                this.setState({tabValue: 1});
                break;
            default:
                history.push(`${this.props.location.pathname}?tab=csv`);
                this.setState({tabValue: 0});
                break;
        }
    }

    setQuery = (e, to) => {
        e.preventDefault();
        this.props.history.push(`${this.props.location.pathname}?tab=${to}`);
        this.handleTabs();
    }

    a11yProps(index) {
        return {
            id: `nlp-tab-${index}`,
            'aria-controls': `nlp-tabpanel-${index}`,
        };
    }

    updateElement = (e, type, sub_type, i) => {
        const {group, whatsapp_phone, template, params} = this.state;
        var str = e?.target?.value,
            new_whatsapp_phone = whatsapp_phone;
        switch (type) {
            case 'name':
                if (!(/\s/).test(str)) {
                    group[type] = str;
                }
                break;
            case 'send_date':
                if (isValidDate(e)) {
                    group[type] = e;
                }
                break;
            case 'category':
                group[type] = e.tag;
                break;
            case 'language':
                group[type] = e.code;
                break;
            case 'whatsapp_phone':
                new_whatsapp_phone = e;
                this.getTemplates(e);
                break;
            case 'header_type':
                template.header.url = str;
                this.setState({template: template});
                break;
            case 'payload':
                template.buttons.buttons[i].payload = str;
                this.setState({template: template});
                break;
            case 'param':
                params[i] = str;
                break;
        }

        this.setState({group: group, whatsapp_phone: new_whatsapp_phone, params});
    }

    handleSelect = (e, type) => {
        const {group} = this.state;
        group.content = [];
        switch (type) {
            case 'whatsapp_phone':
                this.setState({whatsapp_phone: e, template: false, group: group, params: []});
                this.getTemplates(e);
                break;
            case 'template':
                this.getParams(e);
                this.setState({template: e, group: group, params: this.getParams(e)});
                break;
        }
    }

    getGroupContent = () => {
        const {group, tabValue, params, centridesk} = this.state;
        if (tabValue === 0) {
            return group.content;
        } else {
            var new_group = [];
            centridesk.selected.forEach(customer => {
                var new_value = {};
                new_value = {variable_0: customer.phone};
                const regExp = /{{([a-zA-Z0-9_]+)}}/gm;

                params.forEach((param, i) => {
                    var match = [...param.matchAll(regExp)],
                        new_param = param;
                    if (match.length > 0) {
                        var value = match[0][1];
                        if (value && customer[value]) {
                            new_param = customer[value];
                        }
                    }
                    new_value = {...new_value, [`variable_${i+1}`]: new_param};
                })
                new_group.push(new_value);
            })

            return new_group;
        }
    }

    doRequest = async () => {
        this.setState({isDisabled: true});
        const {access, device_id, user} = this.props.data;
        const {group, whatsapp_phone, template} = this.state;

        var date = moment(group.send_date).format("yyyy-MM-DD HH:mm:ss");

        if (["document", "image"].includes(template.header?.type) && !template.header.url) {
            this.props.dispatch(showNotify({message: "url_is_required", severity: 'error'}))
        } else {
            try {
                var body = {
                    send_date: date,
                    phone: whatsapp_phone.phone,
                    name: group.name,
                    content: this.getGroupContent(),
                    template_id: template?.id ?? ""
                }

                if (["document", "image"].includes(template.header?.type)) {
                    body.header = {
                        type: template.header.type,
                        url: template.header.url
                    }
                }
                if (template.buttons?.buttons.length > 0 && template.buttons.type === 'quick_reply') {
                    template.buttons?.buttons.forEach((button, i) => {
                        if (button.payload) {
                            if (!body.buttons) {
                                body.buttons = [];
                            }
                            body.buttons.push({
                                index: i,
                                parameters: [
                                    {
                                        type: "payload",
                                        payload: button.payload
                                    }
                                ]
                            })
                        }
                    })
                }

                let obj = new CampaignsAPI(whatsapp_phone.project_id, access, this.props, body);
                await obj.post_group();

                new AmplitudeAPI({
                    event_type: 'Click on request campaign',
                    device_id: device_id,
                    user_id: user.email
                }).eventLog();


                this.props.dispatch(showNotify({message: "groups", type: "create", severity: "success"}));
            } catch (err) {
                this.props.dispatch(showNotify({message: err, severity: 'error'}))
            } finally {
                this.setState({ isDisabled: false });
            }
            
        }

    }

    returnToList = () => {
        const {history} = this.props;
        history.push('/campaigns/groups');
    }

    handleDisable = () => {
        const {group, whatsapp_phone, centridesk, tabValue, params} = this.state;
        var disabled = false;

        Object.keys(group).forEach(el => {
            if (el === 'content') {
                if (tabValue === 0 && group[el].length === 0) {
                    disabled = true;
                } else if (tabValue === 1) {
                    if (centridesk.selected.length === 0) {
                        disabled = true;
                    } else if (params.length > 0) {
                        var exist = params.filter(param => param === "");
                        if (exist.length > 0) {
                            disabled  = true;
                        }
                    }
                }
            } else if (!group[el] && el !== 'phone' && el !== 'template_id') {
                disabled = true;
            }
        });

        if (!whatsapp_phone) {
            disabled = true;
        }

        return disabled;
    }

    getCSVInfo = (data, info) => {
        const {group, template} = this.state;
        var new_array = [],
            name_split = info?.name?.split('.');

        if (!data) {
            group.content = [];
            this.setState({group: group});
        } else {
            var variable_regex = /{{\w*}}/gm,
                variables_count = template?.body.match(variable_regex)?.length ?? 0;

            if (name_split[name_split.length - 1] !== 'csv') {
                this.props.dispatch(showNotify({
                    message: "csv_no_csv",
                    severity: "error"
                }))
            } else if (!template) {
                this.props.dispatch(showNotify({
                    message: "csv_no_template",
                    severity: "error"
                }))
            } else if (data?.length <= 1001) {
                data.shift();

                if (data[0]?.length !== variables_count + 1) {
                    this.props.dispatch(showNotify({
                        message: "csv_variables_count",
                        severity: "error"
                    }))
                } else {
                    data.forEach(row => {
                        var new_value = {};
                        row.forEach(((row_value, i) => {
                            new_value = {...new_value, [`variable_${i}`]: row_value};
                        }))
                        if (row[0]) {
                            new_array.push(new_value);
                        }
                    });
                    group.content = new_array;

                    this.setState({group: group});
                }
            } else {
                group.content = [];
                this.setState({group: group});
                this.props.dispatch(showNotify({
                    message: "csv_1000_registers",
                    severity: "error"
                }))
            }
        }

        $('.csv-input')[0].value = '';
    }

    handlePagination = (e, next_page) => {
        const {centridesk} = this.state;
        centridesk.page = next_page;
        this.setState({centridesk});
    }

    handleSelectFilter = (e) => {
        const {centridesk} = this.state;
        centridesk.filters.type = e;
        centridesk.filters.str = "";
        if (e === "agent_id") {
            centridesk.filters.str = "-";
        }
        this.getCustomers(centridesk.filters);
        this.setState({centridesk})
    }

    handleFilter = (e, type) => {
        const {centridesk} = this.state;
        centridesk.filters.str = type === "agent" ? e : e?.target?.value;
        this.getCustomers(centridesk.filters);
        this.setState({centridesk})
    }

    handleSelectCustomer = (type, i) => {
        const {centridesk} = this.state;
        switch (type) {
            case 'all':
                centridesk.selected = [];
                centridesk.all_selected = !centridesk.all_selected;
                centridesk.customers.forEach(a => {
                    a.forEach(customer => {
                        customer.selected = centridesk.all_selected;
                        if (customer.selected) {
                            centridesk.selected.push(customer);
                        }
                    })
                })
                this.setState({centridesk})
                break;
            default:
                var customer = centridesk.customers[centridesk.page - 1][i];
                centridesk.all_selected = false;
                centridesk.customers[centridesk.page - 1][i].selected = !customer.selected;

                if (customer.selected) {
                    centridesk.selected.push(customer)
                } else {
                    centridesk.selected = centridesk.selected.filter((customer) => {
                        return customer.selected;
                    });
                }
                this.setState({centridesk})
                break;
        }
    }

    handleCopy = (copyText) => {
        navigator && navigator.clipboard && navigator.clipboard.writeText(copyText);
    }

    render() {
        const {templates, template, group, whatsapp_phone, tabValue, centridesk, params, isDisabled} = this.state;
        const {t} = this.props;
        const {actualLayout, plan, isSending, projects} = this.props.data;
        var project = returnElementFromArray(whatsapp_phone.project_id, projects, 'id');
        return (
            templates ?
                <div className="centribal_card" style={{borderRadius: "0 0 15px 15px"}}>
                    <div className={"campaigns_container_elements campaigns_container_elements_custom"}>
                        <div className={"campaigns_container_elements_content"}>
                            <AlertInfo text={t(`common:card.content.${actualLayout.layout}.campaigns.info`)}/>
                            {/*NAME*/}
                            <ElementsCustom
                                t={t}
                                type={"groups"}
                                input_type={"input"}
                                element_type={"name"}
                                element={group.name}
                                updateElement={this.updateElement}
                            />
                            <Divider/>
                             {/*WHATSAPP PHONE*/}
                            <div className={"elements_custom column"}>
                                <div className={"elements_custom__info"}>
                                    <span
                                        className={"elements_custom__title"}>{t(`elements.custom.templates.card.whatsapp_phone.title`)} </span>
                                    <span
                                        className={"elements_custom__description"}>{t(`elements.custom.templates.card.whatsapp_phone.description`)}</span>
                                </div>
                                {
                                    plan?.whatsapp_phones &&
                                    <div className={"elements_custom__selection"}>
                                        {plan?.whatsapp_phones.map((phone, i) => {
                                            return (
                                                <div
                                                    className={`${phone.phone === whatsapp_phone.phone ? "active" : ""}`}
                                                    onClick={() => this.updateElement(phone, "whatsapp_phone")}
                                                    key={i}>{phone.phone} - {phone.project_name}</div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                            <Divider/>
                             {/*TEMPLATE*/}
                            <ElementsCustom
                                t={t}
                                type={"groups"}
                                input_type={"select_with_code"}
                                element_type={"template"}
                                select_value={"name"}
                                element={template?.id ? template?.name : "-"}
                                elements={templates}
                                updateElement={this.handleSelect}
                            />
                            {
                                template?.id &&
                                <React.Fragment>
                                    <Divider/>
                                     {/*HEADER*/}
                                    <div className={"elements_custom column"}>
                                        <div className={"elements_custom__info"}>
                                    <span
                                        className={"elements_custom__title"}>{t(`elements.custom.templates.card.header.title`)}
                                    </span>
                                        </div>
                                        <div className={"elements_custom__header"}>
                                            <div className={"elements_custom__selection_images"}>
                                                {
                                                    ["null", "text", "document", "image"].map((element, i) => {
                                                        return (
                                                            <div
                                                                className={`${!template?.header && element === "null" || element === template?.header?.type ? "active" : ""}`}
                                                                key={i}>
                                                                <span className={"checkbox"}/>
                                                                <span className={"image"}>{campaignsSvg(element)}</span>
                                                                <span
                                                                    className={"text"}>{t(`elements.custom.templates.card.header.conditional.${element}.selector`)}</span>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                (template?.header?.type === "document" || template?.header?.type === "image") &&
                                                <Input
                                                    placeholder={t(`elements.custom.templates.card.header.conditional.${template.header.type}.placeholder`)}
                                                    value={template?.header?.url}
                                                    onChange={(e) => this.updateElement(e, "header_type", template?.header?.type)}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <Divider/>
                                     {/*FOOTER*/}
                                    <div className={"elements_custom"}>
                                        <div className={"elements_custom__info"}>
                                    <span
                                        className={"elements_custom__title"}>{t(`elements.custom.templates.card.footer.title`)}
                                    </span>
                                        </div>
                                        <div className={"platform_input_content"}>
                                            {
                                                template.footer ?
                                                    <Input
                                                        disabled
                                                        placeholder={t(`elements.custom.groups.card.footer.placeholder`)}
                                                        value={template.footer}
                                                    /> :
                                                    <AlertInfo
                                                        text={t(`elements.custom.groups.card.footer.placeholder`)}/>
                                            }
                                        </div>
                                    </div>
                                    <Divider/>
                                     {/*BUTTONS*/}
                                    <ElementsCustom
                                        t={t}
                                        type={"groups"}
                                        input_type={"select_with_conditional"}
                                        element_type={"buttons"}
                                        select_value={""}
                                        element={template.buttons?.type}
                                        elements={[]}
                                        disabled={true}
                                        alert={true}
                                        conditional={
                                            {
                                                type: "select_with_conditionals",
                                                element_conditional: template.buttons?.type !== null,
                                                elements: [],
                                                element_type: "buttons_type",
                                                element_actions: template.buttons?.buttons,
                                                element_actions_conditional: true,
                                                disabled: true
                                            }
                                        }
                                        updateElement={this.updateElement}
                                    />
                                </React.Fragment>
                            }
                            <Divider/>
                             {/*TIME SELECT*/}
                            <ElementsCustom
                                t={t}
                                type={"groups"}
                                input_type={"time_select"}
                                element_type={"send_date"}
                                element_translate={project?.timezone}
                                element={group.send_date}
                                disabled={true}
                                updateElement={this.updateElement}
                            />
                            <Divider/>
                            <div className="elements_custom column">
                                <div className="elements_custom__info"><span
                                    className="elements_custom__title">{t('common:card.content.groups_customers.header.title')}</span><span
                                    className="elements_custom__description">{t('common:card.content.groups_customers.header.description')}</span>
                                </div>
                            </div>
                            <Divider/>
                            <AppBar id={"app_bar"} position="static" color="default">
                                <Tabs
                                    value={tabValue}
                                    variant="scrollable"
                                    scrollButtons="on"
                                    textColor="primary"
                                >
                                     Sessions & Intents
                                    <Tab label={t('common:card.content.groups_customers.tabs.csv')} onClick={(e) => {
                                        this.setQuery(e, 'csv')
                                    }} {...this.a11yProps(0)} disabled={centridesk.selected.length > 0}/>
                                     Numeric
                                    <Tab label={t('common:card.content.groups_customers.tabs.centridesk_users')} disabled={group.content.length > 0}
                                         onClick={(e) => {
                                             this.setQuery(e, 'centridesk_users')
                                         }} {...this.a11yProps(1)} />
                                </Tabs>
                            </AppBar>
                            <Divider/>
                            <TabPanel value={tabValue} index={0}>
                                <div className="elements_custom column">
                                    <div className={"campaigns_container_elements_csv"}>
                                        {
                                            group.content.length > 0 &&
                                            <div className={"csv_close"} onClick={() => this.getCSVInfo(false)}>
                                                {closeSvg()}
                                            </div>
                                        }
                                        <CSVReader
                                            cssClass="csv_reader"
                                            label={<div
                                                className={`csv_reader_content ${group.content.length > 0 ? "active" : ""}`}>
                                                {csvSvg()}<span>{t(`elements.custom.groups.card.content.${group.content.length > 0 ? 'placeholder_content' : 'placeholder'}`)}</span>
                                            </div>}
                                            onFileLoaded={(data, info, infoType) => this.getCSVInfo(data, info, infoType)}
                                            inputStyle={{color: '#565671'}}
                                        />
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value={tabValue} index={1}>
                                <div className={"centridesk_users"}>
                                    <div className={"centridesk_users_filters"}>
                                        <SelectSimple
                                            valueSelected={t(`commons:input.select.centridesk.filter.${centridesk.filters.type}`)}
                                            items={["name", "phone", "email", "delegation", "company", "agent_id", "external_id"]}
                                            onSelect={this.handleSelectFilter}
                                            label={t("commons:input.select.centridesk.filter.placeholder")}
                                            placeholder={t("commons:input.select.centridesk.filter.placeholder")}
                                            className={"select_secondary"}
                                            type={"translate"}
                                            translate_pattern={"commons:input.select.centridesk.filter."}
                                            t={t}
                                        />
                                        {
                                            centridesk?.filters?.type === 'agent_id'
                                            ? <SelectSimple
                                                    valueSelected={returnElementFromArray(centridesk.filters.str, centridesk.agents, "id")?.name ?? ""}
                                                    items={centridesk.agents}
                                                    onSelect={this.handleFilter}
                                                    placeholder={t("commons:input.select.centridesk.filter.select_agent_id")}
                                                    className={"select_secondary"}
                                                    type={"agent"}
                                                    value={"id"}
                                                    str={"name"}
                                                />
                                            : <Search t={t}
                                                    placeholder={"Filtrar por..."}
                                                    onSearch={this.handleFilter}
                                                    value={centridesk.filters.str}/>
                                        }
                                    </div>
                                    <Divider/>
                                    <div className={"centridesk_users_table"}>
                                        <Table conditional={centridesk.customers}
                                                 all_selected={centridesk.all_selected} items={centridesk.customers}
                                                 type={"groups_customers"} agents={centridesk.agents}
                                                 handlePagination={this.handlePagination} page={centridesk.page}
                                                 max={centridesk.max} handleSelect={this.handleSelectCustomer}
                                                 header={['selected', 'name', 'phone', 'email', 'company', 'agent']}/>
                                        <Divider/>
                                        {
                                            params.length > 0 &&
                                            <div className={"centridesk_users_placeholders"}>
                                                {params.map((param, i) => {
                                                    return <div className={"centridesk_users_placeholders_element"} key={i}>
                                                        <Input
                                                            type={"secondary"}
                                                            placeholder={t('common:card.content.groups_customers.params_placeholder', {iteration: i+1})}
                                                            value={param}
                                                            onChange={(e) => this.updateElement(e, 'param', null, i)}
                                                        />
                                                        {(params.length - 1) !== i && <Divider/>}
                                                    </div>
                                                })}
                                                <Divider />
                                                <a onClick={() => {centridesk.placeholders = true; this.setState({centridesk})}}>{t('common:card.content.groups_customers.placeholders.link')}</a>
                                                <PopUp
                                                    open={centridesk.placeholders}
                                                    handleClose={() => {
                                                        centridesk.placeholders = false;
                                                        this.setState({centridesk})
                                                    }}
                                                    t={t}
                                                    width={500}
                                                    content={
                                                        <div className={"placeholders__popup"}>
                                                            <div className={"placeholders__popup_header"}>
                                                                <span>{t('common:card.content.groups_customers.placeholders.title')}</span>
                                                            </div>
                                                            <Divider />
                                                            <div className={"placeholders__popup_content"}>
                                                            {
                                                                ["name", "email", "company", "delegation", "external_id"].map((item, i) => {
                                                                    return <React.Fragment key={i}>
                                                                        <div className={"placeholders__popup_element"}>
                                                                            <div className={"placeholders__popup_element_title"}>
                                                                                <span>{`{{${item}}}`}</span>
                                                                                <span>{t(`common:card.content.groups_customers.placeholders.${item}`)}</span>
                                                                            </div>
                                                                            <div onClick={() => this.handleCopy(`{{${item}}}`)} className={"placeholders__popup_element_action"}>
                                                                                <span>{t('common:card.content.groups_customers.placeholders.button')}</span>
                                                                            </div>
                                                                        </div>
                                                                        { i < 4 &&
                                                                            <Divider />
                                                                        }
                                                                    </React.Fragment>
                                                                })
                                                            }
                                                            </div>
                                                        </div>
                                                    }
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                        <div className={"campaigns_container_preview"}>
                            <Preview template_selected={JSON.parse(JSON.stringify(template))}/>
                            <div className={"campaigns_container_footer"}>
                                <Button type={"success"}
                                        text={t(`common:card.content.${actualLayout.layout}.action_button`)}
                                        onClick={() => this.doRequest()} 
                                        disabled={isDisabled}
                                        />
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="card">
                    <div className="loading-data">
                        <CircularProgress color="primary" size={70}/>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const create_campaign = connect(
    mapStateToProps
)(Campaign);

export default withTranslation(['commons', 'common'])(withRouter(create_campaign));