import { updateSending } from 'vendor/application/disptach';
import { CustomRequestManager } from 'vendor/infrastructure/custom_request_manager';

export class ChargebeeAPI {

    constructor(userID, token, props, body) {
        this.userID = userID;
        this.token = token;
        this.props = props;
        this.body = body;

        this.props.dispatch(updateSending(true));
    }

    async get_user_chargebee_id() {
        let request = new CustomRequestManager('GET', `chargebee/user/${this.userID}`, null, this.token, this.props);
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_chargebee_user_subscriptions() {
        let request = new CustomRequestManager('GET', `chargebee/subscriptions/${this.userID}`, null, this.token, this.props);
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

}