// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
// Helpers
// Components
import PaginationTable from "./Pagination";
import {CircularProgress, Tooltip} from "@material-ui/core";
// Icons
// Styles
import './styles.scss';

class Table extends Component {
    constructor(props) {
        super(props);
    }

    handleElement = (item, type) => {
        const {t} = this.props;
        var value;
        switch (type) {
            case 'delivery_failure_at':
                value = item[type];
                return (
                    value ?
                        <Tooltip title={item?.delivery_failure_error?.message} placement="right" enterDelay={200}
                                 leaveDelay={100}>
                            <span>{value}</span>
                        </Tooltip> :
                        ""
                )
            case 'status':
                return <span className={`status ${item[type]}`}>{t(`table.status.${item[type]}`)}</span>
            case 'variables':
                return <span className={"variables"}>
                    {item?.parameters?.map((parameter, i) => {
                        return (
                            <span key={i}>{parameter.text}</span>
                        )
                    })}
                </span>
            default:
                return item[type];

        }
    }

    handleActiveElement = (item, field_item) => {
        const {match} = this.props;
        if (field_item === 'name' && item.id === match?.params?.id) {
            return 'active_element';
        }
        return '';
    }

    render() {
        const {t, items, header, type, handlePagination, max, page, is_loading, onClickAction, children} = this.props;
        return (
            <React.Fragment>
                {
                    !children ?
                        <table className={`platform_table_${type}`}>
                            <thead>
                            <tr>
                                {
                                    header.map(((header_item, header_i) => {
                                        return (
                                            <th className={`table_${header_item}`}
                                                key={header_i}>{t(`table.header.${header_item}`)}</th>
                                        )
                                    }))
                                }
                            </tr>
                            </thead>
                            <tbody>
                            {
                                is_loading ?
                                    <tr className={"table-loading"}>
                                        <td style={{borderRadius: 10}} colSpan={header?.length}>
                                            <div style={{height: 425}}>
                                                <div className="loading-data">
                                                    <CircularProgress color="primary" size={70}/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr> :
                                    items?.map(((item, row_i) => {
                                        return (
                                            <tr onClick={(e) => onClickAction ? onClickAction(true, row_i) : e.stopPropagation()}
                                                key={row_i} style={{cursor: onClickAction ? 'pointer' : 'inherit'}}>
                                                {header.map(((field_item, field_i) => {
                                                    return (
                                                        <td className={`table_${field_item} ${this.handleActiveElement(item, field_item)}`}
                                                            key={field_i}>{this.handleElement(item, field_item)}</td>
                                                    )
                                                }))}
                                            </tr>
                                        )
                                    }))
                            }
                            </tbody>
                        </table> :
                        children
                }
                {
                    handlePagination && !children &&
                    <PaginationTable handlePagination={handlePagination} max={max} page={page}/>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_app = connect(
    mapStateToProps
)(Table);

export default withRouter(withTranslation('tables')(connect_app));