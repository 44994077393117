import { CustomRequest } from 'vendor/infrastructure/custom_requests';
import { updateSending, updateDeleting } from 'vendor/application/disptach';

export class IntentsAPI {
    constructor(projectID, token, props, body, notSending) {
        this.projectID = projectID;
        this.token = token;
        this.props = props;
        this.body = body;

        if ( !notSending ) {
            this.props.dispatch(updateSending(true));
        }
    }

    async put_intent(id) {
        const request = new CustomRequest('PUT', `projects/${this.projectID}/intents/${this.props.id ?? id}`, JSON.stringify(this.body), this.token, this.props, true );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async post_intent() {
        const request = new CustomRequest('POST', `projects/${this.projectID}/intents`, JSON.stringify(this.body), this.token, this.props, true);

        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_intents() {
        let request = new CustomRequest('GET', `projects/${this.projectID}/intents`, null, this.token, this.props, true);
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async put_intent_metadata(intent_id) {
        const request = new CustomRequest('PUT', `projects/${this.projectID}/intents/${intent_id}/metadata`, JSON.stringify(this.body), this.token, this.props, true);
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_intents_pagination(reset_filters) {
        const {filters} = this.props.data;

        var page = reset_filters ? 1 : filters.intents.pagination.page,
            page_size = filters.intents.pagination.interval,
            search_by_name = filters.intents.search,
            search_by_context_id = reset_filters ? "" : filters.intents.context_selector.id !== 'all_contexts' ? filters.intents.context_selector.id : "",
            sort_by = filters.intents.order_by.type,
            order_by = filters.intents.order_by.value;

        let request = new CustomRequest('GET', `projects/${this.projectID}/intents?page=${page}&page_size=${page_size}&search_by_name=${search_by_name}&search_by_context_id=${search_by_context_id}&sort_by=${sort_by}&order_by=${order_by}`, null, this.token, this.props, true);
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_intent_by_id() {
        let request = new CustomRequest('GET', `projects/${this.projectID}/intents/${this.props.id}`, null, this.token, this.props, true );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async delete_intent(id) {
        this.props.dispatch(updateDeleting(true));
        let request = new CustomRequest('DELETE', `projects/${this.projectID}/intents/${(this.props.id) ? this.props.id : id}`, null, this.token, this.props, true );
        var req = await request.action();
        this.props.dispatch(updateDeleting(false));
        return req;
    }

    async get_entities() {
        let request = new CustomRequest('GET', `projects/${this.projectID}/entities/available`, null, this.token, this.props, true );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_events() {
        let request = new CustomRequest('GET', `projects/${this.projectID}/intents/events`, null, this.token, this.props, true );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_intents_desk() {
        let request = new CustomRequest('GET', `projects/${this.projectID}/intents/desks`, null, this.token, this.props, true );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }
}