import { CustomRequest } from 'vendor/infrastructure/custom_requests';
import { updateSending, updateDeleting } from 'vendor/application/disptach';

export class ContextsAPI {
    constructor(projectID, token, props, body, notSending) {
        this.projectID = projectID;
        this.token = token;
        this.props = props;
        this.body = body;

        if ( !notSending ) {
            this.props.dispatch(updateSending(true));
        }
    }

    async post_context() {
        const request = new CustomRequest('POST', `projects/${this.projectID}/contexts`, JSON.stringify(this.body), this.token, this.props, true );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_contexts() {
        let request = new CustomRequest('GET', `projects/${this.projectID}/contexts`, null, this.token, this.props, true );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async delete_context(id) {
        this.props.dispatch(updateSending(false));
        this.props.dispatch(updateDeleting(true));
        let request = new CustomRequest('DELETE', `projects/${this.projectID}/contexts/${id}`, null, this.token, this.props, true );
        var req = await request.action();
        this.props.dispatch(updateDeleting(false));
        return req;

    }

}