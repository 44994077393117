// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Infrastructure
import {CustomLogin} from 'vendor/infrastructure/custom_requests/login';
// Material
import {CircularProgress} from '@material-ui/core';
// Vendor
import {showNotify, updateSending} from 'vendor/application/disptach';
import {isValidPassword} from "vendor/application";
// Translations
import {withTranslation} from "react-i18next";
// Components
import {Button} from "@components/Input";
import PasswordInput from "components/Input/PasswordInput/password_input";
// Amplitude API
import {AmplitudeAPI} from '@api/Amplitude';

class Recover extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hash: '',
            token: '',
            password: '',
            confirm_password: '',
            isValid: '',
            isLoading: true
        }
    }

    componentDidMount = () => {
        this.getParams();
    }

    hashValid = async (hash) => {
        try {
            let request = new CustomLogin(null, `password/reestablish/check?v=${hash}`, this.props);
            await request.password_reestablish_check();
            this.setState({isValid: true, isLoading: false});
        } catch (err) {
            this.setState({isValid: false, isLoading: false});
        }
    }

    getParams = () => {
        const url = new URL(window.location.href).searchParams;
        const hash = url.get("v");

        this.setState({hash: hash});

        this.hashValid(hash);
    }

    // update element state
    updateElement = (e, type) => {
        this.setState({[type]: e.target.value});
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const {password, confirm_password, hash} = this.state;

        if (!isValidPassword(password)) {
            var data = {message: 'password_validation', severity: 'error'};
            this.props.dispatch(showNotify(data));
        } else if (password === confirm_password) {
            try {
                this.props.dispatch(updateSending(true));

                let request = new CustomLogin({hash: hash, password: password}, 'password/reestablish', this.props);
                await request.password_reestablish('PUT');

                new AmplitudeAPI({
                    event_type: 'Password changed',
                    device_id: this.props.data.device_id,
                    user_id: atob(hash).split('.#/#.')[0]
                }).eventLog();

                this.props.dispatch(showNotify({message: "recover", type: "password", severity: "success"}));
                this.props.dispatch(updateSending(false));

                this.props.history.push(`/landing/login`);
            } catch (err) {
                this.props.dispatch(showNotify({message: err.responseJSON.message, severity: 'error'}));
                this.props.dispatch(updateSending(false));
            }

        } else {
            this.props.dispatch(showNotify({message: 'password_similar', severity: 'error'}));
        }
    }

    render() {
        const {t, handleSwitch} = this.props;
        const {password, confirm_password, isValid, isLoading} = this.state;
        return (
            !isLoading &&
            <form className={"platform_landing"}>
                <div className={"platform_landing__header"}>
                    <span>{t('landing.recover.title')}</span>
                    {isValid ?
                        <span>{t('landing.recover.description')}</span> :
                        <span className={"error"}>{t('landing.recover.hash')}</span>
                    }
                </div>
                {
                    isValid &&
                    <div className={"platform_landing__content"}>
                        <PasswordInput
                            label={t('landing.recover.password')}
                            value={password}
                            field={"password"}
                            onChange={this.updateElement}
                        />
                        <PasswordInput
                            label={t('landing.recover.confirm_password')}
                            value={confirm_password}
                            field={"confirm_password"}
                            onChange={this.updateElement}
                        />
                        {
                            this.props.data.isSending ? (
                                <Button id={`recover_password`} type={"success"}
                                        text={<CircularProgress size={21}/>}/>
                            ) : (
                                <Button disabled={!(password && confirm_password)} id={`recover_password`}
                                        type={"success"}
                                        onClick={this.handleSubmit}
                                        text={t('landing.recover.button')}/>
                            )
                        }
                    </div>
                }
                <div className={"platform_landing__footer"}>
                    <span>
                        {t('landing.signin.info')}
                        <a id={"signin_session"} onClick={() => handleSwitch('login')}>
                            {t('landing.signin.info_a')}
                        </a>
                    </span>
                    <span>
                        {t('landing.login.info')}
                        <a id={"login_session"} onClick={() => handleSwitch('signin')}>
                                {t('landing.login.info_a')}
                        </a>
                    </span>
                    {
                        !isValid &&
                        <a id={"login_remember_password"}
                           onClick={() => handleSwitch('forgot')}>{t('landing.login.forgot')}</a>
                    }
                </div>
            </form>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const recover = connect(
    mapStateToProps
)(Recover);

export default withTranslation('common')(withRouter(recover));