// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Infrastructure
import {ProjectAPI} from 'api/application/Projects';
// Translations
import {withTranslation} from "react-i18next";
// Material UI
import {CircularProgress, Divider, TextField} from '@material-ui/core';
// Vendor
import {showNotify} from 'vendor/application/disptach';
// Components
import CardEmpty from "components/Card/CardEmpty";
import Input from 'components/Input/input';
import {Copy} from "@components/Input";
// Styles
import 'views/Bots/ChatBot/styles/index.scss';

class ChatbotWebhook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            webhook: false,
            isLoading: true
        }
    }

    componentDidMount = () => {
        this.getWebhook();
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.data.actualProject.id !== this.props.data.actualProject.id) {
            this.getWebhook();
        }
    }

    getWebhook = async () => {
        const {access, user, actualProject} = this.props.data;
        try {
            let obj = new ProjectAPI(actualProject.id, access, this.props);
            let webhook = await obj.get_webhook(user.account_id);

            if (Object.keys(webhook).length === 0) {
                this.setState({webhook: false, isLoading: false});
            } else {
                this.setState({webhook: webhook, isLoading: false});
            }
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data))
        }
    }

    addWebhook = async () => {
        const {webhook} = this.state;
        const {access, user, actualProject} = this.props.data;

        this.setState({isLoading: true});
        try {
            let obj = new ProjectAPI(actualProject.id, access, this.props, webhook);
            var res = await obj.post_webhook(user.account_id);

            this.props.dispatch(showNotify({message: "webhook", type: "create", severity: "success"}));

            this.setState({webhook: res, isLoading: false});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    render() {
        const {actualProject} = this.props.data;
        const {webhook, isLoading} = this.state;
        const {t} = this.props;
        return (
            <React.Fragment>
                {isLoading ? (
                        <div className={"cen_card"}>
                            <div className={"content"}>
                                <div className="loading">
                                    <CircularProgress color="primary" size={50}/>
                                </div>
                            </div>
                        </div>
                    ) :
                    webhook ? (
                            <div className={"cen_card"}>
                                <div className={"header"}>
                                    <div className={"header_content"}>
                                        <div className={"header_title"}>
                                            <span className={"title"}>{t('card.content.project.webhook.title')}</span>
                                            <span
                                                className={"description"}>{t('card.content.project.webhook.description')}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className={"content"}>
                                    <div className={`elements__main elements__webhook`}>
                                        <div>
                                            <div>
                                                <div className={`elements__title`}>
                                                    <span>{t(`card.content.project.webhook.url.title`)}</span>
                                                </div>
                                                {/* url */}
                                                <div className={"elements__copy"}>
                                                    <Input disabled value={webhook.url} type={'primary'} marginTop={0}
                                                           marginBottom={0}/>
                                                    <Copy copyText={webhook.url}/>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider/>
                                        <div>
                                            <div>
                                                <div className={`elements__title`}>
                                                    <span>{t(`card.content.project.webhook.method.title`)}</span>
                                                </div>
                                                {/* url */}
                                                <div className={"elements__copy"}>
                                                    <Input disabled value={'POST'} type={'primary'} marginTop={0}
                                                           marginBottom={0}/>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider/>
                                        <div>
                                            <div>
                                                <div className={`elements__title`}>
                                                    <span>{t(`card.content.project.webhook.auth.title`)}</span>
                                                </div>
                                                {/* auth */}
                                                <div className={"elements__copy"}>
                                                    <Input disabled value={webhook.auth} type={'primary'} marginTop={0}
                                                           marginBottom={0}/>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider/>
                                        <div>
                                            <div>
                                                <div className={`elements__title`}>
                                                    <span>{t(`card.content.project.webhook.token.title`)}</span>
                                                </div>
                                                {/* token */}
                                                <div className={"elements__copy"}>
                                                    <Input disabled value={webhook.token} type={'primary'} marginTop={0}
                                                           marginBottom={0}/>
                                                    <Copy copyText={webhook.token}/>
                                                </div>
                                            </div>
                                        </div>
                                        <Divider/>
                                        <div>
                                            <div className={`elements__grid`}>
                                                <div className={`elements__title`}>
                                                    <span>{t(`card.content.project.webhook.body.title`)}</span>
                                                </div>
                                                {/* body */}
                                                <div className={"elements__copy"}>
                                                    <TextField
                                                        disabled
                                                        className="input-primary"
                                                        id="webhook_body"
                                                        multiline
                                                        value={JSON.stringify(webhook.body, null, 4).replace('{project_id}', actualProject.id)}
                                                    />
                                                    <Copy copyText={webhook.body}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) :
                        <CardEmpty
                            data={this.props.data}
                            t={this.props.t}
                            type={'webhook'}
                            handleClickLink={this.addWebhook}
                            open={false}
                        />
                }
            </React.Fragment>
        );
    }

}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_chatbot_webhook = connect(
    mapStateToProps
)(ChatbotWebhook);

export default withTranslation(['common', 'languages'])(withRouter(connect_chatbot_webhook));