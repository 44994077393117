// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Infrastructure
import {ChatsAPI} from 'api/application/Chats';
// Application
import {ElementsHandleAPI} from 'vendor/application/handleMethods';
// Vendor
import {getCustomizationBody, isConvertedJSON} from 'vendor/application';
import {showNotify, updateSending} from 'vendor/application/disptach';
// Material
import {AppBar, Divider, Tab, Tabs, Tooltip} from '@material-ui/core';
import {TabPanel} from "components/Navigation/tab_panel";
import {withStyles} from '@material-ui/core/styles';
// Components
import {AlertInfo} from "@components/Alert";
import DialogAction from "components/Dialog/DialogAction";
import {Button} from "@components/Input";
import Chat from './components/widget/Chat';
import OpenButton from './components/widget/OpenButton';
import Configuration from './components/content/config';
import Header from './components/content/header';
import Messages from './components/content/messages';
import InputText from './components/content/input_text';
import OpenChat from './components/content/open_chat';
import Buttons from './components/content/buttons';
import Themes from './components/content/themes';
// Translations
import {withTranslation} from "react-i18next";
// SVG
import {restartSvg, undoSvg} from 'assets';
import {Helmet} from "react-helmet";

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        background: "inherit",
        padding: 0
    },
}))(Tooltip);

class ProjectWebInterface extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chat: '',
            save_config: '',
            config: '',
            open: false,
            errorFound: false,
            tabs: {
                contentValue: 0,
                widgetValue: 0,
                contentType: "config",
                widgetType: "chat"
            }
        }
    }

    componentDidMount = () => {
        this.init();
    }

    init = () => {
        this.getConfig();
        this.getChats();
        this.handleTabs();

        document.addEventListener('keydown', (e) => {
            if (e.ctrlKey && e.shiftKey && (e.key === 'r' || e.key === 'R')) {
                e.preventDefault();
                e.stopPropagation();
                this.resetConfig();
            }

            if (e.ctrlKey && e.shiftKey && (e.key === 'o' || e.key === 'O')) {
                e.preventDefault();
                e.stopPropagation();
                this.getConfig();
            }
        });
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.data.actualProject.id !== this.props.data.actualProject.id) {
            setTimeout(() => {
                document.getElementById("cen_ifr_static")?.remove();
                this.init();
            }, 100);
        }
    }

    setQuery = (e, to, type) => {
        e.preventDefault();
        const {history} = this.props;
        if (type === "content") {
            history.push(`${this.props.location.pathname}?content=${to}&widget=${to === "open_chat" ? "button" : "chat"}`);
        } else {
            history.push(`${this.props.location.pathname}?content=${to === "button" ? "open_chat" : "configuration"}&widget=${to}`);
        }

        setTimeout(() => {
            this.handleTabs();
        }, 10);
    }

    handleTabs = () => {
        const {tabs} = this.state;
        const {history} = this.props;
        const queryString = window.location.search,
            urlParams = new URLSearchParams(queryString),
            content = urlParams.get('content'),
            widget = urlParams.get('widget')

        switch (content) {
            case 'config':
                tabs.contentValue = 0;
                tabs.contentType = "config";
                break;
            case 'open_chat':
                tabs.contentValue = 1;
                tabs.contentType = "open_chat";
                break;
            case 'header':
                tabs.contentValue = 2;
                tabs.contentType = "header";
                break;
            case 'messages':
                tabs.contentValue = 3;
                tabs.contentType = "messages";
                break;
            case 'input_text':
                tabs.contentValue = 4;
                tabs.contentType = "input_text";
                break;
            case 'buttons':
                tabs.contentValue = 5;
                tabs.contentType = "buttons";
                break;
            case 'themes':
                tabs.contentValue = 6;
                tabs.contentType = "themes";
                break;
            default:
                tabs.contentValue = 0;
                tabs.contentType = "config";
                break;
        }

        switch (widget) {
            case 'chat':
                tabs.widgetValue = 0;
                tabs.widgetType = "chat";
                break;
            case 'button':
                tabs.widgetValue = 1;
                tabs.widgetType = "button";
                break;
            default:
                tabs.widgetValue = 0;
                tabs.widgetType = "chat";
                break;
        }

        history.push(`${this.props.location.pathname}?content=${tabs.contentType}&widget=${tabs.widgetType}`);
        this.setState({tabs});
    }

    a11yProps = (type, index) => {
        return {
            id: `${type}-interface-tab-${index}`,
            'aria-controls': `${type}-interface-tabpanel-${index}`,
        };
    }

    // get Chat information
    getConfig = async () => {
        const {access} = this.props.data;
        try {
            let obj = new ChatsAPI(this.props.project_id, access, this.props);
            let config = await obj.get_chat_by_id_config();

            this.handleCustomization(config);
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data));
        }
    }

    resetConfig = () => {
        const {config} = this.state;
        config.customization = getCustomizationBody();
        config.customization_ui = JSON.parse(JSON.stringify(getCustomizationBody()));
        this.setState({config: config});
        this.sendConfig();
    }

    handleCustomization = (config) => {
        var default_config = getCustomizationBody();

        if (!config.customization) {
            config.customization = default_config;
            config.customization_ui = JSON.parse(JSON.stringify(default_config));
        } else {
            var incoming_customization = isConvertedJSON(config.customization) ? JSON.parse(config.customization) : config.customization,
                new_customization = {...default_config, ...incoming_customization};

            config.customization = new_customization;
            config.customization_ui = JSON.parse(JSON.stringify(new_customization));
        }

        if (config.customization.open_shape === true) {
            config.customization.open_shape = "50px 50px 50px 50px";
        } else if (config.customization.open_shape === false) {
            config.customization.open_shape = "0px 0px 0px 0px";
        }

        this.setState({config: config, save_config: JSON.parse(JSON.stringify(config))});
    }

    // get all chats from actual project id
    getChats = async () => {
        const {access} = this.props.data;
        try {
            const obj = new ChatsAPI(this.props.project_id, access, this.props);
            const chats = await obj.get_chats();
            chats.forEach(el => {
                if (el.id === this.props.chat_id) {
                    this.setState({chat: el});
                }
            });

        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data));
        }
    }

    // redirect route to list from layout
    returnToList = () => {
        const {location, id, history} = this.props;
        var url = location.pathname.replace(`/edit/${id}`, '');
        history.push(url);
    }

    // update element state
    updateElement = (e, type, i) => {
        const {chat} = this.state;
        const obj = new ElementsHandleAPI(chat, e.target.value, type, i);
        const res = obj.updateObj();

        this.setState({chat: res});
    }

    updateCustomization = (e, type, element, svgItem) => {
        const {config} = this.state;
        switch (type) {
            case "integer":
                var integer = parseInt(e.target.value);
                if (Number.isInteger(integer)) {
                    if (element === "chat_timer" || element === "open_on_load_timer") {
                        if (integer >= 0 && integer <= 300) {
                            config.customization[element] = e.target.value;
                        }
                    } else if (integer >= 0 && integer <= 720) {
                        config.customization[element] = e.target.value;
                    } else if (integer > 720) {
                        config.customization[element] = 720;
                    }
                }
                break;
            case "string":
                config.customization[element] = e.target.value;
                break;
            case "color":
                if (!e?.error) {
                    config.customization[element] = e?.css?.backgroundColor;
                    this.setState({errorFound: false});
                } else {
                    config.customization[element] = e.raw;
                    this.setState({errorFound: true});
                }
                config.customization_ui[element] = e;
                break;
            case 'svg':
                config.customization[element] = svgItem;
                break;
            case "bool":
                config.customization[element] = !config.customization[element];
                break;
        }

        this.setState({config: config});
    }

    // update element config info
    updateElementConfig = (e, type) => {
        e.preventDefault();
        const {config} = this.state;
        const obj = new ElementsHandleAPI(config, e.target.value, type);
        const res = obj.updateObj('edit');
        this.setState({config: res});

        this.sendConfig();
    }

    // update chat
    saveChat = async (e) => {
        e.preventDefault();
        const {access} = this.props.data;
        try {
            const obj = new ChatsAPI(this.props.project_id, access, this.props, this.state.chat);
            await obj.put_chat();
            this.saveChatConfig();
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data));
        }
    }

    // update config
    saveChatConfig = async () => {
        const {access} = this.props.data;
        const {config} = this.state;
        var data = {};
        var welcome_selector = config.customization.welcome_selector,
            welcome_incoming = config.customization.welcome_incoming;

        if (welcome_selector === 'incoming' && !welcome_incoming) {
            this.handleClickOpen();
            this.props.dispatch(updateSending(false));
            this.props.dispatch(showNotify({message: "welcome_incoming_null", severity: "error"}));
        } else {
            try {
                const obj = new ChatsAPI(this.props.project_id, access, this.props, JSON.parse(JSON.stringify(config)));
                await obj.put_chat_config();

                data = {message: "chats", type: "edit", severity: "success"};
                this.props.dispatch(showNotify(data));

                this.setState({save_config: JSON.parse(JSON.stringify(config))});
                this.handleClickOpen();
            } catch (err) {
                data = {message: err, severity: 'error'};
                this.props.dispatch(showNotify(data));
            }
        }
    }

    updateIcon = async (e, type) => {
        const {access} = this.props.data;
        var formData = new FormData()
        formData.append("icon_type", type);
        formData.append("file", e[0]);

        try {
            const obj = new ChatsAPI(this.props.project_id, access, this.props, formData);
            const res = await obj.put_chat_icon();
            var element = `${type}_icon`;
            this.state.config[element] = res[element];
            this.state.save_config[element] = res[element];

            this.setState({config: this.state.config, save_config: this.state.save_config});
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data));
        }
    }

    handleClickOpen = () => {
        this.setState({open: !this.state.open});
    }

    updateConfig = (new_config) => {
        this.setState({config: new_config})
    }

    changesToSave = () => {
        const {config, save_config} = this.state;
        if (config.expanded && !config.text_expanded) {
            return true;
        }
        return JSON.stringify(config) === JSON.stringify(save_config);
    }


    render() {
        const {chat, config, open, tabs} = this.state;
        const {t} = this.props;
        return (
            chat && config &&
            <React.Fragment>
                <div className={"cen_design"}>
                    <DialogAction t={t} actualLayout={"web_interface"} handleAction={this.saveChat}
                                  handleClickOpen={this.handleClickOpen} common success open={open}
                                  item={"web_interface"}/>
                    <div className={"cen_design_content"}>
                        <AppBar id={"app_bar"} position="static" color="default">
                            <Tabs
                                value={tabs.contentValue}
                                variant="scrollable"
                                textColor="primary"
                            >
                                {/* Configuration */}
                                <Tab label={t('card.content.project.web_interface.tabs.design.configuration')}
                                     onClick={(e) => {
                                         this.setQuery(e, 'config', 'content')
                                     }} {...this.a11yProps("content", 0)} />
                                {/* Open Chat */}
                                <Tab label={t('card.content.project.web_interface.tabs.design.open_chat')}
                                     onClick={(e) => {
                                         this.setQuery(e, 'open_chat', 'content')
                                     }} {...this.a11yProps("content", 1)} />
                                {/* Header */}
                                <Tab label={t('card.content.project.web_interface.tabs.design.header')}
                                     onClick={(e) => {
                                         this.setQuery(e, 'header', 'content')
                                     }} {...this.a11yProps("content", 2)} />
                                {/* Messages */}
                                <Tab label={t('card.content.project.web_interface.tabs.design.messages')}
                                     onClick={(e) => {
                                         this.setQuery(e, 'messages', 'content')
                                     }} {...this.a11yProps("content", 3)} />
                                {/* Input Text */}
                                <Tab label={t('card.content.project.web_interface.tabs.design.input_text')}
                                     onClick={(e) => {
                                         this.setQuery(e, 'input_text', 'content')
                                     }} {...this.a11yProps("content", 4)} />
                                {/* Artifacts */}
                                <Tab label={t('card.content.project.web_interface.tabs.design.buttons')}
                                     onClick={(e) => {
                                         this.setQuery(e, 'buttons', 'content')
                                     }} {...this.a11yProps("content", 5)} />
                                {/* Themes */}
                                <Tab label={t('card.content.project.web_interface.tabs.design.themes')}
                                     onClick={(e) => {
                                         this.setQuery(e, 'themes', 'content')
                                     }} {...this.a11yProps("content", 6)} />
                            </Tabs>
                        </AppBar>
                        <Divider/>
                        <div className={"container"}>
                            <TabPanel value={tabs.contentValue} index={0}>
                                <Configuration updateConfig={this.updateConfig}
                                               updateCustomization={this.updateCustomization}
                                               config={JSON.parse(JSON.stringify(config))}/>
                            </TabPanel>
                            <TabPanel value={tabs.contentValue} index={1}>
                                <OpenChat updateConfig={this.updateConfig}
                                          updateCustomization={this.updateCustomization}
                                          config={JSON.parse(JSON.stringify(config))} updateIcon={this.updateIcon}/>
                            </TabPanel>
                            <TabPanel value={tabs.contentValue} index={2}>
                                <Header updateConfig={this.updateConfig} updateCustomization={this.updateCustomization}
                                        config={JSON.parse(JSON.stringify(config))} updateIcon={this.updateIcon}/>
                            </TabPanel>
                            <TabPanel value={tabs.contentValue} index={3}>
                                <Messages updateConfig={this.updateConfig}
                                          updateCustomization={this.updateCustomization}
                                          config={JSON.parse(JSON.stringify(config))} updateIcon={this.updateIcon}/>
                            </TabPanel>
                            <TabPanel value={tabs.contentValue} index={4}>
                                <InputText updateConfig={this.updateConfig}
                                           updateCustomization={this.updateCustomization}
                                           config={JSON.parse(JSON.stringify(config))} updateIcon={this.updateIcon}/>
                            </TabPanel>
                            <TabPanel value={tabs.contentValue} index={5}>
                                <Buttons updateConfig={this.updateConfig} updateCustomization={this.updateCustomization}
                                         config={JSON.parse(JSON.stringify(config))}/>
                            </TabPanel>
                            <TabPanel value={tabs.contentValue} index={6}>
                                <Themes updateConfig={this.updateConfig} updateCustomization={this.updateCustomization}
                                        config={JSON.parse(JSON.stringify(config))}/>
                            </TabPanel>
                            <div className={"actions"} style={{minHeight: 70, borderTop: "1px solid #F4F4F4"}}>
                                <span className={"actions_info"}>
                                    {
                                        !this.changesToSave() &&
                                            <AlertInfo className={"app_bar"} text={t('card.content.project.web_interface.save_info')}/>
                                    }
                                    {
                                        config.expanded && !config.text_expanded &&
                                            <AlertInfo className={"app_bar"} text={t('card.content.project.web_interface.save_expanded_info')}/>
                                    }
                                </span>
                                <div className={"actions_buttons"}>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <div className={"btn_tooltip_info"}>
                                                    <span>{t('card.content.project.web_interface.button_restart')}</span>
                                                    <span>[Ctrl + Shift + R]</span>
                                                </div>
                                            </React.Fragment>
                                        }
                                        placement={'bottom'}
                                        enterNextDelay={500}
                                        enterDelay={500}
                                        leaveDelay={300}
                                    >
                                        <Button className={"btn_icon"} type={"primary"}
                                                onClick={(e) => this.resetConfig(e)} text={restartSvg()}/>
                                    </HtmlTooltip>
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                <div className={"btn_tooltip_info"}>
                                                    <span>{t('card.content.project.web_interface.button_undo')}</span>
                                                    <span>[Ctrl + Shift + O]</span>
                                                </div>
                                            </React.Fragment>
                                        }
                                        placement={'bottom'}
                                        enterNextDelay={500}
                                        enterDelay={500}
                                        leaveDelay={300}
                                    >
                                        <Button className={"btn_icon"} type={"primary"}
                                                onClick={(e) => this.getConfig(e)} text={undoSvg()}/>
                                    </HtmlTooltip>
                                    <Button type={"primary"}
                                            disabled={this.changesToSave()}
                                            text={t('card.content.project.web_interface.button_save')}
                                            onClick={(e) => this.handleClickOpen(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"cen_design_widget"}>
                        <AppBar id={"app_bar"} position="static" color="default">
                            <Tabs
                                value={tabs.widgetValue}
                                variant="scrollable"
                                scrollButtons="on"
                                textColor="primary"
                            >
                                {/* Chat */}
                                <Tab label={t('card.content.project.web_interface.tabs.widget.chat')} onClick={(e) => {
                                    this.setQuery(e, 'chat', 'widget')
                                }} {...this.a11yProps("widget", 0)} />
                                {/* Button */}
                                <Tab label={t('card.content.project.web_interface.tabs.widget.button')}
                                     onClick={(e) => {
                                         this.setQuery(e, 'open_chat', 'content');
                                         this.setQuery(e, 'button', 'widget');
                                     }} {...this.a11yProps("widget", 1)} />
                            </Tabs>
                        </AppBar>
                        <Divider/>
                        <TabPanel value={tabs.widgetValue} index={0}>
                            {
                                config &&
                                <Chat config={JSON.parse(JSON.stringify(config))} handleTabs={this.setQuery}/>
                            }
                        </TabPanel>
                        <TabPanel value={tabs.widgetValue} index={1}>
                            {
                                config &&
                                <OpenButton config={JSON.parse(JSON.stringify(config))} handleTabs={this.setQuery}/>
                            }
                        </TabPanel>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_web_interface = connect(
    mapStateToProps
)(ProjectWebInterface);

export default withTranslation('common')(withRouter(connect_web_interface));