// React
import React, {Component} from 'react';

export class Feedback extends Component {

    render() {
        const {t} = this.props;
        return (
            <div className={"dashboard_feedback"}>
                <img src={process.env.PUBLIC_URL + '/results_feedback.png'}
                     alt={"results_feedback"}/>
                <span>{t(`card.dashboard.results_feedback`)}</span>
            </div>
        );
    }
}
