export const getIntentBody = (type, position, updateIntent, updateNode) => {
    switch (type) {
        case 'intent':
            return {
                id: "1",
                type: type,
                position,
                selected: true,
                dragHandle: '.flow_element__draggable',
                sourcePosition: "left",
                targetPosition: "right",
                data: {
                    "name": "",
                    "description": "",
                    "is_default": false,
                    "phrases": ["."],
                    "responses": [""],
                    "metadata": {
                        "coordinates": position,
                        "relations": [],
                        "type": type
                    },
                    "artifacts": [{"element": "text", "text": ""}],
                    "updateIntent": updateIntent,
                    "updateNode": updateNode,
                    "contexts": {
                        "input": [],
                        "output": []
                    },
                    "trainable": false,
                    "desks": {
                        "name": false,
                        "info": {
                            "zendesk_ticket_status": "",
                            "centridesk_ticket_status": "",
                            "ticket_status": "",
                            "agent_id": null,
                            "mode": "desk",
                            "zendesk_mode": "desk",
                            "centridesk_mode": "desk"
                        }
                    },
                    "parameters": [],
                    "extensions": {},
                    "conditional_responses": [],
                    "events": [],
                    "active": true
                }
            }
        case 'intent_initial':
            return {
                id: "1",
                type: type,
                position,
                selected: true,
                dragHandle: '.flow_element__draggable',
                sourcePosition: "left",
                targetPosition: "right",
                data: {
                    "name": "",
                    "description": "",
                    "is_default": false,
                    "phrases": [""],
                    "responses": [""],
                    "metadata": {
                        "coordinates": position,
                        "relations": [],
                        "type": type
                    },
                    "artifacts": [{"element": "text", "text": ""}],
                    "updateIntent": updateIntent,
                    "updateNode": updateNode,
                    "contexts": {
                        "input": [],
                        "output": []
                    },
                    "trainable": false,
                    "desks": {
                        "name": false,
                        "info": {
                            "zendesk_ticket_status": "",
                            "centridesk_ticket_status": "",
                            "ticket_status": "",
                            "agent_id": null,
                            "mode": "desk",
                            "zendesk_mode": "desk",
                            "centridesk_mode": "desk"
                        }
                    },
                    "parameters": [],
                    "extensions": {},
                    "conditional_responses": [],
                    "events": [],
                    "active": true
                }
            }
        case 'intent_df':
            return {
                id: "1",
                type: type,
                position,
                selected: true,
                dragHandle: '.flow_element__draggable',
                sourcePosition: "left",
                targetPosition: "right",
                data: {
                    "name": "",
                    "description": "",
                    "is_default": true,
                    "phrases": [],
                    "responses": [""],
                    "metadata": {
                        "coordinates": position,
                        "relations": [],
                        "type": type
                    },
                    "artifacts": [{"element": "text", "text": ""}],
                    "updateIntent": updateIntent,
                    "updateNode": updateNode,
                    "contexts": {
                        "input": [],
                        "output": []
                    },
                    "trainable": false,
                    "desks": {
                        "name": false,
                        "info": {
                            "zendesk_ticket_status": "",
                            "centridesk_ticket_status": "",
                            "ticket_status": "",
                            "agent_id": null,
                            "mode": "desk",
                            "zendesk_mode": "desk",
                            "centridesk_mode": "desk"
                        }
                    },
                    "parameters": [],
                    "extensions": {},
                    "conditional_responses": [],
                    "events": [],
                    "active": true
                }
            }
    }
}