// React
import React, {Component} from 'react';
// Material
import {Input, IconButton, InputAdornment, InputLabel} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
// Styles
import 'components/Input/PasswordInput/styles.scss';
// Translations
import {withTranslation} from "react-i18next";
// Icons
import {goodSvg, wrongSvg} from "assets";
import {isValidPassword} from "vendor/application";

class PasswordInput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visibility: false,
            visibility_helper: false,
            is_password_valid: true,
            validity: [
                {text: "char", regEx: /(?=.{8,})/, value: false},
                {text: "uppercase", regEx: /(?=.*[A-Z])/, value: false},
                {text: "lowercase", regEx: /(?=.*[a-z])/, value: false},
                {text: "number", regEx: /(?=.*[0-9])/, value: false},
                {text: "sign", regEx: /(?=.*[.,!?+\-*])/, value: false},
                {text: ";", regEx: /(?=;)/, value: false}
            ]
        }
    }

    handleClickShowPassword = () => {
        this.setState({visibility: !this.state.visibility});
    }

    handleMouseDownPassword = (e) => {
        e.preventDefault();
    }

    handleHelper = () => {
        this.setState({visibility_helper: !this.state.visibility_helper})
    }

    isValid = (value) => {
        const {validity} = this.state;
        var isValid = isValidPassword(value);

        validity.map((item, i) => {
            if (value?.match(item.regEx) && item.text !== ";") {
                validity[i].value = true;
            } else if (value?.match(item.regEx) && item.text === ";") {
                validity[i].value = true;
                isValid = false;
            } else {
                validity[i].value = false;
            }
        });

        this.setState({validity: validity, is_password_valid : isValid});
    }

    onChange = (e, field) => {
        this.isValid(e.target.value);
        this.props.onChange(e, field);
    }

    render() {
        const {value, field, label, t} = this.props;
        const {visibility, visibility_helper, is_password_valid, validity} = this.state;
        return (
            <React.Fragment>
                <div className={"platform_password_input"}>
                    {
                        label &&
                        <InputLabel>{label}</InputLabel>
                    }
                    <Input
                        id={`signin_${field}`}
                        value={value}
                        onFocus={() => this.handleHelper()}
                        onBlur={() => this.handleHelper()}
                        onChange={(e) => {
                            this.onChange(e, field)
                        }}
                        aria-describedby={`signin_${field}`}
                        className={`input-primary ${!is_password_valid ? 'error' : ''}`}
                        autoComplete="off"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label={`adornment_signin_${field}`}
                                    onClick={this.handleClickShowPassword}
                                    onMouseDown={this.handleMouseDownPassword}
                                >
                                    {visibility ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        }
                        type={visibility ? 'text' : 'password'}
                    />
                    {
                        visibility_helper &&
                        <div className={"platform_password_input_content"}>
                            {
                                validity.map((item, i) => {
                                    return (
                                        <div key={i}>
                                            {
                                                item.text === ";"
                                                ? <span>{item.value ? wrongSvg() : goodSvg()}{t(`password_input.${item.text}`)}</span>
                                                    :
                                                    <span>
                                                        {item.value ? goodSvg() : wrongSvg()} {t(`password_input.${item.text}`)}
                                                    </span>
                                            }
                                            {item.text === "sign" &&
                                                <div className={"platform_password_input_badges"}>
                                                    <div className={"badge"}>.</div>
                                                    <div className={"badge"}>,</div>
                                                    <div className={"badge"}>!</div>
                                                    <div className={"badge"}>?</div>
                                                    <div className={"badge"}>+</div>
                                                    <div className={"badge"}>-</div>
                                                    <div className={"badge"}>*</div>
                                                </div>
                                            }
                                        </div>
                                    )
                                })
                            }
                        </div>

                    }
                </div>
            </React.Fragment>
        );
    }
}

    export default withTranslation('inputs')(PasswordInput);
