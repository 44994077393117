// React
import React, {Component} from 'react';

export class Description extends Component {
    render() {
        const {text} = this.props;
        return (
            <div className={"dashboard_element description"}>
                <span>{text}</span>
            </div>
        );
    }
}
