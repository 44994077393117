// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Styles
import 'date-fns';
// Infrastructure
import {StatsAPI} from 'views/Bots/Stats/infrastructure';
import {ProjectAPI} from "../../../../../api/application/Projects";
// Translations
import {withTranslation} from "react-i18next";
import enLocale from "date-fns/locale/en-US";
import esLocale from 'date-fns/locale/es';
import ptLocale from 'date-fns/locale/pt';
// Vendor
import {showNotify} from 'vendor/application/disptach';
// Componentes
import Table from "components/Table";
import {Button, Input} from "@components/Input";
import {AlertInfo} from "@components/Alert";
// Material
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
// Svg
import {deploySvg, svgCalendar, svgClock} from 'assets';

const localeMap = {
    en: enLocale,
    es: esLocale,
    pt: ptLocale
};

class StatsSuncoNotifications extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            dateRange: {},
            start: false,
            end: false,
            objDateRange: {
                startDate: new Date(date.setMonth(date.getMonth() - 1)),
                endDate: new Date(date.setMonth(date.getMonth() + 1))
            },
            elements: false,
            campaign_name: '',
            is_loading: true
        }
    }


    componentDidMount() {
        this.getStats();
    }

    // method that reloads the component when the current project has been changed
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.actualProject.id !== this.props.data.actualProject.id) {
            setTimeout(() => {
                this.getStats();
            }, 100);
        }
    }

    getStats = async () => {
        const {access, actualProject} = this.props.data;
        const {campaign_name} = this.state;
        try {
            const obj = new StatsAPI(actualProject.id, access, this.props, null);
            const res = await obj.get_stats_sunco_notifications(this.getStartDate(), this.getEndDate(), campaign_name);

            this.setState({
                elements: res.reverse(),
                is_loading: false
            })
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    doAction = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props, {
                "action": "request_sunco_notifications",
                "info": {
                    "from": this.getStartDate(),
                    "to": this.getEndDate()
                }
            });
            await obj.do_actions();

            this.props.dispatch(showNotify({message: "project", type: "request_statistics", severity: "success"}));
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    handleSelectStart = (start) => {
        var end = this.state.objDateRange.endDate;
        if (start > end) {
            end = start;
        }
        this.setState({
            objDateRange: {
                startDate: start,
                endDate: end
            }
        })
    }

    handleSelectEnd = (end) => {
        var start = this.state.objDateRange.startDate;
        if (end < start) {
            start = end;
        }
        this.setState({
            objDateRange: {
                startDate: start,
                endDate: end
            }
        })
    }

    getStartDate = (visibility) => {
        const {objDateRange} = this.state;
        var m = objDateRange.startDate.getMonth(),
            y = objDateRange.startDate.getFullYear(),
            d = objDateRange.startDate.getDate();

        if (visibility) {
            return `${d} / ${m + 1} / ${y}`;
        }
        return `${y}-${m + 1}-${d}`;
    }

    getEndDate = (visibility) => {
        const {objDateRange} = this.state;
        var m = objDateRange.endDate.getMonth(),
            y = objDateRange.endDate.getFullYear(),
            d = objDateRange.endDate.getDate();

        if (visibility) {
            return `${d} / ${m + 1} / ${y}`;
        }
        return `${y}-${m + 1}-${d}`;
    }

    openStart = () => {
        this.setState({start: !this.state.start});
    }

    openEnd = () => {
        this.setState({end: !this.state.end});
    }

    newSearch = () => {
        this.setState({is_loading: true});
        this.getStats();
    }

    render() {
        const {t} = this.props;
        const {
            objDateRange,
            start,
            end,
            elements,
            campaign_name,
            is_loading
        } = this.state;
        return (
            <div className="stats-container">
                <div className="stats-header">
                    <div className="stats-date-container">
                        <div className="stats-date">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}
                                                     locale={localeMap[this.props.i18n.language]}>
                                <div className="start-date" onClick={() => this.openStart()}>
                                    <div>
                                        <span>{svgCalendar()}</span>
                                        <span>{t('stats.start')}</span>
                                        <span>{deploySvg(false)}</span>
                                    </div>
                                    <div>
                                        <span>{svgClock()}</span>
                                        <span>{this.getStartDate(true)}</span>
                                    </div>
                                </div>
                                <DatePicker
                                    value={objDateRange.startDate}
                                    onChange={this.handleSelectStart}
                                    animateYearScrolling
                                    autoOk
                                    onClose={this.openStart}
                                    open={start}
                                />
                                <div className="end-date" onClick={() => this.openEnd()}>
                                    <div>
                                        <span>{svgCalendar()}</span>
                                        <span>{t('stats.end')}</span>
                                        <span>{deploySvg(false)}</span>
                                    </div>
                                    <div>
                                        <span>{svgClock()}</span>
                                        <span>{this.getEndDate(true)}</span>
                                    </div>
                                </div>
                                <DatePicker
                                    value={objDateRange.endDate}
                                    onChange={this.handleSelectEnd}
                                    animateYearScrolling
                                    autoOk
                                    onClose={this.openEnd}
                                    open={end}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                        <Input
                            placeholder={t('stats.sunco_notifications.placeholder')}
                            onChange={(e) => this.setState({campaign_name: e?.target?.value})}
                            value={campaign_name}
                        />
                    </div>
                    <div className="actions">
                        <Button type={"primary"} onClick={() => {
                            this.doAction()
                        }} text={t('stats.download_action_button')}/>
                        <Button type={"primary"} onClick={() => {
                            this.newSearch()
                        }} text={t('stats.search_action_button')}/>
                    </div>
                </div>
                {
                    !elements ?
                        <AlertInfo className={"stats-alert"} text={t('stats.sunco_notifications.info')}/> :
                    elements.length === 0 && !is_loading ?
                        <AlertInfo className={"stats-alert"} text={t('stats.sunco_notifications.info')}/> :
                        <div className={"stats-card"}>
                            <Table items={elements} type={"sunco_notifications"} is_loading={is_loading}
                                   header={['campaign_name', 'template_name', 'phone', 'delivered_channel_at', 'delivered_user_at', 'delivery_failure_at']}/>
                        </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_stats_conversations = connect(
    mapStateToProps
)(StatsSuncoNotifications);

export default withTranslation('common')(withRouter(connect_stats_conversations));