// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Translations
import {withTranslation} from "react-i18next";
// Components
import {Input} from "@components/Input";
import {Divider, Switch} from "@material-ui/core";
import ElementColor from "../element_color";
import ElementSwitch from "../element_switch";
import ElementIcon from "../element_icon";
// Vendor
import {ElementsHandleAPI} from "vendor/application/handleMethods";
import {addArtifact, isFreeTrial} from "vendor/application";


class Header extends Component {

    updateElementConfig = (e, type) => {
        e.preventDefault();
        const {config, updateConfig} = this.props;
        const obj = new ElementsHandleAPI(config, e.target.value, type);
        const res = obj.updateObj('edit');
        updateConfig(res);
    }

    handleArtifacts = (e, i, artifact_type, type) => {
        const {config, updateConfig} = this.props;
        var res = addArtifact(i, artifact_type, type, config);
        updateConfig(res);
    }

    render() {
        const {t, config, updateCustomization, updateConfig, updateIcon} = this.props;
        const {plan} = this.props.data;
        return (
            <div className={"cen_card"}>
                {/* HEADER TEXT */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.header.text.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.header.text.description')}</span>
                        </div>
                        <div className={"elements__info"}>
                            <Input disabled={isFreeTrial(plan.plan.name)}
                                   value={config.header_title} type={'primary'}
                                   placeholder={t('card.content.project.web_interface.customization.header.text.placeholder')}
                                   onChange={(e) => this.updateElementConfig(e, 'header_title')}/>
                        </div>
                    </div>
                </div>
                <Divider/>
                {/* SHOW ICON */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.header.switch_icon.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.header.switch_icon.description')}</span>
                        </div>
                        <Switch checked={!!config.customization.header_icon_show} onClick={(e) => {
                            this.updateElementConfig(e, 'header_icon_show')
                        }}/>
                    </div>
                </div>
                <Divider/>
                {/* COLORS */}
                <div className={"content"}>
                    <div className="elements_custom grid">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.header.colors.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.header.colors.description')}</span>
                        </div>
                        <div>
                            <ElementColor
                                updateCustomization={updateCustomization}
                                element={"bg"}
                                value={config.customization.header_bg_color}
                                value_updated={"header_bg_color"}
                            />
                            <ElementSwitch
                                color_1={"header_bg_color"}
                                color_2={"header_text_color"}
                                updateConfig={updateConfig}
                                config={config}
                            />
                            <ElementColor
                                updateCustomization={updateCustomization}
                                element={"text"}
                                value={config.customization.header_text_color}
                                value_updated={"header_text_color"}
                            />
                        </div>
                        <ElementColor
                            updateCustomization={updateCustomization}
                            element={"icons"}
                            value={config.customization.header_icons_color}
                            value_updated={"header_icons_color"}
                        />
                    </div>
                </div>
                <Divider/>
                {/* ICON */}
                <div className={"content"}>
                    <div className="elements_custom grid">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.header.icon.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.header.icon.description')}</span>
                        </div>
                        <ElementIcon
                            updateIcon={updateIcon}
                            updateCustomization={updateCustomization}
                            element={"header"}
                            img={config.header_icon}
                            icon_type={"header"}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const a = connect(
    mapStateToProps
)(Header);

export default withTranslation('common')(withRouter(a));