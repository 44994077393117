// React
import React from 'react';
// Assets
import {flowSvg} from "assets";

const AddElement = ({data, type, i, selected, disabled}) => {
    return (
        selected &&
        <div className={"flow_element__component flow_element__component_add"}>
            <span onClick={() => data.updateNode(`add_${type}`, data, i)} className={`${disabled ? "is_disabled" : ""}`}>
                {flowSvg("add")}
            </span>
        </div>
    )
}

export default AddElement;