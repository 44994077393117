export class ElementsHandleAPI {

    constructor(obj, value, type, i, output) {
        this.obj = obj;
        this.value = value;
        this.type = type;
        this.i = i;
        this.output = output;
    }

    updateObj(from) {
        switch (this.type) {
            // SIGNIN
            case "trial":
                this.obj.trial = !this.obj.trial;
                break;
            case "legal":
                this.obj.legal = !this.obj.legal;
                break;
            case "company":
                this.obj.company = this.value;
                break;
            case "web_page":
                this.obj.web_page = this.value;
                break;
            case "confirm_password" :
                this.obj.confirm_password = this.value;
                break;
            case "confirm_email" :
                this.obj.confirm_email = this.value;
                break;
            case "policy":
                this.obj.policy = !this.obj.policy;
                break;
            case "hs_legal_basis":
                this.obj.hs_legal_basis = !this.obj.hs_legal_basis;
                break;
            // BUSINESS
            case "message" :
                this.obj.message = this.value;
                break;
            // INTENTS
            case "name":
                this.obj.name = this.value;
                break;
            case "description":
                this.obj.description = this.value;
                break;
            case "input":
                this.obj.contexts.input[this.i] = this.value;
                break;
            case "output":
                switch (this.output) {
                    case "id":
                        this.obj.contexts.output[this.i].id = this.value;
                        break;
                    case "lifespan":
                        this.obj.contexts.output[this.i].lifespan = this.value;
                        break;
                }
                break;
            case "phrases":
                this.obj.phrases[this.i] = this.value;
                break;
            case "responses":
                switch (from) {
                    case 'edit':
                        this.obj.responses[this.i].text = this.value;
                        break;
                    case 'create':
                        this.obj.responses[this.i] = this.value;
                        break;
                }
                break;
            case "params_name":
                if (this.value.match(/^[a-zA-Z0-9_-]*$/gm)) {
                    this.obj.parameters[this.i].name = this.value;
                    this.obj.parameters[this.i].variable = `$${this.value}`;
                }
                break;
            case "params_variable":
                if (this.value.match(/^[a-zA-Z0-9_-]*$/gm)) {
                    this.obj.parameters[this.i].variable = this.value;
                }
                break;
            case "params_prompts":
                this.obj.parameters[this.i].prompts[0] = this.value;
                break;
            case "extensions_url":
                this.obj.extensions.url = this.value;
                break;
            case "extensions_prompt":
                this.obj.extensions.prompt = this.value;
                break;
            case "extensions_body":
                this.obj.extensions.body = this.value;
                break;
            case "extensions_variables":
                this.obj.extensions.variables[this.i] = this.value;
                break;
            case "extensions_active":
                this.obj.extensions.active = !this.obj.extensions.active;
                break;
            case "extensions_auth_password":
                this.obj.extensions.auth.password = this.value;
                break;
            case "extensions_auth_token":
                this.obj.extensions.auth.token = this.value;
                break;
            case "extensions_auth_username":
                this.obj.extensions.auth.username = this.value;
                break;
            case "extensions_auth_url":
                this.obj.extensions.auth.url = this.value;
                break;
            case "extensions_auth_header_key":
                this.obj.extensions.auth.header_key = this.value;
                break;
            case "extensions_auth_prefix":
                this.obj.extensions.auth.prefix = this.value;
                break;
            case "extensions_auth_access_key":
                if (this.value.match(/^[\.a-zA-Z0-9_-]*$/gm)) {
                    this.obj.extensions.auth.access_key_input = this.value;
                    this.obj.extensions.auth.access_key = `$.${this.value}`;
                }
                break;
            case "extensions_auth_payload":
                this.obj.extensions.auth.payload = this.value;
                break;
            case "required":
                this.obj.parameters[this.i].required = !this.obj.parameters[this.i].required;
                break;
            case "identification":
                this.obj.parameters[this.i].identification = !this.obj.parameters[this.i].identification;
                break;
            case "channel_zendesk_full":
            case "channel_zendesk_chat":
            case "channel_centridesk":
            case "channel_sunco":
                var type = this.type.split('channel_')[1];
                if (this.obj.desks.name === type) {
                    this.obj.desks.name = "";
                } else {
                    this.obj.desks.name = type;
                }
                break;
            case "channel_centridesk_selector":
                this.obj.desks.info.agent_id = this.value;
                break;
            case "active":
                this.obj.active = !this.obj.active;
                break;
            case "trainable":
                this.obj.trainable = !this.obj.trainable;
                break;
            case "is_default":
                this.obj.is_default = !this.obj.is_default;
                break;
            // PROJECT
            case "dialog_flow_id" :
                this.obj.dialog_flow_id = this.value;
                break;
            case "ddbb_name" :
                this.obj.ddbb_name = this.value;
                break;
            case "lang" :
                this.obj.lang = this.value;
                break;
            case "lambda_url" :
                this.obj.lambda_url = this.value;
                break;
            case "api_key" :
                this.obj.api_key = this.value;
                break;
            // USER
            case "username" :
                this.obj.username = this.value;
                break;
            case "password" :
                this.obj.password = this.value;
                break;
            case "first_name" :
                this.obj.first_name = this.value;
                break;
            case "last_name" :
                this.obj.last_name = this.value;
                break;
            case "email" :
                this.obj.email = this.value;
                break;
            case "projects":
                this.obj.projects[this.i] = this.value;
                break;
            case "roles":
                this.obj.roles[0] = this.value;
                break;
            // CHAT
            case "header_title" :
                this.obj.header_title = this.value;
                break;
            case "text_send" :
                this.obj.text_send = this.value;
                break;
            case "text_welcome":
                this.obj.text_welcome = this.value;
                break;
            case "text_expanded":
                this.obj.text_expanded = this.value;
                break;
            case "expanded":
                this.obj.expanded = !this.obj.expanded;
                break;
            case "open_on_load":
                this.obj.open_on_load = !this.obj.open_on_load;
                break;
            case "open_shape":
                this.obj.customization.open_shape = !this.obj.customization.open_shape;
                break;
            case "open_icon_show":
                this.obj.customization.open_icon_show = !this.obj.customization.open_icon_show;
                break;
            case "header_icon_show":
                this.obj.customization.header_icon_show = !this.obj.customization.header_icon_show;
                break;
            case "messages_user_icon_show":
                this.obj.customization.messages_user_icon_show = !this.obj.customization.messages_user_icon_show;
                break;
            case "messages_agent_icon_show":
                this.obj.customization.messages_agent_icon_show = !this.obj.customization.messages_agent_icon_show;
                break;
            // WHATSAPP
            case "brand_address":
                this.obj.brand_address = this.value;
                break;
            case "brand_name":
                this.obj.brand_name = this.value;
                break;
            case "city":
                this.obj.city = this.value;
                break;
            case "country":
                this.obj.country = this.value;
                break;
            case "facebook_id":
                this.obj.facebook_id = this.value;
                break;
            case "phone":
                if (/^[0-9]*\+?[0-9]*$/gm.test(this.value)) {
                    this.obj.phone = this.value;
                }
                break;
            case "dial_code":
                this.obj.dial_code = this.value;
                break;
            case "postal_code":
                this.obj.postal_code = this.value;
                break;
            case "state":
                this.obj.state = this.value;
                break;
            case "whatsapp_name":
                this.obj.whatsapp_name = this.value;
                break;
            case "whatsapp_phone":
                this.obj.whatsapp_phone = this.value;
                break;
            // ZENDESK FULL
            case "subdomain":
                this.obj.subdomain = this.value;
                break;
            case "token":
                this.obj.token = this.value;
                break;
            case "subject":
                this.obj.subject = this.value;
                break;
            case "subject_hours_control":
                this.obj.hours_control.subject = this.value;
                break;
            case "subject_active":
                this.obj.hours_control.active = !this.obj.hours_control.active;
                break;
            case "use_custom_fields":
                this.obj.use_custom_fields = !this.obj.use_custom_fields;
                break;
            case "use_custom_fields_active":
                this.obj.zendesk.use_custom_fields.active = !this.obj.zendesk.use_custom_fields.active;
                break;
            case "use_custom_fields_subdomain":
                this.obj.zendesk.use_custom_fields.subdomain = this.value;
                break;
            case "use_custom_fields_email":
                this.obj.zendesk.use_custom_fields.email = this.value;
                break;
            case "use_custom_fields_token":
                this.obj.zendesk.use_custom_fields.token = this.value;
                break;
            case "individual_active":
                this.obj.centripush.individual.active = !this.obj.centripush.individual.active;
                break;
            case "subject_individual":
                this.obj.centripush.individual.subject = this.value;
                break;
            case "campaign_active":
                this.obj.centripush.campaign.active = !this.obj.centripush.campaign.active;
                break;
            case "subject_campaign":
                this.obj.centripush.campaign.subject = this.value;
                break;
            // ZENDESK CHAT
            case "account_key":
                this.obj.account_key = this.value;
                break;
            // CENTRIDESK
            case "account_id":
                this.obj.account_id = this.value;
                break;
            // FACEBOOK MESSENGER
            case "page_access_token":
                this.obj.page_access_token = this.value;
                break;
            case "facebook_app_id":
                this.obj.facebook_app_id = this.value;
                break;
            case "facebook_app_secret":
                this.obj.facebook_app_secret = this.value;
                break;
            // INSTAGRAM
            case "instagram_name":
                if (this.value.match(/^[a-zA-Z0-9_-\s]*$/gm)) {
                    this.obj.name = this.value;
                }
                break;
            // TELEGRAM
            case "bot_name":
                this.obj.bot_name = this.value;
                break;
            case "bot_token":
                this.obj.bot_token = this.value;
                break;
            // SUNCO
            case "switchboard_key_id":
                this.obj.switchboard.key_id = this.value;
                break;
            case "switchboard_key_secret":
                this.obj.switchboard.key_secret = this.value;
                break;
            case "campaigns_key_id":
                this.obj.campaigns.app.key_id = this.value;
                break;
            case "campaigns_key_secret":
                this.obj.campaigns.app.key_secret = this.value;
                break;
            // ENTITIES
            case "value":
                this.obj.entries[this.i].value = this.value;
                break;
            // POLLS
            case "detect_inactivity":
                this.obj.detect_inactivity = !this.obj.detect_inactivity;
                break;
            case "send_close_desk":
                this.obj.send_close_desk = !this.obj.send_close_desk;
                break;
            case "send_inactivity_bot":
                this.obj.send_inactivity_bot = !this.obj.send_inactivity_bot;
                break;
        }
        return this.obj;
    }

    addElement = () => {
        switch (this.type) {
            // INTENTS
            case 'phrases':
                this.obj.phrases.push('');
                break;
            case 'responses':
                this.obj.responses.push('');
                break;
            case 'conditional_responses':
                this.obj.conditional_responses.push({
                    "param1": "",
                    "param2": "",
                    "operator": "",
                    "response": ""
                });
                break;
            case 'input':
                this.obj.contexts.input.push('');
                break;
            case 'output':
                this.obj.contexts.output.push({id: '', lifespan: ''});
                break;
            case 'extension':
                this.obj.extensions = {
                    "url": "",
                    "method": "GET",
                    "auth": {
                        "type": "no_authentication",
                        "username": null,
                        "password": null,
                        "token": null,
                        "url": null,
                        "access_key": null,
                        "access_key_input": null,
                        "header_key": "",
                        "prefix": null,
                        "method": null,
                        "payload": "{}",
                    },
                    "body": "",
                    "prompt": "",
                    "variables": [],
                    "active": true
                }
                break;
            // USERS
            case 'projects':
                this.obj.projects.push('');
                break;
            case 'roles':
                this.obj.roles.push('');
                break;
        }

        return this.obj;
    }

    deleteElement = () => {
        switch (this.type) {
            // INTENTS
            case 'phrases':
                this.obj.phrases.splice(this.i, 1);
                break;
            case 'input':
                this.obj.contexts.input.splice(this.i, 1);
                break;
            case 'output':
                this.obj.contexts.output.splice(this.i, 1);
                break;
            case 'responses':
                this.obj.responses.splice(this.i, 1);
                break;
            case 'extensions_variables':
                this.obj.extensions.variables.splice(this.i, 1);
                break;
            case 'extension':
                this.obj.extensions = {};
                break;
            // USERS
            case 'projects':
                this.obj.projects.splice(this.i, 1);
                break;
            case 'roles':
                this.obj.roles.splice(this.i, 1);
                break;

        }

        return this.obj;
    }

    updatePollsMessages = () => {
        switch (this.type) {
            case "question_one":
                this.obj.messages[this.i].question_one = this.value;
                break;
            case "question_two":
                this.obj.messages[this.i].question_two = this.value;
                break;
            case "goodbye":
                this.obj.messages[this.i].goodbye = this.value;
                break;
            case "inactivity":
                this.obj.messages[this.i].inactivity = this.value;
                break;
        }
        return this.obj;
    }
}