// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Styles
import 'date-fns';
// Translations
import {withTranslation} from "react-i18next";
import enLocale from "date-fns/locale/en-US";
import esLocale from 'date-fns/locale/es';
import ptLocale from 'date-fns/locale/pt';
// Components
import LineBar from 'components/Charts/line_chart';
import {Divider} from "@material-ui/core";
import {Button} from "@components/Input";
// Infrastructure
import {StatsAPI} from 'views/Bots/Stats/infrastructure';
// Vendor
import {showNotify} from 'vendor/application/disptach';
import {calcDateDiff} from 'vendor/application';
// Material
import {svgCalendar, svgClock, deploySvg} from 'assets';
import {MuiPickersUtilsProvider, DatePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Alert from "@material-ui/lab/Alert";

const localeMap = {
    en: enLocale,
    es: esLocale,
    pt: ptLocale
};

class StatsCharts extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            dateRange: {},
            initialDateRange: {
                startDate: new Date(date.setMonth(date.getMonth() - 1)),
                endDate: new Date(date.setMonth(date.getMonth() + 1))
            },
            result: false,
            totalSessions: 0,
            totalResponses: 0,
            start: false,
            end: false,
            tabValue: 0
        }
    }

    componentDidMount() {
        this.filterByDate();
    }

    // method that reloads the component when the current project has been changed
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.actualProject.id !== this.props.data.actualProject.id) {
            setTimeout(() => {
                this.filterByDate();
            }, 100);
        }
    }

    filterByDate = () => {
        const {dateRange, initialDateRange} = this.state;
        var date, diff;
        if (dateRange.startDate) {
            date = dateRange;
        } else {
            date = initialDateRange;
        }

        if ( date ) {
            diff = calcDateDiff(date.endDate, date.startDate);
        }

        if (date && diff.days <= 365 && diff.years === 0) {
            var start = new Date(date.startDate);
            var end = new Date(date.endDate);
            var s = `${start.getFullYear()}-${start.getMonth() + 1}-1`
            var e = `${end.getFullYear()}-${end.getMonth() + 1}-1`
            this.getStats(s, e);
        } else {
            var data = { type: "date_diff", severity: "info" };
            this.props.dispatch(showNotify(data));
        }
    }

    getStats = async (s, e) => {
        const {access, actualProject} = this.props.data;
        try {
            let obj = new StatsAPI(actualProject.id, access, this.props, '');
            let array = await obj.get_stats_filter(s, e);
            this.setState({result: array});
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data))
        }
    }

    // update initialDateRange start
    handleSelectStart = (start) => {
        var end = this.state.initialDateRange.endDate;
        if (start > end) {
            end = start;
        }
        this.setState({
            initialDateRange: {
                startDate: start,
                endDate: end
            }
        })
    }

    // update initalDateRange end
    handleSelectEnd = (end) => {
        var start = this.state.initialDateRange.startDate;
        if (end < start) {
            start = end;
        }
        this.setState({
            initialDateRange: {
                startDate: start,
                endDate: end
            }
        })
    }

    getStartDate = () => {
        const {initialDateRange} = this.state;
        var m = initialDateRange.startDate.getMonth(),
            y = initialDateRange.startDate.getFullYear()
        return `${m + 1} / ${y}`;
    }

    getEndDate = () => {
        const {initialDateRange} = this.state;
        var m = initialDateRange.endDate.getMonth(),
            y = initialDateRange.endDate.getFullYear();
        return `${m + 1} / ${y}`;
    }

    handleClassTable = (doc) => {
        var res = '';
        if (doc && doc.length > 0) res = 'table';
        return res;
    }

    openStart = () => {
        this.setState({start: !this.state.start});
    }

    openEnd = () => {
        this.setState({end: !this.state.end});
    }

    render() {
        const {t} = this.props;
        const {
            initialDateRange,
            result,
            start,
            end
        } = this.state;
        return (
            <div className="stats-container">
                <React.Fragment>
                    <Alert severity="info">
                       <span dangerouslySetInnerHTML={{__html: t('stats.charts.info')}}></span>
                    </Alert>
                    <Divider />
                    <div className="stats-header">
                        <div className="stats-date-container">
                            <div className="stats-date">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}
                                                         locale={localeMap[this.props.i18n.language]}>
                                    <div className="start-date" onClick={() => this.openStart()}>
                                        <div>
                                            <span>{svgCalendar()}</span>
                                            <span>{t('stats.start')}</span>
                                            <span>{deploySvg(false)}</span>
                                        </div>
                                        <div>
                                            <span>{svgClock()}</span>
                                            <span>{this.getStartDate()}</span>
                                        </div>
                                    </div>
                                    <DatePicker
                                        value={initialDateRange.startDate}
                                        onChange={this.handleSelectStart}
                                        animateYearScrolling
                                        views={["year", "month"]}
                                        openTo={"month"}
                                        autoOk
                                        onClose={this.openStart}
                                        open={start}
                                    />
                                    <div className="end-date" onClick={() => this.openEnd()}>
                                        <div>
                                            <span>{svgCalendar()}</span>
                                            <span>{t('stats.end')}</span>
                                            <span>{deploySvg(false)}</span>
                                        </div>
                                        <div>
                                            <span>{svgClock()}</span>
                                            <span>{this.getEndDate()}</span>
                                        </div>
                                    </div>
                                    <DatePicker
                                        value={initialDateRange.endDate}
                                        onChange={this.handleSelectEnd}
                                        animateYearScrolling
                                        views={["year", "month"]}
                                        openTo={"month"}
                                        autoOk
                                        onClose={this.openEnd}
                                        open={end}
                                    />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div className="actions">
                            <Button type={"primary"}
                                    text={t('stats.charts.action_button')}
                                    onClick={(e) => this.filterByDate(e)}/>
                        </div>
                    </div>
                    <div className={"stats-graph"} style={{marginTop: 20, marginBottom: 20}}>
                        {result && <LineBar t={t} range={initialDateRange} arr={result} type={'accesses'}/>}
                        {result && <LineBar t={t} range={initialDateRange} arr={result} type={'detections'}/>}
                    </div>
                </React.Fragment>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_charts = connect(
    mapStateToProps
)(StatsCharts);

export default withTranslation('common')(withRouter(connect_charts));