// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Infrastructure
import {ContextsAPI} from 'views/Bots/NLP/application/Build/Tabs/Contexts/infrastructure';
// Components
import CardList from 'components/Card/CardList';
import CardEmpty from 'components/Card/CardEmpty';
import CreateContextModal from "views/Bots/NLP/application/Build/Tabs/Contexts/application/context_create_modal";
import {PopUp} from "@components/Modals";
// Vendor
import {showNotify, updateFilters} from 'vendor/application/disptach';
// Translations
import {withTranslation} from "react-i18next";
import {CircularProgress} from "@material-ui/core";
// Amplitude API
import {AmplitudeAPI} from '@api/Amplitude';


class Contexts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: '',
            context: {
                name: "",
                description: ""
            },
            open: false,
            create_open: false,
            completeArray: false,
            isLoading: true
        }
    }

    componentDidMount() {
        this.openCreate();
        this.getContexts();
    }

    openCreate = () => {
        var queryString = window.location.search,
            urlParams = new URLSearchParams(queryString),
            create = urlParams.get('create');

        if (create) {
            this.handleClickOpen();
        }
    }

    // method that reloads the component when the current project has been changed
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.actualProject.id !== this.props.data.actualProject.id) {
            setTimeout(() => {
                this.setState({isLoading: true});
                this.openCreate();
                this.getContexts();
            }, 100);
        }
    }

    // get contexts from actual project id
    getContexts = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ContextsAPI(actualProject.id, access, this.props);
            const contexts = await obj.get_contexts();
            this.setState({completeArray: contexts, array: contexts, isLoading: false});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    // delete context
    deleteContext = async (e, id) => {
        e.stopPropagation();
        const {access, actualProject, filters} = this.props.data;
        try {
            let obj = new ContextsAPI(actualProject.id, access, this.props);
            await obj.delete_context(id);
            this.props.dispatch(showNotify({message: "contexts", type: "delete", severity: "success"}));
            this.handleClickOpen();

            filters.intents.context_selector.id = '';
            filters.intents.context_selector.name = '';

            this.props.dispatch(updateFilters(filters));
            this.setState({isLoading: true});
            this.props.getLimits();
            this.getContexts();
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    handleCreate = async () => {
        const {access, actualProject, user, device_id} = this.props.data;
        const {context} = this.state;
        try {
            const obj = new ContextsAPI(actualProject.id, access, this.props, context);
            await obj.post_context();

            this.props.dispatch(showNotify({message: "contexts", type: "create", severity: "success"}));

            new AmplitudeAPI({
                event_type: 'Create context',
                device_id: device_id,
                user_id: user.email
            }).eventLog();

            this.props.getLimits();
            this.getContexts();
            this.setState({create_open: false, context: {name: "", description: ""}});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
            this.handleClickOpen();
        }
    }

    handleClickOpen = () => {
        const {open} = this.state;
        this.setState({open: !open})
    }

    updateNewContext = (e, type) => {
        const {context} = this.state;
        if (type === "name" && e.match(/^[a-zA-Z0-9_\/]*$/) || type === "description") {
            context[type] = e;
        }

        this.setState({context: context});
    }

    render() {
        const {data, t, handleTabs, history} = this.props;
        const {array, open, create_open, isLoading, completeArray, context} = this.state;
        return (
            !isLoading && array ?
                <React.Fragment>
                    {array.length > 0 ? (
                        <CardList
                            data={data}
                            t={t}
                            handleClickOpen={this.handleClickOpen}
                            createModal={() => this.setState({create_open: true})}
                            handleDelete={this.deleteContext}
                            handleTabs={handleTabs}
                            list={array}
                            completeArray={completeArray}
                            open={open}
                            history={history}
                            layout={"contexts"}
                        />
                    ) : (
                        <CardEmpty
                            data={data}
                            t={t}
                            handleClickOpen={() => this.setState({create_open: true, context: {name: "", description: ""}})}
                            open={open}
                            type={'contexts'}
                        />
                    )}
                <PopUp
                    open={create_open}
                    handleClose={() => this.setState({create_open: false, context: {name: "", description: ""}})}
                    t={t}
                    width={400}
                    content={
                        <CreateContextModal
                            type={"content"}
                            new_context={JSON.parse(JSON.stringify(context))}
                            updateContext={this.updateNewContext}
                        />
                    }
                    buttons={
                        <CreateContextModal
                            type={"button"}
                            new_context={JSON.parse(JSON.stringify(context))}
                            createContext={this.handleCreate}
                        />
                    }
                />
                </React.Fragment> :
                <div className="card">
                    <div className="loading-data">
                        <CircularProgress color="primary" size={70}/>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_context = connect(
    mapStateToProps
)(Contexts);

export default withTranslation('common')(withRouter(connect_context));