// React
import {connect} from "react-redux";
import React, {Component} from "react";
// Routing
import {withRouter} from 'react-router-dom';
// Translations
import {withTranslation} from "react-i18next";
// API
import {ContextsAPI} from "views/Bots/NLP/application/Build/Tabs/Contexts/infrastructure";
import {IntentsAPI} from "views/Bots/NLP/application/Build/Tabs/Intents/infrastructure";
// Vendor
// Helpers
import {handleArtifacts} from "@helpers";
// Material
// Styles
import "reactflow/dist/style.css";
import "../styles/index.scss";
// NodeTypes
import Intent from "views/Bots/NLP/application/Build/Flow/application/nodeTypes/intent";
import IntentInitial from "views/Bots/NLP/application/Build/Flow/application/nodeTypes/intent_initial";
import IntentDF from "views/Bots/NLP/application/Build/Flow/application/nodeTypes/intent_default_fallback";
import Diagram from "./diagram";

class Flow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nodes: [],
            edges: [],
            contexts: false
        }
    }

    componentDidMount = () => {
        this.handleData();
    }

    handleData = async () => {
        const {access, actualProject} = this.props.data;
        try {
            //GET intents
            let api_intents = new IntentsAPI(actualProject.id, access, this.props),
                intents_array = await api_intents.get_intents(),
                intents = intents_array.intents;

            // GET contexts
            var api_contexts = new ContextsAPI(actualProject.id, access, this.props),
                contexts = await api_contexts.get_contexts();

            var nodes = [],
                edges = [];

            intents.forEach(intent => {
                // GET coordinates from intent
                var coordinates = intent.metadata?.coordinates ?? {x: 0, y: 0};

                // GET artifacts from intent response
                const response = intent.responses[0];
                let artifacts = null;
                if (response) {
                    artifacts = handleArtifacts(response)[0];
                }

                if (!intent.metadata?.coordinates || !intent.metadata?.relations) {
                    intent.metadata = {
                        coordinates: coordinates,
                        type: "intent",
                        relations: []
                    }
                }

                // Add the new node
                nodes.push({
                    id: intent.id,
                    type: intent.metadata?.type,
                    // Attribute to identify which class of node can be dragged
                    dragHandle: '.flow_element__draggable',
                    data: {...intent, artifacts: artifacts},
                    position: coordinates
                });
            });
            // Method where the edges or relations of the intents are created.
            // From the responses of the intents:
            //   we look for the values the buttons have
            //      if that answer has a value
            //          all the other intents are searched for an input phrase that corresponds to that value and create a edge between them
            nodes?.map((source_intent => {
                var source_context = source_intent.data.contexts.output[0],
                    source_relations = source_intent.data.metadata?.relations;

                if (source_relations?.length > 0) {
                    nodes?.map((target_intent) => {
                        var target_with_context = target_intent.data.contexts.input.filter(context => context === source_context?.id)[0];

                        if (target_with_context) {

                            var t_s_relations = source_relations.filter(relation => relation.target === target_intent.id);

                            t_s_relations.forEach((relation) => {
                                edges.push({
                                    id: `flow_${target_intent.id}_${source_intent.id}_${relation.actions.value}`,
                                    sourceHandle: `handle-${source_intent.id}-${relation.actions.value}`,
                                    source: source_intent.id,
                                    target: target_intent.id,
                                    type: "edge"
                                })
                            })
                        }
                    })
                }
            }))

            this.setState({nodes: nodes, edges: edges, contexts: contexts});
        } catch
            (err) {}
    }

    createContext = async (context_name) => {
        const {access, actualProject} = this.props.data;
        try {
            // POST context
            var api_contexts = new ContextsAPI(actualProject.id, access, this.props, {
                name: context_name,
                description: ""
            });
            return await api_contexts.post_context();
        } catch (e) {
        }
    }

    render() {
        const {nodes, edges, contexts} = this.state;

        const nodeTypes = {
            intent: Intent,
            intent_initial: IntentInitial,
            intent_df: IntentDF
        }

        return (
            <Diagram extends={this.props} contexts={contexts} nodes={nodes} edges={edges} nodeTypes={nodeTypes}
                     createContext={this.createContext}/>
        );
    }
}

const
    mapStateToProps = state => {
        return {
            data: state
        }
    }

const
    connect_flow = connect(
        mapStateToProps
    )(Flow);

export default withTranslation(
    'common'
)(
    withRouter(connect_flow)
)
;
