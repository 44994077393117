// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Translations
import {withTranslation} from "react-i18next";

class ElementSwitch extends Component {
    handleSwitch = () => {
        const {updateConfig, color_1, color_2, config} = this.props;
        if (color_1 === "primary") {
            [config.colors[color_1], config.colors[color_2]] = [config.colors[color_2], config.colors[color_1]];
        } else {
            [config.customization[color_1], config.customization[color_2]] = [config.customization[color_2], config.customization[color_1]];
        }
        updateConfig(config);
    }

    render() {
        return (
            <div onClick={() => this.handleSwitch()} className={"element__switch"}>
                <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.0446 2.57812L10.0446 14.1797C10.0446 14.4141 10.1228 14.6094 10.279 14.7656C10.4353 14.9219 10.6306 15 10.8649 15C11.1254 15 11.3337 14.9219 11.4899 14.7656C11.6462 14.6094 11.7243 14.4141 11.7243 14.1797L11.7243 2.57812L13.7165 3.90625C13.8728 4.08854 14.0746 4.16667 14.322 4.14062C14.5694 4.11458 14.7582 4.01042 14.8884 3.82812C15.2009 3.41146 15.1358 3.02083 14.6931 2.65625L10.8649 -1.84349e-07L7.03682 2.65625C6.6462 3.09896 6.62016 3.48958 6.9587 3.82812C7.37537 4.24479 7.76599 4.27083 8.13057 3.90625L10.0446 2.57812ZM8.2087 16.1719C7.81807 15.7292 7.42745 15.7031 7.03682 16.0937L5.04464 17.4219L5.04464 5.82031C5.04464 5.58594 4.96651 5.39062 4.81026 5.23437C4.65401 5.07812 4.4587 5 4.22432 5C3.96391 5 3.75557 5.07812 3.59932 5.23437C3.44307 5.39062 3.36495 5.58594 3.36495 5.82031L3.36495 17.4219L1.37276 16.0937C1.21651 15.9115 1.01469 15.8333 0.767292 15.8594C0.519896 15.8854 0.331094 15.9896 0.200886 16.1719C0.0446355 16.3281 -0.0204686 16.5299 0.00557302 16.7773C0.0316147 17.0247 0.12276 17.2135 0.27901 17.3437L4.22432 20L8.05245 17.3437C8.46911 16.9792 8.5212 16.5885 8.2087 16.1719Z" fill="#565671"/>
                </svg>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const a = connect(
    mapStateToProps
)(ElementSwitch);

export default withTranslation('common')(withRouter(a));