// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Infrastructure
import {IntentsAPI} from 'views/Bots/NLP/application/Build/Tabs/Intents/infrastructure';
import {ContextsAPI} from 'views/Bots/NLP/application/Build/Tabs/Contexts/infrastructure';
// Components
import CardList from 'components/Card/CardList';
import CardEmpty from 'components/Card/CardEmpty';
// Vendor
import {showNotify, updateFilters} from 'vendor/application/disptach';
// Translations
import {withTranslation} from "react-i18next";
import {CircularProgress} from "@material-ui/core";
// Amplitude API
import {AmplitudeAPI} from '@api/Amplitude';

class Intents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            array: false,
            isLoading: true,
            completeArray: false,
            contexts: false,
            open: false,
            max: 0,
            isLimited: false
        }
    }

    componentDidMount = () => {
        this.props.getLimits();
        this.getContexts();
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.actualProject.id !== this.props.data.actualProject.id) {
            setTimeout(() => {
                this.setState({isLoading: true});
                this.getContexts(true);
            }, 100);
        }
    }

    getIntents = async (reset_filters) => {
        const {access, actualProject, filters} = this.props.data;
        this.setState({isLoading: true});
        try {
            let obj = new IntentsAPI(actualProject.id, access, this.props);
            let array = await obj.get_intents_pagination(reset_filters);

            if (array.intents.length === 0 && filters.intents.pagination.page !== 1 && !reset_filters) {
                this.getIntents(true);
            } else {
                if (reset_filters) {
                    filters.intents.pagination.page = 1;
                    this.props.dispatch(updateFilters(filters));
                }

                this.handleLimits();
                var operation = array.count / filters.intents.pagination.interval,
                    is_decimal = (operation - Math.floor(operation)) !== 0,
                    sum = is_decimal ? 1 : 0;
                this.setState({
                    array: array.intents,
                    completeArray: array.intents,
                    max: Math.floor(operation) + sum,
                    isLoading: false
                });
            }
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getContexts = async (reset_filters) => {
        const {access, actualProject} = this.props.data;
        try {
            let obj = new ContextsAPI(actualProject.id, access, this.props);
            let array = await obj.get_contexts(true);
            array.unshift({id: 'all_contexts', name: "all_contexts"});
            this.setState({contexts: array});
            this.getIntents(reset_filters);
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    handleLimits = () => {
        const {limits} = this.props.data;
        if (limits.intents.total >= limits.intents.max) {
            this.setState({isLimited: true});
        } else {
            this.setState({isLimited: false});
        }
    }

    deleteIntent = async (e, id) => {
        e.stopPropagation();
        const {access, actualProject, user, device_id} = this.props.data;
        const {array} = this.state;
        try {
            let obj = new IntentsAPI(actualProject.id, access, this.props);
            await obj.delete_intent(id);

            new AmplitudeAPI({
                event_type: "Delete intent",
                device_id: device_id,
                user_id: user.email
            }).eventLog();

            var intent_name = "";
            array.forEach((el => {
                if (el.id === id) {
                    intent_name = el.name;
                }
            }))

            this.props.dispatch(showNotify({
                message: "intents",
                type: "delete",
                severity: "success",
                item: {name: intent_name}
            }));
            this.handleClickOpen();

            this.setState({isLoading: true});

            this.props.getLimits();

            this.getIntents(true);
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    handleClickLink = () => {
        const {history, location} = this.props;
        const {user, device_id} = this.props.data;

        new AmplitudeAPI({
            event_type: "Click on create intent",
            device_id: device_id,
            user_id: user.email
        }).eventLog();

        history.push(`${history.location.pathname}${location.search}&action=create`);

        this.props.handleTabs();
    }

    handleClickOpen = () => {
        const {open} = this.state;
        const {user, device_id} = this.props.data;

        if (!open) {
            new AmplitudeAPI({
                event_type: "Click on delete intent",
                device_id: device_id,
                user_id: user.email
            }).eventLog();
        }

        this.setState({open: !open});
    }

    noFilters = () => {
        const {filters} = this.props.data;
        var page = filters.intents.pagination.page === 1,
            search = filters.intents.search === '',
            context_id = filters.intents.context_selector.id === 'all_contexts' || !filters.intents.context_selector.id;

        return page && search && context_id;
    }

    render() {
        const {array, open, contexts, completeArray, isLimited, isLoading, max} = this.state;
        return (
            array && contexts ?
                <React.Fragment>
                    {array.length === 0 && this.noFilters() && !isLoading ? (
                            <CardEmpty
                                data={this.props.data}
                                t={this.props.t}
                                handleClickLink={this.handleClickLink}
                                open={open}
                                type={'intents'}
                            />
                        ) :
                        <CardList
                            data={this.props.data}
                            t={this.props.t}
                            handleClickOpen={this.handleClickOpen}
                            handleClickLink={this.handleClickLink}
                            handlePagination={this.getIntents}
                            handleDelete={this.deleteIntent}
                            handleTabs={this.props.handleTabs}
                            history={this.props.history}
                            list={array}
                            completeArray={completeArray}
                            open={open}
                            max={max}
                            isLoading={isLoading}
                            contexts={contexts}
                            isLimited={isLimited}
                            layout={"intents"}
                        />
                    }
                </React.Fragment> :
                <div className="card">
                    <div className="loading-data">
                        <CircularProgress color="primary" size={70}/>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_intents = connect(
    mapStateToProps
)(Intents);

export default withTranslation('common')(withRouter(connect_intents));