// @ts-check

/**
 * @typedef {import('types/zendesk').ZendeskVariable} ZendeskVariable
 */

/**
 * @param {ZendeskVariable[]} variablesDesk
 * @param {string[]} allowedNames
 */


export const parseVariablesDeskEdit = (variablesDesk, allowedNames, validIds) => {
    /**
     * @type {ZendeskVariable[]}
     */
   variablesDesk.forEach(variable => {
        const exists = validIds.find(value => 
            value.zendesk_ticket_field && 
            value.zendesk_ticket_field.title === variable.zendesk_ticket_field.title
        );

        if (!exists || exists.zendesk_ticket_field === null) {
            variable.zendesk_ticket_field = null;
        }
    });

    const filteredVariables = variablesDesk.filter(variable => {
        return variable && allowedNames?.includes(variable.name);
    });

    return filteredVariables.map(variable => {
        const { name, zendesk_ticket_field } = variable;
        if (!zendesk_ticket_field?.desk_id || !zendesk_ticket_field.id) return null;

        return {
            name,
            zendesk_ticket_field: {
                id: zendesk_ticket_field.id,
                type: zendesk_ticket_field.type,
                title: zendesk_ticket_field.title,
                equivalences: zendesk_ticket_field.equivalences,
                desk_id: zendesk_ticket_field.desk_id,
            }
        };
    }).filter(variable => variable !== null);
}