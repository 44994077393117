// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Styles
import 'date-fns';
// Translations
import {withTranslation} from "react-i18next";
// Components
import Consumption from '../StatsConsumption';
import Conversations from './conversations';
import Charts from './charts';
import Statistics from './numeric';
import Polls from './polls';
import Maus from './maus';
import WhatsappSessions from './whatsapp_sessions';
import SuncoNotifications from './sunco_notifications';
import SuncoCampaigns from './SuncoCampaigns';
import {TabPanel} from "components/Navigation/tab_panel";
// Material
import {AppBar, Tab, Tabs, Divider} from '@material-ui/core';
import 'views/Bots/Stats/styles/index.scss';
// Amplitude API
import {AmplitudeAPI} from '@api/Amplitude';

class StatsProject extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            dateRange: {},
            initialDateRange: {
                startDate: new Date(date.setMonth(date.getMonth() - 1)),
                endDate: new Date(date.setMonth(date.getMonth() + 1))
            },
            totalSessions: 0,
            totalResponses: 0,
            start: false,
            end: false
        }
    }

    componentDidMount() {
        this.handleTabs();
    }

    handleTabs = () => {
        const { history, location } = this.props;
        const { actualProject } = this.props.data;
        var tab = location.search.split('&to=');
        switch (tab[1]) {
            case 'charts':
                this.setState({tabValue: 0});
                break;
            case 'numeric':
                this.setState({tabValue: 1});
                break;
            case 'conversations_channels':
                this.setState({tabValue: 2});
                break;
            case 'polls':
                this.setState({tabValue: 3});
                break;
            case 'consumption':
                this.setState({tabValue: 4});
                break;
            case 'maus':
                this.setState({tabValue: 5});
                break;
            case 'whatsapp_sessions':
                this.setState({tabValue: 6});
                break;
            case 'sunco_notifications':
                this.setState({tabValue: 7});
                break;
            case 'sunco_campaigns':
                this.setState({tabValue: 8});
                break;
            default:
                history.push(`/${actualProject.id}/stats?from=stats_project&to=charts`);
                this.setState({tabValue: 0});
                break;
        }
    }

    a11yProps(index) {
        return {
            id: `stats-tab-${index}`,
            'aria-controls': `stats-tabpanel-${index}`,
        };
    }

    setQuery = (e, to) => {
        e.preventDefault();
        const { history } = this.props;
        const {user, device_id, actualProject} = this.props.data;
        history.push(`/${actualProject.id}/stats?from=stats_project&to=${to}`);

        new AmplitudeAPI({
            event_type: `Click on project ${to} stats`,
            device_id: device_id,
            user_id: user.email
        }).eventLog();

        setTimeout(() => {
            this.handleTabs();
        }, 10);

    }

    render() {
        const {project, actualProject, t} = this.props;
        const {plan} = this.props.data;
        const {tabValue} = this.state;

        const isSunco = plan.plan.name === 'consulting_sunco',
            accessCampaigns = plan?.whatsapp_phones?.length > 0 && plan?.platform?.centripush;
        return (
            tabValue !== undefined &&
            <React.Fragment>
                <AppBar id={"app_bar"} position="static" color="default">
                    <Tabs
                        value={tabValue}
                        variant="scrollable"
                        scrollButtons="on"
                        textColor="primary"
                    >
                        {/* Sessions & Intents */}
                        <Tab label={t('stats.charts.title')} onClick={(e) => {
                            this.setQuery(e, 'charts')
                        }} {...this.a11yProps(0)} />
                        {/* Numeric */}
                        <Tab label={t('stats.numeric.title')} onClick={(e) => {
                            this.setQuery(e, 'numeric')
                        }} {...this.a11yProps(2)} />
                        {/* Conversations */}
                        <Tab label={t('stats.conversations.title')} onClick={(e) => {
                            this.setQuery(e, 'conversations_channels')
                        }} {...this.a11yProps(1)} />
                        {/* Polls */}
                        <Tab label={t('stats.polls.title')} onClick={(e) => {
                            this.setQuery(e, 'polls')
                        }} {...this.a11yProps(3)} />
                        {/* Consumption */}
                        <Tab label={t('stats.consumption.title')} onClick={(e) => {
                            this.setQuery(e, 'consumption')
                        }} {...this.a11yProps(4)} />
                        {/* MAUS */}
                        <Tab label={t('stats.maus.title')} onClick={(e) => {
                            this.setQuery(e, 'maus')
                        }} {...this.a11yProps(5)} />
                        {/* Whatsapp Sessions */}
                        <Tab label={t('stats.whatsapp_sessions.title')} onClick={(e) => {
                            this.setQuery(e, 'whatsapp_sessions')
                        }} {...this.a11yProps(6)} />
                        {/* Sunco Notifications */}
                        {
                            isSunco &&
                                <Tab label={t('stats.sunco_notifications.title')} onClick={(e) => {
                                    this.setQuery(e, 'sunco_notifications')
                                }} {...this.a11yProps(7)} />
                        }
                        {/* Sunco Campaigns */}
                        {
                            isSunco && accessCampaigns &&
                            <Tab label={t('stats.sunco_campaigns.title')} onClick={(e) => {
                                this.setQuery(e, 'sunco_campaigns')
                            }} {...this.a11yProps(8)} />
                        }
                    </Tabs>
                </AppBar>
                <Divider />
                <TabPanel value={tabValue} index={0}>
                    <Charts project={project} actualProject={actualProject}/>
                </TabPanel>
                <TabPanel value={tabValue} index={1}>
                    <Statistics project={project} actualProject={actualProject}/>
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                    <Conversations project={project} actualProject={actualProject}/>
                </TabPanel>
                <TabPanel value={tabValue} index={3}>
                    <Polls project={project} actualProject={actualProject}/>
                </TabPanel>
                <TabPanel value={tabValue} index={4}>
                    <Consumption project={project} actualProject={actualProject} type={"projects"}/>
                </TabPanel>
                <TabPanel value={tabValue} index={5}>
                    <Maus project={project} actualProject={actualProject} />
                </TabPanel>
                <TabPanel value={tabValue} index={6}>
                    <WhatsappSessions project={project} actualProject={actualProject} />
                </TabPanel>
                {
                    isSunco &&
                        <TabPanel value={tabValue} index={7}>
                            <SuncoNotifications project={project} actualProject={actualProject} />
                        </TabPanel>
                }
                {
                    isSunco && accessCampaigns &&
                    <TabPanel value={tabValue} index={8}>
                        <SuncoCampaigns project={project} actualProject={actualProject} />
                    </TabPanel>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_stats = connect(
    mapStateToProps
)(StatsProject);

export default withTranslation('common')(withRouter(connect_stats));