// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Translations
import {withTranslation} from "react-i18next";
// Components
import {Divider, Switch} from "@material-ui/core";
import ElementColor from "../element_color";
import ElementIcon from "../element_icon";
import ElementSwitch from "../element_switch";
// Vendor
import {ElementsHandleAPI} from "vendor/application/handleMethods";
import {addArtifact} from "vendor/application";


class Messages extends Component {

    updateElementConfig = (e, type) => {
        e.preventDefault();
        const {config, updateConfig} = this.props;
        const obj = new ElementsHandleAPI(config, e.target.value, type);
        const res = obj.updateObj('edit');
        updateConfig(res);
    }

    handleArtifacts = (e, i, artifact_type, type) => {
        const {config, updateConfig} = this.props;
        var res = addArtifact(i, artifact_type, type, config);
        updateConfig(res);
    }

    render() {
        const {t, config, updateCustomization, updateConfig, updateIcon} = this.props;
        return (
            <div className={"cen_card"}>
                {/* COLORS */}
                <div className={"content"}>
                    <div className="elements_custom grid">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.messages.colors.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.messages.colors.description')}</span>
                        </div>
                        <div>
                            <ElementColor
                                updateCustomization={updateCustomization}
                                element={"chat_bg"}
                                value={config.customization.chat_bg_color}
                                value_updated={"chat_bg_color"}
                            />
                            <ElementSwitch
                                color_1={"chat_bg_color"}
                                color_2={"messages_content_bg_color"}
                                updateConfig={updateConfig}
                                config={config}
                            />
                            <ElementColor
                                updateCustomization={updateCustomization}
                                element={"messages_content_bg"}
                                value={config.customization.messages_content_bg_color}
                                value_updated={"messages_content_bg_color"}
                            />
                        </div>
                    </div>
                </div>
                <Divider/>
                {/* AGENT / VISITOR */}
                <div className={"elements_divider"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.messages.agent.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.messages.agent.description')}</span>
                        </div>
                        <div className={"elements_divider_container"}>
                            <div className={"elements_divider_colors"}>
                                <div>
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"bg"}
                                        value={config.customization.messages_agent_bg_color}
                                        value_updated={"messages_agent_bg_color"}
                                    />
                                    <ElementSwitch
                                        color_1={"messages_agent_bg_color"}
                                        color_2={"messages_agent_text_color"}
                                        updateConfig={updateConfig}
                                        config={config}
                                    />
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"text"}
                                        value={config.customization.messages_agent_text_color}
                                        value_updated={"messages_agent_text_color"}
                                    />
                                </div>
                            </div>
                            <div className={"elements_divider_icons"}>
                                <ElementIcon
                                    updateIcon={updateIcon}
                                    updateCustomization={updateCustomization}
                                    element={"messages.agent"}
                                    img={config.agent_icon}
                                    icon_type={"agent"}
                                />
                                <div className={"element__icon_switch"}>
                                    <Switch
                                        checked={config.customization.messages_agent_icon_show}
                                        onClick={(e) => {
                                            updateCustomization(e, 'bool', "messages_agent_icon_show")
                                        }}
                                    />
                                    <span>{t('card.content.project.web_interface.customization.show_icon')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.messages.visitor.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.messages.visitor.description')}</span>
                        </div>
                        <div className={"elements_divider_container"}>
                            <div className={"elements_divider_colors"}>
                                <div>
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"bg"}
                                        value={config.customization.messages_user_bg_color}
                                        value_updated={"messages_user_bg_color"}
                                    />
                                    <ElementSwitch
                                        color_1={"messages_user_bg_color"}
                                        color_2={"messages_user_text_color"}
                                        updateConfig={updateConfig}
                                        config={config}
                                    />
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"text"}
                                        value={config.customization.messages_user_text_color}
                                        value_updated={"messages_user_text_color"}
                                    />
                                </div>
                            </div>
                            <div className={"elements_divider_icons"}>
                                <ElementIcon
                                    updateIcon={updateIcon}
                                    updateCustomization={updateCustomization}
                                    element={"messages.agent"}
                                    img={config.user_icon}
                                    icon_type={"user"}
                                />
                                <div className={"element__icon_switch"}>
                                    <Switch
                                        checked={config.customization.messages_user_icon_show}
                                        onClick={(e) => {
                                            updateCustomization(e, 'bool', "messages_user_icon_show")
                                        }}
                                    />
                                    <span>{t('card.content.project.web_interface.customization.show_icon')}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* ANIMATION */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.messages.rubberband.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.messages.rubberband.description')}</span>
                        </div>
                        <Switch checked={config.customization.messages_rubberband}
                                onClick={(e) => {
                                    updateCustomization(e, 'bool', 'messages_rubberband')
                                }}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const a = connect(
    mapStateToProps
)(Messages);

export default withTranslation('common')(withRouter(a));