// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Infrastructure
import {ProjectAPI} from 'api/application/Projects';
// Application
import {ElementsHandleAPI} from 'vendor/application/handleMethods';
// Vendor
import {showNotify} from 'vendor/application/disptach';
import {addArtifact, pollsBody, pollsMessageBody} from "vendor/application";
// Components
import {CircularProgress, Divider, Switch} from '@material-ui/core';
import AccordionPolls from 'components/Accordion/AccordionPolls';
import Select from "components/Input/select";
import CardEmpty from "components/Card/CardEmpty";
import {Button} from "@components/Input";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import 'views/Bots/ChatBot/styles/index.scss';

class ChatbotPolls extends Component {
    constructor(props) {
        super(props);
        this.state = {
            polls: {},
            codes: '',
            allCodes: '',
            new_poll: false
        }
    }

    componentDidMount = () => {
        this.getPolls();
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.data.actualProject.id !== this.props.data.actualProject.id) {
            this.getPolls();
        }
    }

    getPolls = async () => {
        const {access, actualProject} = this.props.data;
        try {
            let obj = new ProjectAPI(actualProject.id, access, this.props);
            let polls = await obj.get_polls();

            if (Object.keys(polls).length == 0) {
                this.setState({polls: false, new_poll: true});
            } else {
                this.getLanguageCodes(polls);
                this.setState({polls: polls});
            }
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data))
        }
    }

    getLanguageCodes = async (polls) => {
        const {access} = this.props.data;
        try {
            let obj = new ProjectAPI(null, access, this.props);
            var allCodes = await obj.get_codes();
            this.handleCodesArray(allCodes, polls);
        } catch (err) {
            this.handleCodesArray(['es', 'en', 'pt'], polls);
        }
    }

    addPoll = () => {
        this.getLanguageCodes(pollsBody());
        this.setState({polls: pollsBody(), new_poll: true})
    }

    updatePoll = async () => {
        const {new_poll, polls} = this.state;
        const {access, actualProject} = this.props.data;
        try {
            let obj = new ProjectAPI(actualProject.id, access, this.props, polls);
            await obj.do_polls(new_poll ? 'POST' : 'PUT');

            this.props.dispatch(showNotify({
                message: "polls",
                type: new_poll ? "create" : "edit",
                severity: "success"
            }));

            this.setState({new_poll: false});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    // update element state
    updateElement = (e, type, i) => {
        e.preventDefault();
        const {polls} = this.state;
        const obj = new ElementsHandleAPI(polls, e.target.value, type, i);
        const res = obj.updateObj();

        this.setState({polls: res});
    }

    updateElementMessage = (e, type, i) => {
        const {polls} = this.state;

        const obj = new ElementsHandleAPI(polls, e.target.value, type, i);
        const res = obj.updatePollsMessages();

        this.setState({polls: res});
    }

    selectCode = (e) => {
        const {polls} = this.state;

        polls.messages.push(pollsMessageBody(e));
        this.handleCodesArray(this.state.allCodes, polls);
        this.setState({polls: polls});
    }

    selectDefaultLang = (e) => {
        const {polls, codes} = this.state;

        var a = false;
        polls.messages.forEach((el) => {
            if (el.lang === e) {
                a = true;
            }
        });

        if (!a) {
            polls.messages.push(pollsMessageBody(e));
            codes.forEach((el, i) => {
                if (el === e) {
                    codes.splice(i, 1);
                }
            });

            this.setState({polls: polls, codes: codes});
        }

        polls.default_lang = e;
        this.setState({polls: polls});
    }

    handleCodesArray = (allCodes, polls) => {
        var codes = JSON.parse(JSON.stringify(allCodes));
        polls.messages.forEach((message => {
            codes.forEach(((code, i) => {
                if (message.lang === code) {
                    codes.splice(i, 1);
                }
            }))
        }));

        this.setState({codes: codes, allCodes: allCodes});
    }

    deleteMessage = (e, lang) => {
        e.stopPropagation();
        const {polls} = this.state;
        polls.messages.forEach(((message, i) => {
            if (message.lang === lang) {
                polls.messages.splice(i, 1);
            }
        }))

        this.handleCodesArray(this.state.allCodes, polls);
        this.setState({polls: polls});
    }

    handleClickOpen = () => {
        this.setState({open: !this.state.open});
    }

    handleArtifacts = (e, i, artifact_type, type, element) => {
        const {polls} = this.state;

        var res = addArtifact(i, artifact_type, type, polls, element);

        this.setState({polls: res});
    }

    render() {
        const {polls, codes, allCodes} = this.state;
        const {t} = this.props;
        const {isSending} = this.props.data;
        return (
            polls ? (
                    polls.messages
                        ? <div className={"cen_card"}>
                            <div className={"header"}>
                                <div className={"header_content spacing_title"}>
                                    <div className={"header_title"}>
                                        <span className={"title"}>{t('card.content.project.polls.title')}</span>
                                        <span
                                            className={"description"}>{t('card.content.project.polls.description')}</span>
                                    </div>
                                </div>
                                <div className={"header_actions"}>
                                    {isSending ? (
                                        <Button type={"primary"}
                                                text={<CircularProgress size={21}/>}
                                        />
                                    ) : (
                                        <Button type={"primary"}
                                                onClick={(e) => this.updatePoll(e)}
                                                text={t(`card.content.project.inactivity.button_save`)}
                                        />
                                    )}
                                </div>
                            </div>
                            <div className={"content"}>
                                <div className={`elements__main`}>
                                    <div className={"elements__picker"}>
                                        <div>
                                            <div>
                                                <span>{t(`card.content.project.polls.switches.active`)}</span>
                                            </div>
                                            <Switch checked={polls.active} onClick={(e) => {
                                                this.updateElement(e, 'active')
                                            }} color="primary"/>
                                        </div>
                                    </div>
                                    <Divider/>
                                    <div>
                                        <div className={`elements__grid`}>
                                            <div className={`elements__title`}>
                                                <span>{t(`card.content.project.polls.switches.title`)}</span>
                                                <span>{t(`card.content.project.polls.switches.description`)}</span>
                                            </div>
                                            {/* send_inactivity_bot */}
                                            <div className={`elements__switch`}>
                                                <span>{t(`card.content.project.polls.switches.send_inactivity_bot`)}</span>
                                                <Switch checked={polls.send_inactivity_bot} onClick={(e) => {
                                                    this.updateElement(e, 'send_inactivity_bot')
                                                }}/>
                                            </div>
                                            {/* send_close_desk */}
                                            <div className={`elements__switch`}>
                                                <span>{t(`card.content.project.polls.switches.send_close_desk`)}</span>
                                                <Switch checked={polls.send_close_desk} onClick={(e) => {
                                                    this.updateElement(e, 'send_close_desk')
                                                }}/>
                                            </div>
                                            {/* detect_inactivity */}
                                            <div className={`elements__switch`}>
                                                <span>{t(`card.content.project.polls.switches.detect_inactivity`)}</span>
                                                <Switch checked={polls.detect_inactivity} onClick={(e) => {
                                                    this.updateElement(e, 'detect_inactivity')
                                                }}/>
                                            </div>
                                        </div>
                                    </div>
                                    <Divider/>
                                    <div>
                                        <div className={`elements__grid`}>
                                            <div className={`elements__title`}>
                                                <span>{t(`card.content.project.polls.default_lang.title`)}</span>
                                                <span>{t(`card.content.project.polls.default_lang.description`)}</span>
                                            </div>
                                            {/* default_lang */}
                                            {allCodes &&
                                            <div className={"elements_codes"}>
                                                <Select
                                                    strSelected={t(`languages:languages.${polls.default_lang}`)}
                                                    placeholder={t(`card.content.project.polls.default_lang.select_placeholder`)}
                                                    array={allCodes}
                                                    onSelect={this.selectDefaultLang}
                                                    id={'code-language'}
                                                    type={'code_language'}
                                                    t={t}
                                                />
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <Divider/>
                                    <div>
                                        <div className={`elements__grid`}>
                                            <div className={`elements__title`}>
                                                <span>{t(`card.content.project.polls.messages.title`)}</span>
                                                <span>{t(`card.content.project.polls.messages.description`)}</span>
                                            </div>
                                            {/* codes */}
                                            {codes &&
                                            <div className={"elements_codes"}>
                                                <Select
                                                    strSelected={t(`card.content.project.polls.messages.select_placeholder`)}
                                                    placeholder={t(`card.content.project.polls.messages.select_placeholder`)}
                                                    array={codes}
                                                    onSelect={this.selectCode}
                                                    id={'code-language'}
                                                    type={'code_language'}
                                                    t={t}
                                                />
                                            </div>}
                                            {/* languages */}
                                            {
                                                codes && polls && polls.messages.length > 0 && (
                                                    polls.messages.map((item, i) => {
                                                        return (
                                                            <div key={i} className={"elements__accordion"}>
                                                                <AccordionPolls i={i} defaultLang={polls.default_lang}
                                                                                updateElement={this.updateElementMessage}
                                                                                onDelete={this.deleteMessage}
                                                                                handleArtifacts={this.handleArtifacts}
                                                                                item={JSON.parse(JSON.stringify(item))}/>
                                                            </div>
                                                        )
                                                    })
                                                )
                                            }
                                            {
                                                codes && polls && polls.messages.length === 0 && (
                                                    t(`card.content.project.polls.messages.no_results`)
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div className={"cen_card"}>
                            <div className={"content"}>
                                <div className="loading">
                                    <CircularProgress color="primary" size={50}/>
                                </div>
                            </div>
                        </div>
                )
                : <CardEmpty
                    data={this.props.data}
                    t={this.props.t}
                    type={'polls'}
                    handleClickLink={this.addPoll}
                    open={false}
                />
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_chatbot_polls = connect(
    mapStateToProps
)(ChatbotPolls);

export default withTranslation(['common', 'languages'])(withRouter(connect_chatbot_polls));