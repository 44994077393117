// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Styles
import 'date-fns';
// Translations
import {withTranslation} from "react-i18next";
import enLocale from "date-fns/locale/en-US";
import esLocale from 'date-fns/locale/es';
import ptLocale from 'date-fns/locale/pt';
// Infrastructure
import {StatsAPI} from "views/Bots/Stats/infrastructure";
// Vendor
import {showNotify} from 'vendor/application/disptach';
// Material
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Alert from "@material-ui/lab/Alert";
// Components
import {PopUp} from '@components/Modals';
import {Button} from "@components/Input";
import {Divider} from "@material-ui/core";
import {AlertInfo} from "@components/Alert";
import WhatsappSessions from 'views/Bots/Stats/application/StatsConsumption/WhatsappSessions';
// Styles
import './styles.scss';
// Svg
import {channelsSvg, deploySvg, svgCalendar, svgClock} from 'assets';
// Date
import moment from 'moment';

const localeMap = {
    en: enLocale,
    es: esLocale,
    pt: ptLocale
};

class StatsConsumption extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            dateRange: {},
            start: false,
            end: false,
            objDateRange: {
                startDate: new Date(date.setMonth(date.getMonth() - 4)),
                endDate: new Date(date.setMonth(date.getMonth() + 4))
            },
            array: false,
            open: false,
            whatsapp_sessions: []
        }

    }

    componentDidMount() {
        this.getStats();
    }

    // method that reloads the component when the current project has been changed
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.actualProject.id !== this.props.data.actualProject.id) {
            setTimeout(() => {
                this.getStats();
            }, 100);
        }
    }

    getStats = async () => {
        const {access, actualProject, plan} = this.props.data;
        const {type} = this.props;
        try {
            let obj = new StatsAPI(actualProject.id, access, this.props);
            let array = await obj.get_stats_consumption_filter(this.getStartDate(true), this.getEndDate(true), type, type === 'projects' ? actualProject.id : plan.id);
            this.setState({array: array.reverse()});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    getStatsWhatsapp = async (month, total) => {
        const {access, actualProject, plan} = this.props.data;
        const {type} = this.props;

        if (total && total > 0) {
            try {
                let obj = new StatsAPI(actualProject.id, access, this.props);
                let array = await obj.get_stats_whatsapp(month, type, type === 'projects' ? actualProject.id : plan.id);
                var new_array = {month: "", details: []},
                    date = moment().format("YYYY-MM-DD");

                array[0].values?.forEach((element => {
                    if (date !== element.day) {
                        new_array.details = new_array.details.concat(element?.detail);
                    }
                }))

                var handle_date = month.split("-"),
                    new_date = `${handle_date[0]}-${handle_date[1]}`

                new_array.details.forEach((detail => {
                    var date = detail.initiated_at.split(" "),
                        time = date[1],
                        day = date[0].split('-')[2];

                    detail.day = day;
                    detail.time = time;

                }))

                new_array.month = new_date;
                new_array.total = total;

                this.setState({open: true, whatsapp_sessions: new_array});
            } catch (err) {
            }
        }
    }

    handleClose = () => {
        this.setState({open: false});
    }


    // update objDateRange start
    handleSelectStart = (start) => {
        var end = this.state.objDateRange.endDate;
        if (start > end) {
            end = start;
        }
        this.setState({
            objDateRange: {
                startDate: start,
                endDate: end
            }
        })
    }

    // update initalDateRange end
    handleSelectEnd = (end) => {
        var start = this.state.objDateRange.startDate;
        if (end < start) {
            start = end;
        }
        this.setState({
            objDateRange: {
                startDate: start,
                endDate: end
            }
        })
    }

    getStartDate = (send) => {
        const {objDateRange} = this.state;
        var m = objDateRange.startDate.getMonth(),
            y = objDateRange.startDate.getFullYear()

        if (send) {
            return `${y}-${m + 1}-1`;
        }
        return `${m + 1} / ${y}`;
    }

    getEndDate = (send) => {
        const {objDateRange} = this.state;
        var m = objDateRange.endDate.getMonth(),
            y = objDateRange.endDate.getFullYear();

        if (send) {
            return `${y}-${m + 1}-1`;
        }
        return `${m + 1} / ${y}`;
    }

    openStart = () => {
        this.setState({start: !this.state.start});
    }

    openEnd = () => {
        this.setState({end: !this.state.end});
    }

    getDateTable = (month) => {
        var split = month.split("-"),
            year = split[0],
            month = split[1];

        return `${month}/${year}`
    }

    handleConversationsTotal = (whatsapp, whatsapp_sessions, total) => {
        var res = (total ?? 0) - (whatsapp ?? 0) + (whatsapp_sessions ?? 0)
        return res <= 0 ? 0 : new Intl.NumberFormat('de-DE').format(res);
    }

    render() {
        const {plan} = this.props.data;
        const {t, type} = this.props;
        const {
            objDateRange,
            start,
            end,
            array,
            whatsapp_sessions
        } = this.state;

        const isSunco = plan.plan.name === 'consulting_sunco';
        return (
            <div className="stats-container">
                <AlertInfo className={"app_bar"} text={t('stats.info_2')}/>
                <Divider/>
                <div className="stats-header">
                    <div className="stats-date-container">
                        <div className="stats-date">
                            <MuiPickersUtilsProvider utils={DateFnsUtils}
                                                     locale={localeMap[this.props.i18n.language]}>
                                <div className="start-date" onClick={() => this.openStart()}>
                                    <div>
                                        <span>{svgCalendar()}</span>
                                        <span>{t('stats.start')}</span>
                                        <span>{deploySvg(false)}</span>
                                    </div>
                                    <div>
                                        <span>{svgClock()}</span>
                                        <span>{this.getStartDate()}</span>
                                    </div>
                                </div>
                                <DatePicker
                                    value={objDateRange.startDate}
                                    onChange={this.handleSelectStart}
                                    animateYearScrolling
                                    views={["year", "month"]}
                                    openTo={"month"}
                                    autoOk
                                    onClose={this.openStart}
                                    open={start}
                                />
                                <div className="end-date" onClick={() => this.openEnd()}>
                                    <div>
                                        <span>{svgCalendar()}</span>
                                        <span>{t('stats.end')}</span>
                                        <span>{deploySvg(false)}</span>
                                    </div>
                                    <div>
                                        <span>{svgClock()}</span>
                                        <span>{this.getEndDate()}</span>
                                    </div>
                                </div>
                                <DatePicker
                                    value={objDateRange.endDate}
                                    onChange={this.handleSelectEnd}
                                    animateYearScrolling
                                    views={["year", "month"]}
                                    openTo={"month"}
                                    autoOk
                                    onClose={this.openEnd}
                                    open={end}
                                />
                            </MuiPickersUtilsProvider>
                        </div>
                    </div>
                    <div className="actions">
                        <Button type={"primary"}
                                text={t('stats.consumption.action_button')}
                                onClick={(e) => this.getStats(e)}/>
                    </div>
                </div>
                <div className={"stats-content"}>
                    {
                        array && (
                            array?.length > 0 ?
                                <table className={`mau_table ${isSunco ? 'mau_sunco' : ''}`}>
                                    <thead>
                                    <tr>
                                        <th>
                                            <div>
                                                <span
                                                    className={"table_title"}>{t('stats.consumption.elements.date')}</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span
                                                    className={"table_title"}>{t('stats.consumption.elements.consumption')}</span>
                                            </div>
                                        </th>
                                        <th>
                                            <div>
                                                <span className={"table_title"}
                                                      dangerouslySetInnerHTML={{__html: t(`stats.consumption.elements.${!isSunco ? 'conversations' : 'conversations_sunco'}`)}}/>
                                            </div>
                                        </th>
                                        {
                                            !isSunco &&
                                            <th>
                                                <div>
                                                <span className={"table_title"}
                                                      dangerouslySetInnerHTML={{__html: t('stats.consumption.elements.whatsapp_sessions')}}/>
                                                </div>
                                            </th>
                                        }
                                        <th>
                                            <div>
                                                <span className={"table_title"} dangerouslySetInnerHTML={{__html: t(`stats.consumption.elements.${!isSunco ? 'push_conversations' : 'push_conversations_sunco'}`)}}/>
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"table_calendar"}>
                                            <div>
                                                {svgCalendar()}
                                                <span>{t('stats.consumption.elements.month')}</span>
                                            </div>
                                        </td>
                                        <td className={`table_channels ${!isSunco ? "borders" : ''}`}>
                                            <div className={"maus"}>
                                                {
                                                    !isSunco &&
                                                    <>
                                                        <div>{channelsSvg("chat_web")}</div>
                                                        <div>{channelsSvg("whatsapp")}</div>
                                                        <div>{channelsSvg("telegram")}</div>
                                                        <div>{channelsSvg("messenger")}</div>
                                                        <div>{channelsSvg("instagram")}</div>
                                                    </>
                                                }
                                                <div className={"total"}>Total</div>
                                            </div>
                                        </td>
                                        <td className={`table_channels ${!isSunco ? "borderRight" : ''}`}>
                                            <div className={"sessions"}>
                                                {
                                                    !isSunco &&
                                                    <>
                                                        <div>{channelsSvg("chat_web")}</div>
                                                        <div>{channelsSvg("whatsapp")}</div>
                                                        <div>{channelsSvg("telegram")}</div>
                                                        <div>{channelsSvg("messenger")}</div>
                                                        <div>{channelsSvg("instagram")}</div>
                                                    </>
                                                }
                                                <div className={"total"}>Total</div>
                                            </div>
                                        </td>
                                        {
                                            !isSunco &&
                                            <td className={"table_channels borderRight"}>
                                                <div className={"alone"}>
                                                    <div className={"total"}>Total</div>
                                                </div>
                                            </td>
                                        }
                                        <td className={"table_channels"}>
                                            <div className={"alone"}>
                                                <div className={"total"}>Total</div>
                                            </div>
                                        </td>
                                    </tr>
                                    {array.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td className={"table_month"}>
                                                    <span>{this.getDateTable(item.month)}</span>
                                                </td>
                                                <td className={"table_channels values border"}>
                                                    <div className={"maus"}>
                                                        {
                                                            !isSunco &&
                                                            <>
                                                                <div>{new Intl.NumberFormat('de-DE').format(item.maus?.total_web ?? 0)}</div>
                                                                <div>{new Intl.NumberFormat('de-DE').format(item.maus?.total_whatsapp ?? 0)}</div>
                                                                <div>{new Intl.NumberFormat('de-DE').format(item.maus?.total_telegram ?? 0)}</div>
                                                                <div>{new Intl.NumberFormat('de-DE').format(item.maus?.total_messenger ?? 0)}</div>
                                                                <div>{new Intl.NumberFormat('de-DE').format(item.maus?.total_instagram ?? 0)}</div>
                                                            </>
                                                        }
                                                        <div
                                                            className={"total"}>{new Intl.NumberFormat('de-DE').format(!isSunco ? item.maus?.total ?? 0 : item.maus?.total_sunco ?? 0)}</div>
                                                    </div>
                                                </td>
                                                <td className={"table_channels values"}>
                                                    <div className={"sessions"}>
                                                        {
                                                            !isSunco &&
                                                            <>
                                                                <div>{new Intl.NumberFormat('de-DE').format(item.sessions?.total_web ?? 0)}</div>
                                                                <div>{new Intl.NumberFormat('de-DE').format(item.whatsapp_sessions?.total ?? 0)}</div>
                                                                <div>{new Intl.NumberFormat('de-DE').format(item.sessions?.total_telegram ?? 0)}</div>
                                                                <div>{new Intl.NumberFormat('de-DE').format(item.sessions?.total_messenger ?? 0)}</div>
                                                                <div>{new Intl.NumberFormat('de-DE').format(item.sessions?.total_instagram ?? 0)}</div>
                                                            </>
                                                        }
                                                        <div
                                                            className={"total"}>{!isSunco ? this.handleConversationsTotal(item.sessions?.total_whatsapp, item.whatsapp_sessions?.total, item.sessions?.total) : item.sessions?.total_sunco ?? 0}</div>
                                                    </div>
                                                </td>
                                                {
                                                    !isSunco &&
                                                    <td className={"table_channels values"}>
                                                        <div className={"alone"}>
                                                            <div
                                                                onClick={() => this.getStatsWhatsapp(item.month, item.whatsapp_sessions?.total)}
                                                                className={`total ${item.whatsapp_sessions?.total > 0 && type === "projects" ? 'pointer' : ''}`}>{new Intl.NumberFormat('de-DE').format(item.whatsapp_sessions?.total ?? 0)}</div>
                                                        </div>
                                                    </td>
                                                }
                                                <td className={"table_channels values"}>
                                                    <div className={"alone"}>
                                                        <div className={"total"}>{!isSunco ? item.push_notifications?.total ?? 0 : item.sunco_notifications?.total ?? 0}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                                :
                                <Alert style={{marginTop: 20}} severity="info">
                                    {t('stats.consumption.no_data')}
                                </Alert>)
                    }
                </div>
                <PopUp
                    open={this.state.open}
                    handleClose={this.handleClose}
                    content={<WhatsappSessions whatsapp_sessions={whatsapp_sessions}/>}
                    t={t}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_stats_conversations = connect(
    mapStateToProps
)(StatsConsumption);

export default withTranslation(['common', 'commons'])(withRouter(connect_stats_conversations));