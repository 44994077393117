// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
import enLocale from "date-fns/locale/en-US";
import esLocale from 'date-fns/locale/es';
import ptLocale from 'date-fns/locale/pt';
// Styles
import 'views/Campaigns/styles/index.scss';
// Date
import DateFnsUtils from "@date-io/date-fns";
import 'date-fns';
// Vendor
import {showNotify} from "vendor/application/disptach";
// Helpers
import {getWhatsappPhone} from "@helpers";
// Components
import Table from '../../../components/Table';
import {CampaignsAPI} from "views/Campaigns/infrastructure";
import {SelectSimple} from "@components/Input";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
// Amplitude API
import {svgCalendar} from "assets";

const localeMap = {
    en: enLocale,
    es: esLocale,
    pt: ptLocale
};

class IndividualList extends Component {
    constructor(props) {
        super(props);
        var date = new Date();
        this.state = {
            list: false,
            page: 0,
            max: 0,
            send_date: {
                startDate: new Date(date.setMonth(date.getMonth() - 4)),
                endDate: new Date(date.setMonth(date.getMonth() + 4))
            },
            open_start: false,
            open_end: false,
            languages: false,
            is_loading: true,
            whatsapp_phone: false,
            templates: false
        }
    }

    componentDidMount = () => {
        this.handleProjectID();
    }

    handleProjectID = (e) => {
        const {whatsapp_phone} = this.state;
        const {plan} = this.props.data;
        var __whatsapp_phone;

        if (e && e.project_id !== whatsapp_phone?.project_id) {
            __whatsapp_phone = e;
        } else if (!e) {
            __whatsapp_phone = plan?.whatsapp_phones[0];
        }

        this.getTemplates(__whatsapp_phone.project_id);
        this.getLanguages(__whatsapp_phone.project_id);
        this.setState({whatsapp_phone: __whatsapp_phone});
    }

    getTemplates = async (project_id) => {
        const {access} = this.props.data;
        try {
            let obj = new CampaignsAPI(project_id, access, this.props);
            let res = await obj.get_templates();

            this.setState({templates: res});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getLanguages = async (project_id) => {
        const {access} = this.props.data;
        try {
            let obj = new CampaignsAPI(false, access, this.props);
            let res = await obj.get_languages();

            this.getIndividualNotifications(project_id);

            this.setState({languages: res});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getIndividualNotifications = async (project_id, next_page, date) => {
        const {access} = this.props.data;
        const {send_date} = this.state;
        try {
            var filters = {
                start: this.getDateFormat(date ? date.startDate : send_date.startDate),
                end: this.getDateFormat(date ? date.endDate : send_date.endDate),
                page: next_page ?? 1
            }
            let obj = new CampaignsAPI(project_id, access, this.props);
            let res = await obj.get_individual_notifications(filters);

            var operation = res?.count / 10,
                is_decimal = (operation - Math.floor(operation)) !== 0,
                sum = is_decimal ? 1 : 0;

            this.setState({list: res?.notifications, page: next_page ?? 1, max: Math.floor(operation) + sum, is_loading: false})
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getDateFormat = (date) => {
        var m = date.getMonth(),
            y = date.getFullYear(),
            d = date.getDate();

        return `${y}-${m + 1}-${d}`;
    }

    handleSelectStart = (start) => {
        const {whatsapp_phone, send_date} = this.state;
        var end = send_date.endDate;
        if (start > end) {
            end = start;
        }

        this.setState({
            send_date: {
                startDate: start,
                endDate: end
            }
        });

        this.getIndividualNotifications(whatsapp_phone.project_id, 1, {
            startDate: start,
            endDate: end
        });
    }

    handleSelectEnd = (end) => {
        const {whatsapp_phone, send_date} = this.state;
        var start = send_date.startDate;
        if (end < start) {
            start = end;
        }

        this.setState({
            send_date: {
                startDate: start,
                endDate: end
            }
        });

        this.getIndividualNotifications(whatsapp_phone.project_id, 1, {
            startDate: start,
            endDate: end
        });
    }

    getDate = (type, send) => {
        const {send_date} = this.state;
        var m = send_date[type].getMonth(),
            y = send_date[type].getFullYear()

        if (send) {
            return `${y}-${m + 1}-1`;
        }
        return `${m + 1} / ${y}`;
    }

    handlePagination = (e, next_page) => {
        const {whatsapp_phone} = this.state;
        this.getIndividualNotifications(whatsapp_phone.project_id, next_page);
        this.setState({page: next_page});
    }

    openDatePicker = (type) => {
        this.setState({[type]: !this.state[type]});
    }

    render() {
        const {t} = this.props;
        const {actualLayout, plan} = this.props.data;
        const {
            list,
            send_date,
            open_start,
            open_end,
            templates,
            whatsapp_phone,
            max,
            is_loading,
            languages,
            page
        } = this.state;
        return (
            <React.Fragment>
                <div className="centribal_card">
                    <div className={"campaigns_header"}>
                        <div className={"campaigns_header_title"}>
                            <span
                                className={"title"}>{t(`common:card.content.${actualLayout.layout}.individual.header_title`)}</span>
                        </div>
                        <div className={"campaigns_header_actions"}>
                            {
                                whatsapp_phone?.phone &&
                                <SelectSimple
                                    label={t(`common:card.content.${actualLayout.layout}.individual.filters.whatsapp_phone`)}
                                    valueSelected={getWhatsappPhone(whatsapp_phone)}
                                    items={plan?.whatsapp_phones}
                                    onSelect={(e) => this.handleProjectID(e)}
                                    type={"whatsapp_phone"}
                                    className={"select_secondary templates_whatsapp_phones"}
                                    value={"phone"}
                                    t={t}
                                />
                            }
                            <MuiPickersUtilsProvider utils={DateFnsUtils}
                                                     locale={localeMap[this.props.i18n.language]}>
                                <div className="platform_date_picker_groups"
                                     onClick={() => this.openDatePicker("open_start")}>
                                    <span>{t(`common:card.content.${actualLayout.layout}.individual.filters.send_date_start`)}</span>
                                    <div>
                                        <span>{this.getDate("startDate")}</span>
                                        <span>{svgCalendar()}</span>
                                    </div>
                                </div>
                                <DatePicker
                                    value={send_date.startDate}
                                    onChange={this.handleSelectStart}
                                    animateYearScrolling
                                    className={"date_picker_groups"}
                                    views={["year", "month"]}
                                    openTo={"month"}
                                    autoOk
                                    onClose={() => this.openDatePicker("open_start")}
                                    open={open_start}
                                />
                                <div className="platform_date_picker_groups"
                                     onClick={() => this.openDatePicker("open_end")}>
                                    <span>{t(`common:card.content.${actualLayout.layout}.individual.filters.send_date_end`)}</span>
                                    <div>
                                        <span>{this.getDate("endDate")}</span>
                                        <span>{svgCalendar()}</span>
                                    </div>
                                </div>
                                <DatePicker
                                    value={send_date.endDate}
                                    onChange={this.handleSelectEnd}
                                    animateYearScrolling
                                    className={"date_picker_groups"}
                                    views={["year", "month"]}
                                    openTo={"month"}
                                    autoOk
                                    onClose={() => this.openDatePicker("open_end")}
                                    open={open_end}
                                />
                            </MuiPickersUtilsProvider>

                        </div>
                    </div>
                    <div className={"campaigns_container_elements campaigns_container_elements_alone "}>
                        <div className={"campaigns_container_elements_content"}>
                            <Table conditional={list && templates && languages}
                                   items={list}
                                   templates={templates}
                                   languages={languages}
                                   type={"history.individual"}
                                   handlePagination={this.handlePagination}
                                   page={page}
                                   max={max}
                                   is_loading={is_loading}
                                   normal={true}
                                   header={['template_name', 'phone', 'language', 'processed_at', 'created_at']}/>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const campaigns_list = connect(
    mapStateToProps
)(IndividualList);

export default withTranslation(['commons', 'common'])(withRouter(campaigns_list));