// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Infrastructure
import {ProjectAPI} from 'api/application/Projects';
// Material UI
import {CircularProgress, Divider} from '@material-ui/core';
// Application
import {ElementsHandleAPI} from 'vendor/application/handleMethods';
// Vendor
import {getProjectBody, userLangObject} from 'vendor/application';
import {showNotify, updateActualProject, updateProjects} from 'vendor/application/disptach';
// Components
import {Button, Input} from "@components/Input";
import Select from 'components/Input/select';
// Translations
import {withTranslation} from "react-i18next";
// Amplitude API
import {AmplitudeAPI} from '@api/Amplitude';
// Assets
import {arrowRightSvg} from "@assets";

class ChatbotCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: getProjectBody(),
            error: '',
            bots: false,
            selectedBot: ''
        }
    }

    handleRedirect = () => {
        const {history} = this.props;
        history.push(`/dashboard`);
    }

    // update element state
    updateElement(e, type, i) {
        const {project} = this.state;
        const obj = new ElementsHandleAPI(project, e.target.value, type, i);
        const res = obj.updateObj();
        this.setState({project: res});
    }

    onSelect = (e) => {
        const {project} = this.state;
        project.lang = e;
        this.getPredefinedBot(project);
    }

    // create project and update projects
    onCreate = async (e) => {
        e.preventDefault();
        const {access, projects, user, device_id} = this.props.data;
        const {history} = this.props;
        var empty = '';
        Object.keys(this.state.project).forEach(el => {
            if (!this.state.project[el]) {
                if (!empty && el !== 'template_id') {
                    empty = el;
                }
            }
        });

        if (!empty) {
            try {
                const obj = new ProjectAPI(null, access, this.props, this.state.project);
                var res = await obj.post_project();
                var array = projects;
                var resSend = res;
                array.push(resSend);

                new AmplitudeAPI({
                    event_type: projects?.length === 0 ? 'Create first chatbot' : 'Create new chatbot',
                    device_id: device_id,
                    user_id: user?.email
                }).eventLog();

                this.props.dispatch(updateActualProject(resSend));
                this.props.dispatch(updateProjects(array));
                this.props.dispatch(showNotify({message: "project", type: "create", severity: "success"}));
                setTimeout(() => {
                    history.push(`/${resSend?.id}/chatbot`);
                }, 500);
            } catch (err) {
                this.props.dispatch(showNotify({message: err, severity: 'error'}));
            }
        } else {
            this.props.dispatch(showNotify({message: `${empty}_null`, severity: 'error'}));
            this.setState({error: empty});
        }

    }

    // Methods for templates
    onSelectBot = (e) => {
        const {bots, project} = this.state;
        var element = bots.filter(x => x.id === e);
        if (element.length > 0) {
            this.setState({selectedBot: element[0]});
        }
        project.template_id = e;
        this.setState({project: project});
    }

    getPredefinedBot = async (project) => {
        const {access} = this.props.data;
        try {
            const obj = new ProjectAPI(this.props.project_id, access, this.props, null);
            const res = await obj.predefined_bot(project.lang);
            res.push({
                active: true,
                id: null,
                name: "-"
            });
            project.template_id = null;
            this.setState({
                project: project,
                bots: res,
                selectedBot: {
                    active: true,
                    id: null,
                    name: "-"
                }
            });
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    render() {
        const {project, error, bots, selectedBot} = this.state;
        const {isSending} = this.props.data;
        const {t} = this.props;
        return (
            project &&
            <div className={"cen_card"}>
                <div className={"header"}>
                    <div className={"header_content"}>
                        <div onClick={() => this.handleRedirect()} className={"header_action"}>
                            {arrowRightSvg()}
                        </div>
                        <div className={"header_title"}>
                            <span className={"title"}>{t('card.content.project.general.title')}</span>
                            <span className={"description"}>{t('card.content.project.general.description')}</span>
                        </div>
                    </div>
                    <div className={"header_actions"}>
                        {isSending ? (
                            <Button type={"primary"} text={<CircularProgress size={21}/>}/>
                        ) : (
                            <Button onClick={(e) => {
                                this.onCreate(e)
                            }} type={"primary"} text={t('project.create.button_create')}/>
                        )}
                    </div>
                </div>
                <div className={"content"}>
                    <div className={"elements__main"}>
                        <div>
                            <div>
                                <div>
                                    <span>{t(`card.content.project.general.name.title`)}</span>
                                    <span>{t(`card.content.project.general.name.description`)}</span>
                                </div>
                                <Input error={error === 'name'}
                                       onChange={(e) => {
                                           this.updateElement(e, 'name')
                                       }} value={project.name} type={'primary'}
                                       placeholder={t(`card.content.project.general.name.placeholder`)}/>
                            </div>
                        </div>
                        <Divider/>
                        <div>
                            <div>
                                <div>
                                    <span>{t(`card.content.project.general.p_description.title`)}</span>
                                    <span>{t(`card.content.project.general.p_description.description`)}</span>
                                </div>
                                <Input error={error === 'description'} value={project.description} type={'primary'}
                                       minRows={3} multiline
                                       placeholder={t(`card.content.project.general.p_description.placeholder`)}
                                       onChange={(e) => this.updateElement(e, 'description')}/>
                            </div>
                        </div>
                        <Divider/>
                        <div>
                            <div>
                                <div>
                                    <span>{t(`card.content.project.general.language.title`)}</span>
                                    <span>{t(`card.content.project.general.language.description`)}</span>
                                </div>
                                <Select
                                    strSelected={project.lang ? project.lang : 'select'}
                                    error={error === 'lang' ? true : false} project
                                    array={userLangObject()}
                                    onSelect={this.onSelect}
                                    placeholder={t(`select.language_text`)}
                                    id={'language-project'}
                                    type={'lang'}
                                    t={t}
                                />

                            </div>
                        </div>
                        <Divider/>
                        {/* TEMPLATES */}
                        <div className={!this.state.project.lang || !bots || bots?.length <= 1 ? 'opacity_5' : ''}>
                            <div>
                                <div>
                                    <span>{t(`card.content.project.actions.template.select.title`)}</span>
                                    <span>{t(`card.content.project.actions.template.select.description`)}</span>
                                </div>
                                <Select
                                    strSelected={selectedBot?.name ?? ''}
                                    array={bots}
                                    onSelect={this.onSelectBot}
                                    placeholder={t(`card.content.project.actions.template.select.placeholder`)}
                                    value={'id'}
                                    id={'select-bot'}
                                    disabled={!this.state.project.lang || bots?.length <= 1}
                                    t={t}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_chatbot_create = connect(
    mapStateToProps
)(ChatbotCreate);

export default withTranslation('common')(withRouter(connect_chatbot_create));