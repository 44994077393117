// React
import React, {Component} from 'react';

export class Sandbox extends Component {
    render() {
        const {sandbox_id, onClick} = this.props;
        return (
            <div
                onClick={onClick}
                className={`dashboard_element sandbox ${sandbox_id ? 'active' : ''}`}>
                <span>{"SB"}</span>
            </div>
        );
    }
}
