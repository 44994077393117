// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Translations
import {withTranslation} from "react-i18next";
import { esES, enUS, ptBR } from '@mui/x-date-pickers/locales';
// Routing
import {withRouter} from "react-router-dom";
// Infrastructure
import {BusinessAPI} from 'views/Bots/ChatBot/application/Tabs/BusinessHours/infrastructure';
// Material UI
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {Switch, InputBase, IconButton, FormGroup, FormControlLabel} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// Vendor
import {showNotify} from 'vendor/application/disptach';
// Date
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/en-gb';
// Assets
import {saveSvg, deleteSvg} from 'assets';

class Festivities extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: false,
            festivities: '',
            festivity: {
                "day": new Date(),
                "name": "",
                "every_year": false
            },
            isCreating: false
        }
    }

    componentDidMount = () => {
        this.getFestivities();
    }

    getFestivities = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new BusinessAPI(actualProject.id, access, this.props);
            const festivities = await obj.get_festivities();

            this.setState({festivities: festivities});
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data));
        }
    }

    postFestivity = async (e) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {festivity} = this.state;
        try {
            const obj = new BusinessAPI(actualProject.id, access, this.props, festivity);
            await obj.post_festivities();

            this.cancelCreate();
            this.getFestivities();
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data));
        }
    }

    putFestivity = async (e, i) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {festivities} = this.state;
        try {
            const obj = new BusinessAPI(actualProject.id, access, this.props, festivities[i]);
            await obj.put_festivities();
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data));
        }
    }

    deleteFestivity = async (e, i) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {festivities} = this.state;
        try {
            const obj = new BusinessAPI(actualProject.id, access, this.props, festivities[i]);
            await obj.delete_festivities();

            this.getFestivities();
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data));
        }
    }

    updateFestivity = (e, type, i, mode) => {
        e.preventDefault();
        switch (mode) {
            case 'edit':
                switch (type) {
                    case 'every_year':
                        this.state.festivities[i].day.every_year = !this.state.festivities[i].day.every_year;
                        break;
                    case 'name':
                        this.state.festivities[i].day.name = e.target.value;
                        break;
                }
                break;
            case 'create':
                switch (type) {
                    case 'every_year':
                        this.state.festivity.every_year = !this.state.festivity.every_year;
                        break;
                    case 'name':
                        this.state.festivity.name = e.target.value;
                        break;
                }
                break;
        }
        this.setState({festivities: this.state.festivities, festivity: this.state.festivity});
    }

    updateDate = (new_date) => {
        this.state.festivity.day = this.getDate(new Date(moment(new_date)));

        this.setState({festivity: this.state.festivity});
    }

    getDate = (date) => {
        var m = date.getMonth(),
            y = date.getFullYear(),
            d = date.getDate();

        return `${y}-${m < 9 ? `0${(m+1)}` : (m+1)}-${d < 10 ? `0${d}` : d}`;
    }

    handleCreate = () => {
        const {festivity} = this.state;

        return !(festivity.day && festivity.name);
    }

    isCreating = () => {
        this.setState({isCreating: true});
        this.updateDate(new Date());
    }

    cancelCreate = () => {
        this.setState({
            isCreating: false, festivity: {
                "day": new Date(),
                "name": "",
                "every_year": false
            }
        });
    }

    getLocate = (lang) => {
        if (!lang || lang === 'en') {
            return 'en-gb'
        } return lang;
    }

    render() {
        const {festivities, festivity, isCreating} = this.state;
        const {t, i18n} = this.props;
        return (
            <div>
                {festivities && (
                    <div className={"festivities_content"}>
                        {festivities.map((festivity_item, index) => (
                            <div className={"festivity_element"} key={index}>
                                <FormGroup className="festivity_switch" aria-label="position" row>
                                    <FormControlLabel
                                        control={<Switch
                                            onClick={(e) => this.updateFestivity(e, 'every_year', index, 'edit')}
                                            checked={festivity_item.day.every_year}/>}
                                        label={t(`business_hours.tab_02.card_toggle`)}
                                        labelPlacement="start"
                                    />
                                </FormGroup>
                                <div className={"content"}>
                                    <InputBase onChange={(e) => this.updateFestivity(e, 'name', index, 'edit')}
                                               inputProps={{'aria-label': 'naked'}} value={festivity_item.day.name}
                                               type={'primary'}
                                               placeholder={t(`business_hours.tab_02.card_placeholder`)}/>
                                    <span>{festivity_item.day.day.replaceAll('-', '/')}</span>
                                </div>
                                <div className="actions">
                                    <IconButton onClick={(e) => this.deleteFestivity(e, index)}
                                                className="button_delete" style={{background: 'transparent'}}
                                                disableRipple disableFocusRipple>
                                        {deleteSvg()}
                                    </IconButton>
                                    <IconButton onClick={(e) => this.putFestivity(e, index)} className="button_save"
                                                style={{background: 'transparent'}} disableRipple disableFocusRipple>
                                        {saveSvg()}
                                    </IconButton>
                                </div>
                            </div>
                        ))}
                        {
                            isCreating ? (
                                <div className={"festivity_element"}>
                                    <FormGroup className="festivity_switch" aria-label="position" row>
                                        <FormControlLabel
                                            control={<Switch
                                                onClick={(e) => this.updateFestivity(e, 'every_year', 0, 'create')}
                                                checked={festivity.every_year}/>}
                                            label={t(`business_hours.tab_02.card_toggle`)}
                                            labelPlacement="start"
                                        />
                                    </FormGroup>
                                    <div className={"content"}>
                                        <InputBase onChange={(e) => this.updateFestivity(e, 'name', 0, 'create')}
                                                   inputProps={{'aria-label': 'naked'}} value={festivity.name}
                                                   type={'primary'}
                                                   placeholder={t(`business_hours.tab_02.card_placeholder`)}/>
                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={this.getLocate(i18n?.language)}>
                                            <DatePicker
                                                format="YYYY-MM-DD"
                                                defaultValue={moment(festivity.day)}
                                                onChange={(date) => this.updateDate(date)}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="actions">
                                        <IconButton onClick={() => this.cancelCreate()} className="button_save"
                                                    style={{background: 'transparent'}} disableRipple
                                                    disableFocusRipple>
                                            <ClearIcon/>
                                        </IconButton>
                                        <IconButton disabled={this.handleCreate()}
                                                    onClick={(e) => this.postFestivity(e)} className="button_save"
                                                    style={{background: 'transparent'}} disableRipple
                                                    disableFocusRipple>
                                            {saveSvg()}
                                        </IconButton>
                                    </div>
                                </div>
                            ) : (
                                <div onClick={() => this.isCreating()} className={"festivity_new_element"}>
                                    {t(`business_hours.tab_02.card_new`)}
                                </div>
                            )
                        }
                    </div>
                )
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_ = connect(
    mapStateToProps
)(Festivities);

export default withTranslation('common')(withRouter(connect_));