// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Translations
import {withTranslation} from "react-i18next";
// Styles
import 'views/Campaigns/styles/index.scss';
// Vendor
import {showNotify} from "vendor/application/disptach";
// Components
import {CampaignsAPI} from "views/Campaigns/infrastructure";
import {Button, Input} from "@components/Input";
import {ElementsCustom} from "@components/Elements";
import Preview from '../../components/Preview';
import {CircularProgress, Divider, Tooltip} from '@material-ui/core';
// Assets
import {campaignsSvg} from "assets";
// Amplitude API
import {AmplitudeAPI} from '@api/Amplitude';
import {getTemplateBody} from "vendor/application";

class Templates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            template: getTemplateBody(),
            whatsapp_phone: false,
            categories: false,
            languages: false,
            params: [],
            centripush_limits: false
        }
    }

    componentDidMount = () => {
        this.getCategories();
        this.getLanguages();
        this.getCentripushLimits();
    }

    getCategories = async () => {
        const {access, plan} = this.props.data;
        try {
            let obj = new CampaignsAPI(false, access, this.props);
            let res = await obj.get_categories();

            this.setState({categories: res, whatsapp_phone: plan?.whatsapp_phones[0]});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getLanguages = async () => {
        const {access} = this.props.data;
        try {
            let obj = new CampaignsAPI(false, access, this.props);
            let res = await obj.get_languages();

            this.setState({languages: res});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getCentripushLimits = async () => {
        const {access, user} = this.props.data;
        try {
            let obj = new CampaignsAPI(user.account_id, access, this.props);
            let res = await obj.get_centripush_limits();

            this.setState({centripush_limits: res});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    updateElement = (e, type, sub_type, i) => {
        const {template, whatsapp_phone, params} = this.state;
        var new_whatsapp_phone = whatsapp_phone,
            str = e?.target?.value;

        switch (type) {
            case 'name':
                if (!(/\s/).test(str)) {
                    template[type] = str.toLowerCase();
                }
                break;
            case 'description':
                if (str?.match(/^(?!\s)[\w\s]{0,256}$/g)) {
                    template[type] = str;
                }
                break;
            case 'body':
                var new_body = this.handleBodyStr(str);
                if (str && new_body || !str) {
                    template[type] = new_body;
                    this.handleBodyExamples(new_body)
                } else {
                    this.props.dispatch(showNotify({
                        message: "campaigns_templates_consecutive_numbers",
                        severity: 'error'
                    }))
                }
                break;
            case 'sample_body':
                params[i].value = str;
                this.setState({params})
                break;
            case 'category':
                template[type] = e.tag;
                break;
            case 'language':
                template[type] = e.code;
                break;
            case 'whatsapp_phone':
                new_whatsapp_phone = e;
                break;
            case 'header':
                if (e === 'null') {
                    template[type] = null
                } else if (e === 'text') {
                    template[type] = {
                        type: e,
                        text: ''
                    }
                } else {
                    template[type] = {
                        type: e,
                        url: ''
                    }
                }
                break;
            case 'header_type':
                if (sub_type === "text") {
                    if (str.length <= 60) {
                        template.header.text = str;
                    }
                } else {
                    template.header.url = str;
                }
                break;
            case 'buttons':
                if (e === 'null') {
                    template[type] = null;
                } else {
                    template[type] = {
                        type: e === 'null' ? null : e,
                        buttons: e === 'call_to_action' ? [
                            {
                                "text": "",
                                "action": "phone_number",
                                "phone_number": "+"
                            }
                        ] : [{text: ""}]
                    }
                }
                break;
            case 'footer':
                if (str?.length <= 60) {
                    template[type] = str;
                }
                break;
            case 'quick_reply':
                switch (sub_type) {
                    case 'update':
                        if (e.target.value.length < 25) {
                            template.buttons.buttons[i].text = str;
                        }
                        break;
                    case 'add':
                        template.buttons.buttons.push({text: ""});
                        break;
                    case 'delete':
                        template.buttons.buttons.splice(i, 1);
                        break;
                }
                break;
            case 'phone_number':
                switch (sub_type) {
                    case 'add':
                        if (e === 'website' && template.buttons.buttons.length < 2) {
                            template.buttons.buttons.push({
                                text: "",
                                action: "website",
                                url: ""
                            });
                        }
                        break;
                    case 'update_text':
                        template.buttons.buttons[i].text = str;
                        break;
                    case 'update_action':
                        var regex = /^\+[0-9]{0,23}$/gm;
                        if (str?.match(regex)) {
                            template.buttons.buttons[i].phone_number = str;
                        }
                        break;
                    case 'delete':
                        template.buttons.buttons.splice(i, 1);
                        break;
                }
                break;
            case 'website':
                switch (sub_type) {
                    case 'add':
                        if (e === 'phone_number' && template.buttons.buttons.length < 2) {
                            template.buttons.buttons.push({
                                text: "",
                                action: "phone_number",
                                phone_number: "+"
                            });
                        }
                        break;
                    case 'update_text':
                        template.buttons.buttons[i].text = str;
                        break;
                    case 'update_action':
                        template.buttons.buttons[i].url = e.target.value;
                        break;
                    case 'delete':
                        template.buttons.buttons.splice(i, 1);
                        break;
                }
                break;
        }
        this.setState({template: template, whatsapp_phone: new_whatsapp_phone});
    }

    createTemplate = async () => {
        const {access, user, device_id} = this.props.data;
        const {template, whatsapp_phone, params} = this.state;

        var __template = this.handleComponentsBody(template, params);
        try {
            let obj = new CampaignsAPI(whatsapp_phone.project_id, access, this.props, __template);
            await obj.post_template();

            new AmplitudeAPI({
                event_type: 'Click on request template',
                device_id: device_id,
                user_id: user.email
            }).eventLog();

            this.props.dispatch(showNotify({message: "templates", type: "create", severity: "success"}));
            this.returnToList();
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    handleComponentsBody = (template, params) => {
        if (params.length > 0) {
            template.components = [
                {
                    type: "body",
                    example: {
                        body_text: []
                    }
                }
            ]

            params.forEach(param => {
                template.components[0].example.body_text.push(param.value);
            })
        }

        return template;
    }

    returnToList = () => {
        const {history} = this.props;
        history.push('/campaigns');
    }

    handleDisable = () => {
        const {template, whatsapp_phone} = this.state;
        var disabled = false;

        Object.keys(template).forEach(el => {
            if (!template[el] && !["buttons", "header", "footer", "description", "components"].includes(el)) {
                disabled = true;
            }
        });

        if (!whatsapp_phone) {
            disabled = true;
        }

        return disabled;
    }

    handleBodyStr = (body) => {
        const regex = /{{(\d{1,})}}/gm,
            regex_addParam = /{{\s|{{$/gm,
            regex_empty = /{{}}/gm,
            regex_delete = /{{(\d{1,})}$/gm;

        let m;
        var params = [];
        var max_num = 1,
            min_num = 0;

        body = body.replace(regex_delete, "");

        while ((m = regex.exec(body)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            var match = m[1],
                number = parseInt(match) ? parseInt(match) : "";

            min_num = !min_num ? number : number < min_num ? number : min_num;
            max_num = !max_num ? number : number > max_num ? number : max_num;

            params.push({index: m[0], match, number: number});
        }

        params?.sort(this.compareIndexFound);

        if (params.length > 0 && min_num !== 1) {
            var abs = Math.abs(1 - min_num);
            params.forEach((param, i) => {
                body = body.replaceAll(param.index, `{{${param.number - abs}}}`);
                params[i].number = param.number - abs;
            })
        }

        var is_consecutive = true;

        if (params.length > 0) {
            is_consecutive = this.isConsecutive(params);
        }

        var max_number = Math.max(...params.map(p => p.number));

        body = body.replace(regex_addParam, `{{${params.length === 0 ? 1 : max_number + 1}}}`);
        body = body.replace(regex_empty, `{{${params.length === 0 ? 1 : max_number + 1}}}`);

        if (is_consecutive) {
            return body;
        } return false;

    }

    handleBodyExamples = (body) => {
        const regex = /{{(\d{1,})}}/gm;

        let m;
        var params = [];
        while ((m = regex.exec(body)) !== null) {
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            var exist = params.find(param => param.match === m[0]);

            if (!exist) {
                params.push({
                    match: m[0], number: m[0], value: ""
                })
            }

        }

        params?.sort(this.compareIndexFound);

        this.setState({params: params})
    }

    isConsecutive = (obj) => {
        return Boolean(obj.reduce((prev, next) => (prev ?
            (prev.number === next.number || prev.number + 1 === next.number ? next : false)
            : false)));
    }

    compareIndexFound = (a, b) => {
        if (a.number < b.number) {
            return -1;
        }
        if (a.number > b.number) {
            return 1;
        }
        return 0;
    }

    render() {
        const {categories, languages, template, centripush_limits, whatsapp_phone, params} = this.state;
        const {t} = this.props;
        const {actualLayout, plan, isSending} = this.props.data;
        return (
            <React.Fragment>
                <div className="centribal_card">
                    <div className={"campaigns_header"}>
                        <div className={"campaigns_header_title"}>
                            <span
                                className={"title"}>{t(`common:card.content.${actualLayout.layout}.header_title`)}</span>
                            <span className={"subtitle"}
                                  dangerouslySetInnerHTML={{__html: t(`common:card.content.${actualLayout.layout}.header_create_subtitle`)}}/>
                        </div>
                        <div className={"campaigns_header_actions"}>
                            <Button type={"secondary"}
                                    text={t(`common:card.content.${actualLayout.layout}.button_return`)}
                                    onClick={() => this.returnToList()}/>
                            {isSending ? (
                                <Button type={"primary"} disabled text={<CircularProgress size={21}/>}/>
                            ) : (
                                centripush_limits && centripush_limits.limits.templates.total >= centripush_limits.limits.templates.max ?
                                    <Tooltip
                                        title={t(`common:card.content.${actualLayout.layout}.max_templates.${plan.plan.name}`)}>
                                    <span className={"platform_btn platform_btn__primary disabled"}>
                                        {t(`common:card.content.${actualLayout.layout}.button_create`)}
                                    </span>
                                    </Tooltip> :
                                    <Button type={"primary"}
                                            text={t(`common:card.content.${actualLayout.layout}.button_create`)}
                                            onClick={() => this.createTemplate()} disabled={this.handleDisable()}/>

                            )}

                        </div>
                    </div>
                    <div className={"campaigns_container_elements campaigns_container_elements_custom"}>
                        <div className={"campaigns_container_elements_content"}>
                            {/* NAME */}
                            <ElementsCustom
                                t={t}
                                type={"templates"}
                                input_type={"input"}
                                element_type={"name"}
                                element={template.name}
                                updateElement={this.updateElement}
                            />
                            <Divider/>
                            {/* DESCRIPTION */}
                            <ElementsCustom
                                t={t}
                                type={"templates"}
                                input_type={"input"}
                                element_type={"description"}
                                multiline={true}
                                element={template.description}
                                optional={true}
                                updateElement={this.updateElement}
                            />
                            <Divider/>
                            {/* LANGUAGES */}
                            {
                                languages &&
                                <ElementsCustom
                                    t={t}
                                    type={"templates"}
                                    input_type={"select"}
                                    element_type={"language"}
                                    select_value={"language"}
                                    element={languages?.filter((language => language.code === template.language))[0]?.language}
                                    elements={languages}
                                    updateElement={this.updateElement}
                                />
                            }
                            <Divider/>
                            {/* WHATSAPP PHONE */}
                            <div className={"elements_custom column"}>
                                <div className={"elements_custom__info"}>
                                    <span
                                        className={"elements_custom__title"}>{t(`elements.custom.templates.card.whatsapp_phone.title`)} </span>
                                    <span
                                        className={"elements_custom__description"}>{t(`elements.custom.templates.card.whatsapp_phone.description`)}</span>
                                </div>
                                {
                                    plan?.whatsapp_phones &&
                                    <div className={"elements_custom__selection"}>
                                        {plan?.whatsapp_phones.map((phone, i) => {
                                            return (
                                                <div
                                                    className={`${phone.phone === whatsapp_phone.phone ? "active" : ""}`}
                                                    onClick={() => this.updateElement(phone, "whatsapp_phone")}
                                                    key={i}>{phone.phone} - {phone.project_name}</div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                            <Divider/>
                            {/* CATEGORY */}
                            <div className={"elements_custom column"}>
                                <div className={"elements_custom__info"}>
                                    <span
                                        className={"elements_custom__title"}>{t(`elements.custom.templates.card.category.title`)} </span>
                                    <span
                                        className={"elements_custom__description"}>{t(`elements.custom.templates.card.category.description`)}</span>
                                </div>
                                {
                                    categories &&
                                    <div className={"elements_custom__selection"}>
                                        {categories.map((category, i) => {
                                            return (
                                                <div className={`${category.tag === template.category ? "active" : ""}`}
                                                     onClick={() => this.updateElement(category, "category")}
                                                     key={i}>{category.name}</div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                            <Divider/>
                            {/* HEADER */}
                            <div className={"elements_custom column"}>
                                <div className={"elements_custom__info"}>
                                    <span
                                        className={"elements_custom__title"}>{t(`elements.custom.templates.card.header.title`)}
                                        <div className={"elements_custom__optional"}>Optional</div> </span>
                                    <span
                                        className={"elements_custom__description"}>{t(`elements.custom.templates.card.header.description`)}</span>
                                </div>
                                <div className={"elements_custom__header"}>
                                    <div className={"elements_custom__selection_images"}>
                                        {
                                            ["null", "text", "document", "image"].map((element, i) => {
                                                return (
                                                    <div
                                                        className={`${!template?.header && element === "null" || element === template?.header?.type ? "active" : ""}`}
                                                        onClick={() => this.updateElement(element, "header")} key={i}>
                                                        <span className={"checkbox"}/>
                                                        <span className={"image"}>{campaignsSvg(element)}</span>
                                                        <span
                                                            className={"text"}>{t(`elements.custom.templates.card.header.conditional.${element}.selector`)}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        template?.header?.type === "text" &&
                                        <Input
                                            placeholder={t(`elements.custom.templates.card.header.conditional.${template.header.type}.placeholder`)}
                                            value={template?.header?.text}
                                            onChange={(e) => this.updateElement(e, "header_type", template?.header?.type)}
                                        />
                                    }
                                    {
                                        (template?.header?.type === "document" || template?.header?.type === "image") &&
                                        <Input
                                            placeholder={t(`elements.custom.templates.card.header.conditional.${template.header.type}.placeholder`)}
                                            value={template?.header?.url}
                                            onChange={(e) => this.updateElement(e, "header_type", template?.header?.type)}
                                        />
                                    }
                                </div>
                            </div>
                            <Divider/>
                            {/* BODY */}
                            <div className={"elements_custom column"}>
                                <div className={"elements_custom__info"}>
                                    <span
                                        className={"elements_custom__title"}>{t(`elements.custom.templates.card.body.title`)} </span>
                                    <span
                                        className={"elements_custom__description"}>{t(`elements.custom.templates.card.body.description`)}</span>
                                </div>
                                <div className={"elements_custom__content"}>
                                    <Input
                                        placeholder={t(`elements.custom.templates.card.body.placeholder`)}
                                        multiline
                                        minRows={5}
                                        value={template.body}
                                        inputProps={{maxLength: 1024}}
                                        onChange={(e) => this.updateElement(e, "body")}
                                    />
                                    <span
                                        className={"elements_custom__hint"}>{t(`elements.custom.templates.card.body.hint`)}: {template.body.length ?? 0}/1024</span>
                                    {
                                        params.length > 0 &&
                                            <div className={"campaigns_body_samples"}>
                                                <div className={"elements_custom__info"}>
                                                    <span className={"elements_custom__title"}>{t(`elements.custom.templates.card.body.samples.title`)}</span>
                                                    <span className={"elements_custom__description"}>{t(`elements.custom.templates.card.body.samples.description`)}</span>
                                                </div>
                                                {params.map((param, i) => {
                                                    return <div className={"campaigns_body_samples_element"} key={i}>
                                                        <span>{param.match}</span>
                                                        <Input
                                                            placeholder={t(`elements.custom.templates.card.body.samples.params.placeholder`)}
                                                            value={param.value}
                                                            onChange={(e) => this.updateElement(e, "sample_body", param.value, i)}
                                                        />
                                                    </div>
                                                })}
                                            </div>
                                    }
                                </div>
                            </div>
                            <Divider/>
                            {/* FOOTER */}
                            <ElementsCustom
                                t={t}
                                type={"templates"}
                                input_type={"input"}
                                element_type={"footer"}
                                multiline={true}
                                element={template.footer}
                                updateElement={this.updateElement}
                                optional={true}
                            />
                            <Divider/>
                            {/* BUTTONS */}
                            <ElementsCustom
                                t={t}
                                type={"templates"}
                                input_type={"select_with_conditional"}
                                element_type={"buttons"}
                                select_value={""}
                                element={template.buttons?.type}
                                elements={["quick_reply", "call_to_action", "null"]}
                                conditional={
                                    {
                                        type: "select_with_conditionals",
                                        element_conditional: template.buttons?.type !== null,
                                        elements: ["phone_number", "website"],
                                        element_type: "buttons_type",
                                        element_actions: template.buttons?.buttons,
                                        element_actions_conditional: template.buttons?.type === 'call_to_action' ? template.buttons?.buttons.length <= 1 : template.buttons?.buttons.length >= 3,
                                        disabled: template.buttons?.buttons.length >= 2
                                    }
                                }
                                optional={true}
                                updateElement={this.updateElement}
                            />
                        </div>
                        <Preview params={params} template_selected={JSON.parse(JSON.stringify(template))}/>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const templates = connect(
    mapStateToProps
)(Templates);

export default withTranslation(['commons', 'common'])(withRouter(templates));