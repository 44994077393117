// React
import { connect } from 'react-redux';
import React, { Component } from 'react';
// Routing
import { withRouter } from 'react-router-dom';
// Material
import { withTranslation } from "react-i18next";
// Styles
import 'views/Root/application/Routes/TestChat/styles/index.scss';
import {Helmet} from "react-helmet";

class ChatWebView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            project_id: '',
            chat_id: '',
            no_credentials: false
        }
    }

    componentDidMount() {
        this.handleData();

    }

    handleData = () => {
        var queryString = window.location.search,
            urlParams = new URLSearchParams(queryString),
            project_id = urlParams.get('project_id'),
            chat_id = urlParams.get('chat_id');

        if (project_id && chat_id ) {
            this.setState({project_id: project_id, chat_id: chat_id});
        } else {
            this.setState({no_credentials: true});
        }

    }

    render() {
        const {project_id, chat_id, no_credentials} = this.state;
        return (
            <React.Fragment>
                <div
                    className={`chat-view-content`}
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/centribal_chat.png)`
                    }}
                >
                </div>
                <Helmet>
                    {
                        project_id && chat_id &&
                            <script id="bim-snippet" src={`${process.env.REACT_APP_URL_CHAT}/app/chat?key=${project_id}.${chat_id}`} type="text/javascript"></script>
                    }
                    {
                        no_credentials &&
                            <div>
                                No hay credenciales válidas para abrir un chat
                            </div>
                    }
                </Helmet>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_chat_web = connect(
    mapStateToProps
)(ChatWebView);

export default withTranslation('common')(withRouter(connect_chat_web));
