import { CustomLogin } from 'vendor/infrastructure/custom_requests/login';
import { updateSending, updateDeleting, updateInitApp } from 'vendor/application/disptach';
import $ from 'jquery';

export class CustomRequest {
    constructor(method, url, body, token, props, new_version) {
        this.base_url = new_version ? process.env.REACT_APP_URL_BACK_V2 : process.env.REACT_APP_URL_BACK;
        this.method = method;
        this.url = url;
        this.body = body;
        this.token = token;
        this.props = props;
        this.count = 0;

    }

    async make_request(process) {
        try {
            return { response : await $.ajax({
                url: `${this.base_url}${this.url}`,
                type: this.method,
                data: this.body,
                processData: process ? false : true,
                contentType: (process ? false : "application/json"),
                enctype: (process ? "multipart/form-data" : false),
                headers: {
                    "Authorization": `Bearer ${this.token.access}`,
                }
            }) , status : 200}
        } catch(error) {
            return { response : error , status : error.status };
        }
    }

    async action(process) {
        const res = await this.make_request(process);
        this.count++;
        if ( this.count > 3 ) {
            throw 'too_many_access_requests'
        } else {
            switch ( res.status ) {
                case ( 200 ) : case ( 201 ) :
                    return res.response;
                case ( 401 ) :
                    let request = new CustomLogin({refresh : this.token.refresh}, 'token/refresh', this.props);
                    var req = await request.make_request_refresh();
                    this.token = req;
                    return this.action();
                case ( 400 ) : case ( 404 ) :
                    this.props.dispatch(updateSending(false));
                    this.props.dispatch(updateDeleting(false));
                    throw res.response;
                case ( 500 ) :
                    this.props.dispatch(updateSending(false));
                    this.props.dispatch(updateDeleting(false));
                    this.props.dispatch(updateInitApp(true));
                    throw res.response;
                default :
                    this.props.dispatch(updateSending(false));
                    this.props.dispatch(updateDeleting(false));
                    throw res.response;
            }
        }
    }
}