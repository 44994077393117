// React
import React, {Component} from 'react';
// Translations
import {withTranslation} from "react-i18next";
//Components
import Dropzone from "react-dropzone";
import {sendSvg, clipSvg} from "assets";

class ElementIcon extends Component {

    handleIconRender = (element) => {
        const {updateIcon, updateCustomization, img, icon_type, value} = this.props;
        switch (element) {
            case 'send':
                return (
                    <div>
                        {sendSvg(value)}
                    </div>
                )
            case 'attachment_clip':
                return (
                    <div>
                        {clipSvg()}
                    </div>
                )
            default:
                return (
                    <Dropzone onDrop={(e) => updateIcon(e, icon_type)}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                <img
                                    src={img}
                                    title="Icon"
                                    htmlFor="raised-button-file-chat"
                                />
                            </div>
                        )}
                    </Dropzone>)
        }
    }

    render() {
        const {t, updateCustomization, element, value} = this.props;
        return (
            <div className={"element__icons"}>
                {this.handleIconRender(element)}
                {
                    element !== "send" && element !== "attachment_clip" &&
                        <span>{t('card.content.project.web_interface.customization.icon')}</span>
                }
                {
                    element === "send" &&
                    <div className={"element__icon_selector"}>
                        <span
                            className={`${value === 1 ? 'active' : ''}`}
                            onClick={(e) => {
                                updateCustomization(e, 'svg', 'input_icon', 1)
                            }}>
                            {sendSvg(1)}
                        </span>
                        <span
                            className={`${value === 2 ? 'active' : ''}`}
                            onClick={(e) => {
                                updateCustomization(e, 'svg', 'input_icon', 2)
                            }}>
                                    {sendSvg(2)}
                                </span>
                        <span
                            className={`${value === 3 ? 'active' : ''}`}
                            onClick={(e) => {
                                updateCustomization(e, 'svg', 'input_icon', 3)
                            }}>
                                    {sendSvg(3)}
                                </span>
                        <span
                            className={`${value === 4 ? 'active' : ''}`}
                            onClick={(e) => {
                                updateCustomization(e, 'svg', 'input_icon', 4)
                            }}>
                                    {sendSvg(4)}
                                </span>
                        <span
                            className={`${value === 5 ? 'active' : ''}`}
                            onClick={(e) => {
                                updateCustomization(e, 'svg', 'input_icon', 5)
                            }}>
                                    {sendSvg(5)}
                                </span>
                    </div>
                }
            </div>
        );
    }
}

export default withTranslation('common')(ElementIcon);