// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Views
import Business from 'views/Bots/ChatBot/application/Tabs/BusinessHours/application/days';
import Festivities from 'views/Bots/ChatBot/application/Tabs/BusinessHours/application/festivities';
// Infrastructure
import {IntegrationsAPI} from '../../../../../../../api/application/Integrations';
import {BusinessAPI} from 'views/Bots/ChatBot/application/Tabs/BusinessHours/infrastructure';
import {ProjectAPI} from "../../../../../../../api/application/Projects";
// Material
import {AppBar, Button as ButtonMaterial, CircularProgress, Divider, Switch, Tab, Tabs, Tooltip} from '@material-ui/core';
import Alert from "@material-ui/lab/Alert";
import {withStyles} from '@material-ui/core/styles';
// Translations
import {withTranslation} from "react-i18next";
// Vendor
import {addArtifact, hoursMessagesBody} from 'vendor/application';
import {showNotify} from 'vendor/application/disptach';
// Styles
import '../styles/index.scss';
// Components
import Select from "components/Input/select";
import {Input, Button} from "@components/Input";
import FloatButtons from "components/Input/float_buttons";
import {TabPanel} from "components/Navigation/tab_panel";
// Assets
import {closeSvg, restartSvg} from "assets";

const HtmlTooltip = withStyles(() => ({
    tooltip: {
        backgroundColor: '#EBEBEB',
        color: '#878787',
        maxWidth: 250,
        padding: 20,
        borderRadius: 25,
        fontWeight: 300,
        fontSize: 12
    },
}))(Tooltip);

class BusinessHours extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: false,
            lang_codes: [],
            all_lang_codes: [],
            hours: ''
        };
    }

    // method that reloads the component when the current project has been changed
    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.actualProject.id !== this.props.data.actualProject.id) {
            setTimeout(() => {
                this.getIntegrations();
            }, 100);
        }
    }

    componentDidMount = () => {
        this.getIntegrations();
    }

    getIntegrations = async () => {
        const {access, actualProject} = this.props.data;
        const {history} = this.props;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            const integrations = await obj.get_integrations();

            var exist = false;
            integrations.forEach(integration => {
                if (integration.channel === 'zendesk_full' || integration.channel === 'centridesk' || integration.channel === 'zendesk_chat' || integration.channel === 'sunco') {
                    exist = true;
                }
            });
            if (exist) {
                this.handleTabs();
                this.getHours();
            } else {
                this.props.dispatch(showNotify({message: 'business_no_access', severity: 'error'}));
                history.push(`/${actualProject.id}/chatbot`);
            }
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    getCodes = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new ProjectAPI(actualProject.id, access, this.props);
            const codes = await obj.get_codes();

            this.setState({lang_codes: codes, all_lang_codes: JSON.parse(JSON.stringify(codes))});

            this.handleCodes();
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    getHours = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new BusinessAPI(actualProject.id, access, this.props);
            const hours = await obj.get_hours();
            this.getCodes();

            this.setState({hours: hours});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    handleCodes = () => {
        const {hours, lang_codes} = this.state;

        hours.messages.forEach((message) => {
            lang_codes.forEach((lang, i) => {
                if (message.lang === lang) {
                    lang_codes.splice(i, 1);
                    i--;
                }
            })
        });

        this.setState({lang_codes: lang_codes});
    }

    putHours = async () => {
        const {access, actualProject} = this.props.data;
        const {hours} = this.state;
        try {
            const obj = new BusinessAPI(actualProject.id, access, this.props, hours);
            await obj.put_hours();

            this.props.dispatch(showNotify({message: "business_hours", type: "edit", severity: "success"}));
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    updateElement(e, variable, type, i) {
        e.preventDefault();
        const {hours} = this.state;

        if (type && type !== 'boolean') {
            hours[variable][i][type] = e.target.value;
        } else if (type === 'boolean') {
            hours[variable] = !hours[variable];
        } else {
            hours[variable] = e.target.value;
        }

        this.setState({hours: hours});
    }

    handleTabs = () => {
        const {location, history} = this.props;
        var tab = location.search.split('&to=');
        switch (tab[1]) {
            case 'business':
                this.setState({value: 0});
                break;
            case 'festivities':
                this.setState({value: 1});
                break;
            default:
                history.push(`${this.props.location.pathname}?tab=business_hours&to=business`)
                this.setState({value: 0});
                break;
        }
    }

    a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    selectDefaultLang = (e) => {
        const {hours} = this.state;
        hours.default_lang = e;

        this.setState({hours: hours});
        this.addMessage(e);
    }

    addMessage = (e) => {
        const {hours, lang_codes} = this.state;

        var a = false;
        hours.messages.forEach((el) => {
            if (el.lang === e) {
                a = true;
            }
        });

        if (!a) {
            hours.messages.push(hoursMessagesBody(e));
            lang_codes.forEach((el, i) => {
                if (el === e) {
                    lang_codes.splice(i, 1);
                }
            });

            this.setState({hours: hours, lang_codes: lang_codes});
        }
    }

    deleteMessage = (e, lang, i) => {
        const {hours, lang_codes} = this.state;

        lang_codes.push(lang);
        hours.messages.splice(i, 1);

        this.setState({hours: hours, lang_codes: lang_codes});
    }


    setQuery = (e, to) => {
        e.preventDefault();
        this.props.history.push(`${this.props.location.pathname}?tab=business_hours&to=${to}`);
        setTimeout(() => {
            this.handleTabs();
        }, 10);
    }

    handleArtifacts = (e, i, artifact_type, type) => {
        const {hours} = this.state;

        var res = addArtifact(i, artifact_type, type, hours);

        this.setState({hours: res});
    }

    render() {
        const {value, hours, all_lang_codes, lang_codes} = this.state;
        const {t} = this.props;
        const {isSending} = this.props.data;

        return (
            hours ? (
                <React.Fragment>
                    <div className={"cen_card"}>
                        <div className={"header"}>
                            <div className={"header_content"}>
                                <div className={"header_title"}>
                                    <span
                                        className={"title"}>{t('business_hours.title')}</span>
                                    <span
                                        className={"description"}>{t('business_hours.description')}</span>
                                </div>
                            </div>
                            <div className={"header_actions"}>
                                {isSending ? (
                                    <Button type={"primary"}
                                            text={<CircularProgress size={21}/>}
                                    />
                                ) : (
                                    <Button type={"primary"}
                                            onClick={() => this.putHours()}
                                            text={t(`business_hours.button_save`)}
                                    />
                                )}
                            </div>
                        </div>
                        <div className={"content business_form"}>
                            <div className="business_element business_grid">
                                <div>
                                    <span>{t(`business_hours.default_lang.title`)}</span>
                                    <span>{t(`business_hours.default_lang.description`)}</span>
                                </div>
                                {all_lang_codes &&
                                <Select
                                    strSelected={t(`languages:languages.${hours.default_lang}`)}
                                    placeholder={t(`business_hours.default_lang.select_placeholder`)}
                                    array={all_lang_codes}
                                    onSelect={this.selectDefaultLang}
                                    id={'code-language'}
                                    type={'code_language'}
                                    t={t}
                                />}
                            </div>
                            <Divider/>
                            <div className="business_element business_grid">
                                <div>
                                    <span>{t(`business_hours.messages.title`)}</span>
                                    <span
                                        dangerouslySetInnerHTML={{__html: t(`business_hours.messages.description`)}}></span>
                                </div>
                                <Select
                                    strSelected={t(`business_hours.messages.select_placeholder`)}
                                    placeholder={t(`business_hours.messages.select_placeholder`)}
                                    array={lang_codes}
                                    onSelect={this.addMessage}
                                    id={'code-language'}
                                    type={'code_language'}
                                    t={t}
                                />
                                <div className={"pd-0"}>
                                    <div className={`elements__grid`}>
                                        {
                                            hours && hours.messages && hours.messages.length > 0 ?
                                                hours.messages.map((message, i) => {
                                                    return (
                                                        <div key={i} className={"elements__inactivity"}>
                                                            <div className={"elements__lang"}>{message.lang}</div>
                                                            <Input value={message.message} type={'primary'}
                                                                   multiline
                                                                   placeholder={t(`business_hours.messages.card.placeholder`)}
                                                                   onChange={(e) => this.updateElement(e, 'messages', 'message', i)}/>
                                                            <FloatButtons type={"business"}
                                                                          addArtifact={this.handleArtifacts} iteration={i}
                                                                          buttons_toggle_artifacts={["file", "img", "link", "button", "carousel", "bubble", "for", "reply_button"]}/>
                                                            {
                                                                message.lang !== hours.default_lang &&
                                                                <div className={"elements__close"}
                                                                     onClick={(e) => this.deleteMessage(e, message.lang, i)}>
                                                                    {closeSvg()}
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                }) :
                                                <div className={"elements__alert"}>
                                                    <Alert severity="info">
                                                        <span>{t(`business_hours.messages.no_results`)}</span>
                                                    </Alert>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <Divider/>
                            <div className="business_element business_switch">
                                <div>
                                    <span>{t(`business_hours.active.title`)}</span>
                                    <span>{t(`business_hours.active.description`)}</span>
                                </div>
                                <Switch checked={hours.active} onClick={(e) => {
                                    this.updateElement(e, 'active', 'boolean')
                                }}/>
                            </div>
                        </div>
                        <Divider />
                        <AppBar id={"app_bar"} style={{borderRadius: "0 0 5px 5px", overflow: "hidden"}} position="static" color="default">
                            <Tabs
                                value={value}
                                onChange={this.handleChange}
                                variant="scrollable"
                                scrollButtons="on"
                                textColor="primary"
                            >
                                <Tab label={t(`business_hours.tab_01.title`)} onClick={(e) => {
                                    this.setQuery(e, 'business')
                                }} {...this.a11yProps(0)} />
                                <Tab label={t(`business_hours.tab_02.title`)} onClick={(e) => {
                                    this.setQuery(e, 'festivities')
                                }} {...this.a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                    </div>
                    <TabPanel value={value} index={0}>
                        <Business business_id={hours.business_id} data={this.props}/>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Festivities data={this.props}/>
                    </TabPanel>
                </React.Fragment>

                ) :
                <div className={"cen_card"}>
                    <div className={"content"}>
                        <div className="loading">
                            <CircularProgress color="primary" size={50}/>
                        </div>
                    </div>
                </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const business = connect(
    mapStateToProps
)(BusinessHours);

export default withTranslation('common')(withRouter(business));