// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Translations
import {withTranslation} from "react-i18next";
// Components
import {channelsSvg} from "assets";
import {Switch} from "@material-ui/core";

class IntegrationCard extends Component {
    selectIntegration = (channel) => {
        const {body, updateElement} = this.props;

        if (body.info.story_replies.desks === null) {
            body.info.story_replies.desks = {name: channel};
        } else {
            body.info.story_replies.desks.name = body.info.story_replies.desks.name === channel ? "" : channel;
        }

        if (body.info.story_replies.desks?.name) {
            switch (body.info.story_replies.desks?.name) {
                case 'centridesk':
                    body.info.story_replies.desks.info = {
                        agent_id: "",
                        mode: "desk",
                        ticket_status:"new"
                    }
                    break;
                case 'zendesk_full':
                    body.info.story_replies.desks.info = {
                        ticket_status: "new",
                        mode: "desk"
                    }
                    break;
                default:
                    delete body.info.story_replies.desks?.info;
                    break;
            }
        }

        updateElement(body);
    }

    render() {
        const {integration, body, t} = this.props;
        var desk_name = body.info.story_replies?.desks?.name;

        return (
            <div onClick={() => {this.selectIntegration(integration)}}
                 className={"element__integration"}
            >
                <div className={`element__integration_content`}>
                    <div className={"element__integration_content_img"}>
                        {channelsSvg(integration)}
                    </div>
                    <span>{t(`integrations.integration.${integration}`)}</span>
                </div>
                <Switch checked={desk_name === integration}
                />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const integration_card = connect(
    mapStateToProps
)(IntegrationCard);

export default withTranslation(['common', 'languages'])(integration_card);