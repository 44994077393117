// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Translations
import {withTranslation} from "react-i18next";
// Styles
import 'components/Accordion/styles/styles.scss';
// Components
import {SelectSimple} from "@components/Input";

class ZendeskFull extends Component {
    updateSelectorElement = (e, type) => {
        const {body, updateElement} = this.props;
        switch (type) {
            case 'zendesk_ticket_status':
                body.info.story_replies.desks.info = {
                    ...body.info.story_replies.desks.info,
                    mode: e === "solved" ? "bot" : body.info.story_replies.desks.info.mode,
                    ticket_status: e
                }
                break;
            case 'zendesk_mode':
                body.info.story_replies.desks.info = {
                    ...body.info.story_replies.desks.info,
                    mode: e
                }
                break;
            default:
                break;
        }

        updateElement(body);
    }

    handleSelectorValue = (item, type) => {
        const {t} = this.props;
        var value = item?.[type];
        switch (type) {
            case 'ticket_status':
                if (!value) {
                    return <span className={`status new`}>{t(`elements.custom.status.new`)}</span>;
                } else {
                    return <span className={`status ${value}`}>{t(`elements.custom.status.${value}`)}</span>
                }
            default:
                break;
        }
    }

    render() {
        const {body, t} = this.props;
        return (
            <div className={"info__integration"}>
                <div className={"info__integration_content"}>
                    <SelectSimple
                        valueSelected={this.handleSelectorValue(body.info.story_replies.desks.info, `ticket_status`)}
                        items={["new", "open", "pending", "solved"]}
                        label={t(`common:intents.integrations.zendesk_ticket_status`)}
                        onSelect={(e) => this.updateSelectorElement(e, `zendesk_ticket_status`)}
                        placeholder={t(`common:intents.integrations.zendesk_ticket_status`)}
                        type={"status"}
                        className={"select_secondary"}
                        t={t}
                    />
                    <SelectSimple
                        valueSelected={body.info.story_replies.desks.info.mode}
                        items={["desk", "bot"]}
                        label={t(`common:intents.integrations.mode`)}
                        onSelect={(e) => this.updateSelectorElement(e, `zendesk_mode`)}
                        placeholder={t(`common:intents.integrations.mode`)}
                        disabled={body.info.story_replies.desks.info.zendesk_ticket_status === "solved"}
                        className={"select_secondary"}
                        t={t}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const channel = connect(
    mapStateToProps
)(ZendeskFull);

export default withTranslation(['commons', 'common', 'languages'])(channel);