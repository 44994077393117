// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Translations
import {withTranslation} from "react-i18next";
// Components
import {Divider, Switch} from "@material-ui/core";
import ElementColor from "../element_color";
import ElementIcon from "../element_icon";
// Vendor
import {ElementsHandleAPI} from "vendor/application/handleMethods";
import {addArtifact} from "vendor/application";
import ElementSwitch from "../element_switch";


class Buttons extends Component {

    updateElementConfig = (e, type) => {
        e.preventDefault();
        const {config, updateConfig} = this.props;
        const obj = new ElementsHandleAPI(config, e.target.value, type);
        const res = obj.updateObj('edit');
        updateConfig(res);
    }

    handleArtifacts = (e, i, artifact_type, type) => {
        const {config, updateConfig} = this.props;
        var res = addArtifact(i, artifact_type, type, config);
        updateConfig(res);
    }

    render() {
        const {t, config, updateCustomization, updateConfig} = this.props;
        return (
            <div className={"cen_card"}>
                {/* BUTTONS / LINKS */}
                <div className={"elements_divider"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.buttons.normal.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.buttons.normal.description')}</span>
                        </div>
                        <div className={"elements_divider_container buttons"}>
                            <div className={"elements_divider_colors"}>
                                <div>
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"bg"}
                                        value={config.customization.artifacts_button_bg_color}
                                        value_updated={"artifacts_button_bg_color"}
                                    />
                                    <ElementSwitch
                                        color_1={"artifacts_button_bg_color"}
                                        color_2={"artifacts_button_text_color"}
                                        updateConfig={updateConfig}
                                        config={config}
                                    />
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"text"}
                                        value={config.customization.artifacts_button_text_color}
                                        value_updated={"artifacts_button_text_color"}
                                    />
                                </div>
                                <Divider />
                                <div>
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"bg_hover"}
                                        value={config.customization.artifacts_button_hover_bg_color}
                                        value_updated={"artifacts_button_hover_bg_color"}
                                    />
                                    <ElementSwitch
                                        color_1={"artifacts_button_hover_bg_color"}
                                        color_2={"artifacts_button_hover_text_color"}
                                        updateConfig={updateConfig}
                                        config={config}
                                    />
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"text_hover"}
                                        value={config.customization.artifacts_button_hover_text_color}
                                        value_updated={"artifacts_button_hover_text_color"}
                                    />
                                </div>
                                <Divider />
                                <div>
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"bg_disabled"}
                                        value={config.customization.artifacts_button_disabled_bg_color}
                                        value_updated={"artifacts_button_disabled_bg_color"}
                                    />
                                    <ElementSwitch
                                        color_1={"artifacts_button_disabled_bg_color"}
                                        color_2={"artifacts_button_disabled_text_color"}
                                        updateConfig={updateConfig}
                                        config={config}
                                    />
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"text_disabled"}
                                        value={config.customization.artifacts_button_disabled_text_color}
                                        value_updated={"artifacts_button_disabled_text_color"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.buttons.link.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.buttons.link.description')}</span>
                        </div>
                        <div className={"elements_divider_container buttons"}>
                            <div className={"elements_divider_colors"}>
                                <div>
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"bg"}
                                        value={config.customization.artifacts_link_button_bg_color}
                                        value_updated={"artifacts_link_button_bg_color"}
                                    />
                                    <ElementSwitch
                                        color_1={"artifacts_link_button_bg_color"}
                                        color_2={"artifacts_link_button_text_color"}
                                        updateConfig={updateConfig}
                                        config={config}
                                    />
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"text"}
                                        value={config.customization.artifacts_link_button_text_color}
                                        value_updated={"artifacts_link_button_text_color"}
                                    />
                                </div>
                                <Divider />
                                <div>
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"bg_hover"}
                                        value={config.customization.artifacts_link_button_hover_bg_color}
                                        value_updated={"artifacts_link_button_hover_bg_color"}
                                    />
                                    <ElementSwitch
                                        color_1={"artifacts_link_button_hover_bg_color"}
                                        color_2={"artifacts_link_button_hover_text_color"}
                                        updateConfig={updateConfig}
                                        config={config}
                                    />
                                    <ElementColor
                                        updateCustomization={updateCustomization}
                                        element={"text_hover"}
                                        value={config.customization.artifacts_link_button_hover_text_color}
                                        value_updated={"artifacts_link_button_hover_text_color"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* CHECKBOX */}
                <div className={"content"}>
                    <div className="elements_custom">
                        <div className="elements_custom__info">
                            <span
                                className="elements_custom__title">{t('card.content.project.web_interface.customization.buttons.disable.title')}</span>
                            <span
                                className="elements_custom__description">{t('card.content.project.web_interface.customization.buttons.disable.description')}</span>
                        </div>
                        <Switch checked={config.customization.buttons_disable} onClick={(e) => {
                            updateCustomization(e, 'bool', 'buttons_disable')
                        }}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const a = connect(
    mapStateToProps
)(Buttons);

export default withTranslation('common')(withRouter(a));