// React
import React, { Component } from "react";
import { connect } from "react-redux";
// Translations
import { withTranslation } from "react-i18next";
// Styles
import "components/Accordion/styles/styles.scss";
// Components
import { Divider } from "@material-ui/core";
import { Button, Input, SelectSimple } from "@components/Input";
// Helpers
import { formTypes } from "vendor/application";
import { deleteSvg } from "assets";

class Body extends Component {
  componentDidUpdate = () => {
    const { intent, updateIntent } = this.props;
    if (
      !intent.extensions.body?.type ||
      typeof intent.extensions.body !== "object"
    ) {
      intent.extensions.body = {
        type: "application/json",
        payload: intent.extensions.body,
      };

      updateIntent(intent);
    }
  };
  updateExtension = (e, type) => {
    const { intent, updateIntent } = this.props;
    var str = e?.target?.value ?? e;

    if (
      typeof intent.extensions.body !== "object" ||
      intent.extensions.body === null
    ) {
      intent.extensions.body = {
        type: "application/json",
        payload: intent.extensions.body,
      };
    }

    if (type === "type" && intent.extensions.body[type] !== str) {
      switch (str) {
        case "application/json":
          intent.extensions.body.payload = "{}";
          break;
        case "application/x-www-form-urlencoded":
          intent.extensions.body.payload = [
            {
              key: "",
              value: "",
            },
          ];
          break;

        default:
      }
    }

    intent.extensions.body[type] = str;

    updateIntent(intent);
  };

  updatePayload = (e, type, i) => {
    const { intent, updateIntent } = this.props;
    intent.extensions.body.payload[i][type] = e?.target?.value ?? e;
    updateIntent(intent);
  };

  addPayload = () => {
    const { intent, updateIntent } = this.props;
    intent.extensions.body.payload.push({
      key: "",
      value: "",
    });
    updateIntent(intent);
  };

  deletePayload = (i) => {
    const { intent, updateIntent } = this.props;
    intent.extensions.body.payload.splice(i, 1);
    updateIntent(intent);
  };

  getFormName = (type) => {
    if (!type) {
      return "";
    }
    var get = formTypes().filter((el) => el.value === type);
    return get.length > 0 ? get[0].name : "";
  };

  render() {
    const { intent, t } = this.props;
    return (
      <div className={"accordion__element_extension_body"}>
        <SelectSimple
          valueSelected={this.getFormName(intent.extensions.body?.type)}
          items={formTypes()}
          onSelect={(e) => this.updateExtension(e, "type")}
          placeholder={"Body"}
          type={"form"}
          className={"select_secondary"}
          value={"value"}
          t={t}
        />
        {intent.extensions.body?.type === "application/json" && (
          <Input
            id="extensions_body"
            placeholder={t(
              `intents.config.accordion.extensions.content.body.${intent.extensions.body.type}.placeholder`
            )}
            multiline
            value={
              intent.extensions.body?.payload &&
              Object.keys(intent.extensions.body?.payload).length !== 0
                ? JSON.parse(JSON.stringify(intent.extensions.body?.payload))
                : "{}"
            }
            onChange={(e) => this.updateExtension(e, "payload")}
          />
        )}
        {intent.extensions.body?.type ===
          "application/x-www-form-urlencoded" && (
          <div className={"accordion__element_extension_body_content"}>
            {intent.extensions.body?.payload.length > 0 &&
              intent.extensions.body.payload.map((el, i) => {
                return (
                  <React.Fragment key={i}>
                    <div
                      className={
                        "accordion__element_extension_body_content_payload"
                      }
                    >
                      <Input
                        onChange={(e) => {
                          this.updatePayload(e, "key", i);
                        }}
                        value={el.key ? el.key : ""}
                        placeholder={t(
                          `intents.config.accordion.extensions.content.body.${intent.extensions.body.type}.key`
                        )}
                        className={`secondary`}
                        inputProps={{ maxLength: 4096 }}
                      />
                      <Input
                        onChange={(e) => {
                          this.updatePayload(e, "value", i);
                        }}
                        value={el.value ? el.value : ""}
                        placeholder={t(
                          `intents.config.accordion.extensions.content.body.${intent.extensions.body.type}.value`
                        )}
                        className={`secondary`}
                        inputProps={{ maxLength: 4096 }}
                      />
                      <div
                        className={"platform_input_content_delete"}
                        onClick={(e) => {
                          this.deletePayload(i);
                        }}
                      >
                        {deleteSvg()}
                      </div>
                    </div>
                    {i !== intent.extensions.body.payload.length - 1 && (
                      <Divider />
                    )}
                  </React.Fragment>
                );
              })}
            <Button
              type={"dashed"}
              onClick={(e) => {
                this.addPayload(e);
              }}
              text={t(
                `intents.config.accordion.extensions.content.body.${intent.extensions.body.type}.add`
              )}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state,
  };
};

const body = connect(mapStateToProps)(Body);

export default withTranslation("common")(body);
