// React
import React, {Component} from 'react';
import {CircularProgress} from '@material-ui/core';

export class Loading extends Component {

    render() {
        return (
            <div className={"dashboard_loading"}>
                <CircularProgress color="primary" size={70}/>
            </div>
        );
    }
}
