// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Components
import {Button, Input} from '@components/Input';
import {Divider} from "@material-ui/core";
// Translations
import {withTranslation} from "react-i18next";

class CreateContextModal extends Component {

    returnRender = (type) => {
        const {t, createContext, updateContext, new_context} = this.props;
        switch (type) {
            case 'button':
                return <Button type={"success"} disabled={!new_context.name} onClick={() => createContext()}
                               text={t("card.content.contexts.modal.button")}/>
            case 'content':
            default:
                return (
                <div className={"contexts__popup"}>
                    <div className={"contexts__popup_header"}>
                        <span>{t("card.content.contexts.modal.title")}</span>
                    </div>
                    <Divider/>
                    <div className={"contexts__popup_content"}>
                        <span>{t("card.content.contexts.modal.name.title")}</span>
                        <Input
                            max={50}
                            placeholder={t("card.content.contexts.modal.name.placeholder")}
                            value={new_context.name}
                            helper={t(`card.dialog_create.contexts.name_label`)}
                            onChange={(e) => updateContext(e.target.value, "name")}
                        />
                    </div>
                    <div className={"contexts__popup_content"}>
                        <span>{t("card.content.contexts.modal.description.title")}</span>
                        <Input
                            placeholder={t("card.content.contexts.modal.description.placeholder")}
                            value={new_context.description}
                            onChange={(e) => updateContext(e.target.value, "description")}
                        />
                    </div>
                </div>)
        }
    }

    render() {
        const {type} = this.props;
        return (
            this.returnRender(type)
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_context = connect(
    mapStateToProps
)(CreateContextModal);

export default withTranslation('common')(withRouter(connect_context));