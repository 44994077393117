// React
import React, { Component } from 'react';
import { connect } from 'react-redux';
// Translations
import { withTranslation } from "react-i18next";
// Routing
import { withRouter } from "react-router-dom";
// Infrastructure
import { BusinessAPI } from 'views/Bots/ChatBot/application/Tabs/BusinessHours/infrastructure';
// Vendor
import { weekdaysBody } from 'vendor/application';
// Material
import { Divider, IconButton } from '@material-ui/core';
import {MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AddIcon from '@material-ui/icons/Add';
// SVG
import { saveSvg, deleteSvg } from 'assets';
// Vendor
import { showNotify } from 'vendor/application/disptach';
// Styles
import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
    root: {
        "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
            display: "none"
        }
    }
});

class Days extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: false,
            business: '',
            weekDays: weekdaysBody(),
            isAMPM: false
        };
    }

    componentDidMount = () => {
        if (this.props.business_id) {
            this.getBusiness();
        }

        this.isAMPM();
    }

    isAMPM = () => {
        var userLang = navigator.language || navigator.userLanguage;
        var hourCycle = Intl.DateTimeFormat(userLang,{ hour: 'numeric' }).resolvedOptions().hourCycle;

        if ( hourCycle === 'h12' ) {
            this.setState({isAMPM: true});
        } else {
            this.setState({isAMPM: false});
        }
    }

    componentDidUpdate = (nextProps) => {
        if (nextProps.business_id !== this.props.business_id) {
            this.getBusiness();
        }
    }

    getBusiness = async () => {
        const { access, actualProject } = this.props.data;
        try {
            const obj = new BusinessAPI(actualProject.id, access, this.props);
            const business = await obj.get_business(this.props.business_id);

            this.setState({ business: business.hours });
        } catch (err) {
            var data = { message: err, severity: 'error' };
            this.props.dispatch(showNotify(data));
        }
    }

    addBusiness = (e, key) => {
        e.preventDefault();

        this.state.business[key].push({
            "ini": "00:00",
            "fin": "00:00"
        })

        this.setState({ business: this.state.business });
    }

    putBusiness = async (e, key, index) => {
        e.preventDefault();
        const { access, actualProject } = this.props.data;

        delete this.state.business[key][index].isHovered;
        try {
            const obj = new BusinessAPI(actualProject.id, access, this.props, this.state.business);
            await obj.put_business(this.props.business_id);
        } catch (err) {
            var data = { message: err, severity: 'error' };
            this.props.dispatch(showNotify(data));
        }
    }

    deleteBusiness = async (e, key, index) => {
        e.preventDefault();
        const { access, actualProject } = this.props.data;

        this.state.business[key].splice(index, 1);
        try {
            const obj = new BusinessAPI(actualProject.id, access, this.props, this.state.business);
            await obj.put_business(this.props.business_id);

            this.setState({ business: this.state.business });
        } catch (err) {
            var data = { message: err, severity: 'error' };
            this.props.dispatch(showNotify(data));
        }
    }

    updateElement = (e, type, key, index) => {
        if ( !isNaN(e.getTime()) ) {
            this.state.business[key][index][type] = `${(e.getHours()<10?'0':'') + e.getHours()}:${(e.getMinutes()<10?'0':'') + e.getMinutes()}`;
            this.setState({ business: this.state.business });
        }
    }

    handleMouseEnter = (key, index) => {
        this.state.business[key][index].isHovered = true;
        this.setState({ business: this.state.business });
    };

    handleMouseLeave = (key, index) => {
        delete this.state.business[key][index].isHovered;
        this.setState({ business: this.state.business });
    };

    handleTime = (time) => {
        var new_time = new Date(null), hours, minutes;
        if ( time && time !== 'Invalid Date' ) {
            hours = time.split(":")[0];
            minutes = time.split(":")[1];

            new_time.setHours(hours);
            new_time.setMinutes(minutes);

            return new_time;
        } else if (time === 'Invalid Date') {
            return time;
        } else {
            return new Date();
        }
    }

    render() {
        const { business, weekDays, isAMPM } = this.state;
        const { t } = this.props;

        return (
            <div className="business_content">
                {
                    business && (
                        Object.keys(business).map((key) => {
                            return (
                                <div className="business_element" key={key}>
                                    <span>{t(`business_hours.tab_01.${weekDays[key]}`)}</span>
                                    <div>
                                        {business[key].map((element, index) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    <div onMouseEnter={() => this.handleMouseEnter(key, index)} onMouseLeave={() => this.handleMouseLeave(key, index)}>
                                                        {element.isHovered &&
                                                            <div className="button_actions rubberBand">
                                                                <IconButton onClick={(e) => this.putBusiness(e, key, index)} className="button_save" style={{ background: 'transparent' }} disableRipple disableFocusRipple>
                                                                    {saveSvg()}
                                                                </IconButton>
                                                                <IconButton onClick={(e) => this.deleteBusiness(e, key, index)} className="button_save" style={{ background: 'transparent' }} disableRipple disableFocusRipple>
                                                                    {deleteSvg()}
                                                                </IconButton>
                                                            </div>
                                                        }
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <TimePicker
                                                                id={`${key}-${index}-ini-time`}
                                                                ampm={isAMPM}
                                                                value={this.handleTime(element.ini)}
                                                                onChange={(e) => this.updateElement(e, 'ini', key, index)}
                                                            />
                                                            <TimePicker
                                                                id={`${key}-${index}-fin-time`}
                                                                ampm={isAMPM}
                                                                value={this.handleTime(element.fin)}
                                                                onChange={(e) => this.updateElement(e, 'fin', key, index)}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                    </div>
                                                    <Divider/>
                                                </React.Fragment>
                                            )
                                        })}
                                        <IconButton onClick={(e) => this.addBusiness(e, key)} aria-label="new">
                                            <AddIcon />
                                        </IconButton>
                                    </div>
                                </div>
                            )
                        })
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_ = connect(
    mapStateToProps
)(Days);

export default withRouter(withTranslation('common')(withStyles(useStyles)(connect_)));
