// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Application
import {ElementsHandleAPI} from 'vendor/application/handleMethods';
// Vendor
import {showNotify, updateSending} from 'vendor/application/disptach';
import {countries} from 'vendor/application/countries';
import {
    getSignInBody,
    userLangObject,
    isValidPassword
} from 'vendor/application';
import {handleActualTitle} from '@helpers';
// Components
import {Input, SelectSimple, Button} from "@components/Input";
import PasswordInput from "components/Input/PasswordInput/password_input";
// Infrastructure
import {CustomLogin} from 'vendor/infrastructure/custom_requests/login';
// Translations
import {withTranslation} from "react-i18next";
// Material
import {FormControlLabel, Checkbox, CircularProgress} from '@material-ui/core';
// Jquery
import $ from "jquery";
// Amplitude API
import {AmplitudeAPI} from '@api/Amplitude';

class Signin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            countries: countries(),
            isEnabled: false,
            signin: getSignInBody(),
            lang: userLangObject(),
            finish_signin: false,
            planInit: false,
            error: ''
        }
    }

    componentDidMount = () => {
        new AmplitudeAPI({
            event_type: 'Register show',
            device_id: this.props.data.device_id
        }).eventLog();

        this.handlePaste();
        this.getParams();
    }

    handlePaste = () => {
        $(document).on("cut copy paste", "#signin_repeat_password", function (e) {
            e.preventDefault();
        });
    }

    getParams = () => {
        var obj = this.state.signin;

        const getUrl = window.location.search,
            urlParams = new URLSearchParams(getUrl),
            utm_campaign = urlParams.get('utm_campaign'),
            utm_source = urlParams.get('utm_source'),
            utm_content = urlParams.get('utm_content'),
            utm_medium = urlParams.get('utm_medium'),
            ref = urlParams.get('ref'),
            plan = urlParams.get('plan'),
            reference = urlParams.get('reference'),
            cbsite = urlParams.get('cbsite');

        obj.ref = ref ?? 0;

        if (plan) {
            if (plan === 'free' || plan === 'prof_trial') {
                obj.plan = plan;
                handleActualTitle(this.props.t, `signin_${plan}`);
            } else {
                obj.plan = false;
            }
        } else {
            handleActualTitle(this.props.t, `signin`);
        }

        if (utm_campaign && utm_campaign !== 'null') {
            obj.utm.campaign = utm_campaign;
        }
        if (utm_source && utm_source !== 'null') {
            obj.utm.source = utm_source;
        }
        if (utm_content && utm_content !== 'null') {
            obj.utm.content = utm_content;
        }
        if (utm_medium && utm_medium !== 'null') {
            obj.utm.medium = utm_medium;
        }
        if (reference && reference !== 'null') {
            obj.reference = reference;
        }
        if (cbsite && cbsite !== 'null') {
            obj.cbsite = cbsite;
        }

        this.setState({signin: obj, isEnabled: false, planInit: obj.plan ? true : false});
    }

    // update element state
    updateElement = (e, type) => {
        const {signin} = this.state;
        const obj = new ElementsHandleAPI(signin, e.target.value, type);
        const res = obj.updateObj();
        this.handleConditionals(res);
    }

    handleConditionals = (res) => {
        var isEnabled = true;

        Object.keys(res).forEach(el => {
            if (!res[el] && el !== 'role' && el !== 'ref' && el !== 'hs_legal_basis') {
                isEnabled = false;
            }
        });

        this.setState({signin: res, isEnabled: isEnabled});
    }

    handlePDFs = (e) => {
        e.preventDefault();
        const lang = this.props.i18n.language;
        switch (lang) {
            case 'en':
            case 'pt':
                window.open("https://centribal.com/us/privacy-policy/");
                break;
            case 'es':
                window.open("https://centribal.com/es/politica-de-privacidad/");
                break;
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const {signin} = this.state;

        if (!isValidPassword(signin.password)) {
            this.props.dispatch(showNotify({message: 'password_validation', severity: 'error'}));
        } else {
            signin.lang = this.props.i18n.language;
            try {
                this.props.dispatch(updateSending(true));

                let request = new CustomLogin(signin, 'signins', this.props);
                await request.make_signin();

                this.handleHubspot();

                this.props.dispatch(updateSending(false));

                new AmplitudeAPI({
                    event_type: 'Register ok',
                    device_id: this.props.data.device_id,
                    user_id: signin.email,
                    country: signin.country,
                    language: signin.lang,
                    product_id: signin.plan
                }).eventLog();

                this.setState({finish_signin: true});
            } catch (err) {
                signin.phone = signin.phone.replace(signin.dial_code, '');
                this.props.dispatch(showNotify({message: err, severity: 'error'}));
            }
        }
    }

    onSelect = (e, type) => {
        switch (type) {
            case 'lang':
                this.state.signin.lang = e;
                break;
            case 'country':
                var country = e.split('-')[0];
                this.state.signin.country = country;
                break;
            case 'plain_translate':
                this.state.signin.plan = e;
                break;
        }
        this.handleConditionals(this.state.signin);
    }

    handleHubspot = () => {
        var husbspot = window._hsq = window._hsq || [];
        husbspot.push(["identify", {
            email: this.state.signin.email
        }]);
        husbspot.push(['trackPageView']);
    }

    render() {
        const {t, isVisible} = this.props;
        const {
            company,
            name,
            country,
            password,
            email,
            hs_legal_basis,
            plan
        } = this.state.signin;
        return (
            <React.Fragment>
                {!this.state.finish_signin ?
                    <form className={"platform_landing"}>
                        <div className={"platform_landing__header"}>
                            <span>{t('landing.signin.title')}</span>
                            <span>{t('landing.signin.description')}</span>
                        </div>
                        <div className={"platform_landing__content"}>
                            <Input
                                id={"signin_name"}
                                label={`${t('landing.signin.name')}*`}
                                onChange={(e) => {
                                    this.updateElement(e, 'name')
                                }}
                                value={name}
                                type={'primary'}
                            />
                            <Input
                                id={"signin_business"}
                                label={`${t('landing.signin.company')}*`}
                                onChange={(e) => {
                                    this.updateElement(e, 'company')
                                }}
                                value={company}
                                type={'primary'}
                            />
                            <Input
                                id={"signin_email"}
                                label={`${t('landing.signin.email')}*`}
                                onChange={(e) => {
                                    this.updateElement(e, 'email')
                                }}
                                value={email}
                                type={'primary'}
                                inputType={"email"}
                            />
                            <SelectSimple
                                id={'signin_country'}
                                placeholder={`${t('select.country_text')}*`}
                                label={`${t('landing.signin.country')}*`}
                                valueSelected={country ? country : t('select.country_text')}
                                items={this.state.countries}
                                onSelect={this.onSelect}
                                className={"select_secondary"}
                                type={'country'}
                                value={"name"}
                                t={t}
                            />
                            <PasswordInput
                                label={`${t('landing.signin.password')}*`}
                                value={password}
                                field={"password"}
                                onChange={this.updateElement}
                            />
                        </div>
                        <div className={"platform_landing__footer platform_landing__footer_signin"}>
                            <b>{t('landing.signin.commercial_communications.title')}</b>
                            <span>{t('landing.signin.commercial_communications.description')}
                                <a onClick={(e) => this.handlePDFs(e, 'policy')}>{t('landing.signin.commercial_communications.description_link')}</a>
                            </span>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        id={"signin_general_conditions"}
                                        checked={hs_legal_basis}
                                        onChange={(e) => {
                                            this.updateElement(e, 'hs_legal_basis')
                                        }}
                                    />
                                }
                                label={<span>{t('landing.signin.commercial_communications.checkbox')}</span>}
                            />
                        </div>
                        <div className={"platform_landing__content"}>
                            {
                                this.props.data.isSending ? (
                                    <Button id={`signin_session`} type={"success"}
                                            text={<CircularProgress size={21}/>}/>
                                ) : (
                                    <Button id={`signin_session`} type={"success"} onClick={this.handleSubmit}
                                            text={t('landing.signin.button')}/>
                                )
                            }
                        </div>
                        <div className={"platform_landing__footer platform_landing__footer_signin"}>
                            {
                                isVisible() && (
                                    <span> {t('landing.signin.info')}
                                        <a id={"signin_session"} onClick={() => this.props.handleSwitch('login')}>
                                            {t('landing.signin.info_a')}
                                        </a>
                                    </span>
                                )
                            }
                        </div>
                    </form> :
                    <div className="platform_landing">
                        <div className={"platform_landing__header"}>
                            <span>{t('landing.verify.title')}</span>
                            <span>{t('landing.verify.description')}</span>
                        </div>
                        <div className={"platform_landing__verify"}>
                            <span>{email}</span>
                            <img alt="centribot-email" src={process.env.PUBLIC_URL + '/img/email.jpg'}/>
                        </div>
                        <div className={"platform_landing__footer"}>
                            <span>
                                {t('landing.verify.wrong_email')}
                                <a id={"signin_session"} onClick={() => window.location.reload()}>
                                {t('landing.verify.sign_up_again')}
                            </a>
                            </span>
                            <span>
                                {t('landing.signin.info')}
                                <a id={"signin_session"} onClick={() => this.props.handleSwitch('login')}>
                                    {t('landing.signin.info_a')}
                                </a>
                            </span>
                        </div>
                    </div>
                }
            </React.Fragment>
        )
    }

}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const signin = connect(
    mapStateToProps
)(Signin);

export default withTranslation('common')(withRouter(signin));