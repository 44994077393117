// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Translations
import {withTranslation} from "react-i18next";
// Svg
import {flowSvg} from "assets";

class DropElements extends Component {

    onDragStart = (e, nodeType) => {
        e.dataTransfer.setData('application/reactflow', nodeType);
        e.dataTransfer.effectAllowed = 'move';
    };

    render() {
        const {type, text, disabled} = this.props;
        return (
            <div className={`drop_element ${disabled ? "is_disabled" : ""}`} onDragStart={(e) => this.onDragStart(e, type)} draggable>
                <div className={"drop_element_img"}>
                    {flowSvg(type)}
                </div>
                <div className={"drop_element_text"}>
                    <span>{text}</span>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        global_state: state
    }
}

const connect_intents = connect(
    mapStateToProps
)(DropElements);

export default withTranslation('common')(withRouter(connect_intents));