import { CustomRequest } from 'vendor/infrastructure/custom_requests';
import { updateSending } from 'vendor/application/disptach';

export class BusinessAPI {
    constructor(projectID, token, props, body) {
        this.projectID = projectID;
        this.token = token;
        this.props = props;
        this.body = body;
        this.props.dispatch(updateSending(true));
    }

    async get_hours() {
        const request = new CustomRequest('GET', `projects/${this.projectID}/hours`, null, this.token, this.props );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async put_hours() {
        const request = new CustomRequest('PUT', `projects/${this.projectID}/hours`, JSON.stringify(this.body), this.token, this.props );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_festivities() {
        const request = new CustomRequest('GET', `projects/${this.projectID}/hours/festivities`, null, this.token, this.props );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async post_festivities() {
        const request = new CustomRequest('POST', `projects/${this.projectID}/hours/festivities`, JSON.stringify(this.body), this.token, this.props );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async put_festivities() {
        const request = new CustomRequest('PUT', `projects/${this.projectID}/hours/festivities/${this.body.festivity_id}`, JSON.stringify(this.body.day), this.token, this.props );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async delete_festivities() {
        const request = new CustomRequest('DELETE', `projects/${this.projectID}/hours/festivities/${this.body.festivity_id}`, null, this.token, this.props );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_business(id) {
        const request = new CustomRequest('GET', `projects/${this.projectID}/hours/business/${id}`, null, this.token, this.props );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async put_business(id) {
        const request = new CustomRequest('PUT', `projects/${this.projectID}/hours/business/${id}`, JSON.stringify({ hours: this.body}), this.token, this.props );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }
}