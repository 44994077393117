// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from 'react-router-dom';
// Infrastructure
import {CustomLogin} from 'vendor/infrastructure/custom_requests/login';
// Application
import {emailValidity} from 'vendor/application';
import {handleActualTitle} from '@helpers';
// Components
import {Input, Button} from "@components/Input";
// Material
import {CircularProgress} from '@material-ui/core';
// Vendor
import {showNotify, updateSending} from 'vendor/application/disptach';
// Translations
import {withTranslation} from "react-i18next";
// Amplitude API
import {AmplitudeAPI} from '@api/Amplitude';

class Forgot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
    }

    componentDidMount = () => {
        new AmplitudeAPI({
            event_type: 'Remember password show',
            device_id: this.props.data.device_id
        }).eventLog();

        handleActualTitle(this.props.t, `forgot`);
    }

    updateEmail = (e) => {
        this.setState({email: e.target.value});
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        const {email} = this.state;

        if (emailValidity(email)) {
            try {
                this.props.dispatch(updateSending(true));

                let request = new CustomLogin({email: email}, 'password/reestablish', this.props);
                await request.password_reestablish('POST');

                new AmplitudeAPI({
                    event_type: 'Remember password request',
                    device_id: this.props.data.device_id,
                    user_id: email
                }).eventLog();

                this.props.dispatch(showNotify({message: "forgot", type: "email", severity: "success"}));
                this.props.dispatch(updateSending(false));
            } catch (err) {
                this.props.dispatch(updateSending(false));
                this.props.dispatch(showNotify({message: err, severity: 'error'}));
            }

        } else {
            this.props.dispatch(showNotify({message: 'incorrect_email_format', severity: 'error'}));
        }
    }

    render() {
        const {t, handleSwitch} = this.props;
        const {email} = this.state;
        return (
            <React.Fragment>
                <form className="platform_landing">
                    <div className={"platform_landing__header"}>
                        <span>{t('landing.forgot.title')}</span>
                        <span>{t('landing.forgot.description')}</span>
                    </div>
                    <div className={"platform_landing__content"}>
                        <Input
                            id={"remember_email"}
                            label={t('landing.signin.email_forgot')}
                            onChange={(e) => this.updateEmail(e)}
                            value={email}
                            type={'primary'}
                        />
                        {
                            this.props.data.isSending ? (
                                <Button id={`remember_request_password`} type={"success"}
                                        text={<CircularProgress size={21}/>}/>
                            ) : (
                                <Button id={`remember_request_password`} type={"success"} onClick={this.handleSubmit}
                                        text={t('landing.forgot.button')}/>
                            )
                        }
                    </div>
                    <div className={"platform_landing__footer"}>
                        <span>
                            {t('landing.signin.info')}
                            <a id={"signin_session"} onClick={() => handleSwitch('login')}>
                                {t('landing.signin.info_a')}
                            </a>
                        </span>
                        <span>
                            {t('landing.login.info')}
                            <a id={"login_session"} onClick={() => handleSwitch('signin')}>
                                {t('landing.login.info_a')}
                            </a>
                        </span>
                    </div>
                </form>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const forgot = connect(
    mapStateToProps
)(Forgot);

export default withTranslation('common')(withRouter(forgot));