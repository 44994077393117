import React from 'react';
import {render} from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import {createStore} from 'redux';
import rootReducer from './reducers';
import Root from './views/Root/application/root';
import {getCookieByName} from 'vendor/application';
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
// Translates
import translateES from "./translations/es";
import translateEN from "./translations/en";
import translatePT from "./translations/pt";
// Styles
import './styles/index.scss';
import '@styles/index.scss';

var userLang = navigator.language || navigator.userLanguage;
var lang = getCookieByName('b__lg');
if (!lang) {
    lang = userLang.split('-')[0];
}

export const store = createStore(rootReducer);

i18next.init({
    interpolation: {escapeValue: false},
    lng: ["en", "es", "pt"].includes(lang) ? lang : 'es',
    resources: {
        en: translateEN,
        es: translateES,
        pt: translatePT
    },
});

render(
    <I18nextProvider i18n={i18next}>
        <Provider store={store}>
            <BrowserRouter basename="/centribot">
                <Root path="/"/>
            </BrowserRouter>
        </Provider>
    </I18nextProvider>,
    document.getElementById("root"));