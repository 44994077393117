// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Material
import {CircularProgress, Divider} from '@material-ui/core';
// Vendor
import {showNotify, updateLimitsIntegrations} from 'vendor/application/disptach';
// Infrastructure
import {IntegrationsAPI} from 'api/application/Integrations';
// Application
import {getZendeskGuideBody, returnElementFromArray} from 'vendor/application';
// Translations
import {withTranslation} from "react-i18next";
// Components
import {ElementsCustom} from "@components/Elements";
import {Button} from "@components/Input";
// SVG
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zendesk: getZendeskGuideBody(),
            domain: '.zendesk.com'
        }
    }

    componentDidMount = () => {
        this.getIntegrations();
    }

    getIntegrations = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            const integrations = await obj.get_integrations();
            var exist = returnElementFromArray('zendesk_guide', integrations, 'channel');
            if (exist) {
                this.props.dispatch(showNotify({
                    message: this.props.t('integrations.channels.zendesk_full_restriction'),
                    severity: 'error'
                }));
                this.return();
            }
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    updateElement = (e, type, typeOf) => {
        const {zendesk} = this.state;
        switch (typeOf) {
            case 'input_zendesk':
            case 'input':
                zendesk.info[type] = e.target.value;
                break;
            case 'switch':
                zendesk.info[type] = !zendesk.info[type];
                break;
        }

        this.setState({zendesk: zendesk});
    }

    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations/zendesk_guide`);
    }

    postIntegration = async (e) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {history} = this.props;
        const {zendesk, domain} = this.state;

        zendesk.info.subdomain = zendesk.info.subdomain.replace('.zendesk.com', '') + domain;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props, zendesk);
            let integration = await obj.post_integration();
            this.props.dispatch(showNotify({message: "integrations", type: "create", severity: "success"}));

            this.props.data.limitsIntegrations.zendesk_guide.existIntegration = true;
            this.props.dispatch(updateLimitsIntegrations(this.props.data.limitsIntegrations));

            history.push(`/${actualProject.id}/integrations/zendesk_guide/edit/${integration.id}`);
        } catch (err) {
            zendesk.info.subdomain = zendesk.info.subdomain.replace('.zendesk.com', '');
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    render() {
        const {zendesk} = this.state;
        const {t} = this.props;
        const {isSending} = this.props.data;
        return (
            zendesk &&
            <div className={"cen_integrations"}>
                <div className={"header"}>
                    <div className={"header_content"}>
                        <div onClick={() => this.return()} className={"header_action"}>
                            {arrowRightSvg()}
                        </div>
                        <div className={"header_image"}>
                            {channelsSvg("zendesk_guide")}
                        </div>
                        <div className={"header_title"}>
                            <span className={"title"}>{t('integrations:channels.zendesk_guide.title')}</span>
                            <span className={"description"}>{t('integrations:categories.business')}</span>
                        </div>
                    </div>
                    <div className={"header_actions"}>
                        {
                            isSending ? (
                                <Button type={"primary"} disabled text={<CircularProgress size={21}/>}/>
                            ) : (
                                <Button type={"primary"}
                                        text={t('integrations:create.action_button')}
                                        onClick={(e) => this.postIntegration(e)}/>
                            )
                        }
                    </div>
                </div>
                <div className={"integration"}>
                    {/* SUBDOMAIN */}
                    <ElementsCustom
                        t={t}
                        type={"zendesk_guide"}
                        input_type={"input_zendesk"}
                        element_type={"subdomain"}
                        element={zendesk.info.subdomain.replace('.zendesk.com', '')}
                        updateElement={this.updateElement}
                    />
                    <Divider/>
                    {/* TITLE */}
                    <ElementsCustom
                        t={t}
                        type={"zendesk_guide"}
                        input_type={"input"}
                        element_type={"title"}
                        element={zendesk.info.title}
                        updateElement={this.updateElement}
                    />
                    <Divider/>
                    {/* INPUT TEXT */}
                    <ElementsCustom
                        t={t}
                        type={"zendesk_guide"}
                        input_type={"input"}
                        element_type={"input_text"}
                        element={zendesk.info.input_text}
                        updateElement={this.updateElement}
                    />
                    <Divider/>
                    {/* SHOW IN CHAT */}
                    <ElementsCustom
                        t={t}
                        type={"zendesk_guide"}
                        input_type={"switch"}
                        element_type={"show_in_chat"}
                        element={zendesk.info.show_in_chat}
                        updateElement={this.updateElement}
                    />
                    <Divider/>
                    {/* SHOW ARTICLE IN IFRAME */}
                    <ElementsCustom
                        t={t}
                        type={"zendesk_guide"}
                        input_type={"switch"}
                        element_type={"show_article_in_iframe"}
                        element={zendesk.info.show_article_in_iframe}
                        updateElement={this.updateElement}
                    />
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state
    }
}

const create = connect(
    mapStateToProps
)(Create);

export default withTranslation(['commons', 'common'])(withRouter(create));