// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Material UI
import {Divider} from '@material-ui/core';
// Vendor
import {ElementsCustom} from "@components/Elements";
import {Input} from "@components/Input";
// Translations
import {withTranslation} from "react-i18next";
// Components
import {AlertInfo} from "@components/Alert";
import {Button} from '@components/Input';
// Styles
import 'views/Bots/ChatBot/styles/index.scss';
// Amplitude API
import {CampaignsAPI} from "../../infrastructure";
// Helpers
import {showNotify} from "vendor/application/disptach";
import {isJSONString} from '@helpers';
// SVG
import {campaignsSvg} from "assets";
// Jquery
import $ from 'jquery';
import Preview from "../../components/Preview";

class Individual extends Component {
    constructor(props) {
        super(props);
        this.state = {
            webhook: false,
            isLoading: true,
            templates: false,
            template: false,
            whatsapp_phone: false,
            params: false,
            body: false,
            sender_phone: "",
            tab: 1
        }
    }

    componentDidMount = () => {
        const {plan} = this.props.data;
        this.getTemplates(plan?.whatsapp_phones[0]);
    }

    getTemplates = async (phone) => {
        const {access} = this.props.data;
        try {
            let obj = new CampaignsAPI(phone.project_id, access, this.props);
            let templates = await obj.get_templates();

            var approved_templates = [];
            templates.forEach(template => {
                if (template.status === 'approved') {
                    approved_templates.push(template);
                }
            })
            this.getWebhook(approved_templates, phone);
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getWebhook = async (templates, phone) => {
        const {access, user} = this.props.data;
        try {
            let obj = new CampaignsAPI(user.account_id, access, this.props);
            let webhook = await obj.get_webhook();

            var centripush_active = true;
            webhook?.projects.forEach((project => {
                if (!project.centripush_active) {
                    centripush_active = false;
                }
            }))

            if (!centripush_active) {
                await this.addWebhook();
            }

            this.handleTemplates(templates, phone, webhook);
        } catch (err) {
            var data = {message: err, severity: 'error'};
            this.props.dispatch(showNotify(data))
        }
    }

    handleTemplates = (templates, phone, webhook, template_in) => {
        var template = false;
        if (templates?.length > 0) {
            template = template_in ? template_in : this.state.template ? this.state.template : templates[0];
        }

        // TODO añadir aquí header y footer si lo tiene
        var body = JSON.stringify({
            "account_id": webhook.account_id,
            "project_id": phone.project_id,
            "message": {
                "phone": this.state.sender_phone ? this.state.sender_phone : "",
                "template_name": template?.name,
                "language": template?.language,
                "parameters": []
            }
        }, null, 4);

        var variable_regex = /{{\w*}}/gm,
            variables_count = template?.body?.match(variable_regex)?.length ?? 0;

        var params = [];
        for (var i = 0; i < variables_count; i++) {
            params.push("")
        }

        this.setState({
            webhook: webhook ?? this.state.webhook,
            templates: templates,
            whatsapp_phone: phone,
            template: template,
            isLoading: false,
            body: body,
            params: params
        });
    }

    addWebhook = async () => {
        const {access, user} = this.props.data;
        this.setState({isLoading: true});
        try {
            let obj = new CampaignsAPI(user.account_id, access, this.props, {});
            var res = await obj.post_webhook(user.account_id);

            this.setState({webhook: res, isLoading: false});

            return true;
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    updateElement = (e, type, sub_type, i) => {
        const {template} = this.state;
        var str = e?.target?.value;
        switch (type) {
            case 'body':
                this.setState({body: str});
                break;
            case 'sender_phone':
                this.setState({
                    sender_phone: str,
                    body: this.state.body.replace(/(?:"phone": ")(.*)(?:")/g, `"phone": "${str}"`)
                });
                break;
            case 'header_type':
                template.header.url = str;
                this.setState({template: template});
                break;
            case 'payload':
                template.buttons.buttons[i].payload = str;
                this.setState({template: template});
                break;
        }
    }

    handleSelect = (e, type, sub_type, i) => {
        var {params} = this.state;
        switch (type) {
            case 'whatsapp_phone':
                this.setState({whatsapp_phone: e, template: false});
                this.getTemplates(e);
                break;
            case 'template':
                this.setState({template: e});
                this.handleTemplates(this.state.templates, this.state.whatsapp_phone, this.state.webhook, e);
                break;
            case 'params':
                params[i] = e.target.value;
                var body = JSON.parse(this.state.body);

                body.message.parameters = params;

                this.setState({params: params, body: JSON.stringify(body, null, 4)});
                break;
        }
    }

    doRequest = async () => {
        const {body, webhook, template} = this.state;

        var isJSON = isJSONString(body);

        if (!isJSON) {
            this.props.dispatch(showNotify({message: "body_has_an_invalid_format", severity: 'error'}))
        } else if (["document", "image"].includes(template.header?.type) && !template.header.url) {
            this.props.dispatch(showNotify({message: "url_is_required", severity: 'error'}))
        } else {
            var new_body = this.handleBody();

            try {
                await $.ajax({
                    url: webhook.url,
                    type: 'POST',
                    data: JSON.stringify(new_body),
                    contentType: "application/json",
                    headers: {
                        "Authorization": `Bearer ${webhook.token}`
                    },
                    success: () => {
                        this.props.dispatch(showNotify({message: "webhook_push", type: "create", severity: "success"}));
                    },
                })
            } catch (err) {
                this.props.dispatch(showNotify({message: err, severity: 'error'}))
            }
        }
    }

    handleBody = (no_add_requester_id) => {
        const {user} = this.props.data;
        const {body, template} = this.state;

        if (body) {
            var requester_body = JSON.parse(body);
            if (!no_add_requester_id) {
                requester_body.requester_id = user.id;
            }

            if (["document", "image"].includes(template.header?.type)) {
                requester_body.message.header = {
                    type: template.header.type,
                    url: template.header.url
                }
            }
            if (template.buttons?.buttons.length > 0 && template.buttons.type === 'quick_reply') {
                template.buttons?.buttons.forEach((button, i) => {
                    if (button.payload) {
                        if (!requester_body.message.buttons) {
                            requester_body.message.buttons = [];
                        }
                        requester_body.message.buttons.push({
                            index: i,
                            parameters: [
                                {
                                    type: "payload",
                                    payload: button.payload
                                }
                            ]
                        })
                    }
                })
            }
            return requester_body
        }
    }

    render() {
        const {plan, actualLayout} = this.props.data;
        const {t} = this.props;
        const {
            templates,
            whatsapp_phone,
            sender_phone,
            template,
            params
        } = this.state;
        return (
            <div className={"campaigns_container_elements"}>
                <div className={"campaigns_container_individual"}>
                    <div>
                        <ElementsCustom
                            t={t}
                            type={"webhook"}
                            input_type={"select_with_inputs_code"}
                            element_type={"template"}
                            select_value={"name"}
                            element={template?.id ? template?.name : "-"}
                            elements={templates}
                            updateElement={this.handleSelect}
                            conditional={
                                {
                                    params: params
                                }
                            }
                        />
                        <Divider/>
                        {/* WHATSAPP PHONE */}
                        <div className={"elements_custom column"}>
                            <div className={"elements_custom__info"}>
                                            <span
                                                className={"elements_custom__title"}>{t(`elements.custom.templates.card.whatsapp_phone.title`)} </span>
                                <span
                                    className={"elements_custom__description"}>{t(`elements.custom.templates.card.whatsapp_phone.description`)}</span>
                            </div>
                            {
                                plan?.whatsapp_phones &&
                                <div className={"elements_custom__selection"}>
                                    {plan?.whatsapp_phones.map((phone, i) => {
                                        return (
                                            <div
                                                className={`${phone.phone === whatsapp_phone.phone ? "active" : ""}`}
                                                onClick={() => this.handleSelect(phone, "whatsapp_phone")}
                                                key={i}>{phone.phone} - {phone.project_name}</div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                        <Divider/>
                        <ElementsCustom
                            t={t}
                            type={"webhook"}
                            input_type={"input"}
                            element_type={"sender_phone"}
                            element={sender_phone ?? ""}
                            updateElement={this.updateElement}
                        />
                        {
                            template?.id &&
                            <React.Fragment>
                                <Divider/>
                                {/* HEADER */}
                                <div className={"elements_custom column"}>
                                    <div className={"elements_custom__info"}>
                                            <span
                                                className={"elements_custom__title"}>{t(`elements.custom.templates.card.header.title`)}
                                            </span>
                                    </div>
                                    <div className={"elements_custom__header"}>
                                        <div className={"elements_custom__selection_images"}>
                                            {
                                                ["null", "text", "document", "image"].map((element, i) => {
                                                    return (
                                                        <div
                                                            className={`${!template?.header && element === "null" || element === template?.header?.type ? "active" : ""}`}
                                                            key={i}>
                                                            <span className={"checkbox"}/>
                                                            <span className={"image"}>{campaignsSvg(element)}</span>
                                                            <span
                                                                className={"text"}>{t(`elements.custom.templates.card.header.conditional.${element}.selector`)}</span>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        {
                                            (template?.header?.type === "document" || template?.header?.type === "image") &&
                                            <Input
                                                placeholder={t(`elements.custom.templates.card.header.conditional.${template.header.type}.placeholder`)}
                                                value={template?.header?.url}
                                                onChange={(e) => this.updateElement(e, "header_type", template?.header?.type)}
                                            />
                                        }
                                    </div>
                                </div>
                                <Divider/>
                                {/* FOOTER */}
                                <div className={"elements_custom"}>
                                    <div className={"elements_custom__info"}>
                                            <span
                                                className={"elements_custom__title"}>{t(`elements.custom.templates.card.footer.title`)}
                                            </span>
                                    </div>
                                    <div className={"platform_input_content"}>
                                        {
                                            template.footer ?
                                                <Input
                                                    disabled
                                                    placeholder={t(`elements.custom.groups.card.footer.placeholder`)}
                                                    value={template.footer}
                                                /> :
                                                <AlertInfo text={t(`elements.custom.groups.card.footer.placeholder`)}/>
                                        }
                                    </div>
                                </div>
                                <Divider/>
                                {/* BUTTONS */}
                                <ElementsCustom
                                    t={t}
                                    type={"webhook"}
                                    input_type={"select_with_conditional"}
                                    element_type={"buttons"}
                                    select_value={""}
                                    element={template.buttons?.type}
                                    elements={[]}
                                    disabled={true}
                                    alert={true}
                                    conditional={
                                        {
                                            type: "select_with_conditionals",
                                            element_conditional: template.buttons?.type !== null,
                                            elements: [],
                                            element_type: "buttons_type",
                                            element_actions: template.buttons?.buttons,
                                            element_actions_conditional: true,
                                            disabled: true
                                        }
                                    }
                                    updateElement={this.updateElement}
                                />
                            </React.Fragment>
                        }
                    </div>
                </div>
                <div className={"campaigns_container_preview"}>
                    <Preview template_selected={JSON.parse(JSON.stringify(template))}/>
                    <div className={"campaigns_container_footer"}>
                        <Button type={"success"}
                                text={t(`common:card.content.${actualLayout.layout}.action_button`)}
                                onClick={() => this.doRequest()}/>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_component = connect(
    mapStateToProps
)(Individual);

export default withTranslation(['commons', 'common'])(withRouter(connect_component));