// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Translations
import {withTranslation} from "react-i18next";
// Styles
import 'components/Accordion/styles/styles.scss';
// Components
import FloatButtons from "components/Input/float_buttons";
import {Input, Button} from "@components/Input"
import Tooltip from '@components/Tooltip';
// Vendor
import {addArtifact} from "vendor/application";

class Phrases extends Component {

    handleLimitsPhrases = () => {
        const {limits, intent} = this.props;

        if (!intent.is_default) {
            if (limits.phrases.total < limits.phrases.max) {
                return limits.intents_create.intent_phrases_total >= limits.intents_create.intent_phrases;
            }
            return true;
        }
        return true;
    }

    updateArtifacts = (e, i, artifact_type, type) => {
        const {intent, updateIntent} = this.props;

        var res = addArtifact(i, artifact_type, type, intent);

        updateIntent(res);
    }

    render() {
        const {
            type,
            updateElement,
            intent,
            t
        } = this.props;
        return (
            <div className={"accordion__element"}>
                <div className={"accordion__element_header"}>
                    <div>
                        <span>{t(`intents.config.accordion.${type}.phrases.title`)} <Tooltip
                            text={t(`intents.config.accordion.${type}.phrases.description`)}/></span>
                    </div>
                </div>
                <div className={"accordion__element_content phrases"}>
                    {
                        intent.phrases.length > 0 &&
                            intent.phrases.map((el, i) => {
                                return (
                                    <div className={"accordion__elements"} key={i}>
                                        <Input
                                            id={`intent-phrases-${i}`}
                                            type={'secondary'}
                                            placeholder={t(`intents.config.accordion.${type}.phrases.placeholder`)}
                                            multiline
                                            inputProps={{maxLength: 4096}}
                                            value={el}
                                            onChange={(e) => updateElement(e, "phrases", "string_array", i)}
                                        />
                                        <FloatButtons type={"phrases"}
                                                      onDelete={() => updateElement(null, "phrases", "delete", i)}
                                                      addArtifact={this.updateArtifacts} iteration={i}
                                                      buttons_toggle_actions={["delete"]}/>
                                    </div>
                                )
                            })
                    }
                    <Button disabled={this.handleLimitsPhrases()} type={"dashed"} onClick={(e) => {
                        updateElement(e, "phrases", "add")
                    }} text={t(`intents.config.accordion.${type}.phrases.button`)}/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const accordion_phrases = connect(
    mapStateToProps
)(Phrases);

export default withTranslation('common')(accordion_phrases);