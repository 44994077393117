import { CustomRequest } from 'vendor/infrastructure/custom_requests';
import { updateSending } from 'vendor/application/disptach';

export class ChatsAPI {
    constructor(projectID, token, props, body) {
        this.projectID = projectID;
        this.token = token;
        this.props = props;
        this.body = body;
        this.props.dispatch(updateSending(true));
    }

    async get_chats() {
        const request = new CustomRequest('GET', `projects/${this.projectID}/chats`, null, this.token, this.props );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async get_chat_by_id_config() {
        let request = new CustomRequest('GET', `projects/${this.projectID}/chats/${this.props.chat_id}/config`, null, this.token, this.props );
        var req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }

    async put_chat_icon() {
        const request = new CustomRequest('PUT', `projects/${this.projectID}/chats/${this.props.chat_id}/icons`, this.body, this.token, this.props );
        const req = await request.action(true);
        this.props.dispatch(updateSending(false));
        return req;
    }

    async put_chat() {
        const request = new CustomRequest('PUT', `projects/${this.projectID}/chats/${this.props.chat_id}`, JSON.stringify(this.body), this.token, this.props );
        return await request.action();
    }

    async put_chat_config() {
        const request = new CustomRequest('PUT', `projects/${this.projectID}/chats/${this.props.chat_id}/configs`, JSON.stringify(this.body), this.token, this.props );
        const req = await request.action();
        this.props.dispatch(updateSending(false));
        return req;
    }
}