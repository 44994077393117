// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Translations
import {withTranslation} from "react-i18next";
// Styles
import 'components/Accordion/styles/styles.scss';
// Components
import {Input, SelectSimple} from "@components/Input";
import {FormControlLabel, Switch} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';

class Sunco extends Component {
    componentDidMount = () => {
        this.setAgentWorkspace();
    }

    componentDidUpdate = (nextProps) => {
        const {switchboard} = this.props;
        if (switchboard?.array.length !== nextProps.switchboard?.array.length) {
            this.setAgentWorkspace();
        }
    }

    setAgentWorkspace = () => {
        const {switchboard, intent, updateIntent} = this.props;
        let value = intent.desks.info.switchboard_next_integration_id;
        switchboard?.array?.forEach(integration => {
            if (!value && integration.integration_type === "zd:agentWorkspace") {
                intent.desks.info.switchboard_next_integration_id = integration.id;
                updateIntent(intent)
            }
        })
    }

    updateSelectorElement = (e, type) => {
        const {intent, updateIntent} = this.props;
        switch (type) {
            case 'switchboard_next_integration_id':
                intent.desks.info = {
                    ...intent.desks.info,
                    switchboard_next_integration_id: e,
                    answerbot_send_hidden_message: false
                }
                break;
            case 'group_id':
                intent.desks.info = {
                    ...intent.desks.info,
                    group_id: e.target.value
                }
                break;
            case 'answerbot_send_hidden_message':
                intent.desks.info = {
                    ...intent.desks.info,
                    answerbot_send_hidden_message: !intent.desks.info.answerbot_send_hidden_message
                }
                break;
            default:
                break;
        }

        updateIntent(intent);
    }

    handleSuncoValue = () => {
        const {switchboard, intent} = this.props;

        let res = false;
        switchboard?.array.forEach(integration => {
            if (intent.desks.info.switchboard_next_integration_id === integration.id) {
                res = integration.name;
            }
        })

        return res;
    }

    handleSkeleton = () => {
        const {switchboard, t} = this.props;
        if (!switchboard.updated) {
            return "";
        } else if (switchboard.array.length === 0) {
            return <div className={"info__integration_content_skeleton"}>
                <div>
                    <Skeleton variant="rect" width={"100%"} height={10}/>
                    <Skeleton variant="rect" width={"100%"} height={40}/>
                </div>
            </div>
        }
        return <SelectSimple
            valueSelected={this.handleSuncoValue()}
            items={switchboard?.array}
            label={t(`common:intents.integrations.switchboard_next_integration_id`)}
            onSelect={(e) => this.updateSelectorElement(e, `switchboard_next_integration_id`)}
            placeholder={t(`common:intents.integrations.switchboard_next_integration_id`)}
            value={"id"}
            className={"select_secondary"}
            t={t}
        />
    }

    render() {
        const {intent, t} = this.props;
        return (
            <div className={"info__integration"}>
                <div className={"info__integration_content"}>
                    {
                        this.handleSkeleton()
                    }
                    <Input
                        placeholder={t(`common:intents.integrations.group_id_placeholder`)}
                        onChange={(e) => this.updateSelectorElement(e, 'group_id')}
                        value={intent.desks.info.group_id ?? ""}
                        label={t(`common:intents.integrations.group_id`)}
                        type={"primary"}
                    />
                    {
                        this.handleSuncoValue() === "zd-answerBot" &&
                        <FormControlLabel
                            checked={intent.extensions.active ? intent.extensions.active : false}
                            control={<Switch checked={intent.desks.info.answerbot_send_hidden_message}
                                             onClick={(e) => {
                                                 this.updateSelectorElement(e, 'answerbot_send_hidden_message')
                                             }}/>}
                            onClick={(event) => event.stopPropagation()}
                            onFocus={(event) => event.stopPropagation()}
                            label={t(`common:intents.integrations.answerbot_send_hidden_message`)}
                            labelPlacement="start"
                        />
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const channel = connect(
    mapStateToProps
)(Sunco);

export default withTranslation(['commons', 'common', 'languages'])(channel);