// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {Link, withRouter} from "react-router-dom";
// Material
import {CircularProgress, Divider} from '@material-ui/core';
// Vendor
import {showNotify} from 'vendor/application/disptach';
// Infrastructure
import {IntegrationsAPI} from 'api/application/Integrations';
import {BusinessAPI} from 'views/Bots/ChatBot/application/Tabs/BusinessHours/infrastructure';
// Application
import {getStatusArray, getZendeskFullBody} from 'vendor/application';
import {ElementsHandleAPI} from 'vendor/application/handleMethods';
// Translations
import {withTranslation} from "react-i18next";
// Components
import {ElementsCustom} from "@components/Elements";
import {Button, SelectAutocomplete, Input} from "@components/Input";
import DialogAction from "components/Dialog/DialogAction";
// SVG
import {arrowLeft, channelsSvg, closeSvg} from "assets";
import {arrowRightSvg} from "@assets";

const TMB_PROJECT_ID = "43dcdb7de1ad44e6866a23a3cd650699";

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zendesk: '',
            zendesk_subdomain: '',
            warning_subdomain: false,
            hours: '',
            domain: '.zendesk.com',
            settings: {
                tags: "",
                tags_hours_control: "",
                tags_individual: "",
                tags_campaign: ""
            },
            custom_fields: [],
            open: false
        }
    }

    componentDidMount = () => {
        this.getIntegration();
    }

    // get integration info from actual project id
    getIntegration = async () => {
        const {access, actualProject} = this.props.data;
        try {
            let obj = new IntegrationsAPI(actualProject.id, access, this.props);
            let integration = await obj.get_integration_by_id();
            let default_integration = getZendeskFullBody();

            if (!integration.info.centripush) {
                integration.info.centripush = {...default_integration.info.centripush};
            }
            if (!integration.info.hours_control) {
                integration.info.hours_control = {...default_integration.info.hours_control};
            }
            if (!integration.info.custom) {
                integration.info.custom = null;
            }

            var custom_fields = await this.getCustomFields(integration.id);

            this.setState({zendesk: integration, custom_fields, zendesk_subdomain: integration.info.subdomain});
            this.getHours();
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
            this.return();
        }
    }

    getCustomFields = async (channel_id, auto_update) => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            const custom_fields = await obj.custom_fields(channel_id, true);
            if (auto_update) {
                this.setState({custom_fields});
            }
            return custom_fields;
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}))
        }
    }

    getHours = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new BusinessAPI(actualProject.id, access, this.props);
            const hours = await obj.get_hours();

            this.setState({hours: hours});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    // update element state
    updateElement = (e, type, i) => {
        const {actualProject} = this.props.data;
        const {zendesk, zendesk_subdomain} = this.state;
        const obj = new ElementsHandleAPI(zendesk.info, e.target.value, type, i);
        obj.updateObj();

        var warning = false;
        if (zendesk_subdomain !== zendesk.info.subdomain && actualProject?.id !== TMB_PROJECT_ID) {
            warning = true;
        }

        this.setState({zendesk: zendesk, subdomain_warning: warning});
    }

    handleCustomField = (e, type, i) => {
        const {zendesk} = this.state;

        if (e?.id) {
            zendesk.info.custom.custom_fields[i].name = e.title;
            zendesk.info.custom.custom_fields[i].id = e.id;
            zendesk.info.custom.custom_fields[i].type = e.type;

            this.setState({zendesk});
        }
    }

    handleCustomFieldValue = (e, type, i) => {
        const {zendesk} = this.state;
        zendesk.info.custom.custom_fields[i].value = e.target.value;

        this.setState({zendesk});
    }

    addNewCustomField = () => {
        const {zendesk} = this.state;
        if (!zendesk.info.custom) {
            zendesk.info.custom = {
                custom_fields: []
            }
        }

        zendesk.info.custom.custom_fields.push({
            id: "",
            name: "",
            value: "",
            type: ""
        })

        this.setState({zendesk});
    }

    deleteCustomField = (i) => {
        const {zendesk} = this.state;
        zendesk.info.custom.custom_fields.splice(i, 1);

        if (zendesk.info.custom.custom_fields === 0) {
            zendesk.info.custom = null;
        }

        this.setState({zendesk});
    }

    getCustomFieldsFiltered = (custom_field_id) => {
        let {custom_fields, zendesk} = this.state;
        let integration_custom_fields = zendesk.info.custom.custom_fields;
        if (
          !custom_fields ||
          !custom_fields.length ||
          typeof custom_fields.filter !== "function"
        ) {
          return [];
        }
        let current_custom_field = custom_fields.filter(x => x.id === custom_field_id)[0];

        integration_custom_fields.forEach(i => {
            if (i.id !== custom_field_id)
                custom_fields = custom_fields.filter(x => x.id !== i.id);
        })

        if (current_custom_field) {
            custom_fields.unshift(current_custom_field);
        }

        return custom_fields;
    }

    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations`);
    }

    putIntegration = async (e) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {zendesk, domain, zendesk_subdomain} = this.state;

        var z_object = JSON.parse(JSON.stringify(zendesk));
        if (z_object.info.subdomain !== zendesk_subdomain) {
            z_object.info.custom = null;
        }

        var custom_fields = z_object.info.custom?.custom_fields;
        if (custom_fields?.length > 0) {
            var exist = custom_fields.filter((c) => !c.name || !c.value);
            if (exist.length > 0) {
                this.props.dispatch(showNotify({message: "integration_zendesk_custom_field_empty", severity: 'error'}));
                return;
            }
        }

        z_object.info.subdomain = z_object.info.subdomain.replace('.zendesk.com', '') + domain;

        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props, z_object);
            const res = await obj.put_integration();

            this.props.dispatch(showNotify({message: "integrations", type: "edit", severity: "success"}));

            if (res.info.subdomain !== zendesk_subdomain) {
                this.getCustomFields(res.id, true);
            }
            this.setState({zendesk_subdomain: res.info.subdomain, zendesk: res, subdomain_warning: false})
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    handleTags = (e, type, elementType, chipToDelete, i) => {
        const {zendesk, settings} = this.state;
        var element_to_update;

        switch (elementType) {
            case 'tags':
                element_to_update = zendesk.info.tags;
                break;
            case 'tags_hours_control':
                element_to_update = zendesk.info.hours_control.tags;
                break;
            case 'tags_individual':
                element_to_update = zendesk.info.centripush.individual.tags;
                break;
            case 'tags_campaign':
                element_to_update = zendesk.info.centripush.campaign.tags;
                break;
            default:
        }

        var tag = e.target.value;
        switch (type) {
            case 'add':
                if (tag.match(/^[a-zA-Z0-9_\/]*$/)) {
                    settings[elementType] = tag;
                    this.setState({settings: settings});
                }
                break;
            case 'delete':
                element_to_update.forEach(el => {
                    if (el === chipToDelete) {
                        element_to_update.splice(i, 1);
                    }
                });
                this.setState({zendesk: zendesk})
                break;
            case 'enter':
                element_to_update.push(tag);
                settings[elementType] = "";
                this.setState({zendesk: zendesk, settings: settings});
                break;
            default:
        }
    }

    onSelectStatus = (e, type) => {
        const {zendesk} = this.state;
        switch (type) {
            case 'status_hours_control':
                zendesk.info.hours_control.status = e;
                break;
            case 'status_campaign':
                zendesk.info.centripush.campaign.status = e;
                break;
            case 'status_individual':
                zendesk.info.centripush.individual.status = e;
                break;
        }
        this.setState({zendesk: zendesk});
    }

    handleSortSelect = (e) => {
        const {zendesk} = this.state;
        if (e) {
            zendesk.info.sort_messages = e;
            this.setState({zendesk: zendesk});
        }
    }

    handleClickOpen = () => {
        this.setState({open: !this.state.open});
    }

    handleDelete = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            await obj.delete_integration(this.state.zendesk.id);

            this.props.dispatch(showNotify({message: "integrations", type: "delete", severity: "success"}));

            this.handleClickOpen();
            this.return();
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    render() {
        const {
            zendesk,
            hours,
            settings,
            open
        } = this.state;
        const {t, match} = this.props;
        const {limits, actualLayout, isSending, plan, actualProject} = this.props.data;
        return (
            <React.Fragment>
                {/* ZENDESK */}
                <div className={"cen_integrations"}>
                    <div className={"header"}>
                        <div className={"header_content"}>
                            <div onClick={() => this.return()} className={"header_action"}>
                                {arrowRightSvg()}
                            </div>
                            <div className={"header_image"}>
                                {channelsSvg("zendesk_full")}
                            </div>
                            <div className={"header_title"}>
                                <span className={"title"}>{t('integrations:channels.zendesk_full.title')}</span>
                                <span className={"description"}>{t('integrations:categories.business')}</span>
                            </div>
                        </div>
                        <div className={"header_actions"}>
                            {
                                isSending ? (
                                    <Button type={"primary"} disabled text={<CircularProgress size={21}/>}/>
                                ) : (
                                    <Button type={"primary"}
                                            text={t('integrations:save.action_button')}
                                            onClick={(e) => this.putIntegration(e)}/>
                                )
                            }
                        </div>
                    </div>
                    {
                        zendesk
                            ? <div className={"integration"}>
                                {/* NAME */}
                                <ElementsCustom
                                    t={t}
                                    type={"zendesk"}
                                    input_type={"input"}
                                    element_type={"name"}
                                    element={zendesk.name}
                                    updateElement={(e) => {
                                        zendesk.name = e.target.value;
                                        this.setState({zendesk})
                                    }}
                                />
                                <Divider/>
                                {/* SUBDOMAIN */}
                                <ElementsCustom
                                    t={t}
                                    type={"zendesk"}
                                    input_type={"input_zendesk"}
                                    element_type={"subdomain"}
                                    element={zendesk.info.subdomain.replace('.zendesk.com', '')}
                                    updateElement={this.updateElement}
                                />
                                <Divider/>
                                {/* ZENDESK EMAIL */}
                                <ElementsCustom
                                    t={t}
                                    type={"zendesk"}
                                    input_type={"input"}
                                    element_type={"email"}
                                    element={zendesk.info.email}
                                    updateElement={this.updateElement}
                                />
                                <Divider/>
                                {/* TOKEN */}
                                <ElementsCustom
                                    t={t}
                                    type={"zendesk"}
                                    input_type={"input"}
                                    element_type={"token"}
                                    element={zendesk.info.token}
                                    updateElement={this.updateElement}
                                />
                                <Divider/>
                                {/* TICKET SUBJECT */}
                                <ElementsCustom
                                    t={t}
                                    type={"zendesk"}
                                    input_type={"input"}
                                    element_type={"subject"}
                                    element={zendesk.info.subject}
                                    updateElement={this.updateElement}
                                />
                                <Divider/>
                                {/* SORT MESSAGES */}
                                <ElementsCustom
                                    t={t}
                                    type={"zendesk"}
                                    input_type={"select"}
                                    element_type={"sort_messages"}
                                    select_value={""}
                                    element={t(`elements.custom.zendesk.card.sort_messages.${zendesk.info.sort_messages}`)}
                                    elements={['asc', 'desc']}
                                    updateElement={this.handleSortSelect}
                                />
                                <Divider/>
                                {/* TAGS */}
                                <ElementsCustom
                                    t={t}
                                    type={"zendesk"}
                                    input_type={"input_with_tags"}
                                    element_type={"tags"}
                                    element={settings.tags}
                                    elements={zendesk.info.tags}
                                    updateElement={this.handleTags}
                                />
                                <Divider/>
                                {/* ZENDESK CUSTOM FIELD */}
                                <ElementsCustom
                                    t={t}
                                    type={"zendesk"}
                                    input_type={"switch"}
                                    element_type={"use_custom_fields"}
                                    element={zendesk.info.use_custom_fields}
                                    updateElement={this.updateElement}
                                />
                            </div>
                            : <div className={"content"}>
                                <div className="loading">
                                    <CircularProgress color="primary" size={50}/>
                                </div>
                            </div>
                    }
                </div>
                {/* CUSTOM FIELDS */}
                {
                    zendesk && actualProject?.id !== TMB_PROJECT_ID &&
                    <div className={"card"} style={{borderRadius: "5px"}}>
                        <div className="header-container">
                            <div className="header_info">
                                <div className="header_info__tooltip">
                                    <span
                                        className={"title"}>{t('integrations:channels.zendesk_full.custom.custom_fields.title')}</span>
                                </div>
                                <span
                                    className={"subtitle"}>{t('integrations:channels.zendesk_full.custom.custom_fields.description')}</span>
                            </div>
                        </div>
                        <div className={"content"}>
                            <Divider/>
                            {
                                zendesk.info.custom?.custom_fields.length > 0 &&
                                <div className={"custom_fields_elements"}>
                                    <div className={"custom_fields_header"}>
                                        <span>{t('integrations:channels.zendesk_full.custom.custom_fields.name')}</span>
                                        <div/>
                                        <span>{t('integrations:channels.zendesk_full.custom.custom_fields.value')}</span>
                                        <div/>
                                    </div>
                                    {
                                        zendesk.info.custom?.custom_fields.map((c, i) => {
                                            var arr = this.getCustomFieldsFiltered(c.id);
                                            return <React.Fragment key={i}>
                                                <div className={"custom_fields_element"}>
                                                    <SelectAutocomplete
                                                        searchOption={"title"}
                                                        items={arr}
                                                        value={arr.find((cf) => cf.id === c.id) ?? null}
                                                        className={"select_secondary"}
                                                        onSelect={this.handleCustomField}
                                                        iteration={i}
                                                        t={t}
                                                    />
                                                    <div className={"custom_fields_element_separator"}>
                                                        {arrowLeft()}
                                                    </div>
                                                    <Input
                                                        onChange={(e) => this.handleCustomFieldValue(e, 'url', i)}
                                                        value={c.value}
                                                    />
                                                    <div className={"custom_fields_element_delete"}
                                                         onClick={() => this.deleteCustomField(i)}>
                                                        <div>
                                                            {closeSvg()}
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        })
                                    }
                                </div>
                            }
                            <Button disabled={false} type={"dashed"} onClick={(e) => {
                                this.addNewCustomField(e)
                            }}
                                    text={t('integrations:channels.zendesk_full.custom.custom_fields.add_button')}/>
                        </div>
                    </div>
                }
                {
                    zendesk &&
                    <React.Fragment>
                        {/* HOURS CONTROL */}
                        <div className={"cen_integrations"}>
                            <div className={"header"}>
                                <div className={"header_content"}>
                                    <div className={"header_title"}>
                                            <span
                                                className={"title"}>{t('integrations:channels.zendesk_full.hours_control.title')}</span>
                                        <span
                                            className={"description"}>{t('integrations:channels.zendesk_full.hours_control.description')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"integration"}>
                                {
                                    limits.hours_control && hours && hours.active &&
                                    <React.Fragment>
                                        {/* ACTIVE */}
                                        <ElementsCustom
                                            t={t}
                                            type={"hours_control"}
                                            input_type={"switch"}
                                            element_type={"subject_active"}
                                            element={zendesk.info.hours_control.active}
                                            updateElement={this.updateElement}
                                        />
                                        <Divider/>
                                        {/* TICKET SUBJECT */}
                                        <ElementsCustom
                                            t={t}
                                            type={"hours_control"}
                                            input_type={"input"}
                                            element_type={"subject_hours_control"}
                                            element={zendesk.info.hours_control.subject}
                                            updateElement={this.updateElement}
                                        />
                                        <Divider/>
                                        {/* TAGS */}
                                        <ElementsCustom
                                            t={t}
                                            type={"hours_control"}
                                            input_type={"input_with_tags"}
                                            element_type={"tags_hours_control"}
                                            element={settings.tags_hours_control}
                                            elements={zendesk.info.hours_control.tags}
                                            updateElement={this.handleTags}
                                        />
                                        <Divider/>
                                        {/* STATUS */}
                                        <ElementsCustom
                                            t={t}
                                            type={"hours_control"}
                                            input_type={"select"}
                                            element_type={"status_hours_control"}
                                            element={zendesk.info.hours_control.status ? <span
                                                className={`status ${zendesk.info.hours_control.status}`}>{t(`elements.custom.status.${zendesk.info.hours_control.status}`)}</span> : ''}
                                            elements={getStatusArray()}
                                            updateElement={this.onSelectStatus}
                                        />
                                    </React.Fragment>
                                }
                                {
                                    limits.hours_control && (!hours || !hours.active) && (
                                        <div className="info_message">
                                            <span>
                                                {t('integrations:channels.zendesk_full.hours_control.no_active')}
                                                <Link
                                                    to={`/${match.params.project}/chatbot?tab=business_hours&to=business`}>{t('integrations:channels.zendesk_full.hours_control.title')}</Link>
                                            </span>
                                        </div>
                                    )}
                                {!limits.hours_control && (
                                    <div className="info_message">
                                        <span> {t('integrations:channels.zendesk_full.hours_control.no_limits')} </span>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* CENTRIPUSH */}
                        <div className={"cen_integrations"}>
                            <div className={"header"}>
                                <div className={"header_content"}>
                                    <div className={"header_title"}>
                                        <span
                                            className={"title"}>{t('integrations:channels.zendesk_full.centripush.title')}</span>
                                        <span
                                            className={"description"}>{t('integrations:channels.zendesk_full.centripush.description')}</span>
                                    </div>
                                </div>
                            </div>
                            <div className={"integration"}>
                                {
                                    plan?.whatsapp_phones?.length > 0 && plan?.platform?.centripush ?
                                        <React.Fragment>
                                            {/* INDIVIDUAL */}
                                            <div className={"elements_custom"}>
                                                <div className={"elements_custom__info"}>
                                                    <span
                                                        style={{fontWeight: 500}}
                                                        className={"elements_custom__title"}>{t('integrations:channels.zendesk_full.centripush.individual.title')}</span>
                                                    <span
                                                        className={"elements_custom__description"}>{t('integrations:channels.zendesk_full.centripush.individual.description')}</span>
                                                </div>
                                            </div>
                                            <Divider/>
                                            {/* ACTIVE */}
                                            <ElementsCustom
                                                t={t}
                                                type={"centripush"}
                                                input_type={"switch"}
                                                element_type={"individual_active"}
                                                element={zendesk.info.centripush.individual.active}
                                                updateElement={this.updateElement}
                                            />
                                            <Divider/>
                                            {/* TICKET SUBJECT */}
                                            <ElementsCustom
                                                t={t}
                                                type={"centripush"}
                                                input_type={"input"}
                                                element_type={"subject_individual"}
                                                element={zendesk.info.centripush.individual.subject}
                                                updateElement={this.updateElement}
                                            />
                                            <Divider/>
                                            {/* TAGS */}
                                            <ElementsCustom
                                                t={t}
                                                type={"centripush"}
                                                input_type={"input_with_tags"}
                                                element_type={"tags_individual"}
                                                element={settings.tags_individual}
                                                elements={zendesk.info.centripush.individual.tags}
                                                updateElement={this.handleTags}
                                            />
                                            <Divider/>
                                            {/* STATUS */}
                                            <ElementsCustom
                                                t={t}
                                                type={"centripush"}
                                                input_type={"select"}
                                                element_type={"status_individual"}
                                                element={zendesk.info.centripush.individual.status ? <span
                                                    className={`status ${zendesk.info.centripush.individual.status}`}>{t(`elements.custom.status.${zendesk.info.centripush.individual.status}`)}</span> : ''}
                                                elements={getStatusArray()}
                                                updateElement={this.onSelectStatus}
                                            />
                                            <Divider/>
                                            {/* CAMPAIGNS */}
                                            <div className={"elements_custom"}>
                                                <div className={"elements_custom__info"}>
                                                    <span style={{fontWeight: 500, marginTop: 30}}
                                                          className={"elements_custom__title"}>{t('integrations:channels.zendesk_full.centripush.campaign.title')}</span>
                                                    <span
                                                        className={"elements_custom__description"}>{t('integrations:channels.zendesk_full.centripush.campaign.description')}</span>
                                                </div>
                                            </div>
                                            <Divider/>
                                            {/* ACTIVE */}
                                            <ElementsCustom
                                                t={t}
                                                type={"centripush"}
                                                input_type={"switch"}
                                                element_type={"campaign_active"}
                                                element={zendesk.info.centripush.campaign.active}
                                                updateElement={this.updateElement}
                                            />
                                            <Divider/>
                                            {/* TICKET SUBJECT */}
                                            <ElementsCustom
                                                t={t}
                                                type={"centripush"}
                                                input_type={"input"}
                                                element_type={"subject_campaign"}
                                                element={zendesk.info.centripush.campaign.subject}
                                                updateElement={this.updateElement}
                                            />
                                            <Divider/>
                                            {/* TAGS */}
                                            <ElementsCustom
                                                t={t}
                                                type={"centripush"}
                                                input_type={"input_with_tags"}
                                                element_type={"tags_campaign"}
                                                element={settings.tags_campaign}
                                                elements={zendesk.info.centripush.campaign.tags}
                                                updateElement={this.handleTags}
                                            />
                                            <Divider/>
                                            {/* STATUS */}
                                            <ElementsCustom
                                                t={t}
                                                type={"centripush"}
                                                input_type={"select"}
                                                element_type={"status_campaign"}
                                                element={zendesk.info.centripush.campaign.status ? <span
                                                    className={`status ${zendesk.info.centripush.campaign.status}`}>{t(`elements.custom.status.${zendesk.info.centripush.campaign.status}`)}</span> : ''}
                                                elements={getStatusArray()}
                                                updateElement={this.onSelectStatus}
                                            />
                                        </React.Fragment> :
                                        <div className="content">
                                            <div className="info_message">
                                            <span>
                                                {t('integrations:channels.zendesk_full.centripush.no_active')}
                                            </span>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                        {/* DELETE */}
                        <div className={"cen_integrations delete"}>
                            <div className={"integration"}>
                                <div className={"integration_info"}>
                            <span
                                className={"integration_info_title"}>{t('integrations:channels.zendesk_full.delete.title')}</span>
                                    <span
                                        className={"integration_info_description"}>{t('integrations:channels.zendesk_full.delete.description')}</span>
                                </div>
                                <Button type={"normal_delete"}
                                        text={t('integrations:channels.zendesk_full.delete.action_button')}
                                        onClick={() => this.setState({open: true})}/>
                            </div>
                        </div>
                    </React.Fragment>
                }
                <DialogAction t={t} actualLayout={actualLayout.layout} handleAction={this.handleDelete}
                              handleClickOpen={this.handleClickOpen} common open={open} item={{name: ""}}/>
            </React.Fragment>
        );
    }

}


const mapStateToProps = state => {
    return {
        data: state
    }
}

const edit = connect(
    mapStateToProps
)(Edit);
export default withTranslation(['commons', 'common'])(withRouter(edit));