// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Material
import {CircularProgress, Divider} from '@material-ui/core';
// Vendor
import {showNotify, updateLimitsIntegrations} from 'vendor/application/disptach';
// Infrastructure
import {IntegrationsAPI} from 'api/application/Integrations';
// Application
import {getCentrideskBody, returnElementFromArray} from 'vendor/application';
import {ElementsHandleAPI} from 'vendor/application/handleMethods';
// Translations
import {withTranslation} from "react-i18next";
// Components
import {Button} from '@components/Input';
import {ElementsCustom} from "@components/Elements";
// SVG
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";

class Create extends Component {

    constructor(props) {
        super(props);
        this.state = {
            centridesk: getCentrideskBody(),
            integration: false,
            tokenValid: true,
            set_tag: ""
        }
    }

    componentDidMount() {
        this.getIntegrations();
    }

    getIntegrations = async () => {
        const {access, actualProject} = this.props.data;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            const integrations = await obj.get_integrations();
            var exist = returnElementFromArray('centridesk', integrations, 'channel');
            if (exist) {
                this.props.dispatch(showNotify({message: 'centridesk_restriction', severity: 'error'}));
                this.return();
            }
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    updateElement = (e, type, i) => {
        const {centridesk} = this.state;
        const obj = new ElementsHandleAPI(centridesk.info, e.target.value, type, i);
        obj.updateObj();

        this.setState({centridesk: centridesk});
    }

    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations/centridesk`);
    }

    postIntegration = async (e) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {history} = this.props;
        const {centridesk} = this.state;

        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props, centridesk);
            let integration = await obj.post_integration();

            this.props.dispatch(showNotify({message: "integrations", type: "create", severity: "success"}));

            this.props.data.limitsIntegrations.centridesk.existIntegration = true;
            this.props.dispatch(updateLimitsIntegrations(this.props.data.limitsIntegrations));

            history.push(`/${actualProject.id}/integrations/centridesk/edit/${integration.id}`);
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    handleTags = (e, type, elementType, chipToDelete, i) => {
        var {centridesk, set_tag} = this.state;
        var element_to_update;

        switch (elementType) {
            case 'tags':
                element_to_update = centridesk.info.tags;
                break;
        }

        var tag = e.target.value;
        switch (type) {
            case 'add':
                if (tag.match(/^[a-zA-Z0-9_\/]*$/)) {
                    set_tag = tag;
                    this.setState({set_tag: set_tag});
                }
                break;
            case 'delete':
                element_to_update.forEach(el => {
                    if (el === chipToDelete) {
                        element_to_update.splice(i, 1);
                    }
                });
                this.setState({centridesk: centridesk})
                break;
            case 'enter':
                element_to_update.push(tag);
                set_tag = "";
                this.setState({centridesk: centridesk, set_tag: set_tag});
                break;
        }
    }

    render() {
        const {centridesk, set_tag} = this.state;
        const {t} = this.props;
        const {isSending} = this.props.data;
        return (
            centridesk &&
                <div className={"cen_integrations"}>
                    <div className={"header"}>
                        <div className={"header_content"}>
                            <div onClick={() => this.return()} className={"header_action"}>
                                {arrowRightSvg()}
                            </div>
                            <div className={"header_image"}>
                                {channelsSvg("centridesk")}
                            </div>
                            <div className={"header_title"}>
                                <span className={"title"}>{t('integrations:channels.centridesk.title')}</span>
                                <span className={"description"}>{t('integrations:categories.business')}</span>
                            </div>
                        </div>
                        <div className={"header_actions"}>
                            {
                                isSending ? (
                                    <Button type={"primary"} disabled text={<CircularProgress size={21}/>}/>
                                ) : (
                                    <Button type={"primary"}
                                            text={t('integrations:create.action_button')}
                                            onClick={(e) => this.postIntegration(e)}/>
                                )
                            }
                        </div>
                    </div>
                    <div className={"integration"}>
                        {/* ZENDESK EMAIL */}
                        <ElementsCustom
                            t={t}
                            type={"centridesk"}
                            input_type={"input"}
                            element_type={"subject"}
                            element={centridesk.info.subject}
                            updateElement={this.updateElement}
                        />
                        <Divider/>
                        {/* TAGS */}
                        <ElementsCustom
                            t={t}
                            type={"centridesk"}
                            input_type={"input_with_tags"}
                            element_type={"tags"}
                            element={set_tag}
                            elements={centridesk.info.tags}
                            updateElement={this.handleTags}
                        />
                    </div>
                </div>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state
    }
}

const create = connect(
    mapStateToProps
)(Create);

export default withTranslation(['commons', 'common'])(withRouter(create));