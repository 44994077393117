// React
import { connect } from 'react-redux';
import React, { Component } from 'react';
import {Helmet} from "react-helmet";
// Routing
import { withRouter } from 'react-router-dom';
// Material
import { withTranslation } from "react-i18next";
// Styles
import 'views/Root/application/Routes/HelpChat/styles/index.scss';

class HelpChatView extends Component {

    render() {
        return (
            <React.Fragment>
                <div
                    className={`chat-view-content`}
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/centribal_chat.png)`
                    }}
                >
                </div>
                <Helmet>
                        <script id="bim-snippet" src="https://cwcentribot.centribal.com/app/chat?key=39dd85e094074a1ebb4af95210d517f4.4d327a447bc64279ab930e874afd0c8e" type="text/javascript"/>
                </Helmet>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_chat_web = connect(
    mapStateToProps
)(HelpChatView);

export default withTranslation('common')(withRouter(connect_chat_web));
