// React
import React, {Component} from 'react';
import {codeSvg, layoutsSvg} from '@assets';

export class Links extends Component {
    render() {
        const {isSunco, project, handleRedirect, deleted} = this.props;
        return (
            <div className={"dashboard_element links"}>
                <div>
                    {
                        !isSunco && !deleted &&
                        <span
                            onClick={(e) => handleRedirect(e, "share", project)}>{codeSvg()}</span>
                    }
                    <span
                        onClick={(e) => handleRedirect(e, "stats", project)}>{layoutsSvg("stats")}</span>
                    {
                        !deleted &&
                            <span
                                onClick={(e) => handleRedirect(e, "integrations", project)}>{layoutsSvg("integrations")}</span>
                    }
                </div>
            </div>
        );
    }
}
