// React
import React, { Component } from 'react';
// Styles
import 'components/Accordion/styles/styles.scss';
// Components
import Contexts from "../contexts";
import Phrases from "./phrases";
import {Divider} from "@material-ui/core";
import Events from "./events";

class Inputs extends Component {
    render() {
        const {intent} = this.props;
        return (
            <div className={"accordion__content"}>
                <Contexts key={this.props.type} context_type={"input"} {...this.props} />
                <Divider/>
                {
                    !intent.is_default &&
                    <React.Fragment>
                        <Phrases {...this.props} />
                        <Divider/>
                    </React.Fragment>
                }
                <Events {...this.props} />
            </div>
        );
    }
}

export default Inputs;