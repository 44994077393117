// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Material
import {CircularProgress, Divider} from '@material-ui/core';
// Vendor
import {showNotify} from 'vendor/application/disptach';
// Infrastructure
import {IntegrationsAPI} from 'api/application/Integrations';
// Application
import {ElementsHandleAPI} from 'vendor/application/handleMethods';
// Translations
import {withTranslation} from "react-i18next";
// Components
import {Button} from "@components/Input";
import {ElementsCustom} from "@components/Elements";
import DialogAction from "components/Dialog/DialogAction";
// SVG
import {channelsSvg} from "assets";
import {arrowRightSvg} from "@assets";

class Edit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            zendesk: '',
            open: false,
            domain: '.zendesk.com'
        }
    }

    componentDidMount() {
        this.getIntegration();
    }

    getIntegration = async () => {
        const {access, actualProject} = this.props.data;
        try {
            let obj = new IntegrationsAPI(actualProject.id, access, this.props);
            let integration = await obj.get_integration_by_id();
            this.setState({zendesk: integration});
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
            this.return();
        }
    }

    updateElement = (e, type, i) => {
        const {zendesk} = this.state;
        const obj = new ElementsHandleAPI(zendesk.info, e.target.value, type, i);
        obj.updateObj();

        this.setState({zendesk: zendesk});
    }

    putIntegration = async (e) => {
        e.preventDefault();
        const {access, actualProject} = this.props.data;
        const {zendesk, domain} = this.state;

        zendesk.info.subdomain = zendesk.info.subdomain.replace('.zendesk.com', '') + domain;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props, zendesk);
            await obj.put_integration();
            this.props.dispatch(showNotify({message: "integrations", type: "edit", severity: "success"}));
        } catch (err) {
            zendesk.info.subdomain = zendesk.info.subdomain.replace('.zendesk.com', '');
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    handleDelete = async () => {
        const {access, actualProject} = this.props.data;
        const {zendesk} = this.state;
        try {
            const obj = new IntegrationsAPI(actualProject.id, access, this.props);
            await obj.delete_integration(zendesk.id);

            this.props.dispatch(showNotify({message: "integrations", type: "delete", severity: "success"}));
            this.return();
        } catch (err) {
            this.props.dispatch(showNotify({message: err, severity: 'error'}));
        }
    }

    return = () => {
        const {history} = this.props;
        const {actualProject} = this.props.data;
        history.push(`/${actualProject.id}/integrations`);
    }

    render() {
        const {zendesk, open} = this.state;
        const {isSending, actualLayout} = this.props.data;
        const {t} = this.props;
        return (
            <React.Fragment>
                <div className={"cen_integrations"}>
                    <div className={"header"}>
                        <div className={"header_content"}>
                            <div onClick={() => this.return()} className={"header_action"}>
                                {arrowRightSvg()}
                            </div>
                            <div className={"header_image"}>
                                {channelsSvg("zendesk_chat")}
                            </div>
                            <div className={"header_title"}>
                                <span className={"title"}>{t('integrations:channels.zendesk_chat.title')}</span>
                                <span className={"description"}>{t('integrations:categories.business')}</span>
                            </div>
                        </div>
                        <div className={"header_actions"}>
                            {
                                isSending ? (
                                    <Button type={"primary"} disabled text={<CircularProgress size={21}/>}/>
                                ) : (
                                    <Button type={"primary"}
                                            text={t('integrations:save.action_button')}
                                            onClick={(e) => this.putIntegration(e)}/>
                                )
                            }
                        </div>
                    </div>
                    {
                        zendesk
                            ? <div className={"integration"}>
                                {/* SUBDOMAIN */}
                                <ElementsCustom
                                    t={t}
                                    type={"zendesk_chat"}
                                    input_type={"input_zendesk"}
                                    element_type={"subdomain"}
                                    element={zendesk.info.subdomain.replace('.zendesk.com', '')}
                                    updateElement={this.updateElement}
                                />
                                <Divider/>
                                {/* TOKEN */}
                                <ElementsCustom
                                    t={t}
                                    type={"zendesk_chat"}
                                    input_type={"input"}
                                    element_type={"account_key"}
                                    element={zendesk.info.account_key}
                                    updateElement={this.updateElement}
                                />
                            </div>
                            : <div className={"content"}>
                                <div className="loading">
                                    <CircularProgress color="primary" size={50}/>
                                </div>
                            </div>
                    }
                </div>
                {/* DELETE */}
                {
                    zendesk &&
                    <div className={"cen_integrations delete"}>
                        <div className={"integration"}>
                            <div className={"integration_info"}>
                                <span
                                    className={"integration_info_title"}>{t('integrations:channels.zendesk_guide.delete.title')}</span>
                                <span
                                    className={"integration_info_description"}>{t('integrations:channels.zendesk_guide.delete.description')}</span>
                            </div>
                            <Button type={"normal_delete"}
                                    text={t('integrations:channels.zendesk_guide.delete.action_button')}
                                    onClick={() => this.setState({open: true})}/>
                        </div>
                    </div>
                }
                <DialogAction t={t} actualLayout={actualLayout.layout} handleAction={this.handleDelete}
                              handleClickOpen={() => this.setState({open: false})} common open={open}
                              item={{name: ""}}/>
            </React.Fragment>
        );
    }
}


const mapStateToProps = state => {
    return {
        data: state
    }
}

const edit = connect(
    mapStateToProps
)(Edit);

export default withTranslation(['commons', 'common', 'integrations'])(withRouter(edit));