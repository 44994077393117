// React
import React, {Component} from 'react';
import {connect} from 'react-redux';
// Routing
import {withRouter} from "react-router-dom";
// Material UI
import {Divider} from '@material-ui/core';
// Vendor
import {ElementsCustom} from "@components/Elements";
import {Input} from "@components/Input";
// Translations
import {withTranslation} from "react-i18next";
// Components
import {AlertInfo} from "@components/Alert";
// Styles
import 'views/Bots/ChatBot/styles/index.scss';
// Amplitude API
import {campaignsSvg} from "assets";

class Template extends Component {

    render() {
        const {plan, actualLayout} = this.props.data;
        const {
            t,
            handleSelect,
            templates,
            whatsapp_phone,
            updateElement,
            sender_phone,
            template,
            params,
            doRequest
        } = this.props;
        return (
            <div className={"campaigns_container_individual"}>
                <div>
                    <ElementsCustom
                        t={t}
                        type={"webhook"}
                        input_type={"select_with_inputs_code"}
                        element_type={"template"}
                        select_value={"name"}
                        element={template?.id ? template?.name : "-"}
                        elements={templates}
                        updateElement={handleSelect}
                        conditional={
                            {
                                params: params
                            }
                        }
                    />
                    <Divider/>
                    {/* WHATSAPP PHONE */}
                    <div className={"elements_custom column"}>
                        <div className={"elements_custom__info"}>
                                        <span
                                            className={"elements_custom__title"}>{t(`elements.custom.templates.card.whatsapp_phone.title`)} </span>
                            <span
                                className={"elements_custom__description"}>{t(`elements.custom.templates.card.whatsapp_phone.description`)}</span>
                        </div>
                        {
                            plan?.whatsapp_phones &&
                            <div className={"elements_custom__selection"}>
                                {plan?.whatsapp_phones.map((phone, i) => {
                                    return (
                                        <div
                                            className={`${phone.phone === whatsapp_phone.phone ? "active" : ""}`}
                                            onClick={() => handleSelect(phone, "whatsapp_phone")}
                                            key={i}>{phone.phone} - {phone.project_name}</div>
                                    )
                                })}
                            </div>
                        }
                    </div>
                    <Divider/>
                    <ElementsCustom
                        t={t}
                        type={"webhook"}
                        input_type={"input"}
                        element_type={"sender_phone"}
                        element={sender_phone ?? ""}
                        updateElement={updateElement}
                    />
                    {
                        template?.id &&
                        <React.Fragment>
                            <Divider/>
                            {/* HEADER */}
                            <div className={"elements_custom column"}>
                                <div className={"elements_custom__info"}>
                                        <span
                                            className={"elements_custom__title"}>{t(`elements.custom.templates.card.header.title`)}
                                        </span>
                                </div>
                                <div className={"elements_custom__header"}>
                                    <div className={"elements_custom__selection_images"}>
                                        {
                                            ["null", "text", "document", "image"].map((element, i) => {
                                                return (
                                                    <div
                                                        className={`${!template?.header && element === "null" || element === template?.header?.type ? "active" : ""}`}
                                                        key={i}>
                                                        <span className={"checkbox"}/>
                                                        <span className={"image"}>{campaignsSvg(element)}</span>
                                                        <span
                                                            className={"text"}>{t(`elements.custom.templates.card.header.conditional.${element}.selector`)}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {
                                        (template?.header?.type === "document" || template?.header?.type === "image") &&
                                        <Input
                                            placeholder={t(`elements.custom.templates.card.header.conditional.${template.header.type}.placeholder`)}
                                            value={template?.header?.url}
                                            onChange={(e) => updateElement(e, "header_type", template?.header?.type)}
                                        />
                                    }
                                </div>
                            </div>
                            <Divider/>
                            {/* FOOTER */}
                            <div className={"elements_custom"}>
                                <div className={"elements_custom__info"}>
                                        <span
                                            className={"elements_custom__title"}>{t(`elements.custom.templates.card.footer.title`)}
                                        </span>
                                </div>
                                <div className={"platform_input_content"}>
                                    {
                                        template.footer ?
                                            <Input
                                                disabled
                                                placeholder={t(`elements.custom.groups.card.footer.placeholder`)}
                                                value={template.footer}
                                            /> :
                                            <AlertInfo text={t(`elements.custom.groups.card.footer.placeholder`)}/>
                                    }
                                </div>
                            </div>
                            <Divider/>
                            {/* BUTTONS */}
                            <ElementsCustom
                                t={t}
                                type={"webhook"}
                                input_type={"select_with_conditional"}
                                element_type={"buttons"}
                                select_value={""}
                                element={template.buttons?.type}
                                elements={[]}
                                disabled={true}
                                alert={true}
                                conditional={
                                    {
                                        type: "select_with_conditionals",
                                        element_conditional: template.buttons?.type !== null,
                                        elements: [],
                                        element_type: "buttons_type",
                                        element_actions: template.buttons?.buttons,
                                        element_actions_conditional: true,
                                        disabled: true
                                    }
                                }
                                updateElement={updateElement}
                            />
                        </React.Fragment>
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        data: state
    }
}

const connect_component = connect(
    mapStateToProps
)(Template);

export default withTranslation(['commons', 'common'])(withRouter(connect_component));